import request from '@/utils/request'

// 查询设备及报警
export function alarmPageList(data) {
  return request({
    url: '/monitor/alarm/alarmPageList',
    method: 'post',
	data: data
  })
}

// 新增设备
export function addAlarm(data) {
  return request({
    url: '/monitor/alarm/addAlarm',
    method: 'post',
    data: data
  })
}

// 查询设备详细
export function getAlarmById(alarmId) {
  return request({
    url: '/monitor/alarm/getAlarmById?alarmId=' + alarmId,
    method: 'post'
  })
}

// 修改设备
export function updateDevice(data) {
  return request({
    url: '/monitor/alarm/updateAlarm',
    method: 'post',
    data: data
  })
}

// 删除设备
export function delDevice(alarmId) {
  return request({
    url: '/monitor/alarm/delAlarm?alarmId=' + alarmId,
    method: 'post'
  })
}
/* // 查询设备列表
export function listDevice(data) {
  return request({
    url: 'staff/device/selectDeviceList',
    method: 'post',
    data: data
  })
}

// 查询设备详细
export function getDevice(DeviceId) {
  return request({
    url: 'staff/device/selectDeviceById?DeviceId=' + DeviceId,
    method: 'post'
  })
}

// 新增设备
export function addDevice(data) {
  return request({
    url: 'staff/device/insertDevice',
    method: 'post',
    data: data
  })
}

// 修改设备
export function updateDevice(data) {
  return request({
    url: 'staff/device/updateDevice',
    method: 'post',
    data: data
  })
}


// 删除设备
export function delDevice(DeviceId) {
  return request({
    url: 'staff/device/deleteDeviceById?DeviceId=' + DeviceId,
    method: 'post'
  })
}
// 批量删除设备
export function delDevices(data) {
  return request({
    url: 'staff/device/deleteDeviceByIds',
    method: 'post',
    data:data
  })
}

// 校检设备ip
export function checkDeviceIp(data) {
  return request({
    url: 'staff/device/checkIp',
    method: 'post',
    data: data
  })
} */
