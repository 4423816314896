import request from '@/utils/request'

//缴费统计汇总
export function selectAgentPayCount(data) {
  return request({
    url: '/free/devicePay/selectAgentPayCount',
    method: 'post',
	data: data
  })
}

//代理商收费统计
export function selectAgentPayRecordsCount(data) {
  return request({
    url: '/free/devicePay/selectAgentPayRecordsCount',
    method: 'post',
	data: data
  })
}