<template>
  <div class="components-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col :span="24" :xs="24">
        <el-back>
          <div class="formBox">信息搜索</div>
          <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
          >
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="queryParams.deviceName"
                placeholder="请输入设备名称"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="设备类型" prop="deviceType">
              <el-select
                v-model="queryParams.deviceType"
                placeholder="设备类型"
                clearable
                size="small"
                @change="ChangeDeviceType"
                style="width: 240px"
              >
                <el-option
                  v-for="dict in deviceTypeOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="ICCID" prop="iccid">
              <el-input
                v-model="queryParams.iccid"
                placeholder="请输入ICCID"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="设备状态" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="设备状态"
                clearable
                size="small"
                style="width: 240px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.alarmId"
                  :label="dict.alarmType"
                  :value="dict.alarmId"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="IMEI" prop="imei">
              <el-input
                v-model="queryParams.imei"
                placeholder="请输入IMEI"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="房间号" prop="roomNum">
              <el-input
                v-model="queryParams.roomNum"
                placeholder="请输入房间号"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="设备号" prop="deviceCode">
              <el-input
                v-model="queryParams.deviceCode"
                placeholder="请输入设备号"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="dateRange"
                size="small"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="handleQuery"
                >搜索</el-button
              >
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
                >重置</el-button
              >
            </el-form-item>
          </el-form>

          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                v-hasPermi="['smoke:device:add']"
                >新增</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
                v-hasPermi="['smoke:device:edit']"
                >修改</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['smoke:device:remove']"
                >删除</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="info"
                icon="el-icon-upload2"
                size="mini"
                @click="handleImport"
                v-hasPermi="['smoke:device:imp']"
                >导入</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="warning"
                icon="el-icon-download"
                size="mini"
                @click="handleExport"
                v-hasPermi="['smoke:device:export']"
                >导出</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="primary"
                icon="el-icon-setting"
                size="mini"
                @click="handleMoreSet"
                v-if="isDeviceType"
                :disabled="ids.length <= 0"
                >批量设置</el-button
              >
            </el-col>
          </el-row>

          <el-table
            v-loading="loading"
            :data="deviceList"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="编号" align="center" prop="deviceId" />
            <el-table-column
              label="设备名称"
              align="center"
              prop="deviceName"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="产品ID"
              align="center"
              prop="deviceCode"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="ICCID"
              align="center"
              prop="iccid"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="所在楼层"
              align="center"
              class-name="small-padding fixed-width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.buildName
                  }}{{ formatUnitName(scope.row.unitName)
                  }}{{ scope.row.tier }}层{{ scope.row.roomNum }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="安装位置"
              align="center"
              prop="address"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="责任人"
              align="center"
              prop="functionaryName"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="责任人电话"
              align="center"
              prop="phone"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="有效期限"
              align="center"
              prop="indate"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="设备状态"
              align="center"
              prop="statusName"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="操作"
              align="center"
              width="300"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-key"
                  @click="handleDeviceInfo(scope.row)"
                  v-hasPermi="['smoke:device:info']"
                  >设备详情</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  v-hasPermi="['smoke:device:edit']"
                  >修改</el-button
                >
                <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click="handleCheck(scope.row, 1)"
                  v-hasPermi="['smoke:device:self']">远程自检</el-button> -->
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleCheck(scope.row, 2)"
                  v-hasPermi="['smoke:device:self']"
                  >远程复位</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-hasPermi="['smoke:device:remove']"
                  >删除</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  @click="handlePeople(scope.row)"
                  >分配责任人</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  @click="handleChangeDevice(scope.row)"
                  >替换设备</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.size"
            @pagination="getList"
          />
        </el-back>
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="1300px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="form.deviceName"
                placeholder="请输入设备名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品ID" prop="deviceCode">
              <el-input v-model="form.deviceCode" placeholder="请输入产品ID" />
            </el-form-item>
          </el-col>
          <!-- 				<el-col :span="6">
					<el-form-item label="责任电话" prop="phone">
					  <el-input v-model="form.phone" placeholder="请输入责任人电话" />
					</el-form-item>
				</el-col> -->
          <el-col :span="6">
            <el-form-item label="楼栋" prop="buildId">
              <el-select
                v-model="form.buildId"
                placeholder="请输入所属楼栋"
                clearable
                @change="buildChange()"
              >
                <el-option
                  v-for="dict in buildingOptions"
                  :key="dict.buildId"
                  :label="dict.buildName"
                  :value="dict.buildId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单元" prop="unitId">
              <el-select
                v-model="form.unitId"
                placeholder="请输入所属单元"
                clearable
                @change="unitChange()"
              >
                <el-option
                  v-for="dict in unitOptions"
                  :key="dict.unitId"
                  :label="dict.unitName"
                  :value="dict.unitId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="层数" prop="tier">
              <el-select
                v-model="form.tier"
                placeholder="请输入所属层数"
                clearable
                @change="tierChange()"
              >
                <el-option
                  v-for="dict in tierOptions"
                  :key="dict"
                  :label="dict"
                  :value="dict"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="房间" prop="roomId">
              <el-select
                v-model="form.roomId"
                placeholder="请输入所属房间"
                clearable
              >
                <el-option
                  v-for="dict in roomOptions"
                  :key="dict.roomId"
                  :label="dict.roomNum"
                  :value="dict.roomId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="安装位置" prop="address">
              <el-input v-model="form.address" placeholder="请输入安装位置" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="6">
            <el-form-item label="x轴坐标" prop="x">
              <el-input
                v-model="form.x"
                :disabled="true"
                placeholder="请输入x轴坐标"
              />
            </el-form-item>
            <el-form-item label="y轴坐标" prop="y">
              <el-input
                v-model="form.y"
                :disabled="true"
                placeholder="请输入y轴坐标"
              />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <div class="mapBox" @click="mapClick">
              <img :src="dialogImg" alt="" class="bigImg" />
              <img
                src="@/assets/image/deptIcon.png"
                alt=""
                class="smallImg"
                :style="{
                  left: form.x - 20.5 + 'px',
                  top: form.y - 20.5 + 'px'
                }"
              />
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="'替换设备'" :visible.sync="changeOpen" width="700px">
      <el-form ref="form" :model="changeForm" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="旧设备编码" prop="oldDeviceCode">
              <el-input
                v-model="changeForm.oldDeviceCode"
                :disabled="true"
                placeholder="请输入旧设备编码"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="新设备编码" prop="deviceCode">
              <el-input
                v-model="changeForm.deviceCode"
                placeholder="请输入新设备编码"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeSubmitForm">确 定</el-button>
        <el-button @click="changeCancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="infoTitle" :visible.sync="infoOpen" width="850px">
      <div
        style="
                text-align: center;
                margin-bottom: 20px;
                font-size: 18px;
                color: #e3e7f7;
              "
      >
        {{ infoTitle }}
      </div>
      <el-row :gutter="30">
        <el-col :span="12">
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">产品ID</el-col>
              <el-col :span="16">{{ form.deviceCode }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">设备名称</el-col>
              <el-col :span="16">{{ form.deviceName }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">设备型号</el-col>
              <el-col :span="16">{{ form.deviceModel }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">上报时间</el-col>
              <el-col :span="16">{{ form.newDate }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">责任人</el-col>
              <el-col :span="16">{{ form.functionaryName }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">电话</el-col>
              <el-col :span="16">{{ form.phone }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">单位地址</el-col>
              <el-col :span="16">{{ form.address }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">卡状态信息</el-col>
              <el-col :span="16">{{ form.remark }}</el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">设备状态</el-col>
              <el-col :span="16">{{ form.status }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">{{
                concnLabel
              }}</el-col>
              <el-col :span="16">{{ form.concn }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">电池电压</el-col>
              <el-col :span="16">{{ form.voltage }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">电池电量</el-col>
              <el-col :span="16">{{ form.electricQuantity }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">信号强度</el-col>
              <el-col :span="16">{{ form.dbm }}</el-col>
            </el-row>
          </div>
          <div
            style="
                    background-color: rgba(16, 30, 46, 0.2);
                    padding: 10px 0;
                    color: #e3e7f7;
                  "
          >
            <el-row>
              <el-col :span="8" style="text-align: center">deviceCode</el-col>
              <el-col :span="16">{{ form.deviceCode }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #172332; padding: 10px 0; color: #e3e7f7"
          >
            <el-row>
              <el-col :span="8" style="text-align: center">ICCID</el-col>
              <el-col :span="16">{{ form.iccid }}</el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-table
        v-loading="infoLoading"
        :data="recordList"
        style="margin-top: 20px"
      >
        <el-table-column label="编号" align="center" type="index" />
        <el-table-column
          label="报警类型"
          align="center"
          prop="alarmType"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          :label="concnLabel"
          align="center"
          prop="concn"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope" v-if="scope.row.concn !== null">
            <span>{{ scope.row.concn }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="报警时间"
          align="center"
          prop="alarmTime"
          :show-overflow-tooltip="true"
        />
      </el-table>

      <pagination
        v-show="recordTotal > 0"
        :total="recordTotal"
        :page.sync="recordQueryForm.page"
        :limit.sync="recordQueryForm.size"
        @pagination="getAlarmList"
      />
    </el-dialog>
    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px">
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <!-- <el-checkbox v-model="upload.updateSupport" />是否更新已经存在的用户数据 -->
          <el-link
            type="info"
            style="font-size: 12px; color: #34e6fe"
            @click="importTemplate"
            >下载模板</el-link
          >
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xls”或“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 分配责任人 -->
    <el-dialog :title="peopleTitle" :visible.sync="peopleOpen" width="1200px">
      <handlePeople
        v-if="peopleOpen"
        :deviceCode="deviceCode"
        @closePeopleOpen="closePeopleOpen"
      ></handlePeople>
    </el-dialog>
    <!--    批量下发阈值 -->
    <el-dialog title="批量设置阈值" :visible.sync="moreOpen" width="500px">
      <el-form
        ref="moreform"
        :model="moreform"
        label-width="100px"
        style="text-align: center"
      >
        <el-form-item label="服务标识" prop="sendType">
          <el-select
            style="width: 100%"
            v-model="moreform.sendType"
            placeholder="请选择服务标识"
            clearable
            @change="changeMoreSetOptions"
          >
            <el-option
              v-for="dict in moreSetOptions"
              :key="dict.dictLabel"
              :label="dict.dictLabel"
              :value="dict.dictLabel"
            />
          </el-select>
        </el-form-item>
        <div v-for="(item, index) in remarkOptions" :key="index">
          <el-form-item label="参数">
            <div style="text-align: left;color: #fff">
              {{ item.key }}（{{ item.name }}）
            </div>
            <el-input-number
              v-model="item.value"
              controls-position="right"
              :min="0"
              :max="99999"
              style="width: 100%"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="moreOpen = false">取 消</el-button>
        <el-button type="primary" @click="handlerMoreSubmint">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导入结果信息提示 -->
    <el-dialog title="温馨提示" :visible.sync="imResOpen" width="800px">
      <el-table :data="imRestable">
        <el-table-column label="设备编号" prop="deviceCode" />
        <el-table-column label="设别类型" prop="deviceType" />
        <el-table-column label="导入结果" prop="log" />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imResOpen = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    replaceDevice,
    listSmokeDevice,
    getSmokeDevice,
    addSmokeDevice,
    updateSmokeDevice,
    deleteUserSmokeDetectorById,
    deleteUserSmokeDetectorList,
    selfTest,
    exportSmokeDevice,
    importSmokeDevice,
    checkSmokeDetector,
    sendOrderAll,
    resetButton
  } from '@/api/smoke/userSmokeDevice'
  import { listBuildingsData } from '@/api/system/build/index'
  import { listUnitData } from '@/api/system/build/unit'
  import { roomNoPageList } from '@/api/system/build/room'
  import { getDevAlaRecordPageList, getAlarmLabel } from '@/api/alarm/index'
  import { getUserSession } from '@/utils/auth'
  import { downloadExcel } from '@/api/login'
  import { selectDevicePlanList } from '@/api/smoke/plane'
  import handlePeople from './handlePeople'

  import { alarmNoPageList } from '@/api/alarm/index'
  import qs from 'qs'
  export default {
    name: 'userSmokeDevice',

    data() {
      return {
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 用户表格数据
        deviceList: null,
        // 弹出层标题
        title: '',
        infoTitle: '设备详情',
        // 部门树选项
        deptOptions: undefined,
        // 是否显示弹出层
        open: false,
        infoOpen: false,
        // 部门名称
        deptName: undefined,
        // 默认密码
        initPassword: undefined,
        // 日期范围
        dateRange: [],
        // 状态数据字典
        statusOptions: [],
        // 性别状态字典
        sexOptions: [],
        buildingOptions: [],
        unitOptions: [],
        tierOptions: [],
        roomOptions: [],
        // 角色选项
        roleOptions: [],
        // 表单参数
        form: {},
        defaultProps: {
          children: 'children',
          label: 'label'
        },

        // 查询参数
        queryParams: {
          page: 1,
          size: 10,
          deviceName: undefined,
          deviceType: undefined,
          iccid: undefined,
          status: undefined,
          imei: undefined,
          roomNum: undefined,
          deviceCode: undefined
        },
        // 设备状态
        deviceTypeOptions: [],
        // 表单校验
        rules: {
          deviceName: [
            {
              required: true,
              message: '设备名称不能为空',
              trigger: 'blur'
            }
          ],
          deviceCode: [
            {
              required: true,
              message: '产品ID不能为空',
              trigger: 'blur'
            }
          ],
          phone: [
            {
              required: true,
              message: '责任人电话不能为空',
              trigger: 'blur'
            }
          ],
          buildId: [
            {
              required: true,
              message: '所属楼栋不能为空',
              trigger: 'blur'
            }
          ],
          unitId: [
            {
              required: true,
              message: '所属单元不能为空',
              trigger: 'blur'
            }
          ],
          roomId: [
            {
              required: true,
              message: '所属房间不能为空',
              trigger: 'blur'
            }
          ],
          tier: [
            {
              required: true,
              message: '所属楼层不能为空',
              trigger: 'blur'
            }
          ],
          address: [
            {
              required: true,
              message: '安装位置不能为空',
              trigger: 'blur'
            }
          ],
          x: [
            {
              required: true,
              message: 'x轴不能为空',
              trigger: 'blur'
            }
          ],
          y: [
            {
              required: true,
              message: 'y轴不能为空',
              trigger: 'blur'
            }
          ]
        },
        // 用户导入参数
        upload: {
          // 是否显示弹出层（用户导入）
          open: false,
          // 弹出层标题（用户导入）
          title: '',
          // 是否禁用上传
          isUploading: false,
          // 是否更新已经存在的用户数据concnLabel
          updateSupport: 0,
          // 设置上传的请求头部
          headers: {
            Authorization: 'Bearer ' + getUserSession('iffp_jwt')
          },
          // 上传的地址
          url: importSmokeDevice()
        },

        /* 弹窗详情*/
        infoLoading: true,
        recordList: [],
        recordQueryForm: {
          page: 1,
          size: 10,
          deviceCode: undefined,
          beginTime: '',
          endTime: ''
        },
        recordTotal: 0,
        dialogImg: '',
        /* 分配责任人*/
        deviceCode: '',
        peopleOpen: false,
        peopleTitle: '',
        /* 替换设备 */
        changeOpen: false,
        changeForm: {},
        concnLabel: '',
        imResOpen: false,
        imRestable: [],
        isDeviceType: false,
        moreOpen: false,
        moreSetOptions: [],
        remarkOptions: [],
        moreform: {}
      }
    },
    components: {
      handlePeople
    },
    created() {
      this.getList()
      alarmNoPageList({}).then((res) => {
        this.statusOptions = res
      })
      // this.getDicts("sys_normal_disable").then(response => {
      //   this.statusOptions = response;
      // });
      this.getDicts('sys_user_sex').then((response) => {
        this.sexOptions = response
      })
      this.getDicts('sys_device_type').then((response) => {
        this.deviceTypeOptions = response
      })
      selectDevicePlanList({}).then((res) => {
        this.dialogImg = this.$viewIp + res.queryResult.list[0].pic
      })
      // this.getConfigKey("sys.user.initPassword").then(response => {
      //   this.initPassword = response;
      // });
    },
    methods: {
      changeSubmitForm() {
        if (!this.changeForm.deviceCode) {
          this.msgError('请输入新的设备编码')
          return
        }
        replaceDevice(qs.stringify(this.changeForm)).then((response) => {
          if (response.code === 10000) {
            this.msgSuccess('操作成功')
            this.changeOpen = false
            this.getList()
          } else {
            this.msgError(response.msg)
          }
        })
      },

      changeCancel() {
        this.changeOpen = false
      },
      /* 分配责任人*/
      handlePeople(row) {
        this.deviceCode = row.deviceCode
        this.peopleOpen = true
        this.peopleTitle = '分配责任人'
      },
      /* 替换设备 */
      handleChangeDevice(row) {
        this.changeOpen = true
        this.changeForm = {
          deviceCode: undefined,
          oldDeviceCode: row.deviceCode
        }
      },
      closePeopleOpen(val) {
        this.peopleOpen = val
      },
      mapClick(e) {
        if (e.target.className == 'bigImg') {
          this.form.x = e.offsetX
          this.form.y = e.offsetY
        }
      },
      /** 查询用户列表 */
      getList() {
        this.loading = true
        listSmokeDevice(
          this.addDateRange(this.queryParams, this.dateRange)
        ).then((response) => {
          this.deviceList = response.queryResult.list
          this.total = response.queryResult.total
          this.loading = false
        })
      },
      /** 查询楼栋 */
      getBuild() {
        listBuildingsData().then((response) => {
          this.buildingOptions = response
        })
      },
      getRoom() {
        let room = {
          buildId: this.form.buildId,
          unitId: this.form.unitId,
          tier: this.form.tier
        }
        roomNoPageList(room).then((response) => {
          this.roomOptions = response
        })
      },
      buildChange() {
        this.form.unitId = undefined
        this.form.tier = undefined
        this.form.roomId = undefined
        this.unitOptions = []
        this.tierOptions = []
        this.roomOptions = []
        let unitData = { buildId: this.form.buildId }
        //获取所有的单元
        listUnitData(unitData).then((res) => {
          this.unitOptions = res
        })
      },
      unitChange() {
        let tier = undefined
        this.form.tier = undefined
        this.form.roomId = undefined
        this.tierOptions = []
        this.roomOptions = []
        this.unitOptions.forEach((item, index) => {
          if (item.unitId == this.form.unitId) {
            tier = item.tier
          }
        })
        for (let i = 1; i <= parseInt(tier); i++) {
          this.tierOptions.push(i)
        }
      },
      tierChange() {
        this.form.roomId = undefined
        // this.roomOptions = []
        this.getRoom()
      },
      // 取消按钮
      cancel() {
        this.open = false
        this.reset()
      },
      // 表单重置
      reset() {
        this.form = {
          deviceModel: undefined,
          deviceName: undefined,
          functionaryName: undefined,
          phone: undefined,
          address: undefined,
          buildId: undefined,
          unitId: undefined,
          tier: undefined,
          roomId: undefined,
          x: undefined,
          y: undefined,
          remark: '1'
        }
        this.unitOptions = []
        this.tierOptions = []
        this.roomOptions = []
        this.resetForm('form')
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.page = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = []
        this.resetForm('queryForm')
        this.handleQuery()
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map((item) => item.deviceId)
        this.single = selection.length != 1
        this.multiple = !selection.length
      },
      /** 新增按钮操作 */
      handleAdd() {
        this.reset()
        this.getBuild()

        this.open = true
        this.title = '添加设备'
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset()
        this.getBuild()
        const deviceId = row.deviceId || this.ids[0]
        getSmokeDevice(deviceId).then((response) => {
          this.form = response

          let unitData = { buildId: this.form.buildId }
          //获取所有的单元
          listUnitData(unitData).then((res) => {
            this.unitOptions = res

            let tier = undefined
            this.unitOptions.forEach((item, index) => {
              if (item.unitId == this.form.unitId) {
                tier = item.tier
              }
            })
            for (let i = 1; i <= parseInt(tier); i++) {
              this.tierOptions.push(i)
            }
          })

          this.getRoom()
          this.open = true
          this.title = '修改设备'
        })
      },
      /* 远程自检 */
      handleCheck(row, type) {
        console.log(row)
        this.$confirm(
          `是否确认对该设备进行${type == 1 ? '远程自检' : '远程复位'}?`,
          '温馨提示',
          {
            customClass: 'deleteBox',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(function() {
            if (type == 1) {
              return selfTest(row.deviceCode)
            }
            if (type == 2) {
              return resetButton(row.deviceCode)
            }
          })
          .then((response) => {
            this.getList()
            this.msgSuccess(response.message)
          })
          .catch(function() {})
      },
      /** 设备详情 */
      handleDeviceInfo(row) {
        this.recordQueryForm.deviceCode = row.deviceCode
        console.log(row)
        let deviceType = this.deviceTypeOptions.filter((item) => {
          return item.dictValue === row.deviceType
        })[0].dictLabel
        getAlarmLabel(deviceType).then((response) => {
          if (response.length > 0) {
            this.concnLabel = response[0].dictLabel
          }
        })

        getSmokeDevice(row.deviceId).then((response) => {
          this.form = response
          this.infoOpen = true
        })
        this.getAlarmList()
      },
      getAlarmList() {
        this.infoLoading = true
        getDevAlaRecordPageList(this.recordQueryForm).then((response) => {
          this.recordList = response.queryResult.list
          this.recordTotal = response.queryResult.total
          this.infoLoading = false
        })
      },
      /** 提交按钮 */
      submitForm: function() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.form.deviceId != undefined) {
              updateSmokeDevice(this.form).then((response) => {
                if (response.code === 10000) {
                  this.msgSuccess('修改成功')
                  this.open = false
                  this.getList()
                } else {
                  this.msgError(response.msg)
                }
              })
            } else {
              addSmokeDevice(this.form).then((response) => {
                if (response.code === 10000) {
                  this.msgSuccess('新增成功')
                  this.open = false
                  this.getList()
                } else {
                  this.msgError(response.msg)
                }
              })
            }
          }
        })
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        if (row.deviceId) {
          var deviceIds = [row.deviceId]
        } else {
          var deviceIds = this.ids
        }
        this.$confirm(
          '是否确认删除用户编号为"' + deviceIds + '"的数据项?',
          '警告',
          {
            customClass: 'deleteBox',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(function() {
            if (row.deviceId) {
              return deleteUserSmokeDetectorById(deviceIds[0])
            } else {
              return deleteUserSmokeDetectorList(deviceIds)
            }
          })
          .then(() => {
            this.getList()
            this.msgSuccess('删除成功')
          })
          .catch(function() {})
      },
      /** 导出按钮操作 */
      handleExport() {
        let that = this
        this.$confirm('是否确认导出所有数据项?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'deleteBox',
          type: 'warning'
        })
          .then(function() {
            return exportSmokeDevice(that.queryParams)
          })
          .then((response) => {
            let blob = new Blob([response], {
              type: 'application/vnd.ms-excel'
            }) //type是文件类，详情可以参阅blob文件类型
            let objectUrl = URL.createObjectURL(blob) //生成下载链接
            window.location.href = objectUrl
          })
          .catch(function() {})
      },
      /** 导入按钮操作 */
      handleImport() {
        this.upload.title = '设备导入'
        this.upload.open = true
      },
      /** 下载模板操作 */
      importTemplate() {
        downloadExcel('用户设备信息批量导入模板').then((response) => {
          let blob = new Blob([response], {
            type: 'application/vnd.ms-excel'
          }) //type是文件类，详情可以参阅blob文件类型
          let objectUrl = URL.createObjectURL(blob) //生成下载链接
          window.location.href = objectUrl
        })
      },
      // 文件上传中处理
      handleFileUploadProgress(event, file, fileList) {
        this.upload.isUploading = true
      },
      // 文件上传成功处理
      handleFileSuccess(response, file, fileList) {
        this.upload.open = false
        this.upload.isUploading = false
        this.$refs.upload.clearFiles()
        if (response.code === 10000 && response.object.length > 0) {
          this.imRestable = response.object
          this.imResOpen = true
        } else {
          this.msgInfo(response.message)
        }
        this.getList()
      },
      // 提交上传文件
      submitFileForm() {
        this.$refs.upload.submit()
      },
      /**
       * @description 批量设置
       */
      handleMoreSet() {
        this.moreOpen = true
      },
      /**
       * @description 是否可以批量下发
       * @param val
       * @constructor
       */
      ChangeDeviceType(val) {
        checkSmokeDetector({ deviceType: val }).then((res) => {
          if (res.code === 10000) {
            this.isDeviceType = res.queryResult.list.length > 0
            this.moreSetOptions = res.queryResult.list
            this.moreform.deviceType = val
          } else {
            this.msgError(res.msg)
            this.isDeviceType = false
          }
        })
      },
      /**
       * @description 服务标识
       * @param val
       * @constructor
       */
      changeMoreSetOptions(val) {
        this.remarkOptions = []
        let changeMoreVal = this.moreSetOptions.filter((item) => {
          if (item.dictLabel === val) {
            return item
          }
        })[0]
        let remark = JSON.parse(changeMoreVal.remark)
        remark.forEach((item) => {
          let key = Object.keys(item)[0]
          this.remarkOptions.push({
            name: item[key],
            key,
            value: ''
          })
        })
        console.log(this.remarkOptions, this.remarkOptions)
      },
      /**
       * @description 批量下发
       */
      handlerMoreSubmint() {
        this.moreform.deviceIds = this.ids.join(',')
        this.moreform.remark = JSON.stringify(this.remarkOptions)
        sendOrderAll(this.moreform).then((res) => {
          if (res.code === 10000) {
            this.msgSuccess('操作成功')
            this.isDeviceType = false
          } else {
            this.msgError(res.msg)
          }
        })
      },
      /**
       * @description: 单元名称格式化
       */
      formatUnitName(name) {
        if (name.includes('单元')) {
          return name
        } else {
          return `${name}单元`
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .mapBox {
    width: 100%;
    height: 548px;
    position: relative;
    overflow: hidden;

    .bigImg {
      height: 548px;
      width: 100%;
    }

    .smallImg {
      position: absolute;
      width: 41px;
      height: 41px;
      left: -41px;
      right: -41px;
    }
  }
</style>
