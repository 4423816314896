<template>
  <div class="">

		<div class="formBox">信息搜索</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true">
		      <el-form-item label="接收者姓名" prop="username">
		        <el-input
		          v-model="queryParams.username"
		          placeholder="请输入接收者姓名"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="handleQuery"
		        />
		      </el-form-item>
		      <el-form-item label="手机号" prop="phone">
		        <el-input
		          v-model="queryParams.phone"
		          placeholder="请输入手机号"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="handleQuery"
		        />
		      </el-form-item>
			  <el-form-item label="状态" prop="status">
				<el-select
				  v-model="queryParams.status"
				  placeholder="接收者状态"
				  clearable
				  size="small"
				  style="width: 240px"
				>
				  <el-option
					v-for="dict in statusOptions"
					:key="dict.dictValue"
					:label="dict.dictLabel"
					:value="dict.dictValue"
				  />
				</el-select>
			  </el-form-item>
		      <el-form-item>
		        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
		        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
		      </el-form-item>
		    </el-form>
		
		    <el-row :gutter="10" class="mb8">
		      <el-col :span="1.5">
		        <el-button
		          type="primary"
		          icon="el-icon-plus"
		          size="mini"
		          @click="handleAdd"
				  v-hasPermi="['system:revevier:add']"
		        >新增</el-button>
		      </el-col>
		    </el-row>
		
		    <el-table v-loading="loading" :data="receiverList">
		      <el-table-column label="编号" align="center" type="index" />
		      <el-table-column label="接收人姓名" prop="username"  align="center" :show-overflow-tooltip="true"/>
		      <el-table-column label="接收人手机号" prop="phone"  align="center" />
			  <el-table-column label="状态" prop="status" align="center" />
			  <el-table-column label="创建人" prop="createBy" align="center" />
		      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
		        <template slot-scope="scope">
		          <span>{{ parseTime(scope.row.createTime) }}</span>
		        </template>
		      </el-table-column>
			            <el-table-column label="更新时间" align="center" prop="updateTime" width="180">
			    <template slot-scope="scope">
			      <span>{{ parseTime(scope.row.updateTime) }}</span>
			    </template>
			  </el-table-column>
		      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="220">
		        <template slot-scope="scope">
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-edit"
		            @click="handleUpdate(scope.row)"
					v-hasPermi="['system:revevier:edit']"
		          >修改</el-button>
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-delete"
		            @click="handleDelete(scope.row)"
					v-hasPermi="['system:revevier:remove']"
		          >删除</el-button>
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-key"
		            @click="handleSafe(scope.row)"
					v-hasPermi="['system:revevier:alarm']"
		          >分配警告</el-button>
		        </template>
		      </el-table-column>
		    </el-table>
		
		   <pagination
		      v-show="total>0"
		      :total="total"
		      :page.sync="queryParams.pageNum"
		      :limit.sync="queryParams.pageSize"
		      @pagination="getList"
		    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-form-item label="姓名" prop="username">
          <el-input v-model="form.username" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
          <el-radio
            v-for="dict in statusOptions"
            :key="dict.dictValue"
            :label="dict.dictValue"
          >{{dict.dictLabel}}</el-radio>
          </el-radio-group>
        </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 分配警告-->
    <el-dialog :title="safeTitle" :visible.sync="safeOpen" width="800px">
		<div>
			<ul>
				<li v-for="item in allotList" :key="item.dictValue" class="menuItem" :class="{activeItem:activeItem==item.dictValue}" @click="getTabIndex(item)">
						{{ item.dictLabel }}
				</li>
			</ul>
		</div>
		<el-table v-loading="loading" :data="allsafeList">
		     <el-table-column label="编号" type="index" align="center" />
			 <el-table-column label="报警种类" prop="alarmType"  align="center" />
		     <el-table-column label="查看分配" align="center" >
		       <template slot-scope="scope">
		         <el-switch
		           v-model="scope.row.receiverId"
		           active-value="1"
		           inactive-value="0"
		           @change="handleStatusChange(scope.row)"
		         ></el-switch>
		       </template>
		     </el-table-column>
		   </el-table>
		   <div slot="footer" class="dialog-footer">
		     <el-button type="primary" @click="handleStatus">确 定</el-button>
		   </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectReceiverList,
		 insertReceiver,
		 updateReceiver,
		 deleteSmsById,
		 deleteSmsByIds,
		 checkPhone,
		 selectReceiverById,
		 selectNotRecAlaList,
		 updateNotRecAlaList
	   } from "@/api/system/receiver/actions.dev";
export default {
  name: "receiver",
  data() {
      var checkReceiverPhone = (rule, value, callback) => {
        let phone = {phone:value};
        if(this.title == '添加接收者'){
          checkPhone(phone).then(res =>{
              if(res == 0){
                callback();
              }else{
               callback(new Error('用户手机号已添加，请重新输入'));
              }
            })
        }else{
          callback();
        }
    };
    return {
		tabVal: 0,
      // 遮罩层
      loading: true,
	  // 选中数组
	  ids: [],
	  // 非单个禁用
	  single: true,
	  // 非多个禁用
	  multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      receiverList: [],
	  allsafeList: [],
      // 弹出层标题
      title: "",
      safeTitle:'',
      // 是否显示弹出层
      open: false,
      safeOpen:false,
      // 状态数据字典
      statusOptions: [],
	  allotList: [],
      /* 查询所有的安全警告*/
      allSafe:[],
     
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
		receiverId: undefined,
        username: undefined,
        phone: undefined,
        status: "",
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        username: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: "请输入正确的手机号码", trigger: "blur"},
          // { required: true,validator: checkReceiverPhone, trigger: 'blur'}
        ],
		status: [
		  { required: true, message: "状态必选", trigger: "blur" }
		],
      },
      receiverId: undefined,
	  activeItem:''
    };
  },
  created() {
    this.getList();
    this.getDicts("notice_receiver_status").then(response => {
      this.statusOptions = response;
    });
	this.getDicts("sys_device_type").then(response => {
		response.forEach((val,index) =>{
			val.deviceType = val.dictValue
		  });
	  this.allotList = response;
	  this.activeItem = response[0].dictValue
	});

  },
  computed:{
  	active(){
  		return this.$store.state.permission.nowTopActive
  	}
  },
  methods: {
	  getTabIndex(val){
	  		  this.activeItem = val.dictValue
	  		  this.handleSafe();
	  },
    handleSafe(row){
		/* let that = this
      this.allSafe = [] */
      this.allsafeList = []
	  if(row)  
	  this.receiverId = row.receiverId	  
      let receiverId = {
		  receiverId: this.receiverId,
		  deviceType: this.activeItem
	  }
      selectNotRecAlaList(receiverId).then(res => {
		  this.safeOpen = true
          if(res){
            res.forEach((val,index) =>{	
				
            	if(val.receiverId !== 0){
            	  val.receiverId = 1
            	}
				val.receiverId = String(val.receiverId)
            })
			this.allsafeList = res;
          }
        })
    },
	handleStatusChange(row) {
		
	},
    //分配安全警告给接收者
	handleStatus(row) {
	  /* 先生成传递数据*/
	  let vdata = []
	  this.allsafeList.forEach((val1,index1) =>{
	  		  if(val1.receiverId == 1)
	    vdata.push({typeValue:val1.typeValue,receiverId:this.receiverId,deviceType:this.activeItem})
	  })
	  updateNotRecAlaList(vdata).then(res=>{
	      if (res.code === 10000) {
	        this.msgSuccess("分配成功");
			this.safeOpen = false
	      } else {
	        this.msgError(res.msg);
	      }
	  })
	},
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      selectReceiverList(this.queryParams).then(
        response => {
          this.receiverList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset() {
      this.form = {
		receiverId: undefined,
        username: undefined,
        phone: undefined,
        status: "",
        params:{}
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加接收者";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const receiverIds = row.receiverId || this.ids[0]
      // this.$nextTick(() => {
      //   this.getRoleMenuTreeselect(roleId);
      // });
      selectReceiverById(receiverIds).then(response => {
        this.form = response;
        this.open = true;
        this.title = "修改接收人";
      });
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.receiverId != undefined) {
            updateReceiver(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            insertReceiver(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const receiverIds = row.receiverId || this.ids
      this.$confirm('是否确认删除编号为"' + receiverIds + '"的数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if(row.receiverId){
            return deleteSmsById(receiverIds);
          }else{
            return deleteSmsByIds(this.ids);
          }
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
	
  }
};
</script>
<style scoped lang="scss">
	ul{
		margin: 20px auto;
		width: max-content;
		.menuItem{
			background-image: url('../../../assets/image/allsafeItem.png');
			background-size: 100% 100%;
			margin-left: 50px;
			width: 155px;
			height: 67px;
			text-align: center;
			line-height: 67px;
			display: inline-block;
			*display: inline;
			*zoom: 1;
			color: $weakColor;
			cursor: pointer;
			&:nth-child(1){
				margin-left: 0;
			}
		}
		.activeItem{
			background-image: url('../../../assets/image/allsafeItemActive.png');
			color: $resetColor;
		}
	}
</style>