import request from '@/utils/request'

//已分配列表
export function selectUserDeviceList(data) {
  return request({
    url: '/monitor/device/selectUserDeviceList',
    method: 'post',
	data: data
  })
}

//未分配列表
export function selectUserSmokeDetectorList(data) {
  return request({
    url: '/monitor/device/selectUserSmokeDetectorList',
    method: 'post',
	data: data
  })
}

//查询指定设备续费套餐
export function selectRankPayTypeList(data) {
  return request({
    url: '/free/rankPay/selectRankPayTypeList',
    method: 'post',
	data: data
  })
}

//查询商场支付套餐内容
export function selectRankPayTypeById(infoId) {
  return request({
    url: '/free/rankPay/selectRankPayTypeById?infoId=' + infoId,
    method: 'post'
  })
}

//线下缴费
export function selectPayTypeList(data) {
  return request({
    url: '/free/pay/selectPayTypeList',
    method: 'post',
	data: data
  })
}

//赠送时长+线下缴费
export function updateRankDevicePay(deviceCode,month,cost,remark) {
  return request({
    url: '/free/rankDevicePay/updateRankDevicePay?deviceCode=' + deviceCode + '&month=' + month + '&cost=' + cost + '&remark=' + remark,
    method: 'post'
  })
}

//更换设备使用人
export function updateUserDevice(oldUserId,newUserId,deviceCode) {
  return request({
    url: '/monitor/device/updateUserDevice?oldUserId=' + oldUserId + '&newUserId=' + newUserId + '&deviceCode=' + deviceCode,
    method: 'post'
  })
}

//删除商场用户设备关联
export function deleteUserDevice(data) {
  return request({
    url: '/monitor/device/deleteUserDevice',
    method: 'post',
	data: data
  })
}

//根据部门id查询分管用户
export function selectDeptRankUser(data) {
  return request({
    url: '/ucenter/user/selectDeptRankUser',
    method: 'post',
	data: data
  })
}

//分配设备使用人
export function insertUserDevice(data) {
  return request({
    url: '/monitor/device/insertUserDevice',
    method: 'post',
	data: data
  })
}