<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<div class="formBox">信息搜索</div>
				<build v-if="tabVal == 1"></build>
				<unit v-if="tabVal == 2"></unit>	
				<room v-if="tabVal == 3"></room>
			</el-back>
  </div>
</template>

<script>

import build from './build'
import unit from './unit'
import room from './room'
export default {
  name: "erection",
  data(){
	  return {
		tabList:[
			{ id:1,name:'楼栋管理' },
			{ id:2,name:'单元管理' },
			{ id:3,name:'房间管理' }
		],
		 tabVal:1
	  }
  },
  components:{
	  build,
	  unit,
	  room
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
