<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<div class="formBox">信息搜索</div>
				<deviceRenew v-if="tabVal == 1"></deviceRenew>
				<payment v-if="tabVal == 2"></payment>	

			</el-back>
  </div>
</template>

<script>

import deviceRenew from './deviceRenew'
import payment from './payment'

export default {
  name: "renew",
  data(){
	  return {
		tabList:[
			{ id:1,name:'设备续费' },
			{ id:2,name:'我的缴费记录' }
		],
		 tabVal:1
	  }
  },
  components:{
	  deviceRenew,
	  payment
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
