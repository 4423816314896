import request from '@/utils/request'

// 查询角色列表
export function listRole(data) {
  return request({
    url: 'ucenter/role/selectRole.do',
    method: 'post',
    data: data
  })
}
// 查询所有角色列表
export function listAllRole(deptId) {
  return request({
    url: '/ucenter/sysDeptRole/getSysDeptRoleByDeptId?deptId=' + deptId,
    method: 'post'
  })
}
// 查询角色详细
export function getRole(roleId) {
  return request({
    url: 'ucenter/role/findRoleInfo.do?roleId=' + roleId,
    method: 'post'
  })
}

// 新增角色
export function addRole(data) {
  return request({
    url: 'ucenter/role/saveRole.do',
    method: 'post',
    data: data
  })
}

// 修改角色
export function updateRole(data) {
  return request({
    url: 'ucenter/role/updateRole.do',
    method: 'post',
    data: data
  })
}

// 角色数据权限
export function dataScope(data) {
  return request({
    url: '/system/role/dataScope',
    method: 'put',
    data: data
  })
}

// 角色状态修改
export function changeRoleStatus(roleId, status) {
  const data = {
    roleId,
    status
  }
  return request({
    url: '/system/role/changeStatus',
    method: 'put',
    data: data
  })
}

// 删除角色
export function delRole(roleId) {
  return request({
    url: 'ucenter/role/deleteRole.do?roleId=' + roleId,
    method: 'post'
  })
}

// 导出角色
export function exportRole(query) {
  return request({
    url: '/system/role/export',
    method: 'get',
    params: query
  })
}
// 校检角色name
export function checkRoleName(data) {
  return request({
    url: 'ucenter/role/checkRoleNameUnique.do',
    method: 'post',
    data: data
  })
}
// 校检角色key
export function checkRoleKey(data) {
  return request({
    url: 'ucenter/role/checkRoleKeyUnique.do',
    method: 'post',
    data: data
  })
}
