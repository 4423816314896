export const alarmRoutes = [{
    path: '/',
    component: () => import('@/views/home/page/home.vue'),
    hidden: true,
    children: [{
      path: '/alarm',
      component: () => import('@/views/alarm/index.vue'),
      name: '报警记录',
    }]
  },
]
