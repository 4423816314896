import request from '@/utils/request'


// 查询通知接受者列表
export function selectReceiverList(data) {
  return request({
    url: '/monitor/receiver/selectReceiverList',
    method: 'post',
    data: data
  })
}

// 新增通知接受者
export function insertReceiver(data) {
  return request({
    url: '/monitor/receiver/insertReceiver',
    method: 'post',
    data: data
  })
}

// 修改通知接受者
export function updateReceiver(data) {
  return request({
    url: '/monitor/receiver/updateReceiver',
    method: 'post',
    data: data
  })
}
// 根据id查询通知接收者列表
export function selectReceiverById(receiverId) {
  return request({
    url: '/monitor/receiver/selectReceiverById?receiverId=' + receiverId,
    method: 'post'
  })
}

// 根据id删除通知接收者
export function deleteSmsById(receiverId) {
  return request({
    url: '/monitor/receiver/deleteSmsById?receiverId=' + receiverId,
    method: 'delete'
  })
}

// 批量删除通知接收者
export function deleteSmsByIds(receiverIds) {
  return request({
    url: '/monitor/receiver/deleteSmsByIds',
    method: 'delete',
    data:receiverIds
  })
}

// 校检接收人手机号
export function checkPhone(data) {
  return request({
    url: '/monitor/receiver/checkPhone',
    method: 'post',
    data: data
  })
}

// 产看接受人与安全警告的关系
export function selectNotRecAlaList(data) {
  return request({
    url: '/monitor/notRecAla/selectNotRecAlaList',
    method: 'post',
	data: data

  })
}
// 绑定接收人和事件关系
export function updateNotRecAlaList(data) {
  return request({
    url: '/monitor/notRecAla/updateNotRecAlaList',
    method: 'post',
	data: data

  })
}
// 修改短信接收人状态
/* export function changeUserStatus(receiverId,status) {
    const data = {
    receiverId,
    status
  }
  return request({
    url: 'monitor/receiver/changeStatus',
    method: 'post',
    data: data
  })
} */
