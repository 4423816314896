import request from '@/utils/request'

// 登录方法
export function alarmEchartsAll(data) {
  return request({
    url: '/monitor/devAlaRecord/alarmAnalyEcharts',
    method: 'post',
	data:data
  })
}

