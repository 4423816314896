<template>
  <div class="components-container">		
			<el-back>
				<!-- <tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button> -->
				<phoneReceiver ></phoneReceiver>
				<!-- <noteReceiver v-if="tabVal == 2"></noteReceiver>	 -->

			</el-back>
  </div>
</template>

<script>

import phoneReceiver from './phoneReceiver'
import noteReceiver from './noteReceiver'

export default {
  name: "receiver",
  data(){
	  return {
		tabList:[
			{ id:1,name:'电话接收人' },
			{ id:2,name:'短信接收人' }
		],
		 tabVal:1
	  }
  },
  components:{
	  phoneReceiver,
	  noteReceiver
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
