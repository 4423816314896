<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
		<router-view />
		
 <!--     <keep-alive  :include="cachedViews">     
      </keep-alive> -->
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',

  computed: {
    // cachedViews() {
    //   return this.$store.state.tagsView.cachedViews
    // },
	key() {
	  return this.$route.path
	}
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  height: calc(100% - 183px);
  width: 100%;
  position: relative;
	overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 103px);
  }

  .fixed-header+.app-main {
    padding-top: 103px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
