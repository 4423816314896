<template>
  <div class="menu-wrapper">
    <template v-if="hasOneShowingChild(item.children,item)">
      <app-link :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)">
          <item  :title="onlyOneChild.menuName" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <item :title="item.menuName" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
// import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  // mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {

      const showingChildren = children.some(item => {

        if(item.children == undefined){
          return false
        }else{
          return item.children.length != 0
        }

        // if (item.children.length != 0) {
        //   return false
        // } else {
        //   // Temp set(will be used if only has one showing child)
        //   this.onlyOneChild = item
        //   return true
        // }
      })

      if(showingChildren){
        return false
      }else{
        if(children.length == 1 ){
          this.onlyOneChild = children[0];
          return true
        }else if(children.length == 0){
          this.onlyOneChild = { ... parent};
          return true
        }else{
          return false
        }
      }
//       // When there is only one child router, the child router is displayed by default
//       if (showingChildren.length === 1) {
//         return true
//       }
//
//       // Show parent if there are no child router to display
//       if (showingChildren.length === 0) {
//         this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
//         return true
//       }
//
//       return false
    },
    resolvePath(routePath) {

      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
