import Cookies from 'js-cookie'
// import { getPosition } from '@/api/position.js'

const position = {
  state: {
    positionInfo: '',
    build:{}
  },

  mutations: {

    SET_POSI: (state, data) => {
      state.positionInfo = data
    },
    SET_BUILD:(state, data) =>{
      state.build = data
    }

  },

  actions: {
          // 获取位置
      getPositions({ commit, state }) {
        return new Promise((resolve, reject) => {
          getPosition().then(res => {
            commit('SET_POSI', res.content)
            resolve(res.content)
          }).catch(error => {
            reject(error)
          })
        })
      },

    }
}

export default position
