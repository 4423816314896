import request from '@/utils/request'

// 查询短信列表
export function listNote(page,size,data) {
  return request({
    url: 'monitor/sms/selectsmslist?page=' + page +'&size='+size,
    method: 'post',
    data: data
  })
}

// 查询短信详细
export function getNote(smsId) {
  return request({
    url: '/monitor/sms/selectSmsById?smsId=' + smsId,
    method: 'post'
  })
}

// 新增短信
export function addNote(data) {
  return request({
    url: '/monitor/sms/insertsms',
    method: 'post',
    data: data
  })
}

// 修改短信
export function updateNote(data) {
  return request({
    url: '/monitor/sms/updatesms',
    method: 'post',
    data: data
  })
}


// 删除短信
export function delNote(smsId) {
  return request({
    url: '/monitor/sms/deletesms?smsId=' + smsId,
    method: 'post'
  })
}
// 批量删除短信
export function delNotes(smsIds) {
  return request({
    url: 'monitor/sms/deleteSmsByIds',
    method: 'delete',
    data:smsIds
  })
}
// 导出短信
export function exportRole(query) {
  return request({
    url: '/system/role/export',
    method: 'get',
    params: query
  })
}
// 校检短信类型
export function checkSms(data) {
  return request({
    url: '/monitor/sms/checkSms',
    method: 'post',
    data: data
  })
}
