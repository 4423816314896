<template>






</template>

<script>
import { checkLocalRsaDept } from "@/api/system/timeliness";
import { setUserSession,getUserSession } from '@/utils/auth'

  export default {
    name: 'projectEndTime',
    data() {
      return {
        // 遮罩层
        loading: true,
        //条数
        total: 0,
        // 角色表格数据
        DataList: [],
        // 弹出层标题
        title: "项目服务到期列表",
        // 是否显示弹出层
        open: false,

      }
    },
    created() {
      let flag = getUserSession('projectEndDialog')
      if(getUserSession('projectEndDialog') == null){
        checkLocalRsaDept().then(res => {
			if(res.code == 23012){
				this.msgSuccess(res.message)  
			}         

			setUserSession( 'projectEndDialog',true )
          
        });
      }

    },
    methods: {


    }
  };
</script>

<style>

</style>
