<template>
	<div class="el-back padding-20">
		<div class="leftTop"></div>
		<div class="rightTop"></div>
		<div class="leftBottom"></div>
		<div class="rightBottom"></div>
		<el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbar-wrapper">		
			<slot />		
		</el-scrollbar>
		
	</div>
</template>

<script>

	export default {
		name:'el-back',
		data(){
			return {
				
			}
		},

	}
</script>

<style scoped lang="scss">
	.el-back{
		height: 100%;		
		border: 1px solid rgba(52, 228, 252, 0.2);
		background-color: #1E2A3A;
		position: relative;
		overflow-x: hidden;
		.scrollbar-wrapper{
			height: 100%;
			/deep/ .el-scrollbar__wrap {overflow-x: hidden;}
			/deep/ .el-scrollbar__view{
				width: 99.6%;
			}
		}
		.leftTop{
			width: 16px;
			height: 16px;
			border: 1px solid;
			border-color: #0DA8EB transparent transparent #0DA8EB; 
			position: absolute;
			left: 0;
			top: 0;
			z-index: 100;
		}
		.rightTop{
			width: 16px;
			height: 16px;
			border: 1px solid;
			border-color: #0DA8EB #0DA8EB transparent transparent ; 
			position: absolute;
			right: 0;
			top: 0;
			z-index: 100;
		}
		.leftBottom{
			width: 16px;
			height: 16px;
			border: 1px solid;
			border-color: transparent transparent #0DA8EB  #0DA8EB; 
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 100;
		}
		.rightBottom{
			width: 16px;
			height: 16px;
			border: 1px solid;
			border-color: transparent #0DA8EB #0DA8EB transparent ; 
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
	}
</style>
