import request from '@/utils/request'

// 查询房屋列表
export function listRoom(data) {
  return request({
    url: 'ucenter/room/roomPageList',
    method: 'post',
    data: data
  })
}
// 查询所有房屋列表
export function listAllRoom(data) {
  return request({
    url: 'ucenter/community/selectRoomAll',
    method: 'post',
    data: data
  })
}
// 查询房屋详细
export function getRoom(roomId) {
  return request({
    url: '/ucenter/room/getRoomById?roomId=' + roomId,
    method: 'post'
  })
}



// 新增房屋
export function addRoom(data) {
  return request({
    url: '/ucenter/room/addRoom',
    method: 'post',
    data: data
  })
}

// 修改房屋
export function updateRoom(data) {
  return request({
    url: '/ucenter/room/updateRoom',
    method: 'post',
    data: data
  })
}

// 删除房屋
export function delRoom(roomId) {
  return request({
    url: 'ucenter/room/delRoomById?roomId=' + roomId,
    method: 'post',
  })
}

//烟感管理中查询房屋信息
export function roomNoPageList(data) {
  return request({
    url: '/ucenter/room/roomNoPageList',
    method: 'post',
    data: data
  })
}

// 导入设备信息
export function importRoom() {
  return process.env.VUE_APP_BASE_URL_COUNTY + 'ucenter/room/importRoom'
}