<template>
    <div class="components-container">
        <el-row :gutter="20">
            <!--用户数据-->
            <el-col :span="24" :xs="24">
                <el-back>
                    <el-row :gutter="10" class="mb8">
                        <el-col :span="1.5" v-if="planeList.length == 0">
                            <el-button
                                type="primary"
                                icon="el-icon-plus"
                                size="mini"
                                @click="handleAdd"
                                v-hasPermi="['smoke:viewplane:add']"
                                >新增平面图</el-button
                            >
                        </el-col>
                    </el-row>

                    <el-table v-loading="loading" :data="planeList">
                        <el-table-column
                            label="编号"
                            align="center"
                            prop="planId"
                        />
                        <el-table-column
                            label="监控中心名称"
                            align="center"
                            prop="planName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column label="平面图" align="center">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    @click="handlePicture(scope.row)"
                                    v-hasPermi="['smoke:viewplane:view']"
                                    >点击查看</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="创建人"
                            align="center"
                            prop="createBy"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="创建时间"
                            align="center"
                            prop="createTime"
                            width="220"
                        >
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.createTime)
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="更新时间"
                            align="center"
                            prop="updateTime"
                            width="220"
                        >
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.updateTime)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="用户操作"
                            align="center"
                            width="180"
                            class-name="small-padding fixed-width"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="handleUpdate(scope.row)"
                                    v-hasPermi="['smoke:viewplane:edit']"
                                    >修改</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-delete"
                                    @click="handleDelete(scope.row)"
                                    v-hasPermi="['smoke:viewplane:remove']"
                                    >删除</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>

                    <pagination
                        v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        @pagination="getList"
                    />
                </el-back>
            </el-col>
        </el-row>

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="监控中心名称" prop="planName">
                            <el-input
                                v-model="form.planName"
                                placeholder="请输入监控中心名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传平面图" prop="imageUrl">
                            <el-upload
                                :action="addCertificate()"
                                list-type="picture-card"
                                ref="upload"
                                :show-file-list="false"
                                :on-change="handleChange"
                                :on-success="handleSuccess"
                                :headers="header"
                                :data="form"
                                :auto-upload="false"
                            >
                                <img
                                    v-if="form.imageUrl && upFlag"
                                    :src="form.imageUrl"
                                    class="avatar"
                                />
                                <img
                                    v-if="form.pic && !upFlag"
                                    :src="$viewIp + form.pic"
                                    class="avatar"
                                />

                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="openPic" width="400px">
            <el-image
                style="width: 100%;height: 400px;"
                :src="picSrc"
                :preview-src-list="[picSrc]"
            >
            </el-image>
        </el-dialog>
    </div>
</template>

<script>
    import {
        selectDevicePlanList,
        insertDevicePlan,
        insertDevicePlanPath,
        updateDevicePlan,
        updateDevicePlanPath,
        selectDevicePlanById,
        deleteDevicePlanById
    } from '@/api/smoke/plane';
    import { getUserSession } from '@/utils/auth';
    import { removeKeyByNull } from '@/utils/oaktree';
    import qs from 'qs';
    export default {
        name: 'plane',
        data() {
            return {
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 总条数
                total: 0,
                // 表格数据
                planeList: null,
                // 弹出层标题
                title: '',
                // 是否显示弹出层
                open: false,
                openPic: false,
                // 状态数据字典
                statusOptions: [],
                // 性别状态字典
                sexOptions: [],
                // 角色选项
                // 表单参数
                form: {},

                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10
                },
                /* 图片地址*/
                picSrc: '',
                picNum: 0,
                // 表单校验
                rules: {
                    planName: [
                        {
                            required: true,
                            message: '名称不能为空',
                            trigger: 'blur'
                        }
                    ],
                    imageUrl: [
                        {
                            required: true,
                            message: '平面图不能为空',
                            trigger: 'blur'
                        }
                    ]
                },
                header: { Authorization: '' },
                //图片上传
                upFlag: true
            };
        },
        created() {
            this.getList();
            this.header.Authorization = 'Bearer ' + getUserSession('iffp_jwt');
            this.getDicts('sys_normal_disable').then((response) => {
                this.statusOptions = response;
            });
            this.getDicts('sys_user_sex').then((response) => {
                this.sexOptions = response;
            });
            // this.getConfigKey("sys.user.initPassword").then(response => {
            //   this.initPassword = response;
            // });
        },
        methods: {
            addCertificate() {
                if (this.title == '新增平面图') {
                    return insertDevicePlanPath();
                } else {
                    return updateDevicePlanPath();
                }
            },
            /** 查询用户列表 */
            getList() {
                this.loading = true;
                selectDevicePlanList(this.queryParams).then((response) => {
                    this.planeList = response.queryResult.list;
                    this.total = response.queryResult.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    imageUrl: undefined,
                    planName: undefined
                };
                this.resetForm('form');
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.dateRange = [];
                this.resetForm('queryForm');
                this.handleQuery();
            },
            /* 上传*/
            handleChange(file, fileList) {
                this.form.imageUrl = URL.createObjectURL(file.raw);
                console.log(this.form.imageUrl);
                this.upFlag = true;
            },
            handleSuccess(file, fileList) {
                if (file.code == 10000) {
                    this.msgSuccess('上传成功');
                    this.open = false;
                    this.getList();
                } else {
                    this.msgError('上传失败');
                }
            },
            //点击查看图片
            handlePicture(row) {
                this.picSrc = this.$viewIp + row.pic;

                this.openPic = true;
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                // getUser().then(response => {
                //   this.postOptions = response.posts;
                this.open = true;
                this.title = '新增平面图';
                //   this.form.password = this.initPassword;
                // });
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const planId = row.planId || this.ids[0];
                selectDevicePlanById(planId).then((response) => {
                    response.imageUrl = response.pic;
                    this.form = response;
                    this.open = true;
                    this.title = '修改平面图';
                    this.upFlag = false;
                });
            },
            /** 提交按钮 */
            submitForm: function() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.planId != undefined) {
                            if (this.upFlag) {
                                this.$delete(this.form, 'createTime');
                                this.$delete(this.form, 'updateTime');
                                this.form = removeKeyByNull(this.form);
                                this.$refs.upload.submit();
                            } else {
                                let data = qs.stringify({
                                    planName: this.form.planName,
                                    planId: this.form.planId
                                });
                                updateDevicePlan(data).then((response) => {
                                    if (response.code === 10000) {
                                        this.msgSuccess('修改成功');
                                        this.open = false;
                                        this.getList();
                                    } else {
                                        this.msgError(response.msg);
                                    }
                                });
                            }
                        } else {
                            this.$refs.upload.submit();
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                if (row.planId) {
                    var planIds = [row.planId];
                } else {
                    var planIds = this.ids;
                }
                this.$confirm(
                    '是否确认删除用户编号为"' + planIds + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        return deleteDevicePlanById(planIds[0]);
                    })
                    .then(() => {
                        this.getList();
                        this.msgSuccess('删除成功');
                    })
                    .catch(function() {});
            }
        }
    };
</script>
<style scoped lang="scss"></style>
