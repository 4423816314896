import request from '@/utils/request'

// 查询楼栋信息列表
export function listBuilding(data) {
  return request({
    url: 'ucenter/building/buildingPageList',
    method: 'post',
    data: data
  })
}
// 查询所有楼栋信息列表
export function listBuildingsData() {
  return request({
    url: 'ucenter/building/deptBuildingList',
    method: 'post'
  })
}
// 查询楼栋信息详细
export function getBuilding(buildingId) {
  return request({
    url: 'ucenter/building/getBuildingById?buildingId=' + buildingId,
    method: 'post'
  })
}

// 新增楼栋信息
export function addBuilding(data) {
  return request({
    url: 'ucenter/building/addBuilding',
    method: 'post',
    data: data
  })
}

// 修改楼栋信息
export function updateBuilding(data) {
  return request({
    url: 'ucenter/building/updateBuilding',
    method: 'post',
    data: data
  })
}

// 删除楼栋信息
export function delBuilding(data) {
  return request({
    url: 'ucenter/building/delBuildingById',
    method: 'post',
	data:data
  })
}
// 批量删除楼栋信息
export function delAllType(dictIds) {
  return request({
    url: 'system/dictType/deleteDictTypeByIds',
    method: 'post',
    data:dictIds
  })
}


// 获取字典选择框列表
export function optionselect() {
  return request({
    url: '/system/dict/type/optionselect',
    method: 'get'
  })
}
