export const smokeRoutes = [{
    path: '/',
    component: () => import('@/views/home/page/home.vue'),
    hidden: true,
    children: [{
      path: '/smoke',
      component: () => import('@/views/smoke/index.vue'),
      name: '烟感管理',
	  
    }]
  },


]
