<template>
  <div class="logoBg">
    <div class="topBg">
      <!-- <img src="../../../assets/image/logo.png" /> -->
      <!-- <div style="display: inline-block;">
				<div>橡树科技</div>
				<div>Oaktree.AI</div>
			</div> -->
    </div>
    <div class="timeliness">
      <el-button type="primary" size="mini" @click="handleTimeliness"
        >更新时效性</el-button
      >
      <el-dialog
        :title="'更新时效性'"
        :visible.sync="open"
        width="500px"
        :append-to-body="true"
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="生效时间" prop="startTime">
            <el-date-picker
              v-model="form.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="startDatePicker"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="失效时间" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="endDatePicker"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="签名" prop="sign">
            <el-input
              type="textarea"
              v-model="form.sign"
              placeholder="请输入签名"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="twoCode">
      <img :src="App_URL_CODE" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="loginBox">
      <div class="loginTitle">
        欢迎登录
      </div>
      <div class="login">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
        >
          <div style="text-align: center;"></div>
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              placeholder="请输入您的账号"
            >
              <svg-icon
                slot="prefix"
                icon-class="user"
                class="el-input__icon input-icon"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="请输入您的密码"
              @keyup.enter.native="handleLogin"
            >
              <svg-icon
                slot="prefix"
                icon-class="password"
                class="el-input__icon input-icon"
              />
            </el-input>
          </el-form-item>
          <el-checkbox
            v-model="loginForm.rememberMe"
            style="margin:0px 0px 25px 0px;"
            >记住密码</el-checkbox
          >
          <el-form-item style="width:100%;">
            <el-button
              :loading="loading"
              size="medium"
              type="primary"
              style="width:100%;"
              @click.native.prevent="handleLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { getCodeImg } from '@/api/login'
  import Cookies from 'js-cookie'
  import { updLocalRsaDept } from '@/api/system/timeliness'
  import qs from 'qs'
  export default {
    name: 'Login',
    data() {
      return {
        loginForm: {
          username: '',
          password: '',
          verifyCode: 'password',
          rememberMe: false
          // code: "",
          // uuid: ""
        },
        loginRules: {
          username: [
            {
              required: true,
              trigger: 'blur',
              message: '用户名不能为空'
            }
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              message: '密码不能为空'
            }
          ],
          code: [
            {
              required: true,
              trigger: 'change',
              message: '验证码不能为空'
            }
          ]
        },
        loading: false,
        redirect: undefined,
        open: false,
        form: {
          sign: undefined,
          startTime: undefined,
          endTime: undefined
        },
        // 表单校验
        rules: {
          startTime: [
            { required: true, message: '生效时间不能为空', trigger: 'blur' }
          ],
          endTime: [
            { required: true, message: '失效时间不能为空', trigger: 'blur' }
          ],
          sign: [{ required: true, message: '签名不能为空', trigger: 'blur' }]
        },
        //开始时间和结束时间的判断
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        title: process.env.VUE_APP_TITLE,
        App_URL_CODE: require(`@/assets/image/app_${process.env.VUE_APP_ENV}.png`)
      }
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    created() {
      // this.getCode();
      this.getCookie()
    },
    methods: {
      //开始时间和结束时间的限制
      beginDate() {
        const self = this
        return {
          disabledDate(time) {
            if (self.form.endTime) {
              //如果结束时间不为空，则小于结束时间
              return new Date(self.form.endTime).getTime() < time.getTime()
            } else {
              // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
            }
          }
        }
      },
      processDate() {
        const self = this
        return {
          disabledDate(time) {
            if (self.form.startTime) {
              //如果开始时间不为空，则结束时间大于开始时间
              return new Date(self.form.startTime).getTime() > time.getTime()
            } else {
              // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
            }
          }
        }
      },
      cancel() {
        this.form = {
          sign: undefined,
          startTime: undefined,
          endTime: undefined
        }
        this.open = false
      },
      submitForm() {
        updLocalRsaDept(qs.stringify(this.form)).then((response) => {
          if (response.code === 10000) {
            this.msgSuccess('更新成功')
            this.open = false
          } else {
            this.msgError(response.msg)
          }
        })
      },
      /* 更新时效性 */
      handleTimeliness() {
        this.open = true
        this.form = {
          sign: undefined,
          startTime: undefined,
          endTime: undefined
        }
      },
      // getCode() {
      //   getCodeImg().then(res => {
      //     this.codeUrl = "data:image/gif;base64," + res.img;
      //     this.loginForm.uuid = res.uuid;
      //   });
      // },
      getCookie() {
        const username = Cookies.get('iffp_username')
        const password = Cookies.get('iffp_password')
        const rememberMe = Cookies.get('iffp_rememberMe')
        this.loginForm = {
          username: username === undefined ? this.loginForm.username : username,
          password: password === undefined ? this.loginForm.password : password,
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        }
      },
      handleLogin() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true

            if (this.loginForm.rememberMe) {
              Cookies.set('iffp_username', this.loginForm.username, {
                expires: 30
              })
              Cookies.set('iffp_password', this.loginForm.password, {
                expires: 30
              })
              Cookies.set('iffp_rememberMe', this.loginForm.rememberMe, {
                expires: 30
              })
            } else {
              Cookies.remove('iffp_username')
              Cookies.remove('iffp_password')
              Cookies.remove('iffp_rememberMe')
            }
            this.$store
              .dispatch('Login', this.loginForm)
              .then(() => {
                this.loading = false
                // this.$router.push({
                // 	path: this.redirect || "/"
                // }).catch(err => {})
                this.$router
                  .push({
                    path: '/'
                  })
                  .catch((err) => {})
              })
              .catch(() => {
                this.loading = false
                // this.getCode();
              })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .logoBg {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/image/huaxinbackground.png');
    background-size: 100% 100%;
  }
  .topBg {
    position: fixed;
    top: 25px;
    left: 25px;
  }
  .twoCode {
    position: fixed;
    top: 70px;
    right: 70px;
    width: 90px;
    height: 90px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .timeliness {
    position: fixed;
    top: 70px;
    left: 70px;
  }
  .loginBox {
    position: absolute;
    top: 48%;
    right: 25%;
  }
  .loginTitle {
    text-align: center;
    font-size: 30px;
    color: #e3e7f7;
  }
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/image/frame.png');
    background-size: 100% 100%;
  }

  .title {
    position: absolute;
    top: 12%;
    left: 50%;
    margin-left: -270px;
    font-size: 54px;
    color: #e3e7f7;
  }
  .login-form {
    border-radius: 6px;
    width: 400px;
    padding: 50px 25px 5px 25px;
    .el-button {
      color: #e3e7f7;
      font-size: 20px;
    }
    .el-input /deep/ .el-input__inner {
      background-color: rgba(36, 230, 253, 0.1);
      color: #45e2f5;
    }
  }
  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
  .el-checkbox {
    color: #34e6fe;
  }

  .el-button--primary {
    background-color: #0f6d7a;
  }
</style>
