<template>
  <div class="">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="产品ID" prop="deviceCode">
        <el-input
          v-model="queryParams.deviceCode"
          placeholder="请输入产品ID"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName">
        <el-input
          v-model="queryParams.deviceName"
          placeholder="请输入设备名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备类别" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          size="small"
          placeholder="请选择设备类别"
        >
          <el-option
            v-for="dict in deviceOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
            @keyup.enter.native="handleQuery"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="报警类型" prop="alarmType">
        <el-select
          v-model="queryParams.alarmType"
          size="small"
          placeholder="请选择报警类型"
        >
          <el-option
            v-for="dict in alarmList"
            :key="dict.alarmId"
            :label="dict.alarmType"
            :value="dict.typeValue"
            @keyup.enter.native="handleQuery"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="报警时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出</el-button
        >
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="recordList">
      <el-table-column label="编号" prop="dealRecordId" align="center" />
      <el-table-column
        label="产品ID"
        align="center"
        prop="deviceCode"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="设备名称"
        align="center"
        prop="deviceName"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="设备类别"
        align="center"
        prop="deviceType"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="建筑楼层"
        align="center"
        prop="roomAddress"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="责任人电话"
        align="center"
        prop="phone"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="报警类型"
        align="center"
        prop="alarmType"
        :show-overflow-tooltip="true"
      />
      <!-- 		<el-table-column label="烟雾浓度" align="center" prop="concn" :show-overflow-tooltip="true" >
				<template slot-scope="scope" v-if="scope.row.concn !==null ">
					<span>{{ scope.row.concn }}%</span>
				</template>
			</el-table-column> -->
      <el-table-column
        label="报警时间"
        align="center"
        prop="alarmTime"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="是否复位"
        align="center"
        prop="resetButton"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="处理状态"
        align="center"
        prop="processState"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="用户操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDeviceInfo(scope.row)"
            v-hasPermi="['alarm:record:info']"
            >设备详情</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="viewUpdate(scope.row)"
            v-if="scope.row.processState === '待查看'"
            v-hasPermi="['alarm:record:view']"
            >查看</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="resetUpdate(scope.row)"
            v-if="scope.row.resetButton === '否'"
            v-hasPermi="['alarm:record:reset']"
            >复位</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="handleDeal(scope.row)"
            v-if="scope.row.processState === '待处理'"
            v-hasPermi="['alarm:record:deal']"
            >处理</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="handleResult(scope.row)"
            v-if="scope.row.processState === '已处理'"
            v-hasPermi="['alarm:record:result']"
            >处理结果</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="警情真实性" prop="alarmTrue">
          <el-select
            v-model="form.alarmTrue"
            style="width: 360px;"
            size="small"
            :disabled="resultFlag"
            placeholder="请选择警情真实性"
          >
            <el-option
              v-for="item in alarmOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="报警详情描述" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            :disabled="resultFlag"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片上传" prop="imageUrl">
          <el-upload
            :action="''"
            list-type="picture-card"
            ref="upload"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :limit="3"
            :class="{ mydisabled: maxnum == 3 }"
            :file-list="imageUrls"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!resultFlag">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="infoTitle" :visible.sync="infoOpen" width="850px">
      <div
        style="text-align: center;margin-bottom: 20px;font-size: 18px;color: #34E4FC;"
      >
        {{ infoTitle }}
      </div>
      <el-row :gutter="30">
        <el-col :span="12">
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">产品ID</el-col>
              <el-col :span="16">{{ form.deviceCode }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">设备名称</el-col>
              <el-col :span="16">{{ form.deviceName }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">设备型号</el-col>
              <el-col :span="16">{{ form.deviceModel }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">上报时间</el-col>
              <el-col :span="16">{{
                form.createTime || form.alarmTime
              }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">责任人</el-col>
              <el-col :span="16">{{ form.functionaryName }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">电话</el-col>
              <el-col :span="16">{{ form.phone }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">单位地址</el-col>
              <el-col :span="16">{{ form.address }}</el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">设备状态</el-col>
              <el-col :span="16">{{ form.status }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">{{
                concnLabel
              }}</el-col>
              <el-col :span="16">{{ form.concn }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">电池电压</el-col>
              <el-col :span="16">{{ form.voltage }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">电池电量</el-col>
              <el-col :span="16">{{ form.electricQuantity }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">信号强度</el-col>
              <el-col :span="16">{{ form.dbm }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">IMEI</el-col>
              <el-col :span="16">{{ form.imei }}</el-col>
            </el-row>
          </div>
          <div
            style="background-color: #081D62;padding: 10px 0;color: #34E4FC;"
          >
            <el-row>
              <el-col :span="8" style="text-align:center">ICCID</el-col>
              <el-col :span="16">{{ form.iccid }}</el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getDevAlaRecordPageList,
    updateDeAlRecord,
    resetButton,
    alarmNoPageList,
    deviceInfo,
    hanldeResult,
    devAlaRecordExport,
    getAlarmLabel
  } from '@/api/alarm/index'
  import { getSmokeDevice } from '@/api/smoke/userSmokeDevice'
  import qs from 'qs'
  export default {
    name: 'record',
    data() {
      return {
        // 遮罩层
        loading: true,
        // 总条数
        total: 0,
        // 表格数据
        recordList: [],
        alarmList: [],
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        // 状态数据字典
        deviceOptions: [],
        alarmOptions: [],
        // 日期范围
        dateRange: [],
        // 查询参数
        queryParams: {
          page: 1,
          size: 10,
          deviceType: undefined,
          deviceName: undefined,
          deviceCode: undefined
        },
        // 表单参数
        form: {},
        /* 上传图片*/
        imageUrls: [],
        maxnum: 0,
        addFlag: true,
        fileList: [],
        infoTitle: '设备详情',
        // 部门树选项
        deptOptions: undefined,
        dealRecordId: undefined,
        infoOpen: false,
        infoTitle: '设备详情',
        resultFlag: false,
        concnLabel: ''
      }
    },
    created() {
      this.getList()
      this.getDicts('sys_device_type').then((response) => {
        this.deviceOptions = response
      })
      this.getDicts('alarm_true').then((response) => {
        this.alarmOptions = response
      })
      this.getDicts('detail_show_name').then((response) => {
        // console.log('获取 detail_show_name 字典', response)
        // this.concnOprions = response
      })
    },
    watch: {
      'queryParams.deviceType': {
        handler(val) {
          if (val) {
            this.remoteMethod(val)
          } else {
            this.alarmList = []
          }
        },
        deep: true,
        immediate: true
      }
    },
    computed: {
      concnVal() {
        console.log('计算气体浓度  this.concnOprions', this.concnOprions)
        console.log('计算气体浓度  form.concn', this.form.concn)
        if (this.concnOprions.length > 0 && this.form.concn) {
          let filterVal = this.concnOprions.filter((item) => {
            return item.dictValue === this.form.concn
          })[0]
          return filterVal ? filterVal.dictLabel : ''
        } else {
          return ''
        }
      }
    },
    methods: {
      handleRemove(file, fileList) {
        /* 移除显示图片的url*/
        // for (const i in this.imageUrls) {
        //   if (this.imageUrls[i].name === file.name) {
        //     this.imageUrls.splice(i, 1)
        //   }
        // }
        /* 移除上传图片的url*/
        for (const i in this.fileList) {
          if (this.fileList[i].name === file.name) {
            this.fileList.splice(i, 1)
          }
        }
      },
      handleChange(file, fileList) {
        // this.imageUrls.push({name:file.name,url:URL.createObjectURL(file.raw)});
        this.fileList.push(file.raw)
        this.maxnum = this.fileList.length
      },
      getList() {
        this.loading = true
        getDevAlaRecordPageList(
          this.addDateRange(this.queryParams, this.dateRange)
        ).then((response) => {
          console.log('列表结果', response.queryResult.list)
          this.recordList = response.queryResult.list
          this.total = response.queryResult.total
          this.loading = false
        })
      },
      /** 设备详情 */
      handleDeviceInfo(row) {
        getAlarmLabel(row.deviceType).then((response) => {
          if (response.length > 0) {
            this.concnLabel = response[0].dictLabel
          }
        })

        deviceInfo(row.deviceCode).then((response) => {
          this.form = response
          this.infoOpen = true
        })
      },
      // 取消按钮
      cancel() {
        this.open = false
        this.reset()
      },
      // 表单重置
      reset() {
        this.form = {
          processState: 3,
          deviceCode: undefined,
          deviceName: undefined,
          deviceType: undefined,
          alarmType: undefined,
          content: '',
          alarmTrue: '',
          imageUrl: ''
        }
        this.fileList = []
        this.imageUrls = []
        this.resetForm('form')
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.page = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.alarmList = []
        this.dateRange = []
        this.resetForm('queryForm')
        this.handleQuery()
      },
      /** 处理按钮操作 */
      handleDeal(row) {
        this.reset()
        this.open = true
        this.title = '处理提示'
        this.resultFlag = false
        this.maxnum = 0
        this.dealRecordId = row.dealRecordId
      },
      handleResult(row) {
        this.open = true
        this.imageUrls = []
        hanldeResult(row.dealRecordId).then((res) => {
          if (res.alarmFile1)
            this.imageUrls.push({
              name: res.alarmFile1,
              url: this.$viewIp + res.alarmFile1
            })
          if (res.alarmFile2)
            this.imageUrls.push({
              name: res.alarmFile1,
              url: this.$viewIp + res.alarmFile2
            })
          if (res.alarmFile3)
            this.imageUrls.push({
              name: res.alarmFile1,
              url: this.$viewIp + res.alarmFile3
            })
          this.maxnum = 3
          this.resultFlag = true
          this.form = res
        })
      },
      /** 提交按钮 */
      submitForm: function() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var upData = new FormData()
            this.fileList.forEach((val, index) => {
              console.log(this.fileList, '1')
              let num = index + 1
              upData.append('file' + num, val, val.name)
            })
            upData.append('alarmTrue', this.form.alarmTrue)
            upData.append('content', this.form.content)
            upData.append('dealRecordId', this.dealRecordId)
            upData.append('processState', this.form.processState)
            updateDeAlRecord(upData).then((response) => {
              if (response.code === 10000) {
                this.msgSuccess('修改成功')
                this.open = false
                this.getList()
                /* 上传图片*/
              } else {
                this.msgError(response.msg)
              }
            })
          }
        })
      },
      //报警类型
      remoteMethod(val) {
        let alarmtype = {
          deviceType: val
        }
        alarmNoPageList(alarmtype).then((res) => {
          this.alarmList = res
        })
      },
      //点击查看
      viewUpdate(row) {
        var data = new FormData()
        data.append('dealRecordId', row.dealRecordId)
        data.append('processState', 2)
        /* let data = {
					dealRecordId: row.dealRecordId,
					processState: 2,
				} */
        this.$confirm('是否确认标记为已查看?', '温馨提示', {
          customClass: 'deleteBox',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(function() {
            return updateDeAlRecord(data)
          })
          .then(() => {
            this.getList()
            this.msgSuccess('已查看')
          })
          .catch(function() {})
      },
      //点击复位
      resetUpdate(row) {
        this.$confirm('是否确认执行复位操作?', '温馨提示', {
          customClass: 'deleteBox',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(function() {
            return resetButton(row.deviceCode)
          })
          .then((response) => {
            this.getList()
            this.msgSuccess(response.message)
          })
          .catch(function() {})
      },
      /** 导出按钮操作 */
      handleExport() {
        let that = this
        this.$confirm('是否确认导出所有数据项?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'deleteBox',
          type: 'warning'
        })
          .then(function() {
            return devAlaRecordExport(that.queryParams)
          })
          .then((response) => {
            let blob = new Blob([response], {
              type: 'application/vnd.ms-excel'
            }) //type是文件类，详情可以参阅blob文件类型
            let objectUrl = URL.createObjectURL(blob) //生成下载链接
            window.location.href = objectUrl
          })
          .catch(function() {})
      }
    }
  }
</script>
<style scoped lang="scss">
  .mydisabled /deep/ .el-upload--picture-card {
    display: none;
  }

  .el-textarea.is-disabled /deep/.el-textarea__inner {
    background-color: #081d62;
    color: #34e6fe !important;
  }
</style>
