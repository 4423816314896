<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    },
    maxNum:{
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions(val) {
      let that = this
      this.chart.setOption({
        // title: {
        //     text: '总预警次数:' + this.allMount + '次',
        //     left:'center',
        //     top:'30'
        // },
        xAxis: {
            type:'value',
			splitLine:{
			  show:false
			},
          axisTick: {
            show: false
          }
        },
        grid: {
		  x:20,
		  y:20,
		  x2:30,
		  y2:55,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: '{b1}: {c1}',
          padding: [5, 10]
        },
        yAxis: {
			splitLine:{
			  show:false
			},
          boundaryGap: ['20%', '20%'],
          axisTick: {
            show: false
          },
		  data: (function(){
		    var res = []
		      val.forEach((item,index) => {
		          res.push(item.name)
		      })
		      return res;
		  })(),
        },
        series: [
          /* 设置背景色*/
        {
          type: 'bar',
          itemStyle: {
              color: 'rgba(11, 210, 189, 0.2)',
          },
          barGap: '-100%',
          barCategoryGap: '40%',
          barWidth:15,
          data: (function(){
              var res = []
              val.forEach((item,index)=>{
                res.push(that.maxNum)
              })
              return res
          })(),
          animation: false
        },
        {
          name: '柱形数',
          smooth: true,
          type: 'bar',
          itemStyle: {
            normal: {
              color: '#0BD2BD',
              lineStyle: {
                color: '#3888fa',
                width: 1
              },
              areaStyle: {
                color: '#f3f8ff'
              },       
            }
          },
          barWidth:15,
          data: (function(){
            var res = []
              val.forEach((item,index) => {
                  res.push(item.value)
              })
              return res;
          })(),
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        }]
      })
    }
  }
}
</script>
