<template>
  <div class="">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="产品ID" prop="deviceCode">
        <el-input
          v-model="queryParams.deviceCode"
          placeholder="请输入产品ID"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName">
        <el-input
          v-model="queryParams.deviceName"
          placeholder="请输入设备名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备类别" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          size="small"
          placeholder="请选择设备类别"
        >
          <el-option
            v-for="dict in deviceOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="报警时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:user:remove']"
          type="warning"
          >导出</el-button
        >
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="selfInspectionList">
      <el-table-column label="编号" align="center" type="index" />
      <el-table-column
        label="产品ID"
        align="center"
        prop="deviceCode"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="设备名称"
        align="center"
        prop="deviceName"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="设备类别"
        align="center"
        prop="deviceType"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="建筑楼层"
        align="center"
        prop="roomAddress"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="安装位置"
        align="center"
        prop="address"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="责任人电话"
        align="center"
        prop="phone"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="自检时间"
        align="center"
        prop="selfTestTime"
        :show-overflow-tooltip="true"
      />
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />
  </div>
</template>

<script>
  import { getDevSelTestPageList, exportDevSelTest } from '@/api/alarm/index'
  import qs from 'qs'
  // import { listCommunityData } from '@/api/property/village'
  export default {
    name: 'record',
    data() {
      return {
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 表格数据
        selfInspectionList: [],
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        // 状态数据字典
        deviceOptions: [],
        // 日期范围
        dateRange: [],
        // 查询参数
        queryParams: {
          page: 1,
          size: 10,
          deviceCode: undefined,
          deviceName: undefined,
          devicetype: undefined
        },
        // 表单参数
        form: {}
      }
    },
    created() {
      this.getList()
      this.getDicts('sys_device_type').then((response) => {
        this.deviceOptions = response
      })
    },
    methods: {
      /** 查询字典类型列表 */
      getList() {
        this.loading = true
        getDevSelTestPageList(
          this.addDateRange(this.queryParams, this.dateRange)
        ).then((response) => {
          this.selfInspectionList = response.queryResult.list
          this.total = response.queryResult.total
          this.loading = false
        })
      },
      // 表单重置
      reset() {
        this.form = {
          address: undefined,
          deviceCode: undefined,
          remark: undefined
        }
        this.resetForm('form')
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.page = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = []
        this.resetForm('queryForm')
        this.handleQuery()
      },
      /** 导出按钮操作 */
      handleExport() {
        let queryData = qs.stringify(this.queryParams)
        this.$confirm('是否确认导出所有数据项?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'deleteBox',
          type: 'warning'
        })
          .then(function() {
            return exportDevSelTest(queryData)
          })
          .then((response) => {
            let blob = new Blob([response], {
              type: 'application/vnd.ms-excel'
            }) //type是文件类，详情可以参阅blob文件类型
            let objectUrl = URL.createObjectURL(blob) //生成下载链接
            window.location.href = objectUrl
          })
          .catch(function() {})
      }
    }
  }
</script>

<style></style>
