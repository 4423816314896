<template>
	<div>
		<el-row>
		  <el-col :span="20" style="padding-right: 10px;">
		    <el-form ref="peopleForm" :model="peopleForm" label-width="100px" :inline="true" style="display:flex;align-items: center;">
		      <el-form-item label="责任人" prop="username">
		        <el-input
		          v-model="peopleForm.username"
		          placeholder="请输入责任人"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="peopleHandleQuery"
		        />
		      </el-form-item>
		      <el-form-item label="责任人电话" prop="phone">
		        <el-input
		          v-model="peopleForm.phone"
		          placeholder="请输入责任人电话"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="peopleHandleQuery"
		        />
		      </el-form-item>
						
		      <el-form-item>
		        <el-button type="primary" icon="el-icon-search" style="margin-left: 10px;" size="mini" @click="peopleHandleQuery">搜索</el-button>
		        <el-button icon="el-icon-refresh" size="mini" @click="peopleResetQuery">重置</el-button>
		      </el-form-item>
		      <el-form-item style="margin-bottom:0">
		        <el-button @click="addpeople" type="primary" size="mini" :disabled="!peopleData.length">加入</el-button>
		      </el-form-item>
		    </el-form>
		    <el-table ref="peopleTable" v-loading="peopleLoading" :data="leftList"
		      @selection-change="handlepeopleChange">
						<el-table-column type="selection" width="55" align="center" :selectable="checkSelectable" />
						<el-table-column label="编号" align="center" type="index"/>
						<el-table-column label="责任人" align="center" prop="username" :show-overflow-tooltip="true" />
						<el-table-column label="责任人电话" align="center" prop="phone" :show-overflow-tooltip="true" />
						<el-table-column label="接收状态" align="center" prop="status" :show-overflow-tooltip="true" />
		    </el-table>
		    <Pagination v-show="peopleTotal>0" :total="peopleTotal" :page.sync="peopleForm.page" :limit.sync="peopleForm.size" @pagination="getSelectedList" />
		  </el-col>
		  <el-col :span="4">
		    <el-card class="box-card" shadow="never" :body-style="{height:'580px'}">
		      <div slot="header" class="clearfix">
		        <span>已选责任人</span>
		      </div>
		      <div v-for="item in rightList" :key="item.receiverId">
		        {{ item.username }}
		        <i class="el-icon-circle-close" style="color: #39f6ff;float: right;" @click="removeRightValue(item)"></i>
		      </div>
		    </el-card>
				
		  </el-col>
		</el-row>
		<div slot="footer" class="dialog-footer">
		  <el-button type="primary" @click="peopleSubmitForm">确 定</el-button>
		  <el-button @click="peopleCancel">取 消</el-button>
		</div>
	</div>
</template>

<script>
import { deviceReceivePageList  } from "@/api/smoke/userSmokeDevice";
import { getReceiverList,updBatchNotRecDev } from '@/api/smoke/duty.js'
export default {
  name: "handlePeople",
  props:{
	deviceCode:{
		default:'',
		required:true
	}
  },
  data() {
    return {
		// 查询参数
		peopleForm: {
		  page: 1,
		  size: 10,
		  username: undefined,
		  phone: undefined
		},
	  /* 分配责任人*/

	  peopleOpen:true,
	  peopleTitle:'',
	  peopleLoading:true,
	  rightList:[],
	  leftList:[],
	  peopleData:[],
	  peopleTotal:0
    };
  },

  created() {
    deviceReceivePageList(this.deviceCode).then(response =>{
    	this.rightList = response.queryResult.list
    	this.getSelectedList()
    })

  },
  methods: {
	  /** 提交按钮 */
	  peopleSubmitForm() {
	    let formData = []
	    this.rightList.forEach((item, index) => {
	      formData.push({
	        deviceCode: this.deviceCode,
	  	  receiverId: item.receiverId,
	      })
	    })
	    if (this.rightList.length !== 0) {
	  	  let dataPeople = JSON.stringify(formData)
	  	// let dataEallot = qs.stringify({list: JSON.stringify(formData)},{arrayFormat: 'brackets'})
	      updBatchNotRecDev(dataPeople).then(res => {
	        this.$emit('closePeopleOpen',false)
	        if (res.code === 10000) {
	          this.msgSuccess("保存成功");
	          this.getList()
	        } else {
	          this.msgError(res.msg);
	        }
	      });
	    } else {
	      this.msgInfo("请选择添加责任人")
	    }
	  },
	  peopleCancel(){
	  	this.peopleOpen = false;
	  	this.resetForm("peopleForm");
	  },
	  handlepeopleChange(rows){
		  this.peopleData = []
		  if (rows) {
		    rows.forEach(row => {
		      if (row && !row.checkFlag) {
		        this.peopleData.push(row);
		      }
		    });
		  }
	  },
	  // 左边表格选择项移到右边
	  addpeople() {
	    this.peopleData.forEach((item, index) => {
	      this.rightList.push(item);
	      for (let i = 0; i < this.leftList.length; i++) {
	        if (item.receiverId == this.leftList[i].receiverId) {
	          this.$set(this.leftList[i], 'checkFlag', true)
	          // this.leftList[i].checkFlag = false
	          break
	        }
	      }
	      //每次点击之后初始化peopleData
	      if (index == this.peopleData.length - 1) this.peopleData = []
	    });
	  },
		peopleHandleQuery(){
			this.peopleForm.page = 1;
			this.getSelectedList();
		},

		peopleResetQuery(){
			this.resetForm("peopleForm");
			this.eallotHandleQuery();
		},
	  //判断选择框是否能被选择
	  checkSelectable(row) {
	    return !row.checkFlag
	  },
	  /** 查询弹出框列表 */
	  getSelectedList(val) {
	    this.peopleLoading = true;
	    getReceiverList(this.peopleForm).then(res => {
	      this.leftList = res.queryResult.list;
	      this.peopleTotal = res.queryResult.total;
	      this.peopleLoading = false;
	      //判断切换分页之后是否能选择数据
	      if (this.rightList.length !== 0) {
	        for (let i = 0; i < this.rightList.length; i++) {
	          for (let j = 0; j < this.leftList.length; j++) {
	            if (
	              this.rightList[i] &&
	              this.leftList[j] &&
	              this.rightList[i].receiverId === this.leftList[j].receiverId
	            ) {
	              this.$set(this.leftList[j], 'checkFlag', true)
	              this.$nextTick(() => {
	                this.$refs['peopleTable'].toggleRowSelection(this.leftList[j])
	              })
	              break
	            }
	          }
	        }
	      }
	    })
	  },
		//删除右边数据
		removeRightValue(item) {
		  //删除
		  for (let i = 0; i < this.rightList.length; i++) {
		    if (this.rightList[i].receiverId == item.receiverId) {
		      this.rightList.splice(i, 1);
		      break
		    }
		  }
		  //左边选择数据处理
		  for (let j = 0; j < this.leftList.length; j++) {
		    if (this.leftList[j].receiverId === item.receiverId) {
		      this.$set(this.leftList[j], 'checkFlag', undefined)
		      this.$nextTick(() => {
		        this.$refs['peopleTable'].clearSelection(item)
		      })
		      break
		    }
		  }
		},

  }
};
</script>
<style scoped lang="scss">
	.mapBox{
		width: 100%;
		height: 548px;
		position: relative;
		overflow: hidden;
		.bigImg{
			height: 548px;
			width: 100%;
		}
		.smallImg{
			position: absolute;
			width: 41px;
			height: 41px;
			left: -41px;
			right: -41px;
		}
	}
</style>