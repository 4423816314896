<template>
    <div class="components-container">
        <el-row :gutter="20">
            <!--用户数据-->
            <el-col :span="24" :xs="24">
                <el-back>
                    <div class="formBox">信息搜索</div>
                    <el-form
                        :model="queryParams"
                        ref="queryForm"
                        :inline="true"
                        label-width="85px"
                    >
                        <el-form-item label="产品ID" prop="deviceCode">
                            <el-input
                                v-model="queryParams.deviceCode"
                                placeholder="请输入产品ID"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="所在单位" prop="deptName">
                            <el-input
                                v-model="queryParams.deptName"
                                placeholder="请输入所在单位"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="所属代理商" prop="deptId">
                            <treeselect
                                v-model="queryParams.deptId"
                                :options="deptOptions"
                                placeholder="请选择归属部门"
                                style="width: 240px"
                            />
                        </el-form-item>
                        <el-form-item label="出库状态" prop="library">
                            <el-select
                                v-model="queryParams.library"
                                placeholder="出库状态"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in outInOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="设备状态" prop="status">
                            <el-select
                                v-model="queryParams.status"
                                placeholder="设备状态"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <el-date-picker
                                v-model="dateRange"
                                size="small"
                                style="width: 240px"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="设备类型" prop="deviceType">
                            <el-select
                                v-model="queryParams.deviceType"
                                placeholder="设备类型"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in deviceTypeOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                size="mini"
                                @click="handleQuery"
                                >搜索</el-button
                            >
                            <el-button
                                icon="el-icon-refresh"
                                size="mini"
                                @click="resetQuery"
                                >重置</el-button
                            >
                        </el-form-item>
                    </el-form>

                    <el-row :gutter="10" class="mb8">
                        <el-col :span="1.5">
                            <el-button
                                type="primary"
                                icon="el-icon-plus"
                                size="mini"
                                @click="handleAdd"
                                v-hasPermi="['outIn:smoke:add']"
                                >新增</el-button
                            >
                        </el-col>
                        <el-col :span="1.5">
                            <el-button
                                type="info"
                                icon="el-icon-upload2"
                                size="mini"
                                @click="handleImport"
                                v-hasPermi="['outIn:smoke:imp']"
                                >导入</el-button
                            >
                        </el-col>
                        <el-col :span="1.5">
                            <el-button
                                type="warning"
                                icon="el-icon-download"
                                size="mini"
                                @click="handleExport"
                                v-hasPermi="['outIn:smoke:export']"
                                >导出</el-button
                            >
                        </el-col>
                        <!-- v-hasPermi="['outIn:smoke:out']" -->
                        <el-col :span="1.5">
                            <el-button
                                type="danger"
                                icon="el-icon-folder-checked"
                                size="mini"
                                @click="handleBatchOut"
                                :disabled="multiple"
                                >出库</el-button
                            >
                        </el-col>
                    </el-row>

                    <el-table
                        v-loading="loading"
                        :data="deviceList"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                        />
                        <el-table-column
                            label="编号"
                            align="center"
                            prop="etectorId"
                        />
                        <el-table-column
                            label="设备类别"
                            align="center"
                            prop="typeName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="产品ID"
                            align="center"
                            prop="deviceCode"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="所在单位"
                            align="center"
                            prop="deptName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="所属代理商"
                            align="center"
                            prop="agentName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="IMEI"
                            align="center"
                            prop="imei"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="支持运营商"
                            align="center"
                            prop="operatorName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="出库状态"
                            align="center"
                            prop="ifLabel"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="ICCID"
                            align="center"
                            prop="iccid"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="安装位置"
                            align="center"
                            prop="address"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="联系电话"
                            align="center"
                            prop="phone"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="设备状态"
                            align="center"
                            prop="statusName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="操作"
                            align="center"
                            width="180"
                            class-name="small-padding fixed-width"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-key"
                                    @click="handleOut(scope.row)"
                                    v-hasPermi="['outIn:smoke:out']"
                                    v-if="scope.row.ifLabel === '未出库'"
                                    >出库</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    v-if="scope.row.ifLabel === '未出库'"
                                    @click="handleUpdate(scope.row)"
                                    v-hasPermi="['outIn:smoke:edit']"
                                    >修改</el-button
                                >
                                <el-button
                                    v-if="scope.row.ifLabel === '未出库'"
                                    size="mini"
                                    type="text"
                                    icon="el-icon-delete"
                                    @click="handleDelete(scope.row)"
                                    v-hasPermi="['outIn:smoke:remove']"
                                    >删除</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>

                    <pagination
                        v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.page"
                        :limit.sync="queryParams.size"
                        @pagination="getList"
                    />
                </el-back>
            </el-col>
        </el-row>

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="产品ID" prop="deviceCode">
                            <el-input
                                v-model="form.deviceCode"
                                placeholder="请输入产品ID"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="IMEIIMEI" prop="imei">
                            <el-input
                                v-model="form.imei"
                                placeholder="请输入IMEIIMEI"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="运营商" prop="operator">
                            <el-select
                                v-model="form.operator"
                                placeholder="请输入运营商"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="dict in operatorOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="设备类型" prop="deviceType">
                            <el-select
                                v-model="form.deviceType"
                                placeholder="设备类型"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in deviceTypeOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 用户导入对话框 -->
        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx, .xls"
                :headers="upload.headers"
                :action="upload.url"
                :disabled="upload.isUploading"
                :on-progress="handleFileUploadProgress"
                :on-success="handleFileSuccess"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <!-- <el-checkbox v-model="upload.updateSupport" />是否更新已经存在的用户数据 -->
                    <el-link
                        type="info"
                        style="font-size: 12px; color: #34e6fe"
                        @click="importTemplate"
                        >下载模板</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xls”或“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFileForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        listSmokeDevice,
        getSmokeDevice,
        addSmokeDevice,
        updateSmokeDevice,
        delSmokeDevice,
        exportSmokeDevice,
        importSmokeDevice,
        bantchOut
    } from '@/api/outIn/smoke';

    import { traverseTreeArray } from '@/utils/index';
    import { treeselect } from '@/api/system/dept';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import { downloadExcel } from '@/api/login';
    import { getUserSession } from '@/utils/auth';
    export default {
        name: 'User',
        components: { Treeselect },
        data() {
            return {
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 总条数
                total: 0,
                // 用户表格数据
                deviceList: null,
                // 弹出层标题
                title: '',
                // 部门树选项
                deptOptions: [],
                // 是否显示弹出层
                open: false,
                // 部门名称
                deptName: undefined,
                // 默认密码
                initPassword: undefined,
                // 日期范围
                dateRange: [],
                // 状态数据字典
                statusOptions: [],
                //出库状态
                outInOptions: [],
                // 运营商
                operatorOptions: [],

                // 角色选项
                roleOptions: [],
                // 表单参数
                form: {},
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },

                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    imei: undefined,
                    deviceCode: undefined,
                    library: undefined,
                    status: undefined,
                    deptId: undefined,
                    deptName: undefined,
                    deviceType: undefined,
                    flag: '0'
                },
                deviceTypeOptions: [],
                // 表单校验
                rules: {
                    deviceCode: [
                        {
                            required: true,
                            message: '产品ID不能为空',
                            trigger: 'blur'
                        }
                    ],
                    imei: [
                        {
                            required: true,
                            message: 'IMEI不能为空',
                            trigger: 'blur'
                        }
                    ],
                    deptId: [
                        {
                            required: true,
                            message: '所属单位不能为空',
                            trigger: 'blur'
                        }
                    ],
                    operator: [
                        {
                            required: true,
                            message: '运营商不能为空',
                            trigger: 'blur'
                        }
                    ],
                    deviceType: [
                        {
                            required: true,
                            message: '设备类型不能为空',
                            trigger: 'changer'
                        }
                    ]
                },
                // 用户导入参数
                upload: {
                    // 是否显示弹出层（用户导入）
                    open: false,
                    // 弹出层标题（用户导入）
                    title: '',
                    // 是否禁用上传
                    isUploading: false,
                    // 是否更新已经存在的用户数据
                    updateSupport: 0,
                    // 设置上传的请求头部
                    headers: {
                        Authorization: 'Bearer ' + getUserSession('iffp_jwt')
                    },
                    // 上传的地址
                    url: importSmokeDevice()
                },
                bantchOutList: []
            };
        },
        watch: {
            // 根据名称筛选部门树
            deptName(val) {
                this.$refs.tree.filter(val);
            }
        },
        created() {
            this.getList();
            this.getTreeselect();
            this.getDicts('sys_normal_disable').then((response) => {
                this.statusOptions = response;
            });
            this.getDicts('sys_delivery_status').then((response) => {
                this.outInOptions = response;
            });
            this.getDicts('sys_device_type').then((response) => {
                this.deviceTypeOptions = response;
            });
            this.getDicts('sys_operator').then((response) => {
                this.operatorOptions = response;
            });
            // this.getConfigKey("sys.user.initPassword").then(response => {
            //   this.initPassword = response;
            // });
        },
        methods: {
            /** 查询用户列表 */
            getList() {
                this.loading = true;
                listSmokeDevice(
                    this.addDateRange(this.queryParams, this.dateRange)
                ).then((response) => {
                    this.deviceList = response.queryResult.list;
                    this.total = response.queryResult.total;
                    this.loading = false;
                });
            },
            /** 查询部门下拉树结构 */
            getTreeselect() {
                treeselect().then((response) => {
                    this.deptOptions = traverseTreeArray(
                        response,
                        'deptName',
                        'deptId'
                    );
                });
            },

            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    deviceCode: undefined,
                    imei: undefined,
                    operator: undefined,
                    deptId: undefined,
                    etectorId: undefined,
                    deviceType: '0'
                };
                this.resetForm('form');
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.dateRange = [];
                this.resetForm('queryForm');
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.bantchOutList = selection;
                this.ids = selection.map((item) => item.etectorId);
                this.single = selection.length != 1;
                this.multiple = !selection.length;
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.getTreeselect();

                this.open = true;
                this.title = '添加设备';
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                this.getTreeselect();

                const etectorId = row.etectorId || this.ids[0];
                getSmokeDevice(etectorId).then((response) => {
                    this.form = response;
                    this.open = true;
                    this.title = '修改设备';
                });
            },

            /** 出库按钮操作 */
            handleOut(row) {
                let that = this;
                this.$confirm(
                    '是否确认出库设备编号为"' + row.etectorId + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        let formData = JSON.parse(JSON.stringify(row));
                        formData.library = '1';
                        formData.ifLabel = '出库';
                        updateSmokeDevice(formData).then((response) => {
                            if (response.code == 10000) {
                                that.msgSuccess('出库成功');
                                that.getList();
                            } else {
                                that.msgError(response.msg);
                            }
                        });
                    })
                    .catch(function() {});
            },
            /** 提交按钮 */
            submitForm: function() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.etectorId != undefined) {
                            updateSmokeDevice(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('修改成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        } else {
                            addSmokeDevice(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('新增成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                let etectorIds = {
                    etectorId: row.etectorId,
                    flag: 1
                };
                this.$confirm(
                    '是否确认删除用户编号为"' + row.etectorId + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        return updateSmokeDevice(etectorIds);
                    })
                    .then(() => {
                        this.msgSuccess('删除成功');
                        this.getList();
                    })
                    .catch(function() {});
            },
            /** 批量出库 */
            handleBatchOut() {
                let list = this.bantchOutList
                    .filter((item) => {
                        return item.ifLabel === '未出库';
                    })
                    .map((item) => {
                        return item.etectorId;
                    });
                console.log('批量出库 list', list);
                bantchOut(list).then((response) => {
                    console.log('批量出库结果', response);
                    if (response.code == 10000) {
                        this.msgSuccess('出库成功');
                        this.multiple = true;
                        this.getList();
                    } else {
                        this.msgError('出库失败');
                        this.multiple = true;
                        this.getList();
                    }
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                let that = this;
                this.$confirm('是否确认导出所有数据项?', '警告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'deleteBox',
                    type: 'warning'
                })
                    .then(function() {
                        return exportSmokeDevice(that.queryParams);
                    })
                    .then((response) => {
                        let blob = new Blob([response], {
                            type: 'application/vnd.ms-excel'
                        }); //type是文件类，详情可以参阅blob文件类型
                        let objectUrl = URL.createObjectURL(blob); //生成下载链接
                        window.location.href = objectUrl;
                    })
                    .catch(function() {});
            },
            /** 导入按钮操作 */
            handleImport() {
                this.upload.title = '设备导入';
                this.upload.open = true;
            },
            /** 下载模板操作 */
            importTemplate() {
                downloadExcel('设备信息批量导入模板').then((response) => {
                    let blob = new Blob([response], {
                        type: 'application/vnd.ms-excel'
                    }); //type是文件类，详情可以参阅blob文件类型
                    let objectUrl = URL.createObjectURL(blob); //生成下载链接
                    window.location.href = objectUrl;
                });
            },
            // 文件上传中处理
            handleFileUploadProgress(event, file, fileList) {
                this.upload.isUploading = true;
            },
            // 文件上传成功处理
            handleFileSuccess(response, file, fileList) {
                this.upload.open = false;
                this.upload.isUploading = false;
                this.$refs.upload.clearFiles();
                this.msgInfo(response.message);
                this.getList();
            },
            // 提交上传文件
            submitFileForm() {
                this.$refs.upload.submit();
            }
        }
    };
</script>
