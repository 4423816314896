<template>
    <div class="">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="设备类型" prop="deviceType">
                <el-select
                    v-model="queryParams.deviceType"
                    placeholder="设备类型"
                    clearable
                    size="small"
                    style="width: 240px"
                >
                    <el-option
                        v-for="dict in deviceOptions"
                        :key="dict.dictValue"
                        :label="dict.dictLabel"
                        :value="dict.dictValue"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="产品ID" prop="deviceCode">
                <el-input
                    v-model="queryParams.deviceCode"
                    placeholder="产品ID"
                    clearable
                    size="small"
                    style="width: 240px"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="IMEI" prop="imei">
                <el-input
                    v-model="queryParams.imei"
                    placeholder="IMEI"
                    clearable
                    size="small"
                    style="width: 240px"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="缴费状态" prop="status">
                <el-select
                    v-model="queryParams.status"
                    placeholder="缴费状态"
                    clearable
                    size="small"
                    style="width: 240px"
                >
                    <el-option
                        v-for="dict in statusOptions"
                        :key="dict.dictValue"
                        :label="dict.dictLabel"
                        :value="dict.dictValue"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="代理商" prop="agentId">
                <el-select
                    v-model="queryParams.agentId"
                    placeholder="代理商"
                    clearable
                    size="small"
                    style="width: 240px"
                    @change="handleQuery"
                >
                    <el-option
                        v-for="dict in agentList"
                        :key="dict.agentId"
                        :label="dict.deptName"
                        :value="dict.agentId"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    size="mini"
                    :disabled="single"
                    @click="handleAllRenew"
                    >批量续费</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    >导出</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button type="primary" size="mini" @click="handleDeviceRenew"
                    >设备续费</el-button
                >
            </el-col>
        </el-row>
        <el-table
            v-loading="loading"
            :data="deviceRenewList"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
                label="编号"
                type="index"
                width="55"
                align="center"
            />
            <el-table-column
                label="设备种类"
                align="center"
                prop="typeName"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="产品ID"
                align="center"
                prop="deviceCode"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="代理商"
                align="center"
                prop="deptName"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="IMEI"
                align="center"
                prop="imei"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="有效使用期限"
                align="center"
                prop="indate"
            />
            <el-table-column
                label="缴费状态"
                align="center"
                :formatter="statusFormat"
                prop="status"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleRenew(scope.row)"
                        v-hasPermi="['system:building:edit']"
                        >续费</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.size"
            @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            @close="handleDialogClose"
        >
            <el-form
                ref="form"
                :model="form"
                label-width="150px"
                v-if="!weixinFlag"
                :rules="rules"
            >
                <el-form-item
                    label="设备码"
                    prop="deviceCode"
                    v-if="deviceRenewFlag"
                >
                    <el-input
                        v-model="form.deviceCode"
                        placeholder="请输入设备码"
                        size="small"
                        @blur="deviceCodeChange"
                    />
                </el-form-item>
                <el-form-item label="请选择续费套餐">
                    <el-radio-group v-model="form.infoId">
                        <el-radio
                            @change="changeCost(dict.cost)"
                            v-for="dict in validTimeOptions"
                            :key="dict.infoId"
                            :label="dict.infoId"
                            >{{ dict.validTime }}个月({{
                                dict.cost
                            }}元)</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="合计:">
                    <span>{{ form.amount }}元</span>
                </el-form-item>
                <el-form-item label="支付途径">
                    <el-select v-model="form.pay" placeholder="请选择">
                        <el-option
                            v-for="dict in payOptions"
                            :key="dict.id"
                            :label="dict.name"
                            :value="dict.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer" v-if="!weixinFlag">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
            <div v-if="weixinFlag" id="qrcode" ref="qrcode"></div>
        </el-dialog>
    </div>
</template>

<script>
    import { selectPayTypeList } from '@/api/cashierDesk/normalDevice.js';
    import {
        deviceGetWebObjByDeptId,
        getPayTypeListByDeviceCode,
        checkSmokeDevCode,
        outUserDevicePayExcel,
        wxPaywebBatch,
        aliPaywebBatch,
        findAgentName,
        weixinResult,
        selectUserDevicePayList,
        submitaliPay,
        submitunionPay,
        submitweixinPay,
        getWebObjByDeptId,
        unionPayResult,
        aliPayResult,
        getObjByLocalDept,
        getWebObjByAgentId
    } from '@/api/smoke/renew.js';
    import qs from 'qs';
    import QRCode from 'qrcodejs2';
    import { getUserSession } from '@/utils/auth';
    export default {
        name: 'deviceRenew',
        data() {
            return {
                // 遮罩层
                loading: true,
                // 总条数
                total: 0,
                single: true,
                ids: [],
                // 表格数据
                deviceRenewList: [],
                // 弹出层标题
                title: '',
                // 是否显示弹出层
                open: false,
                // 状态数据字典
                deviceOptions: [],
                statusOptions: [],
                payOptions: [],
                //续费套餐
                validTimeOptions: [],
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    deviceType: undefined,
                    status: undefined,
                    deviceCode: undefined,
                    imei: undefined,
                    agentId: undefined
                },
                // 表单参数
                form: {
                    remark: '0',
                    infoId: undefined,
                    pay: undefined,
                    //总金额
                    amount: undefined,
                    deviceCode: undefined,
                    agentId: undefined
                },
                /* 微信生成二维码开关 */
                weixinFlag: false,
                qrcode: '',
                wexinOrderNo: '',
                alipayOrderNo: '',
                agentList: [],
                renewFlag: false,
                deviceCodes: [],
                rules: {
                    deviceCode: [
                        {
                            required: true,
                            message: '设备码不能为空',
                            trigger: 'blur'
                        }
                    ]
                },
                /* 设备续费开关 */
                deviceRenewFlag: false
            };
        },
        watch: {
            open(newVal) {
                if (!newVal && this.weixinFlag && this.form.pay == 2) {
                    weixinResult(this.wexinOrderNo).then((res) => {
                        if (res.trade_state_desc) {
                            this.msgSuccess(res.trade_state_desc);
                        } else {
                            this.msgError(res.err_code_des);
                        }
                    });
                } else if (!newVal && this.weixinFlag && this.form.pay == 1) {
                    aliPayResult(this.alipayOrderNo).then((res) => {
                        console.log('支付宝支付结果', res);
                        if (
                            res.alipay_trade_query_response.code == '10000' &&
                            res.alipay_trade_query_response.trade_status ==
                                'TRADE_SUCCESS'
                        ) {
                            this.msgSuccess('支付成功');
                        } else {
                            this.msgError('支付失败');
                        }
                    });
                }
            }
        },
        created() {
            /* 获取代理商列表  */
            findAgentName().then((response) => {
                this.agentList = response.object.list;
            });
            this.getDicts('sys_device_type').then((response) => {
                this.deviceOptions = response;
            });
            this.getDicts('sys_free_status').then((response) => {
                this.statusOptions = response;
            });
            this.getList();
            // 从支付宝返回路径判断当前路由
            if (this.$route.query.charset) {
                let routeList = this.$store.getters.permission_routes;
                this.$store.commit('SET_NOWTOPACTIVE', routeList[1].menuId);
            }
            // if(this.$route.query.outTradeNo){
            //  let outTradeNo = this.$route.query.outTradeNo
            //  aliPayResult(outTradeNo).then(res =>{
            //   setTimeout(function() {
            // 	  if(res == 1){
            // 		  this.$alert('支付成功','支付结果',{
            // 			  customClass: "deleteBox",
            // 			  confirmButtonText: '确定',
            // 			  callback: action =>{}
            // 		  })
            // 	  }else if(res == 2){
            // 		  this.$alert('支付失败','支付结果',{
            // 			  customClass: "deleteBox",
            // 			  confirmButtonText: '确定',
            // 			  callback: action =>{}
            // 		  })
            // 	  }else if(res == 0){

            // 	  }
            //   },2000)
            //  })
            // }
            // 从银联返回路径判断当前路由
            if (this.$route.query.orderId) {
                let orderId = this.$route.query.orderId;
                let txnTime = this.$route.query.txnTime;
                // response = $.get(unionPayResult(orderId,txnTime))
                unionPayResult(orderId, txnTime).then((res) => {
                    setTimeout(function() {
                        if (res == 1) {
                            this.$alert('支付成功', '支付结果', {
                                customClass: 'deleteBox',
                                confirmButtonText: '确定',
                                callback: (action) => {}
                            });
                        } else if (res == 2) {
                            this.$alert('支付失败', '支付结果', {
                                customClass: 'deleteBox',
                                confirmButtonText: '确定',
                                callback: (action) => {}
                            });
                        } else if (res == 0) {
                        }
                    }, 2000);
                });
            }
        },
        methods: {
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.deviceCodes = selection.map((item) => item.deviceCode);
                this.single = !selection.length;
            },
            /* deviceCodeChange */
            deviceCodeChange(e) {
                console.log(e);
                checkSmokeDevCode(e.target.value).then((res) => {
                    if (res == 0) {
                        this.msgError('设备码不存在，请重新输入');
                        this.form.deviceCode = '';
                        return;
                    } else {
                        deviceGetWebObjByDeptId(e.target.value).then(
                            (response) => {
                                if (response.aliWebId) {
                                    this.payOptions.push({
                                        id: 1,
                                        name: '支付宝'
                                    });
                                }
                                if (response.wxWebAppid) {
                                    this.payOptions.push({
                                        id: 2,
                                        name: '微信'
                                    });
                                }
                                if (response.unionMerId) {
                                    this.payOptions.push({
                                        id: 3,
                                        name: '银联'
                                    });
                                }
                            }
                        );
                        getPayTypeListByDeviceCode(e.target.value).then(
                            (res) => {
                                this.validTimeOptions = res;
                            }
                        );
                    }
                });
            },
            /* 设备续费 */
            handleDeviceRenew() {
                this.payOptions = [];
                this.validTimeOptions = [];
                this.form.deviceCode = undefined;
                this.form.pay = undefined;
                this.form.infoId = undefined;
                this.form.amount = undefined;
                this.form.deviceCodes = [];
                this.weixinFlag = false;

                this.open = true;
                this.title = '设备续费';
                /* 代理商选择*/
                this.renewFlag = false;
                this.deviceRenewFlag = true;
            },
            /* 批量续费 */
            handleAllRenew() {
                if (!this.queryParams.agentId) {
                    this.msgError('请先选择代理商，再做批量处理');
                    return;
                }

                this.payOptions = [];
                this.form.deviceCode = undefined;
                this.form.pay = undefined;
                this.form.infoId = undefined;
                this.form.amount = undefined;

                this.form.deviceCodes = [];
                this.weixinFlag = false;

                this.open = true;
                this.title = '批量续费';
                /* 代理商选择*/
                this.renewFlag = true;
                this.deviceRenewFlag = false;

                let jwtData = JSON.parse(getUserSession('iffpJwtDecodeVal'));
                let payType = {
                    subordinate: jwtData.deptId,
                    deviceType: '0',
                    deptId: this.queryParams.agentId
                };

                getWebObjByAgentId(this.queryParams.agentId).then(
                    (response) => {
                        if (response.aliWebId) {
                            this.payOptions.push({ id: 1, name: '支付宝' });
                        }
                        if (response.wxWebAppid) {
                            this.payOptions.push({ id: 2, name: '微信' });
                        }
                        if (response.unionMerId) {
                            this.payOptions.push({ id: 3, name: '银联' });
                        }
                    }
                );
                selectPayTypeList(payType).then((res) => {
                    this.validTimeOptions = res;
                });
            },

            // 展示二维码
            payOrder() {
                // 使用$nextTick确保数据渲染
                this.$nextTick(() => {
                    this.qr = new QRCode('qrcode', {
                        width: 150,
                        height: 150, // 高度
                        text: this.qrcode // 二维码内容
                        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                        // background: '#f0f'
                        // foreground: '#ff0'
                    });
                });
            },
            /**
             * 关闭续费支付弹窗
             */
            handleDialogClose() {
                console.log('关闭续费支付弹窗');
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                selectUserDevicePayList(this.queryParams).then((response) => {
                    this.deviceRenewList = response.queryResult.list;
                    this.total = response.queryResult.total;
                    this.loading = false;
                });
            },
            //所选金额的变化
            changeCost(val) {
                this.form.amount = val;
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            //续费
            handleRenew(row) {
                this.payOptions = [];
                this.form.deviceCode = row.deviceCode;
                this.form.pay = undefined;
                this.form.infoId = undefined;
                this.form.amount = undefined;
                this.form.deviceCodes = undefined;

                this.renewFlag = false;
                this.deviceRenewFlag = false;

                let payType = {
                    subordinate: row.deptId,
                    deviceType: row.deviceType,
                    deptId: row.agentId
                };
                this.weixinFlag = false;
                getWebObjByDeptId(row.deviceCode).then((response) => {
                    if (response.aliWebId) {
                        this.payOptions.push({ id: 1, name: '支付宝' });
                    }
                    if (response.wxWebAppid) {
                        this.payOptions.push({ id: 2, name: '微信' });
                    }
                    if (response.unionMerId) {
                        this.payOptions.push({ id: 3, name: '银联' });
                    }
                });
                selectPayTypeList(payType).then((res) => {
                    this.validTimeOptions = res;
                    this.open = true;
                    this.title = '续费';
                });
            },
            // 表单重置
            reset() {
                this.form = {
                    pay: undefined,
                    amount: undefined,
                    infoId: undefined,
                    remark: '0',
                    deviceCode: undefined
                };
                this.resetForm('form');
            },
            // 字典状态字典翻译
            statusFormat(row, column) {
                return this.selectDictLabel(this.statusOptions, row.status);
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm('queryForm');
                this.handleQuery();
            },
            /** 提交按钮 */
            submitForm() {
                let deviceCode = this.form.deviceCode;
                let totalAmount = this.form.amount;
                let infoId = this.form.infoId;
                let remark = this.form.remark;
                let formData = qs.stringify({
                    deviceCodes: this.deviceCodes.join(),
                    remark: this.form.remark,
                    infoId: this.form.infoId
                });
                if (this.form.pay == 1) {
                    // this.open = false
                    // window.location.href = submitaliPay(deviceCode,totalAmount,infoId,remark)
                    if (this.renewFlag) {
                        aliPaywebBatch(formData).then((response) => {
                            if (response.qrUrl) {
                                this.qrcode = response.qrUrl;
                                this.alipayOrderNo = response.orderNo;
                                this.weixinFlag = true;
                                this.payOrder();
                            }
                        });
                    } else {
                        submitaliPay(
                            deviceCode,
                            totalAmount,
                            infoId,
                            remark
                        ).then((response) => {
                            if (response.qrUrl) {
                                this.qrcode = response.qrUrl;
                                this.alipayOrderNo = response.orderNo;
                                this.weixinFlag = true;
                                this.payOrder();
                            }
                        });
                    }
                }
                if (this.form.pay == 2) {
                    // window.location.href = submitweixinPay(deviceCode,totalAmount,infoId,remark)
                    if (this.renewFlag) {
                        wxPaywebBatch(formData).then((response) => {
                            if (response.qrUrl) {
                                this.qrcode = response.qrUrl;
                                this.wexinOrderNo = response.orderNo;
                                this.weixinFlag = true;
                                this.payOrder();
                            }
                        });
                    } else {
                        submitweixinPay(
                            deviceCode,
                            totalAmount,
                            infoId,
                            remark
                        ).then((response) => {
                            if (response.qrUrl) {
                                this.qrcode = response.qrUrl;
                                this.wexinOrderNo = response.orderNo;
                                this.weixinFlag = true;
                                this.payOrder();
                            }
                        });
                    }
                }
                if (this.form.pay == 3) {
                    this.open = false;
                    window.location.href = submitunionPay(
                        deviceCode,
                        totalAmount,
                        infoId,
                        remark
                    );
                }
            },
            /** 导出按钮操作 */
            handleExport() {
                let that = this;
                this.$confirm('是否确认导出所有数据项?', '警告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'deleteBox',
                    type: 'warning'
                })
                    .then(function() {
                        return outUserDevicePayExcel(that.queryParams);
                    })
                    .then((response) => {
                        let blob = new Blob([response], {
                            type: 'application/vnd.ms-excel'
                        }); //type是文件类，详情可以参阅blob文件类型
                        let objectUrl = URL.createObjectURL(blob); //生成下载链接
                        window.location.href = objectUrl;
                    })
                    .catch(function() {});
            }
        }
    };
</script>
<style lang="scss" scoped>
    .el-radio {
        margin: 10px;
    }

    /deep/ .el-dialog__body {
        color: #34e6f1;
    }
</style>
