import request from '@/utils/request'

//列表
export function selectRankPayRuleList(data) {
  return request({
    url: '/free/rankPay/selectRankPayRuleList',
    method: 'post',
	data: data
  })
}

//根据id查询支付规则
export function selectRankPayRuleById(ruleId) {
  return request({
    url: '/free/rankPay/selectRankPayRuleById?ruleId=' + ruleId,
    method: 'post'
  })
}

//新增
export function insertRankPayRule(data) {
  return request({
    url: '/free/rankPay/insertRankPayRule',
    method: 'post',
	data: data
  })
}

//修改
export function updateRankPayRule(data) {
  return request({
    url: '/free/rankPay/updateRankPayRule',
    method: 'post',
	data: data
  })
}

//删除
export function deleteRankPayRuleById(ruleId) {
  return request({
    url: '/free/rankPay/deleteRankPayRuleById?ruleId=' + ruleId,
    method: 'post'
  })
}

