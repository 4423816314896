<template>
  <div class="">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="设备类型" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          placeholder="设备类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in deviceOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
	  <el-form-item label="产品ID" prop="deviceCode">
	    <el-input
	      v-model="queryParams.deviceCode"
	      placeholder="产品ID"
	      clearable
	      size="small"
	      style="width: 240px"
	      @keyup.enter.native="handleQuery"
	    />
	  </el-form-item>
	  <el-form-item label="IMEI" prop="imei">
	    <el-input
	      v-model="queryParams.imei"
	      placeholder="IMEI"
	      clearable
	      size="small"
	      style="width: 240px"
	      @keyup.enter.native="handleQuery"
	    />
	  </el-form-item>
	  <el-form-item label="支付途径" prop="status">
	    <el-select
	      v-model="queryParams.status"
	      placeholder="支付途径"
	      clearable
	      size="small"
	      style="width: 240px"
	    >
	      <el-option
	        v-for="dict in statusOptions"
	        :key="dict.dictValue"
	        :label="dict.dictLabel"
	        :value="dict.dictValue"
	      />
	    </el-select>
	  </el-form-item>
	  <el-form-item label="缴费日期">
	    <el-date-picker
	      v-model="dateRange"
	      size="small"
	      style="width: 240px"
	      value-format="yyyy-MM-dd"
	      type="daterange"
	      range-separator="-"
	      start-placeholder="开始时间"
	      end-placeholder="结束时间"
	    ></el-date-picker>
	  </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
     <el-col :span="1.5">
        <el-button
          icon="el-icon-download"
          size="mini"
    	  @click="handleExport"
        type="warning"
        >导出报表</el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="paymentList">
      <el-table-column label="编号" type="index" width="55" align="center" />
      <el-table-column label="设备种类" align="center" prop="typeName" :show-overflow-tooltip="true" />
      <el-table-column label="产品ID" align="center" prop="deviceCode" :show-overflow-tooltip="true" />
      <el-table-column label="IMEI" align="center" prop="imei" :show-overflow-tooltip="true" />
	  <el-table-column label="缴费时间" align="center" prop="createTime" :show-overflow-tooltip="true" />
	  <el-table-column label="缴费金额" align="center" prop="cost" :show-overflow-tooltip="true" />
	  <el-table-column label="缴费时长" align="center" prop="renewTime" :show-overflow-tooltip="true" />
	  <el-table-column label="支付途径" align="center" prop="way" :show-overflow-tooltip="true" />
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />
  </div>
</template>

<script>
	import { selectUserPayRecordsList,outUserDeviceExcel } from '@/api/smoke/renew.js'
export default {
  name: "payment",
  data() {
    return {
      // 遮罩层
      loading: true,
	  dateRange: [],
      // 总条数
      total: 0,
      // 表格数据
      paymentList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
	  deviceOptions: [],
	  statusOptions: [{dictValue: 0,dictLabel: '线上缴费'},{dictValue: 1,dictLabel: '线下缴费'}],
	  form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
		deviceType: undefined,
		status: undefined,
		deviceCode: undefined,
		imei: undefined
      },
    }
  },
  created() {
	  this.getDicts("sys_device_type").then(response => {
	    this.deviceOptions = response;
	  });
      this.getList();
  },
  methods: {

    /** 查询列表 */
    getList() {
      this.loading = true;
      selectUserPayRecordsList(this.addDateRange(this.queryParams,this.dateRange)).then(response => {
          this.paymentList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
    // 表单重置
    reset() {
      this.form = {
        deviceType: undefined,
        status: undefined,
        deviceCode: undefined,
        imei: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
	/** 导出按钮操作 */
	handleExport() {
	let queryData = this.queryParams
	  this.$confirm('是否确认导出所有数据项?', "警告", {
	      confirmButtonText: "确定",
	      cancelButtonText: "取消",
		  customClass: "deleteBox",
	      type: "warning"
	    }).then(function() {
		
	      return outUserDeviceExcel(queryData);
		  
	    }).then(response => {
			let blob = new Blob([response], {type: "application/vnd.ms-excel"});//type是文件类，详情可以参阅blob文件类型
			let objectUrl = URL.createObjectURL(blob);//生成下载链接
			window.location.href = objectUrl
	     
	    }).catch(function() {
	
		});
	},
  }
};
</script>
