import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'

export function getToken(TokenKey) {
  return Cookies.get(TokenKey)
}

export function setToken(TokenKey,token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken(TokenKey) {
  return Cookies.remove(TokenKey)
}

 export function getUserSession(key) {
    return sessionStorage.getItem(key);
  }
  export function setUserSession(key, value) {
    return sessionStorage.setItem(key, value);
  }
  export function delUserSession(key) {
    return sessionStorage.removeItem(key)
  }
