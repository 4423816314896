<template>
  <div class="monitor">
    <!-- 地图容器 -->
    <el-back class="elBack" ref="elBack" v-if="mapFlag && !deviceMapFlag">
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        @ready="handler"
        :scrollWheelZoom="true"
        id="BMap"
        :ak="MAP_KEY"
      >
      </baidu-map>
    </el-back>
    <el-back class="elBack" ref="elBack" v-if="deviceMapFlag">
      <div class="mapBox">
        <div
          ref="imgWrap"
          @mousewheel.prevent="rollImg"
          @DOMMouseScroll.prevent="rollImg"
          class="modal-img-wrap"
        >
          <div class="imgBox" ref="img" @mousedown="moveImg">
            <img
              v-for="(item, index) in userDevices"
              :src="item.img"
              :key="index"
              @click="deviceClick(item)"
              alt=""
              class="smallImg"
              :style="{
                left: item.x - 20.5 + 'px',
                top: item.y - 20.5 + 'px'
              }"
            />
            <img :src="$viewIp + deviceMap.pic" alt="" class="modal-img" />
          </div>
        </div>

        <div
          class="deviceInfoBox"
          v-if="deviceInfoFlag"
          :style="{
            left: deviceInfo.positionX + 'px',
            top: deviceInfo.positionY + 'px'
          }"
        >
          <i
            class="el-icon-close"
            style="position: absolute;right: 20px;top: 30px;"
            @click="iconClick"
          ></i>
          <div style="margin-top:18px;font-size: 26px;">
            {{ deviceInfo.deviceName }}
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>产品ID:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.deviceCode }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>设备名称:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.deviceName }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>设备状态:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.statusName }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>设备剩余电量:</div>
            <div style="font-size:20px;width: 169px;">
              {{ deviceInfo.electricQuantity }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>当前值:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.concn }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>上报时间:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.newDate }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>安装位置:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.buildName }}{{ deviceInfo.unitName
              }}{{ deviceInfo.tier }}层{{ deviceInfo.roomNum }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>安装位置:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.address }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>责任人:</div>
            <div style="font-size:20px;width: 200px;">
              {{ deviceInfo.functionaryName }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>责任电话:</div>
            <div style="font-size:20px;width: 200px;word-break:break-all;">
              {{ deviceInfo.phone }}
            </div>
          </div>
          <div
            style="margin-top:18px;display:flex;justify-content:flex-start;align-items:baseline;"
          >
            <div>物联网卡状态:</div>
            <div style="font-size:20px;width: 169px;">
              {{ deviceInfo.cardStatus }}
            </div>
          </div>
        </div>
      </div>
    </el-back>
    <div class="deviceTitle" v-if="deviceMapFlag">
      <el-box :width="'100%'" :height="'78px'" style="line-height: 78px;">
        <div class="titleBox">
          <h2>{{ nowDataInfo.deptName }}</h2>
          <div>
            <div class="titleBack" @click="backTop">
              <i class="el-icon-back"></i>返回
            </div>
            <i
              :class="moduleFlag ? 'el-icon-full-screen' : 'el-icon-aim'"
              class="deviceStatus"
              @click="changeDeviceStatus"
            ></i>
          </div>
        </div>
      </el-box>
    </div>
    <div class="monitorLeft" v-if="moduleFlag">
      <div class="monitorSmallBox">
        <p>今日消防</p>
        <div>
          <div style="font-size: 14px;margin: 10px 0 10px 50px;color: #FBB007;">
            今日报警
          </div>
          <div class="alarmBox">
            <div v-for="(item, index) in alarmCount.allAlarmCount" :key="index">
              <div style="font-size: 30px;" v-if="item == ','">
                ,
              </div>
              <div class="alarmNumBox" v-else>{{ item }}</div>
            </div>
            <div style="margin-left: 5px;">次</div>
          </div>
        </div>
        <div class="alarmBottomBox">
          <el-box
            :color="'#FB590A'"
            :shadowColor="'rgba(251, 89, 10, 0.74)'"
            :backColor="'rgba(251, 89, 10, 0.2)'"
          >
            <div>今日警情</div>
            <div>
              <span style="font-size: 18px;">{{
                alarmCount.callAlarmCount
              }}</span
              >次
            </div>
          </el-box>
          <el-box
            :color="'#D408D2'"
            :shadowColor="'rgba(230, 5, 228, 0.74)'"
            :backColor="'rgba(230, 5, 228, 0.2)'"
          >
            <div>今日设备异常</div>
            <div>
              <span style="font-size: 18px;">{{
                alarmCount.exceptionCount
              }}</span
              >次
            </div>
          </el-box>
          <el-box
            :color="'#F2296B'"
            :shadowColor="'rgba(240, 26, 101, 0.74)'"
            :backColor="'rgba(240, 26, 101, 0.2)'"
          >
            <div>今日离线</div>
            <div>
              <span style="font-size: 18px;">{{ alarmCount.outLineCount }}</span
              >次
            </div>
          </el-box>
        </div>
      </div>
      <div class="monitorSmallBox">
        <p>报警统计</p>
        <alarmTypeData :chart-data="alarmTypeData" />
      </div>
      <div class="monitorSmallBox">
        <p>年度报警统计</p>
        <yearAlarmColumn :chart-data="yearAlarmData" />
      </div>
    </div>
    <div class="monitorRight" v-if="moduleFlag">
      <div class="monitorSmallBox">
        <p>当前设备总览</p>
        <nowDeviceStatus :chart-data="nowDeviceStatus" />
      </div>
      <div class="monitorSmallBox">
        <p v-if="!deviceMapFlag">警情高发单位</p>
        <p v-else>警情高发设备</p>
        <deviceAlarm
          :chart-data="deviceAlarmData"
          :maxNum="deviceAlarmMaxNum"
        />
      </div>
      <div class="monitorSmallBox">
        <p>警情高发时段</p>
        <deviceAlarm :chart-data="alarmTimerData" :maxNum="alarmTimerMaxNum" />
      </div>
    </div>
  </div>
</template>

<script>
  import { BaiduMap, BmMapType } from 'vue-baidu-map/components/index'
  // import BMapLib from 'BMapLib'
  import deviceAlarm from './dashBoard/deviceAlarm.vue'
  import alarmTypeData from './dashBoard/alarmTypeData.vue'
  import nowDeviceStatus from './dashBoard/nowDeviceStatus.vue'
  import yearAlarmColumn from './dashBoard/yearAlarmColumn.vue'
  import {
    todayAlarm,
    deptDeviceInfo,
    alarmHignDeptTop5,
    yearAlarmColumns,
    alarmHignTimeTop5,
    yearAlarms,
    userDeparts,
    userDepartInfo,
    userTrueMap,
    userDeviceList,
    getDeviceInfo,
    todayAlarmByDept,
    deptDeviceInfoByDept,
    yearAlarmsByDept,
    alarmHignDeviceTop5ByDept,
    yearAlarmColumnsByDept,
    alarmHignTimeTop5ByDept
  } from '@/api/monitor/alarm'
  import { toThousands } from '@/utils/index'
  import { getUserSession } from '@/utils/auth'
  import { getAlarmLabel } from '@/api/alarm/index'
  export default {
    name: 'monitor',
    data() {
      return {
        center: process.env.VUE_APP_LOCAL_CENTER, //经纬度
        // center: { lng: 112.533425, lat: 32.996291 }, //经纬度
        zoom: 10, //地图展示级别
        alarmCount: {},
        deviceAlarmData: [],
        alarmTypeData: [],
        nowDeviceStatus: [],
        yearAlarmData: [],
        alarmTimerData: [],
        deviceAlarmMaxNum: 0,
        alarmTimerMaxNum: 0,
        /* 监控弹窗 */
        map: '',
        lastInfoBox: '',
        nowDataInfo: '',
        /* 经销商列表 */
        dotList: [],
        mapFlag: false,
        /* 获取user设备列表 */
        deviceMapFlag: false,
        userDevices: [],
        deviceMap: {},
        moduleFlag: true,
        /* 设备详情 */
        deviceInfoFlag: false,
        deviceInfo: {},
        userInfo: {},
        imgRoom: 100,
        MAP_KEY: process.env.VUE_APP_MAP_KEY
      }
    },
    components: {
      BaiduMap,
      BmMapType,
      deviceAlarm,
      alarmTypeData,
      nowDeviceStatus,
      yearAlarmColumn
    },
    mounted() {},
    methods: {
      /**
       * @deprecated 地图初始化
       */

      /* 移动图片 */
      moveImg(e) {
        e.preventDefault()
        // 获取元素
        let imgWrap = this.$refs.imgWrap
        let img = this.$refs.img
        let x = e.pageX - img.offsetLeft
        let y = e.pageY - img.offsetTop
        console.log(e)
        // 添加鼠标移动事件
        imgWrap.addEventListener('mousemove', move)
        function move(e) {
          img.style.left = e.pageX - x + 'px'
          img.style.top = e.pageY - y + 'px'
        }
        // 添加鼠标抬起事件，鼠标抬起，将事件移除
        img.addEventListener('mouseup', () => {
          imgWrap.removeEventListener('mousemove', move)
        })
        // 鼠标离开父级元素，把事件移除
        imgWrap.addEventListener('mouseout', () => {
          imgWrap.removeEventListener('mousemove', move)
        })
      },

      /* 缩放图片*/
      rollImg(e) {
        /* e.detail兼容火狐 */
        let wheelData = event.wheelDelta / 12 || (-e.detail * 40) / 12
        this.imgRoom += wheelData
        /* 最小范围 和 最大范围 的图片缩放尺度 */

        if (this.imgRoom >= 80 && this.imgRoom < 500) {
          let scale = this.imgRoom / 100
          this.$refs.img.style.transform = 'scale(' + scale + ')'
        } else if (this.imgRoom < 80) {
          /* 防止this.imgRoom过大过小 */
          this.imgRoom = 70
        } else if (this.imgRoom >= 500) {
          this.imgRoom = 500
        }
        return false
      },
      /* 获取设备详情 */
      deviceClick(val) {
        getDeviceInfo(val.deviceCode).then((res) => {
          let width = this.$refs.elBack.$el.offsetWidth
          let height = this.$refs.elBack.$el.offsetHeight
          let top = height - 650
          /* 判断Y，height743 */
          if (val.y < top) {
            res.positionX = val.x
            res.positionY = val.y
          } else if (val.y > 650 && val.y < height) {
            res.positionX = val.x
            res.positionY = val.y - 650
          } else if (val.y > top && val.y < 650) {
            res.positionX = val.x
            res.positionY = val.y - 325
            if (val.y < 325) {
              res.positionY = 0
            } else if (val.y > height - 325) {
              res.positionY = height - 650
            }
          }
          /* 判断X */
          if (val.x > width - 330 && val.x < width) {
            res.positionX = val.x - 330
          }
          this.deviceInfo = res
          console.log('设备详情--=', this.deviceInfo)
          this.deviceInfoFlag = true
        })
      },
      iconClick() {
        this.deviceInfoFlag = false
      },
      /* 返回代理商界面 */
      backTop() {
        this.deviceInfoFlag = false
        this.deviceMapFlag = false
        this.moduleFlag = true
        this.getAgentData()
      },
      /* 改变查看设备时状态 */
      changeDeviceStatus() {
        this.moduleFlag = !this.moduleFlag
      },
      /* 添加标点 */
      addMarker(point, imgUrl, data) {
        let that = this
        var myIcon = new BMap.Icon(imgUrl, new BMap.Size(60, 55))
        var marker = new BMap.Marker(point, { icon: myIcon })
        this.map.addOverlay(marker)
        ;(function(data) {
          //点标记的点击事件
          marker.addEventListener('click', function(e) {
            userDepartInfo(data.deptId).then((response) => {
              that.openInfo(response, function(str) {
                var infoBox = new BMapLib.InfoBox(that.map, str, {
                  boxStyle: {
                    width: '330px',
                    height: '388px'
                  },
                  closeIconMargin: '17px 17px 0 0',
                  closeIconUrl: require('../../assets/image/monitorError.png'),
                  enableAutoPan: false,
                  align: INFOBOX_AT_TOP
                  // ,offset: new BMap.Size(-50,0)
                })

                if (that.lastInfoBox) {
                  //判断上一个窗体是否存在，若存在则执行close
                  that.lastInfoBox.close()
                }
                that.lastInfoBox = infoBox
                that.nowDataInfo = response
                infoBox.open(marker)

                // marker.enableDragging();
              })
            })
          })
        })(data)
      },
      openInfo(data, callback) {
        let backGroundImgBig = require('../../assets/image/dialogBigInfo.png')
        let backGroundImgSmall = require('../../assets/image/dialogSmallInfo.png')
        let flag = this.userInfo.deptId == data.deptId ? 'block' : 'none'
        var str = `<div style="background: url(${backGroundImgBig});background-size: 100% 100%;width: 330px;height: 388px;padding: 30px;color: #E1E3F0">
					<div style="display:flex;justify-content:space-between;align-items:center;margin-top:20px">
						<p>${data.deptName}</p>
						<div style="background: url(${backGroundImgSmall});display:${flag};background-size: 100% 100%;width: 105px;height: 48px;line-height:45px;text-align:center;font-size:14px;"  onclick="onInfoWindowClick()">
								查看详情							
						</div>
					</div>
					<div style="margin-top:20px;display:flex;justify-content:flex-start;align-items:center;">
						<div>单位名称:</div>
						<div style="font-size:20px">${data.deptName}</div>
					</div>
					<div style="margin-top:20px;display:flex;justify-content:flex-start;align-items:center;">
						<div>设备总数:</div>
						<div style="font-size:20px">${data.deviceCount}</div>
					</div>
					<div style="margin-top:20px;display:flex;justify-content:flex-start;align-items:center;">
						<div>正常设备:</div>
						<div style="font-size:20px">${data.normalCount}</div>
					</div>
					<div style="margin-top:20px;display:flex;justify-content:flex-start;align-items:center;">
						<div>单位地址:</div>
						<div style="font-size:20px">${data.deptAddress}</div>
					</div>
					<div style="margin-top:20px;display:flex;justify-content:flex-start;align-items:center;">
						<div>本年报警统计:</div>
						<div style="font-size:20px">${data.alarmCount}</div>
					</div>
			  </div>`
        callback(str)
      },

      /* 获取地图*/
      handler({ BMap, map }) {
        this.map = map
        var mapStyle = { style: 'midnight' }
        map.setMapStyle(mapStyle)
        map.setCurrentCity(process.env.VUE_APP_LOCAL_CENTER)
        let that = this
        var myGeo = new BMap.Geocoder()
        this.dotList.forEach((item, index) => {
          let place = item.province + item.city + item.area + item.deptAddress
          myGeo.getPoint(place, function(point) {
            console.log(point)
            var point = new BMap.Point(point.lng, point.lat)
            that.addMarker(
              point,
              require('../../assets/image/deptIcon.png'),
              item
            )
          })
        })
      },
      /* 获取首页详情 */
      getAgentData() {
        /* 今日消防 */
        todayAlarm().then((response) => {
          /* 处理违规数 */
          let newAlarmCount = ''
          if (response.allAlarmCount == null) response.allAlarmCount = ''
          response.allAlarmCount = String(response.allAlarmCount)
          if (response.allAlarmCount.length > 7) {
            newAlarmCount = response.allAlarmCount.splice(-7)
          } else {
            newAlarmCount = response.allAlarmCount
            for (let i = 0; i < 7 - response.allAlarmCount.length; i++) {
              newAlarmCount = '0' + newAlarmCount
            }
          }
          response.allAlarmCount = toThousands(newAlarmCount)
          this.alarmCount = response
        })
        /* 年度报警统计 */
        yearAlarms().then((response) => {
          this.alarmTypeData = [
            { name: '离线', value: response.outLineCount },
            { name: '警情', value: response.callAlarmCount },
            { name: '设备异常', value: response.exceptionCount }
          ]
        })
        /* 设备详情 */
        deptDeviceInfo().then((response) => {
          this.nowDeviceStatus = [
            { name: '正常', value: response.normalCount },
            { name: '离线', value: response.offlineCount },
            { name: '警情', value: response.alarmCount },
            { name: '设备异常', value: response.abnormalCount }
          ]
        })
        this.deviceAlarmData = []
        this.deviceAlarmMaxNum = 0
        /* 高发单位统计 */
        alarmHignDeptTop5().then((response) => {
          response.forEach((item, index) => {
            if (item.count > this.deviceAlarmMaxNum) {
              this.deviceAlarmMaxNum = item.count
            }
            this.deviceAlarmData.push({
              name: item.deptName,
              value: item.count
            })
          })
        })
        /* 年度报警统计 */
        yearAlarmColumns().then((response) => {
          let yearData = [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12'
          ]
          for (let j = 0; j < yearData.length; j++) {
            this.yearAlarmData.push({
              month: yearData[j],
              value: 0
            })
            for (let i = 0; i < response.length; i++) {
              if (response[i].alarmTime == yearData[j]) {
                this.yearAlarmData[j].value = response[i].count
                break
              }
            }
          }
        })
        /* 警情高发时段统计 */
        this.alarmTimerMaxNum = 0
        this.alarmTimerData = []
        alarmHignTimeTop5().then((response) => {
          response.forEach((item, index) => {
            if (item.count > this.alarmTimerMaxNum) {
              this.alarmTimerMaxNum = item.count
            }
            this.alarmTimerData.push({
              name: item.alarmTime,
              value: item.count
            })
          })
        })
      },
      /* 获取单位详情数据 */
      getDeptData() {
        /* 今日消防 */
        todayAlarmByDept().then((response) => {
          /* 处理违规数 */
          let newAlarmCount = ''
          if (response.allAlarmCount == null) response.allAlarmCount = ''
          response.allAlarmCount = String(response.allAlarmCount)
          if (response.allAlarmCount.length > 7) {
            newAlarmCount = response.allAlarmCount.splice(-7)
          } else {
            newAlarmCount = response.allAlarmCount
            for (let i = 0; i < 7 - response.allAlarmCount.length; i++) {
              newAlarmCount = '0' + newAlarmCount
            }
          }
          response.allAlarmCount = toThousands(newAlarmCount)
          this.alarmCount = response
        })
        /* 年度报警统计 */
        yearAlarmsByDept().then((response) => {
          this.alarmTypeData = [
            { name: '离线', value: response.outLineCount },
            { name: '警情', value: response.callAlarmCount },
            { name: '设备异常', value: response.exceptionCount }
          ]
        })
        /* 设备详情 */
        deptDeviceInfoByDept().then((response) => {
          this.nowDeviceStatus = [
            { name: '正常', value: response.normalCount },
            { name: '离线', value: response.offlineCount },
            { name: '警情', value: response.alarmCount },
            { name: '设备异常', value: response.abnormalCount }
          ]
        })
        /* 高发设备统计 */
        this.deviceAlarmMaxNum = 0
        this.deviceAlarmData = []
        alarmHignDeviceTop5ByDept().then((response) => {
          response.forEach((item, index) => {
            if (item.count > this.deviceAlarmMaxNum) {
              this.deviceAlarmMaxNum = item.count
            }
            this.deviceAlarmData.push({
              name: item.deviceCode,
              value: item.count
            })
          })
        })
        /* 年度报警统计 */
        this.yearAlarmData = []
        yearAlarmColumnsByDept().then((response) => {
          let yearData = [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12'
          ]
          for (let j = 0; j < yearData.length; j++) {
            this.yearAlarmData.push({
              month: yearData[j],
              value: 0
            })
            for (let i = 0; i < response.length; i++) {
              if (response[i].alarmTime == yearData[j]) {
                this.yearAlarmData[j].value = response[i].count
                break
              }
            }
          }
        })
        /* 警情高发时段统计 */
        this.alarmTimerMaxNum = 0
        this.alarmTimerData = []
        alarmHignTimeTop5ByDept().then((response) => {
          response.forEach((item, index) => {
            if (item.count > this.alarmTimerMaxNum) {
              this.alarmTimerMaxNum = item.count
            }
            this.alarmTimerData.push({
              name: item.alarmTime,
              value: item.count
            })
          })
        })
      }
    },
    mounted() {
      this.userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'))
      /* 点击查看详情 */
      let that = this
      window.onInfoWindowClick = function(data) {
        console.log(that.nowDataInfo)
        /* 获取平面图信息 */
        userTrueMap({ deptId: that.nowDataInfo.deptId }).then((res) => {
          if (res.queryResult.list.length == 0) {
            that.msgError('请先绑定平面图！')
          } else {
            that.deviceMapFlag = true
            that.deviceMap = res.queryResult.list[0]
            that.moduleFlag = true
            /* 获取图表数据 */
            that.getDeptData()
            /* 获取设备信息 */
            that.userDevices = []
            userDeviceList(that.nowDataInfo.deptId).then((res) => {
              /* 缩放坐标 */
              let xScale = that.$refs.elBack.$el.offsetWidth / 937.5
              let yScale = that.$refs.elBack.$el.offsetHeight / 548
              res.forEach((item, index) => {
                item.x = item.x * xScale
                item.y = item.y * yScale
              })

              res.forEach((item) => {
                item.img = require(`../../assets/image/000${item.deviceKey}.png`)
              })
              console.log('获取设备信息 all -----------=', res)
              that.userDevices = res
            })
          }
        })
      }

      /* 获取代理商 */
      userDeparts().then((response) => {
        this.dotList = response
        this.mapFlag = true
      })

      this.getAgentData()
    }
  }
</script>

<style scoped lang="scss">
  .monitor {
    height: 100%;

    /deep/ .el-scrollbar__view {
      height: 100%;
    }

    .map {
      position: absolute;
      left: 0;
      top: 1px;
      width: 100%;
      height: 100%;

      /* 去除百度地图版权那行字 和 百度logo */
      /deep/ .BMap_cpyCtrl {
        display: none;
      }

      /deep/ .anchorBL {
        display: none;
      }
    }

    .elBack {
      padding: 0;
      width: 100%;
      height: 100%;

      .mapBox {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        .modal-img-wrap {
          position: relative;
          width: 100%;
          height: 100%;

          overflow: hidden;

          .imgBox {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            .modal-img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .smallImg {
          position: absolute;
          width: 41px;
          height: 41px;
          left: -41px;
          right: -41px;
        }
      }
    }

    .monitorSmallBox {
      width: 100%;
      height: 32%;
      background-image: url('../../assets/image/monitorBack.png');
      background-size: 100% 100%;
      margin-top: 3%;

      p {
        font-size: 20px;
        padding-top: 17px;
        padding-left: 20px;
      }

      .alarmBox {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        color: #fbb007;
        font-weight: bold;

        .alarmNumBox {
          width: 32px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          border-radius: 5px;
          background-color: rgba(140, 99, 8, 0.2);
          margin-left: 5px;
          box-shadow: 0px 0px 8px 0px rgba(140, 99, 8, 0.74) inset;
        }
      }
    }

    .monitorLeft {
      position: absolute;
      left: 30px;
      top: 15px;
      width: 410px;
      height: calc(100% - 30px);

      .monitorSmallBox:nth-child(1) {
        margin-top: 0%;
      }
    }

    .monitorRight {
      position: absolute;
      right: 30px;
      top: 15px;
      width: 410px;
      height: calc(100% - 30px);

      .monitorSmallBox:nth-child(1) {
        margin-top: 0%;
      }
    }

    .deviceTitle {
      width: calc(100% - 920px);
      position: absolute;
      left: 50%;
      top: 15px;
      transform: translate(-50%, 0);

      // background-color: rgba(10, 22, 59, 0.9);
      .titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h2 {
          margin: 0;
        }

        .deviceStatus {
          font-size: 20px;
          cursor: pointer;
        }

        .titleBack {
          background-image: url('../../assets/image/back.png');
          background-size: 100% 100%;
          width: 128px;
          height: 48px;
          line-height: 48px;
          cursor: pointer;
          text-align: center;
          margin-right: 10px;
        }
      }
    }

    .alarmBottomBox {
      padding: 15px 28px;
      display: flex;
      justify-content: space-between;
    }

    .deviceInfoBox {
      background: url('../../assets/image/deviceInfoBack.png');
      background-size: 100% 100%;
      width: 330px;
      height: 650px;
      padding: 30px;
      color: #e1e3f0;
      position: absolute;
      z-index: 1000;
    }
  }
</style>
