<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<div class="formBox">信息搜索</div>
				<normalDevice v-if="tabVal == 1"></normalDevice>
				<arrearsDevice v-if="tabVal == 2"></arrearsDevice>	

			</el-back>
  </div>
</template>

<script>

import normalDevice from './normalDevice'
import arrearsDevice from './arrearsDevice'

export default {
  name: "renew",
  data(){
	  return {
		tabList:[
			{ id:1,name:'正常设备' },
			{ id:2,name:'欠缴设备' }
		],
		 tabVal:1
	  }
  },
  components:{
	  normalDevice,
	  arrearsDevice
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
