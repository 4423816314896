import request from '@/utils/request'

// 查询设备信息列表
export function listSmokeDevice(data) {
  return request({
    url: '/monitor/device/selectUserSmokeDetectorList',
    method: 'post',
    data:data
  })
}

// 查询设备信息详细
export function getSmokeDevice(deviceId) {
  return request({
    url: '/monitor/device/selectUserSmokeDetectorById?deviceId=' + deviceId,
    method: 'post'
  })
}

// 新增设备信息
export function addSmokeDevice(data) {
  return request({
    url: '/monitor/device/insertUserSmokeDetector',
    method: 'post',
    data: data
  })
}

// 修改设备信息
export function updateSmokeDevice(data) {
  return request({
    url: '/monitor/device/updateUserSmokeDetector',
    method: 'post',
    data: data
  })
}

export function replaceDevice(data) {
  return request({
    url: '/monitor/device/replaceDevice?' + data,
    method: 'post'

  })
}
// 删除设备信息
export function deleteUserSmokeDetectorById(deviceId) {
  return request({
    url: '/monitor/device/deleteUserSmokeDetectorById?deviceId=' + deviceId,
    method: 'post'
  })
}

// 批量删除设备信息
export function deleteUserSmokeDetectorList(data) {
  return request({
    url: '/monitor/device/deleteUserSmokeDetectorList',
    method: 'post',
	data:data
  })
}


// 自检
export function selfTest(deviceCode) {
  return request({
    url: '/monitor/devAlaRecord/selfTest?deviceCode=' + deviceCode,
    method: 'post'
  })
}

// 导出设备信息
export function exportSmokeDevice(data) {
  return request({
    url: '/monitor/deviceExcel/outUserSmokeDetectorExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}

// 导入设备信息
export function importSmokeDevice() {
  return  process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/addUserSmokeDetectors'
}
// 查询设备已分配得责任人
export function deviceReceivePageList(deviceCode) {
  return request({
    url: '/monitor/notRecDev/deviceReceivePageList?deviceCode=' + deviceCode,
    method: 'post'
  })
}
// 批量操作按钮是否有权限
export function checkSmokeDetector(data){
  return request({
    url : `/monitor/eapi/aep/down/checkSmokeDetector`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 批量操作按钮是否有权限
export function sendOrderAll(data){
  return request({
    url : `/monitor/eapi/aep/down/sendOrderAll`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 远程复位
export function resetButton(deviceCode){
  return request({
    url: '/monitor/devAlaRecord/resetButton?deviceCode=' + deviceCode,
    method: 'post'
  })
}