import request from '@/utils/request'

// 查询违规列表
export function listAlarm(page,size,data) {
  return request({
    url: 'monitor/alarm/findAlarmList.do?page=' + page +'&size='+size,
    method: 'post',
    data: data
  })
}



// 批量删除违规列表
export function delAlarms(alarmIds) {
  return request({
    url: 'monitor/alarm/remove.do?alarmIds=' + alarmIds,
    method: 'post'
  })
}

// 按年查询违规列表信息
export function listAlarmByYear(year) {
  return request({
    url: 'monitor/alarm/alarmCountByYear?year=' + year,
    method: 'post',

  })
}
// 查看最新违规信息
export function getNewAlarm() {
  return request({
    url: 'monitor/alarm/findAlarm',
    method: 'post'
  })
}
// 修改违规信息状态
export function updateNewAlarm(data) {
  return request({
    url: 'monitor/alarm/updateAlarmStatus',
    method: 'post',
    data:data
  })
}





export function todayAlarm() {
  return request({
    url: '/monitor/devAlaRecord/agentTodayAlarm',
    method: 'post'
  })
}

export function deptDeviceInfo() {
  return request({
    url: '/monitor/device/selectDeptDeviceInfo2',
    method: 'post'
  })
}

export function yearAlarms() {
  return request({
    url: '/monitor/devAlaRecord/agentYearAnaly',
    method: 'post'
  })
}

export function alarmHignDeptTop5() {
  return request({
    url: '/monitor/devAlaRecord/alarmHignDeptTop5',
    method: 'post'
  })
}

export function yearAlarmColumns() {
  return request({
    url: '/monitor/devAlaRecord/agentYearAlarmColumn',
    method: 'post'
  })
}

export function alarmHignTimeTop5() {
  return request({
    url: '/monitor/devAlaRecord/agentAlarmHignTimeTop5',
    method: 'post'
  })
}
/* 单位详情 */
export function todayAlarmByDept() {
  return request({
    url: '/monitor/devAlaRecord/todayAlarm',
    method: 'post'
  })
}

export function deptDeviceInfoByDept() {
  return request({
    url: '/monitor/device/selectDeptDeviceInfo',
    method: 'post'
  })
}

export function yearAlarmsByDept() {
  return request({
    url: '/monitor/devAlaRecord/yearAnaly',
    method: 'post'
  })
}

export function alarmHignDeviceTop5ByDept() {
  return request({
    url: '/monitor/devAlaRecord/alarmHignDeviceTop5',
    method: 'post'
  })
}

export function yearAlarmColumnsByDept() {
  return request({
    url: '/monitor/devAlaRecord/yearAlarmColumn',
    method: 'post'
  })
}

export function alarmHignTimeTop5ByDept() {
  return request({
    url: '/monitor/devAlaRecord/alarmHignTimeTop5',
    method: 'post'
  })
}

/* 获取当前用户及一下单位 */
export function userDeparts() {
  return request({
    url: '/ucenter/dept/deptList',
    method: 'post'
  })
}
/* 获取部门详情 */
export function userDepartInfo(deptId) {
  return request({
    url: '/monitor/device/selectDeptSmokeDetectorInfo?deptId=' + deptId,
    method: 'post'
  })
}
/* 获取单位平面图 */
export function userTrueMap(data) {
  return request({
    url: '/monitor/device/plan/selectDevicePlanList',
    method: 'post',
	data:data
	
  })
}
/* 获取用户设备位置信息 */
export function userDeviceList(deptId) {
  return request({
    url: '/monitor/device/selectDeptSmokeDetectorList?deptId=' + deptId,
    method: 'post'
  })
}

/* 获取设备详情 */
export function getDeviceInfo(deviceCode) {
  return request({
    url: '/monitor/device/selectUserSmokeDetectorInfo?deviceCode=' + deviceCode,
    method: 'post'
  })
}