<template>
  <div class="components-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col :span="24" :xs="24">
			<div class="formBox">信息搜索</div>
			<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="118px">
			          <el-form-item label="ICCID" prop="iccid">
			            <el-input
			              v-model="queryParams.iccid"
			              placeholder="请输入ICCID"
			              clearable
			              size="small"
			              style="width: 240px"
			              @keyup.enter.native="handleQuery"
			            />
			          </el-form-item>
					  <el-form-item label="删除前所属单位" prop="deptId">
					  	<treeselect v-model="queryParams.deptId" :options="deptOptions" placeholder="请选择归属部门" style="width: 240px;" />
					  </el-form-item>
					  <el-form-item label="删除人单位" prop="deptId">
					  	<treeselect v-model="queryParams.deptId" :options="deptOptions" placeholder="请选择" style="width: 240px;" />
					  </el-form-item>
					  <el-form-item label="删除时间">
					  	<el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd" type="daterange"
					  	 range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					  </el-form-item>
			          
			          <el-form-item>
			            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
			            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			          </el-form-item>
			        </el-form>
			
			        <!-- <el-row :gutter="10" class="mb8">
			          <el-col :span="1.5">
			            <el-button
			              type="primary"
			              icon="el-icon-plus"
			              size="mini"
			              @click="handleAdd"
			            v-hasPermi="['system:user:add']"
			            >新增</el-button>
			          </el-col>
			          <el-col :span="1.5">
			            <el-button
			              type="danger"
			              icon="el-icon-delete"
			              size="mini"
			              :disabled="multiple"
			              @click="handleDelete"
			            v-hasPermi="['system:user:remove']"
			            >批量删除</el-button>
			          </el-col>
			          <el-col :span="1.5">
			            <el-button
			              type="info"
			              icon="el-icon-upload2"
			              size="mini"
			              @click="handleImport"
							v-hasPermi="['system:dict:add']"
			            >批量入库</el-button>
			          </el-col>
			          <el-col :span="1.5">
			            <el-button
			              type="warning"
			              icon="el-icon-download"
			              size="mini"
			              @click="handleExport"
							v-hasPermi="['system:dict:add']"
			            >导出报表</el-button>
			          </el-col>
			        </el-row> -->
			
			        <el-table v-loading="loading" :data="cardList">
			          <el-table-column label="编号" prop="cardId" align="center" :show-overflow-tooltip="true" />
			          <el-table-column label="ICCID" align="center" prop="iccid" :show-overflow-tooltip="true" />
			          <el-table-column label="删除前所属单位" prop="deptName" align="center" :show-overflow-tooltip="true" />
			          <el-table-column label="支持运营商" prop="operatorName" align="center" :show-overflow-tooltip="true" />
			          <el-table-column label="删除人" prop="nickname" align="center" :show-overflow-tooltip="true" />
			          <el-table-column label="删除人单位" prop="deleteDept" align="center" :show-overflow-tooltip="true" />
					  <el-table-column label="删除时间" align="center" prop="updateTime" width="180" :show-overflow-tooltip="true" >
					  	<template slot-scope="scope">
					  		<span>{{ parseTime(scope.row.updateTime) }}</span>
					  	</template>
					  </el-table-column>
			          <el-table-column label="用户操作" align="center" class-name="small-padding fixed-width" width="220">
			          	<template slot-scope="scope">
			          		<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)" >恢复</el-button>
			          		<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" >彻底删除</el-button>
			          	</template>
			          </el-table-column>
			        </el-table>
			
			        <pagination
			          v-show="total>0"
			          :total="total"
			          :page.sync="queryParams.page"
			          :limit.sync="queryParams.size"
			          @pagination="getList"
			        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
			<el-row>
				<el-col :span="24">
					<el-form-item label="ICCID" prop="iccid">
					  <el-input v-model="form.iccid" placeholder="请输入ICCID" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="运营商" prop="operator">
					  <el-select v-model="form.operator" placeholder="请输入运营商" style="width: 100%;">
					    <el-option
					      v-for="dict in operatorOptions"
					      :key="dict.dictValue"
					      :label="dict.dictLabel"
					      :value="dict.dictValue"
					    ></el-option>
					  </el-select>
					</el-form-item>
				</el-col>
			</el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { insertInternetCard,deleteInternetCardById,selectInternetCardById,updateInternetCard,
 deleteInternetCardList
 } from "@/api/outIn/cars";
import { traverseTreeArray } from "@/utils/index"
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "User",
  components: { Treeselect },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
	  dateRange: [],
      // 表格数据
      cardList: null,
      // 弹出层标题
      title: "",
      // 部门树选项
      deptOptions: [],
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: undefined,
      // 状态数据字典
      statusOptions: [],
	  //出库状态
	  outInOptions: [],
      // 运营商
      operatorOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        iccid: undefined,
        library: undefined,
        status: undefined,
        deptId: undefined,
      },
      // 表单校验
      rules: {
        iccid: [
          { required: true, message: "产品ID不能为空", trigger: "blur" }
        ],
        imei: [
          { required: true, message: "IMEI不能为空", trigger: "blur" }
        ],
        deptId: [
          { required: true, message: "所属单位不能为空", trigger: "blur" }
        ],
        operator: [
          { required: true, message: "运营商不能为空", trigger: "blur" }
        ],
      }
    };
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.getList();
    this.getTreeselect();
    this.getDicts("sys_normal_disable").then(response => {
      this.statusOptions = response;
    });
	this.getDicts("sys_delivery_status").then(response => {
	  this.outInOptions = response;
	});
    this.getDicts("sys_operator").then(response => {
      this.operatorOptions = response;
    });
    // this.getConfigKey("sys.user.initPassword").then(response => {
    //   this.initPassword = response;
    // });
  },
  methods: {
    /** 查询用户列表 */
    getList() {
      this.loading = true;
      deleteInternetCardList(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
          this.cardList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then(response => {
        this.deptOptions = traverseTreeArray(response, "deptName", 'deptId');
      });
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {

        deviceCode: undefined,
        imei: undefined,
        operator: undefined,
        deptId: undefined,
		iccid:undefined

      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
   /* handleSelectionChange(selection) {
        this.ids = selection.map(item => item.cardId)
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      
    }, */
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getTreeselect();
        this.open = true;
        this.title = "添加设备";

    },
    /** 恢复按钮操作 */
    handleUpdate(row) {
    	let that = this
    	this.$confirm('是否确认恢复?', "温馨提示", {
    		customClass: "deleteBox",
    	    confirmButtonText: "确定",
    	    cancelButtonText: "取消",
    	    type: "warning"
    	  }).then(function() {
    		  let formData = JSON.parse(JSON.stringify(row))
    		  formData.flag = '0'
    		updateInternetCard(formData).then(response => {
    		  if (response.code == 10000) {
    		    that.msgSuccess("恢复成功");
				that.getList();
    		  } else {
    		    that.msgError(response.msg);
    		  }
    		});
    	  }).catch(function() {});
    
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.cardId != undefined) {
            updateInternetCard(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            insertInternetCard(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除用户编号为"' + row.cardId + '"的数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return deleteInternetCardById(row.cardId);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },

  }
};
</script>
