<template>
	<div class="components-container">
		<el-back>
			<h3>各代理商设备统计</h3>
			<el-form :inline="true">
				<el-form-item label="设备选择:">
					<el-select v-model="queryParams.searchValue" placeholder="设备类型" size="small" @change="handleQuery">
						<el-option v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue" />
					</el-select>
				</el-form-item>
			</el-form>
			<nooutDevice :chart-data="deptList" :maxNum="maxNum"></nooutDevice>
		</el-back>
	</div>
</template>

<script>
	import nooutDevice from './dashBoard/nooutDevice.vue'
	import { listnoOutDevice } from "@/api/reports/nooutDevice"
	export default {
		name: "nooutDevices",
		data() {
			return {

				// 表格树数据
				deptList: [],
				maxNum:0,
				// 状态数据字典
				statusOptions: [],
				// 查询参数
				queryParams: {
					searchValue: ''
				}
			};
		},
		components:{
			nooutDevice
		},
		created() {
			this.getList();
			this.getDicts("sys_device_type").then(response => {
				this.statusOptions = response;
			});
		},
		methods: {
			/** 查询单位列表 */
			getList() {
				listnoOutDevice(this.queryParams.searchValue).then(response => {
					this.deptList = response
					this.deptList.forEach((item,index) =>{
						if(item.stockCount>this.maxNum){
							if(item.stockOut>this.maxNum){
								this.maxNum = item.stockOut
							}
							this.maxNum = item.stockCount
						}
					})
				});
			},


			/** 搜索按钮操作 */
			handleQuery() {
				this.getList()
			}
		}
	};
</script>

<style scoped>
	.components-container{
		text-align: center;
	}
</style>
