<template>

    <div class="app-container">
		
         <homeHead></homeHead>
		 <topMenu></topMenu>
          <app-main />
    </div>


</template>

<script>
  import homeHead from '../components/homeHead.vue'
  import topMenu from '../components/topMenu.vue'
// import TagsView from '../components/TagsView/index.vue'
import appMain from '../components/AppMain.vue'



export default {
  name: 'home',
  components: {
 
    homeHead,
	topMenu,
    appMain,
    // TagsView
  },

  computed: {

  },
  methods: {

  },
  created() {
	let routeList = this.$store.getters.permission_routes
	if(this.$route.path == '/'){
		this.$store.commit('SET_NOWTOPACTIVE',routeList[0].menuId)
		this.$router.push(routeList[0].path)
	}
  }
}
</script>

<style type='stylesheet/scss' lang="scss">

</style>
