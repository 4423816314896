import request from '@/utils/request'

// 查询施工安装列表
export function smokeDetecorRecordPageList(data) {
  return request({
    url: '/monitor/usdr/smokeDetecorRecordPageList',
    method: 'post',
	data: data
  })
}

//导出列表
export function exportUseSmDeRecord(data) {
  return request({
    url: '/monitor/usdr/exportUseSmDeRecord?' + data,
    method: 'post',
	'responseType':'blob'
  })
}