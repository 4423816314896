import request from '@/utils/request'

// 登录方法
export function listnoOutDevice(deviceType) {
  return request({
    url: '/monitor/device/selectDeptStockSmokeDetector?deviceType=' + deviceType,
    method: 'post'

  })
}

