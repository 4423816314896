import request from '@/utils/request'

//列表
export function selectPayRuleList(data) {
  return request({
    url: '/free/pay/selectPayRuleList',
    method: 'post',
	data: data
  })
}

//根据id查询支付规则
export function selectPayRuleById(ruleId) {
  return request({
    url: '/free/pay/selectPayRuleById?ruleId=' + ruleId,
    method: 'post'
  })
}

//新增
export function insertPayRule(data) {
  return request({
    url: '/free/pay/insertPayRule',
    method: 'post',
	data: data
  })
}

//修改
export function updatePayRule(data) {
  return request({
    url: '/free/pay/updatePayRule',
    method: 'post',
	data: data
  })
}

//删除
export function deletePayRuleById(ruleId) {
  return request({
    url: '/free/pay/deletePayRuleById?ruleId=' + ruleId,
    method: 'post'
  })
}