import request from '@/utils/request'

// 登录方法
export function listOutDevice(data) {
  return request({
    url: '/ucenter/dept/treeDataCount',
    method: 'post',
    data: data
  })
}

