var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-box",style:({
		width:_vm.width,
		height:_vm.height,
		boxShadow:_vm.shadowColors,
		background:_vm.backColor,
		color:_vm.color
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }