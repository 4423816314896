<template>
  <div class="components-container">
    <el-back>
      <div class="menuBox">
        <div class="formBox">菜单信息搜索</div>
        <el-form :inline="true">
          <el-form-item label="菜单名称">
            <el-input v-model="queryParams.menuName" placeholder="请输入菜单名称" clearable size="small"
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <!--      <el-form-item label="状态">
			        <el-select v-model="queryParams.visible" placeholder="菜单状态" clearable size="small">
			          <el-option
			            v-for="dict in visibleOptions"
			            :key="dict.dictValue"
			            :label="dict.dictLabel"
			            :value="dict.dictValue"
			          />
			        </el-select>
			      </el-form-item> -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"
              v-hasPermi="['system:menu:list']">搜索</el-button>
            <el-button type="primary" icon="el-icon-refresh" size="mini" @click="handleReset"
              v-hasPermi="['system:menu:list']">重置</el-button>
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
              v-hasPermi="['system:menu:add']">新增</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="menuList" row-key="menuId"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column prop="menuName" label="菜单名称" :show-overflow-tooltip="true"></el-table-column>
          <!--      <el-table-column prop="icon" label="图标" align="center" width="100px">
			        <template slot-scope="scope">
			          <svg-icon :icon-class="scope.row.icon" />
			        </template>
			      </el-table-column> -->
          <el-table-column prop="orderNum" label="排序"></el-table-column>
          <el-table-column prop="perms" label="权限标识" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="component" label="组件路径" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="path" label="路由配置" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="visible" label="可见" :formatter="visibleFormat"></el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime">
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                v-hasPermi="['system:menu:edit']">修改</el-button>
              <el-button size="mini" type="text" icon="el-icon-plus" @click="handleAdd(scope.row)"
                v-hasPermi="['system:menu:add']">新增</el-button>
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                v-hasPermi="['system:menu:remove']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-back>

    <!-- 添加或修改菜单对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级菜单">
              <treeselect v-model="form.parentId" :options="menuOptions" :show-count="true" placeholder="选择上级菜单" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="form.menuType">
                <el-radio label="M">目录</el-radio>
                <el-radio label="C">菜单</el-radio>
                <el-radio label="F">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!--         <el-col :span="24">
            <el-form-item v-if="form.menuType != 'F'" label="菜单图标">
              <el-popover
                placement="bottom-start"
                width="460"
                trigger="click"
                @show="$refs['iconSelect'].reset()"
              >
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                  <svg-icon
                    v-if="form.icon"
                    slot="prefix"
                    :icon-class="form.icon"
                    class="el-input__icon"
                    style="height: 32px;width: 16px;"
                  />
                  <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                </el-input>
              </el-popover>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="路由地址" prop="path">
              <el-input v-model="form.path" placeholder="请输入路由地址" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组件路径" prop="component">
              <el-input v-model="form.component" placeholder="请输入组件路径" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权限标识" prop="perms">
              <el-input v-model="form.perms" placeholder="请输入权限标识" maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="菜单状态">
              <el-radio-group v-model="form.visible">
                <el-radio v-for="dict in visibleOptions" :key="dict.dictValue" :label="dict.dictValue">{{ dict.dictLabel
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listMenu, getMenu, treeselect, delMenu, addMenu, updateMenu, checkMenuName, listMenuBy } from "@/api/system/menu";
// import { listMenu } from "@/api/system/menu";
import { translateDataToTree } from "@/utils/index"
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import IconSelect from "@/components/IconSelect";

export default {
  components: {
    Treeselect,
    // IconSelect
  },
  data() {
    var checkMenuNames = (rule, value, callback) => {
      let menuName = { menuName: value, parentId: this.form.parentId };
      if (this.title == '添加菜单') {
        checkMenuName(menuName).then(res => {
          if (res == 0) {
            callback();
          } else {
            callback(new Error('菜单名称重复，请重新输入'));
          }
        })
      } else {
        callback();
      }
    };
    return {
      // 遮罩层
      loading: true,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: undefined,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 菜单状态数据字典
      visibleOptions: [],
      // 查询参数
      queryParams: {
        menuName: undefined,
        visible: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        menuName: [
          { required: true, message: "菜单名称不能为空", trigger: "blur" },
          { required: true, validator: checkMenuNames, trigger: 'blur' }

        ],
        orderNum: [
          { required: true, message: "菜单顺序不能为空", trigger: "blur" }
        ],
        perms: [
          { required: true, message: "权限标识不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_show_hide").then(response => {
      this.visibleOptions = response;
    });
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name;
    },
    /** 查询菜单列表 */
    getList() {
      this.loading = true;
      listMenu().then(response => {
        // this.menuList = response.queryResult.list
        this.menuList = translateDataToTree(response.queryResult.list, 'menuId');
        this.loading = false;
      });
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      treeselect().then(response => {
        response.queryResult.list.forEach((val, index) => {
          val.id = val.menuId;
          val.label = val.menuName;
          val.name = val.menuName
        })
        this.menuOptions = [];
        const menu = { id: 0, label: '主类目', children: [] };
        menu.children = translateDataToTree(response.queryResult.list, 'menuId');
        this.menuOptions.push(menu);
      });
    },
    // 菜单显示状态字典翻译
    visibleFormat(row, column) {
      if (row.menuType == "F") {
        return "";
      }
      return this.selectDictLabel(this.visibleOptions, row.visible);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        menuId: undefined,
        parentId: 0,
        menuName: undefined,
        icon: undefined,
        menuType: "M",
        orderNum: undefined,
        path: undefined,
        isFrame: "1",
        visible: "0"
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.loading = true;
      if (this.queryParams.menuName) {
        listMenuBy(this.queryParams).then(response => {
          this.menuList = response.queryResult.list;
          this.loading = false;
        });
      }else{
        this.getList();
      }
    },
    /** 重置按钮操作 */
    handleReset() {
      this.queryParams.menuName = "";
      this.getList();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      this.getTreeselect();
      if (row != null) {
        this.form.parentId = row.menuId;
      }
      this.open = true;
      this.title = "添加菜单";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getTreeselect();
      getMenu(row.menuId).then(response => {
        this.form = response.menuExt;
        this.open = true;
        this.title = "修改菜单";
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.menuId != undefined) {
            updateMenu(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addMenu(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.menuName + '"的数据项?', "警告", {
        customClass: "deleteBox",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delMenu(row.menuId);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      }).catch(function () { });
    }
  }
};
</script>
<style scoped lang="scss">
.components-container {}
</style>