import request from '@/utils/request'

//列表
export function selectRankPayRecordsList(data) {
  return request({
    url: '/free/rankDevicePay/selectRankPayRecordsList',
    method: 'post',
	data: data
  })
}

//导出商场设备缴费记录
export function outRankDeviceExcel(data) {
  return request({
    url: '/free/devicePayExcel/outRankDeviceExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}