<template>

      <el-menu
        :default-active="$route.path"
        :unique-opened="true"
        :collapse="false"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in leftRoutes" :key="route.path" :item="route" :base-path="basePath" />
      </el-menu> 
</template>

<script>
// import { mapGetters } from 'vuex'

import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.scss'

export default {
  name:"sidebar",
  data(){
	  return {
			leftRoutes:[],
			basePath:''
    }
  },

  components: {SidebarItem},
  computed: {
    // ...mapGetters([
    //   'permission_routes',
    //   'sidebar'
    // ]),
	/* 现在路由信息 */

	variables() {
	  return variables
	},
	isCollapse() {
	  return !this.sidebar.opened
	},

    // activeMenu() {
    //   const route = this.$route
    //   const { meta, path } = route
    //   // if set path, the sidebar will highlight the path you set
    //   if (meta.activeMenu) {
    //     return meta.activeMenu
    //   }
    //   return path
    // },
    // showLogo() {
    //   return this.$store.state.settings.sidebarLogo
    // }
  },

  methods:{

  },
  mounted() {
	let routeList = this.$store.getters.permission_routes
	routeList.forEach((item,index) => {
		/* 添加左侧路由 */
		if(item.menuId == this.$store.state.permission.nowTopActive){
			this.leftRoutes = item.children
			this.basePath = item.path
			/* 判断当前左边路由 */
			if(this.$route.path === item.path){
				this.$router.push(this.basePath + '/' + this.leftRoutes[0].path)
			}
			
		}
	})
  }
}
</script>
<style lang="scss" scoped>
.el-menu{
  border-right: none;
}
</style>
