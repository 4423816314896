<template>
  <div class="">

		<div class="formBox">信息搜索</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true">
		      <el-form-item label="接收者姓名" prop="username">
		        <el-input
		          v-model="queryParams.username"
		          placeholder="请输入接收者姓名"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="handleQuery"
		        />
		      </el-form-item>
		      <el-form-item label="手机号" prop="phone">
		        <el-input
		          v-model="queryParams.phone"
		          placeholder="请输入手机号"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="handleQuery"
		        />
		      </el-form-item>
			  <el-form-item label="状态" prop="status">
				<el-select
				  v-model="queryParams.status"
				  placeholder="接收者状态"
				  clearable
				  size="small"
				  style="width: 240px"
				>
				  <el-option
					v-for="dict in statusOptions"
					:key="dict.dictValue"
					:label="dict.dictLabel"
					:value="dict.dictValue"
				  />
				</el-select>
			  </el-form-item>
		      <el-form-item>
		        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
		        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
		      </el-form-item>
		    </el-form>
		
		    <el-row :gutter="10" class="mb8">
		      <el-col :span="1.5">
		        <el-button
		          type="primary"
		          icon="el-icon-plus"
		          size="mini"
		          @click="handleAdd"
				  v-hasPermi="['system:revevier:add']"
		        >新增</el-button>
		      </el-col>
		      <el-col :span="1.5">
		        <el-button
		          type="success"
		          icon="el-icon-edit"
		          size="mini"
		          :disabled="single"
		          @click="handleUpdate"
				  v-hasPermi="['system:revevier:edit']"
		        >修改</el-button>
		      </el-col>
		      <el-col :span="1.5">
		        <el-button
		          type="danger"
		          icon="el-icon-delete"
		          size="mini"
		          :disabled="multiple"
		          @click="handleDelete"
				  v-hasPermi="['system:revevier:remove']"
		        >删除</el-button>
		      </el-col>
		    </el-row>
		
		    <el-table v-loading="loading" :data="receiverList">
		      <el-table-column label="编号" align="center" type="index" />
		      <el-table-column label="接收人姓名" prop="username"  align="center" :show-overflow-tooltip="true"/>
		      <el-table-column label="接收人手机号" prop="phone"  align="center" />
			  <el-table-column label="状态" prop="status" align="center" />
			  <el-table-column label="创建人" prop="createBy" align="center" />
		      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
		        <template slot-scope="scope">
		          <span>{{ parseTime(scope.row.createTime) }}</span>
		        </template>
		      </el-table-column>
			            <el-table-column label="更新时间" align="center" prop="updateTime" width="180">
			    <template slot-scope="scope">
			      <span>{{ parseTime(scope.row.updateTime) }}</span>
			    </template>
			  </el-table-column>
		      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="220">
		        <template slot-scope="scope">
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-edit"
		            @click="handleUpdate(scope.row)"
					v-hasPermi="['system:revevier:edit']"
		          >修改</el-button>
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-delete"
		            @click="handleDelete(scope.row)"
					v-hasPermi="['system:revevier:remove']"
		          >删除</el-button>
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-key"
		            @click="handleSafe(scope.row)"
					v-hasPermi="['system:revevier:alarm']"
		          >分配警告</el-button>
		        </template>
		      </el-table-column>
		    </el-table>
		
		   <pagination
		      v-show="total>0"
		      :total="total"
		      :page.sync="queryParams.pageNum"
		      :limit.sync="queryParams.pageSize"
		      @pagination="getList"
		    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-form-item label="姓名" prop="username">
          <el-input v-model="form.username" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
          <el-radio
            v-for="dict in statusOptions"
            :key="dict.dictValue"
            :label="dict.dictValue"
          >{{dict.dictLabel}}</el-radio>
          </el-radio-group>
        </el-form-item>
          </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 分配警告-->
    <el-dialog :title="safeTitle" :visible.sync="safeOpen" width="800px">
    <el-transfer
      v-model="checkedSafe"
      filterable
      :titles="['未分配', '已分配']"
      :button-texts="['取消分配', '分配']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"

      :data="allSafe">

    </el-transfer>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="checkSafes">确 定</el-button>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { selectReceiverList,
		 insertReceiver,
		 updateReceiver,
		 deleteSmsById,
		 deleteSmsByIds,
		 checkPhone,
		 selectReceiverById,
		 
	   } from "@/api/system/receiver/actions.dev";
export default {
  name: "receiver",
  data() {
      var checkReceiverPhone = (rule, value, callback) => {
        let phone = {phone:value};
        if(this.title == '添加接收者'){
          checkPhone(phone).then(res =>{
              if(res == 0){
                callback();
              }else{
               callback(new Error('用户手机号已添加，请重新输入'));
              }
            })
        }else{
          callback();
        }
    };
    return {
      // 遮罩层
      loading: true,
	  // 选中数组
	  ids: [],
	  // 非单个禁用
	  single: true,
	  // 非多个禁用
	  multiple: true,
      // 总条数
      total: 0,
      // 角色表格数据
      receiverList: [],
      // 弹出层标题
      title: "",
      safeTitle:'',
      // 是否显示弹出层
      open: false,
      safeOpen:false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      /* 查询所有的安全警告*/
      allSafe:[],
      checkedSafe:[],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        username: undefined,
        phone: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        username: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: "请输入正确的手机号码", trigger: "blur"},
          // { required: true,validator: checkReceiverPhone, trigger: 'blur'}
        ]
      },
      receiverId:''
    };
  },
  created() {
    this.getList();
    this.getDicts("notice_receiver_status").then(response => {
      this.statusOptions = response;
    });

  },
  methods: {
    handleSafe(row){
      this.allSafe = []
      this.checkedSafe = []
      let that = this
      this.receiverId = row.receiverId
      getSafeByReceiver(row.receiverId).then(res => {
          this.safeOpen = true
          if(res){
            res.forEach((val,index) =>{
            	that.allSafe.push({
            	    key: val.typeId,
            	    label: val.type,
            	    disabled: false
            	  });

            	if(val.tag == "0" ){
            	  that.checkedSafe.push(val.typeId)
            	}
            })
          }

        })
    },
    //分配安全警告给接收者
    checkSafes(){

      /* 先生成传递数据*/
      let vdata = []
      this.allSafe.forEach((val1,index1) =>{
        vdata.push({typeId:val1.key,tag:'1',receiverId:this.receiverId})
      })
      /* 判断选择的小区*/

      for(let i = 0;i<this.checkedSafe.length;i++){
        for(let j = 0;j<vdata.length;j++){
          if(this.checkedSafe[i] == vdata[j].typeId){
            vdata[j].tag = '0'
            break
          }
        }
      }

      addSafeByReceiver(vdata).then(res=>{
          this.safeOpen = false
          if (res.code === 10000) {
            this.msgSuccess("分配成功");
          } else {
            this.msgError(res.msg);
          }
      })
    },
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      selectReceiverList(this.queryParams).then(
        response => {
          this.receiverList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset() {
      this.form = {
        username: undefined,
        phone: undefined,
        status: "0",
        params:{}
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加接收者";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const receiverIds = row.receiverId || this.ids[0]
      // this.$nextTick(() => {
      //   this.getRoleMenuTreeselect(roleId);
      // });
      selectReceiverById(receiverIds).then(response => {
        this.form = row;
        this.open = true;
        this.title = "修改接收人";
      });
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.receiverId != undefined) {
            updateReceiver(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            insertReceiver(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const receiverIds = row.receiverId || this.ids
      this.$confirm('是否确认删除编号为"' + receiverIds + '"的数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if(row.receiverId){
            return deleteSmsById(receiverIds);
          }else{
            return deleteSmsByIds(this.ids);
          }
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
  }
};
</script>
