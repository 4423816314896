import request from '@/utils/request'

// 查询字典类型列表
export function getPageRsaRecord(data) {
  return request({
    url: '/ucenter/rsa/getPageRsaRecord',
    method: 'post',
	data:data
  })
}



// 新增字典类型
export function addRsaRecord(data) {
  return request({
    url: '/ucenter/rsa/addRsaRecord',
    method: 'post',
    data: data
  })
}

// 修改字典类型
export function updLocalRsaDept(data) {
  return request({
    url: '/ucenter/rsa/eapi/updLocalRsaDept?' + data,
    method: 'post'
  })
}

// 删除字典类型
export function deleteRsaDept(rsaRecordId) {
  return request({
    url: '/ucenter/rsa/delRsaRecord?rsaRecordId=' + rsaRecordId,
    method: 'post'
  })
}

export function checkLocalRsaDept() {
  return request({
    url: '/ucenter/rsa/checkLocalRsaDept',
    method: 'post'
  })
}