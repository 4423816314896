import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 定义路由配置
let routes = []
let concat = (router) => {
  routes = routes.concat(router)
}
// // 导入路由规则
import {constantRoutes} from '@/views/home/router'
import {systemRoutes} from '@/views/system/router'
import {smokeRoutes} from '@/views/smoke/router'
import {monitorRoutes} from '@/views/monitor/router'
import {deviceRoutes} from '@/views/device/router'
import {alarmRoutes} from '@/views/alarm/router'

// 合并路由规则
concat(constantRoutes)
concat(systemRoutes)
concat(smokeRoutes)
concat(monitorRoutes)
concat(deviceRoutes)
concat(alarmRoutes)


const router = new Router({
	mode:'history',
  routes:routes
})

export default router;
