export const cashierDeskRoutes = [{
    path: '/',
    component: () => import('@/views/home/page/home.vue'),
    hidden: true,
    children: [{
      path: '/cashierDesk',
      component: () => import('@/views/cashierDesk/index.vue'),
      name: '出入库管理',
    }]
  },
]
