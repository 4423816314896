<!--
 * @Author: 倪志磊 956640361@qq.com
 * @Date: 2022-10-11 18:13:42
 * @LastEditors: 倪志磊 956640361@qq.com
 * @LastEditTime: 2023-03-25 16:46:38
 * @FilePath: \oaktree-ai-iffp-web\src\views\reports\agentCharge\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="components-container">
		<el-back>
			<div style="text-align: center;">
				<h3>代理商年度收费统计图</h3>
				<el-form :inline="true" label-weight="80">
					<el-form-item label="统计年份:">
					  <el-date-picker
						v-model="queryParams.beginTime"
						type="year"
						value-format="yyyy"
						placeholder="选择年"
						@change="handleQuery"
						>
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="设备类型:" style="margin-left: 20px;">
						<el-select
						  v-model="queryParams.deviceType"
						  placeholder="请选择设备类型"
						  clearable
						  size="small"
						  class="float-width"
						  @change="handleQuery"
						>
						  <el-option
							v-for="dict in deviceOptions"
							:key="dict.dictValue"
							:label="dict.dictLabel"
							:value="dict.dictValue"
						  />
						</el-select>
					</el-form-item>
				</el-form>
			</div>
				<chargeEchart :chart-data="deptData"></chargeEchart>
		</el-back>
	</div>
</template>

<script>
	import chargeEchart from './chargeEchart'
	import { selectPayRecordsCount } from '@/api/cashierDesk/charge.js'
	import { selectAgentPayRecordsCount } from "@/api/reports/payCount.js"
	import { toThousands } from '@/utils/index'
	export default {
		name: "alarmEcharts",
		data() {
			return {
				//设备类别
				deviceOptions: [],
				maxNum:0,
				// 查询参数
				queryParams: {
					beginTime:undefined,
					deviceType: undefined,
				},
				deptData: {
				  dept :[],
				  cost :[]
				},
			};
		},
		components:{
			chargeEchart,
		},
		created() {
			var date = new Date();				
			this.queryParams.beginTime =  date .getFullYear().toString(); //获取完整的年份(4位)			
			this.getList();
			this.getDicts("sys_device_type").then(response => {
			  this.deviceOptions = response;
			});
		},
		methods: {
			/** 查询单位列表 */
			getList() {
				this.deptData.dept = []
				this.deptData.cost = []
				selectAgentPayRecordsCount(this.queryParams).then(response => {
					response.forEach((item,index) => {
					  this.deptData.dept.push(item.deptName);
					  this.deptData.cost.push(item.cost);
					})
					this.deptData.dept.unshift('')
					this.deptData.cost.unshift(0)
				});
			},
			/** 搜索按钮操作 */
			handleQuery(val) {
				
				this.getList()
			}
		}
	};
</script>

