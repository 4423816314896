<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<div class="formBox">信息搜索</div>
				<record v-if="tabVal == 1"></record>
				<statistics v-if="tabVal == 2"></statistics>	
				<selfInspection v-if="tabVal == 3"></selfInspection>
			</el-back>
  </div>
</template>

<script>

import record from './record'
import statistics from './statistics'
import selfInspection from './selfInspection'
export default {
  name: "erection",
  data(){
	  return {
		tabList:[
			{ id:1,name:'报警记录' },
			{ id:2,name:'报警统计' },
			{ id:3,name:'设备自检记录' }
		],
		 tabVal:1
	  }
  },
  components:{
	  record,
	  statistics,
	  selfInspection
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
