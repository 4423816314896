import request from '@/utils/request'

// 查询报警记录分页
export function getDevAlaRecordPageList(data) {
    return request({
        url: '/monitor/devAlaRecord/getDevAlaRecordPageList',
        method: 'post',
        data: data
    })
}

//报警记录统计报表
export function deAlRecordEcharts(data) {
    return request({
        url: '/monitor/devAlaRecord/deAlRecordEcharts',
        method: 'post',
        data: data
    })
}
// 查询报警统计中报警记录分页
export function alarmAnalyRecord(data) {
    return request({
        url: '/monitor/devAlaRecord/alarmAnalyRecord',
        method: 'post',
        data: data
    })
}


// 查询设备自检记录分页
export function getDevSelTestPageList(data) {
    return request({
        url: '/monitor/devSelTest/getDevSelTestPageList',
        method: 'post',
        data: data
    })
}
// 更新已查看状态
export function updateDeAlRecord(data) {
    return request({
        url: '/monitor/devAlaRecord/updateDeAlRecord',
        method: 'post',
        data: data
    })
}
// 复位
export function resetButton(deviceCode) {
    return request({
        url: '/monitor/devAlaRecord/resetButton?deviceCode=' + deviceCode,
        method: 'post'
    })
}
//报警类型
export function alarmNoPageList(data) {
    return request({
        url: '/monitor/alarm/alarmNoPageList',
        method: 'post',
        data: data
    })
}
/* 查看设备详情 */
export function deviceInfo(deviceCode) {
    return request({
        url: '/monitor/device/selectUserSmokeDetectorInfo?deviceCode=' + deviceCode,
        method: 'post'
    })
}
/* 查看记录处理结果 */
export function hanldeResult(dealRecordId) {
    return request({
        url: '/monitor/devAlaRecord/getDeAlRecordById?id=' + dealRecordId,
        method: 'post'
    })
} // 导出设备信息
export function exportDevSelTest(data) {
    return request({
        url: '/monitor/devSelTest/exportDevSelTest?' + data,
        method: 'post',
        'responseType': 'blob'
    })
}
//待处理报警记录
export function RecordTitle() {
    return request({
        url: '/monitor/devAlaRecord/devAlaRecordTitle',
        method: 'post'
    })
}
//报警弹窗
export function alarmDialog() {
    return request({
        url: '/monitor/devAlaRecord/popAlaRecord',
        method: 'post'
    })
}
//已读报警弹窗
export function readAlarmDialog(dealRecordId) {
    return request({
        url: '/monitor/devAlaRecord/readPopAlaRecord?dealRecordId=' + dealRecordId,
        method: 'post'
    })
}
//导出报表
export function alarmAnalyRecordExport(data) {
    return request({
        url: '/monitor/devAlaRecord/alarmAnalyRecordExport',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 导出
export function devAlaRecordExport(data) {
    return request({
        url: '/monitor/devAlaRecord/devAlaRecordExport',
        method: 'post',
        timeout: 1000 * 1000,
        'responseType': 'blob',
        data: data
    })
}
// 根据字典获取label值
export function getAlarmLabel(dictLabel) {
    
    return request({
        url: 'system/dictData/selectDictDictLabel?dictLabel=' + dictLabel,
        method: 'post'
    })
}