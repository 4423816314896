<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }

  },
  data() {
    return {
      chart: null,
      legendData:[]
    }
  },
  watch: {
    chartData: {
      deep: true,

      handler(val) {

        this.legendData = val.filter((item,index)=>{ return item.name })
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions(val) {
	  let colorList = ['#1F79FC', '#47E19A', '#FDAA1A','#EE3D77'];

	  this.chart.setOption({
		title:{
			// text:'当前设备总览',
					
			x:'center',
			textStyle:{
				color:'#34E6FE',
				fontSize:'16'
			}
		},
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}({d}%)'
        },
		
        series: [
            {
                type: 'pie',
				center:["60%","40%"],
                radius: ['35%', '50%'],
                label: {
                    show: true
                },
				labelLine:{
					show:true,
					lineStyle:{

						width:'2'
					}
				},
                itemStyle: {
                  normal: {
                    color: function(params) {
                      return colorList[params.dataIndex]
                    },
					shadowColor: 'rgba(255,255,255,0.7)',
					shadowBlur: 10
                  }
                },
                hoverOffset:3,
                emphasis: {
                    label: {
                        show: false,
                        fontSize: '18',
                        fontWeight: 'bold',
                    }
                },
                data: val,
                width:'90%',
                left:'-10%'
            }
        ]

      })
    }
  }
}
</script>
