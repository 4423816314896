import request from '@/utils/request'

// 查询用户物联网卡设备信息
export function selectUserInternetCardList(data) {
  return request({
    url: '/monitor/device/selectUserInternetCardList',
    method: 'post',
	data: data
  })
}

// 查询用户物联网卡信息详细
export function selectUserInternetCardById(deviceId) {
  return request({
    url: '/monitor/device/selectUserInternetCardById?deviceId=' + deviceId,
    method: 'post'
  })
}

// 新增用户物联网卡信息
export function insertUserInternetCard(data) {
  return request({
    url: '/monitor/device/insertUserInternetCard',
    method: 'post',
    data: data
  })
}

// 修改用户物联网卡信息
export function updateUserInternetCard(data) {
  return request({
    url: '/monitor/device/updateUserInternetCard',
    method: 'post',
    data: data
  })
}

// 根据id删除用户物联网卡信息
export function deleteUserInternetCardById(deviceId) {
  return request({
    url: '/monitor/device/deleteUserInternetCardById?deviceId=' + deviceId,
    method: 'post'
  })
}
// 导出卡信息
export function exportCards(data) {
  return request({
    url: '/monitor/deviceExcel/outUserInternetCardExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}
// 导入卡信息
export function importCards() {
  return  process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/addUserInternetCards'
}