<template>
	<div class="components-container">
		<el-back>
			<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
			<div class="formBox">信息搜索</div>
			<el-form :model="queryParams" ref="queryForm" :inline="true">
				<el-form-item label="报警种类" prop="alarmType">
					<el-input v-model="queryParams.alarmType" placeholder="请输入报警种类名称" clearable size="small"
						style="width: 240px" @keyup.enter.native="handleQuery" />
				</el-form-item>

				<el-form-item label="预警状态" prop="status">
					<el-select v-model="queryParams.status" placeholder="请选择预警状态" clearable size="small"
						style="width: 240px">
						<el-option v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictLabel"
							:value="dict.dictValue" />
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
				</el-form-item>
			</el-form>

			<el-row :gutter="10" class="mb8">
				<el-col :span="1.5">
					<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
						v-hasPermi="['system:alarm:add']">新增</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
						v-hasPermi="['system:alarm:edit']">修改</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
						v-hasPermi="['system:alarm:remove']">删除</el-button>
				</el-col>
			</el-row>
			<el-table v-loading="loading" :data="alarmList" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column label="编号" prop="alarmId" align="center" />
				<el-table-column label="报警种类" prop="alarmType" align="center" :show-overflow-tooltip="true" />
				<el-table-column label="预警状态" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-value="0" inactive-value="1"
							@change="handleStatusChange(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="是否通信" prop="sendMessage" align="center"
					:formatter="(row, column, cellValue, index) => formatValue(cellValue)" />
				<el-table-column label="备注" prop="remark" align="center" />
				<el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180">
					<template slot-scope="scope">
						<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
							v-hasPermi="['system:alarm:edit']">修改</el-button>
						<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
							v-hasPermi="['system:alarm:remove']">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.size"
				@pagination="getList" />
			<!-- 添加或修改角色配置对话框 -->
			<el-dialog :title="title" :visible.sync="open" width="500px">
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="报警种类" prop="alarmType">
						<el-input v-model="form.alarmType" placeholder="请输入报警种类" />
					</el-form-item>
					<el-form-item label="预警键值" prop="typeValue">
						<el-input v-model="form.typeValue" placeholder="请输入预警键值" />
					</el-form-item>
					<el-form-item label="预警状态">
						<el-radio-group v-model="form.status">
							<el-radio v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictValue">{{
								dict.dictLabel }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- <el-form-item label="是否通信">
						<el-radio-group v-model="form.sendMessage">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item> -->
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="cancel">取 消</el-button>
				</div>
			</el-dialog>

		</el-back>
	</div>
</template>

<script>
import { alarmPageList, addAlarm, getAlarmById, updateDevice, delDevice } from "@/api/system/device";

export default {
	name: "receiver",
	data() {
		return {
			tabList: [{
				id: 0,
				name: undefined,
				deviceType: undefined,
			}],
			tabVal: 0,
			// 遮罩层
			loading: true,
			// 选中数组
			ids: [],
			// 非单个禁用
			single: true,
			// 非多个禁用
			multiple: true,
			// 总条数
			total: 0,
			// 表格数据
			alarmList: [],
			// 弹出层标题
			title: "",
			// 是否显示弹出层
			open: false,
			deptName: undefined,
			// 状态数据字典
			statusOptions: [],
			// 菜单列表
			// 部门列表
			deptOptions: [],
			// 查询参数
			queryParams: {
				page: 1,
				size: 10,
				alarmId: undefined,
				status: undefined,
				deviceType: undefined,
				alarmType: undefined
			},
			// 表单参数
			form: {},
			// 表单校验
			/* rules: {
			  roleName: [
				{ required: true, message: "角色名称不能为空", trigger: "blur" },
				{ required: true,validator: checkRoleNames, trigger: 'blur'}
			  ],
			  roleKey: [
				{ required: true, message: "权限字符不能为空", trigger: "blur" },
				{ required: true,validator: checkRoleKeys, trigger: 'blur'}
		  
			  ],
			  roleSort: [
				{ required: true, message: "角色顺序不能为空", trigger: "blur" }
			  ]
			} */

		}
	},
	created() {
		this.getList();
		this.getDicts("sys_normal_disable").then(response => {
			this.statusOptions = response;
		});
		this.getDicts("sys_device_type").then(response => {
			response.forEach((val, index) => {
				val.id = val.index;
				val.name = val.dictLabel
				val.deviceType = val.dictValue
			});
			this.tabList = response
		});
	},
	methods: {
		getTabIndex(val) {
			this.tabVal = val.deviceType
			this.getList();
		},
		/** 查询角色列表 */
		getList() {
			this.loading = true;
			let queryData = JSON.parse(JSON.stringify(this.queryParams))
			queryData.deviceType = this.tabVal
			alarmPageList(queryData).then(
				response => {
					this.alarmList = response.queryResult.list;
					this.total = response.queryResult.total;
					this.loading = false;
				}
			);
		},
		// 角色状态修改
		handleStatusChange(row) {
			let text = row.status === "0" ? "启用" : "停用";
			this.$confirm('确认要"' + text + '""' + row.alarmType + '"?', "警告", {
				customClass: "deleteBox",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				let form = {
					alarmId: row.alarmId,
					status: row.status,
					typeValue: row.typeValue,
				}
				return updateDevice(form)
			}).then(() => {
				this.msgSuccess(text + "成功");
			}).catch(function () {
				row.status = row.status === "0" ? "1" : "0";
			});
		},
		// 取消按钮
		cancel() {
			this.open = false;
			this.reset();
		},

		// 表单重置
		reset() {
			this.form = {
				alarmId: undefined,
				deviceType: undefined,
				typeValue: undefined,
				status: "0",
			};
			this.resetForm("form");
		},
		/** 搜索按钮操作 */
		handleQuery() {
			this.queryParams.page = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},
		// 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map(item => item.alarmId)
			/* let ad = selection.some(value => value.roleKey == 'admin')
			if(ad){
			  this.single = true;
			  this.multiple = true;
			}else{ */
			this.single = selection.length != 1;
			this.multiple = !selection.length;
			// }
		},
		/** 新增按钮操作 */
		handleAdd() {
			this.reset();
			// this.getMenuTreeselect();
			this.open = true;
			this.title = "添加预警种类";
		},
		/** 修改按钮操作 */
		handleUpdate(row) {
			this.reset();
			const alarmId = row.alarmId || this.ids[0]
			/* this.$nextTick(() => {
			  this.getRoleMenuTreeselect(roleId);
			}); */
			getAlarmById(alarmId).then(response => {
				this.form = response;
				this.open = true;
				this.title = "修改预警种类";
			});
		},

		/** 提交按钮 */
		submitForm: function () {
			this.$refs["form"].validate(valid => {
				if (valid) {
					if (this.form.alarmId != undefined) {
						// this.form.menuIds = this.getMenuAllCheckedKeys();
						let formData = JSON.parse(JSON.stringify(this.form))
						formData.deviceType = this.tabVal
						updateDevice(formData).then(response => {
							if (response.code === 10000) {
								this.msgSuccess("修改成功");
								this.open = false;
								this.getList();
							} else {
								this.msgError(response.msg);
							}
						});
					} else {
						// this.form.menuIds = this.getMenuAllCheckedKeys();
						let formData = JSON.parse(JSON.stringify(this.form))
						formData.deviceType = this.tabVal
						addAlarm(formData).then(response => {
							if (response.code === 10000) {
								this.msgSuccess("新增成功");
								this.open = false;
								this.getList();
							} else {
								this.msgError(response.msg);
							}
						});
					}
				}
			});
		},

		/** 删除按钮操作 */
		handleDelete(row) {
			const alarmIds = row.alarmId || this.ids;
			this.$confirm('是否确认删除角色编号为"' + alarmIds + '"的数据项?', "警告", {
				customClass: "deleteBox",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return delDevice(alarmIds);
			}).then(() => {
				this.getList();
				this.msgSuccess("删除成功");
			}).catch(function () { });
		},
		// formatValue
		formatValue(val) {
			switch (Number(val)) {
				case 0:
					return "否"
				case 1:
					return "是"
				default:
					return "-"
			}
		}
	}
};
</script>
