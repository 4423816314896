export const systemRoutes = [{
    path: '/',
    component: () => import('@/views/home/page/home.vue'),
    hidden: true,
    children: [{
      path: '/system',
      component: () => import('@/views/system/index.vue'),
      name: '系统管理',
	  children:[
		{
		  path: 'type/data/:dictId(\\d+)',
		  component: () => import('@/views/system/dict/data'),
		  name: 'Data',
		  meta: {		
		  }
		}

	  ]
    }]
  },

]
