import request from '@/utils/request'
// 查询密钥列表
export function ProductKeyList(data) {
  return request({
    url: '/monitor/productKey/selectProductKeyList',
    method: 'post',
    data: data
  })
}

// 新增密钥
export function addProductKey(data) {
  return request({
    url: '/monitor/productKey/addProductKey',
    method: 'post',
    data: data
  })
}

// 修改密钥信息
export function updateProductKey(data) {
  return request({
    url: '/monitor/productKey/updateProductKey',
    method: 'post',
    data: data
  })
}

// 删除设备
export function delProductKey(id) {
  return request({
    url: `/monitor/productKey/delProductKey?id=${id}`,
    method: 'post'
  })
}
