import axios from 'axios';
import { Notification, MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getUserSession } from '@/utils/auth';
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_URL_COUNTY,
    // 超时
    timeout: 10000,
    withCredentials: true
});

// request拦截器
service.interceptors.request.use(
    (config) => {
        if (getUserSession('iffp_jwt')) {
        }
        config.headers['Authorization'] =
            'Bearer ' + getUserSession('iffp_jwt'); // 让每个请求携带自定义token 请根据实际情况自行修改
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        const code = res.data.code;
        if (code === 10001) {
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示',
                {
                    customClass: 'deleteBox',
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch('LogOut').then(() => {
                    location.reload(); // 为了重新实例化vue-router对象 避免bug
                });
            });
        } else if (code == 23011 || code == 23014 || code == 23013) {
            let mes = res.data.message;
            MessageBox.confirm(mes, '系统提示', {
                customClass: 'deleteBox',
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                closeOnHashChange: false,
                showClose: false,
                confirmButtonText: '退出登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('LogOut').then(() => {
                    location.reload(); // 为了重新实例化vue-router对象 避免bug
                });
            });
        } else if (code == 23012) {
            return res.data;
        } else if (code !== 10000 && code !== undefined && code !== 99998) {
            if (res.config.url.indexOf('/ffmpeg/stopAllFfmpeg') < 0) {
                Notification.error({
                    title: res.data.message
                });
                return Promise.reject('error');
            } else {
                return Promise.reject('error');
            }
        } else {
            return res.data;
        }
    },
    (error) => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;
