import request from '@/utils/request'

// 查询物联网卡设备信息
export function selectInternetCardList(data) {
  return request({
    url: '/monitor/device/selectInternetCardList',
    method: 'post',
	data: data
  })
}

// 查询物联网卡信息详细
export function selectInternetCardById(cardId) {
  return request({
    url: '/monitor/device/selectInternetCardById?cardId=' + cardId,
    method: 'post'
  })
}

// 新增物联网卡信息
export function insertInternetCard(data) {
  return request({
    url: '/monitor/device/insertInternetCard',
    method: 'post',
    data: data
  })
}

// 修改物联网卡信息
export function updateInternetCard(data) {
  return request({
    url: '/monitor/device/updateInternetCard',
    method: 'post',
    data: data
  })
}
// 修改车辆信息接收人状态
export function changeUserStatus(receiverId,status) {
    const data = {
    receiverId,
    status
  }
  return request({
    url: 'monitor/receiver/changeStatus',
    method: 'post',
    data: data
  })
}

// 根据id删除物联网卡信息
export function deleteInternetCardById(cardId) {
  return request({
    url: '/monitor/device/deleteInternetCardById?cardId=' + cardId,
    method: 'post'
  })
}

// 导出卡信息
export function exportCards(data) {
  return request({
    url: '/monitor/deviceExcel/outInternetCardExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}
// 导入卡信息
export function importCards() {
  return  process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/addInternetCards'
}

//逻辑删除的物联网卡
export function deleteInternetCardList(data) {
  return request({
    url: '/monitor/device/deleteInternetCardList',
    method: 'post',
    data: data
  })
}
//批量续存
export function updateCardTimeList(cycle,data) {
  return request({
    url: '/monitor/GetLongCardInFo/updateCardTimeList?cycle=' + cycle,
    method: 'post',
    data: data
  })
}
//单个续存
export function updateCardTime(cycle,data) {
  return request({
    url: '/monitor/GetLongCardInFo/updateCardTime?cycle=' + cycle + '&ICCID=' + data,
    method: 'post'
  })
}