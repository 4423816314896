<template>
	<div class="topMenu">
		<scroll-pane ref="scrollPane" class="tags-view-wrapper">
			<ul>
				<li v-for="(item,index) in routeList" :key="item.menuId" class="menuItem" :class="{activeItem:active==item.menuId}" @click="menuItemClick(item)">
		
						{{ item.menuName }}
		
				</li>
			</ul>
		</scroll-pane>
	</div>


</template>

<script>
	import ScrollPane from './ScrollPane/ScrollPane'
	export default{
		name:'topMenu',
		data(){
			return{
				
			}
		},
		computed:{
			routeList(){				
				return this.$store.getters.permission_routes
			},
			active(){
				return this.$store.state.permission.nowTopActive
			}
		},
		components:{
			ScrollPane
		},
		methods:{
			menuItemClick(val){
				// console.log(this.$store.state.permission.nowTopActive,val.menuId)
				if(parseInt(this.$store.state.permission.nowTopActive) !== val.menuId){
					this.$store.commit('SET_NOWTOPACTIVE',val.menuId)
					this.$router.push(val.path)
				}
			}
		},

		created() {
		
		}
	}
</script>

<style scoped lang="scss">
	.topMenu{
		padding: 0 30px;
	}
	ul{
		margin: 20px auto;
		width: max-content;
		.menuItem{
			background-image: url('../../../assets/image/menuItem.png');
			background-size: 100% 100%;
			margin-left: 50px;
			width: 155px;
			height: 56px;
			text-align: center;
			line-height: 56px;
			display: inline-block;
			*display: inline;
			*zoom: 1;
			color: $weakTabColor;
			cursor: pointer;

			border-radius: 10px;
			&:nth-child(1){
				margin-left: 0;
			}
		}
		.activeItem{
			background-image: url('../../../assets/image/menuItemActive.png');
			color: $tabColor;
			
		}
	}

</style>
