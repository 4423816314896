import request from '@/utils/request'

//缴费统计
export function selectRankPayRecordsCount(data) {
  return request({
    url: '/free/rankDevicePay/selectRankPayRecordsCount',
    method: 'post',
	data: data
  })
}
