<template>
	<div class="components-container">
		<el-row :gutter="20">
			<el-col :span="24" :xs="24">
				<el-back>
					<div class="formBox">信息搜索</div>
					<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="88px">
						<el-form-item label="适用公司" prop="subordinate">
							<treeselect v-model="queryParams.subordinate" :options="deptOptions" placeholder="请选择"
								style="width: 240px;" />
						</el-form-item>
						<el-form-item label="设备类型" prop="deviceType">
							<el-select v-model="queryParams.deviceType" placeholder="设备类型" clearable size="small"
								style="width: 240px">
								<el-option v-for="dict in deviceOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
							<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
						</el-form-item>
					</el-form>

					<el-row :gutter="10" class="mb8">
						<el-col :span="1.5">
							<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
						</el-col>
					</el-row>
					<el-table v-loading="loading" :data="pertinenceList">
						<el-table-column label="编号" align="center" type="index" />
						<el-table-column label="适用公司" align="center" prop="deptName" :show-overflow-tooltip="true" />
						<el-table-column label="使用设备" align="center" prop="typeName" :show-overflow-tooltip="true" />
						<el-table-column label="续费方式" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="handleDefault(scope.row)">点击查看</el-button>
							</template>
						</el-table-column>
						<el-table-column label="免费时长" align="center" prop="free" :show-overflow-tooltip="true" />
						<el-table-column label="缴费通知" align="center">
							<template slot-scope="scope">
								<span>提前{{ scope.row.informInfo }}</span>
							</template>
						</el-table-column>
						<el-table-column label="创建人" align="center" prop="createBy" :show-overflow-tooltip="true" />
						<el-table-column label="创建时间" align="center" prop="createTime" :show-overflow-tooltip="true" />
						<el-table-column label="操作" align="center" width="180" class-name="small-padding fixed-width">
							<template slot-scope="scope">
								<el-button size="mini" type="text" icon="el-icon-edit"
									@click="handleUpdate(scope.row)">修改</el-button>
								<el-button size="mini" type="text" icon="el-icon-delete"
									@click="handleDelete(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.size"
						@pagination="getList" />
				</el-back>
			</el-col>
		</el-row>

		<el-dialog :title="title" :visible.sync="open" width="500px">
			<el-table v-loading="infoLoading" :data="infoList" style="margin-top: 20px;">
				<el-table-column label="编号" align="center" type="index" />
				<el-table-column label="缴费时长" align="center" prop="validTime" :formatter="validTimeFormat"
					:show-overflow-tooltip="true" />
				<el-table-column label="需缴纳金额" align="center" prop="cost" :show-overflow-tooltip="true" />
			</el-table>
		</el-dialog>

		<el-dialog :title="ruleTitle" :visible.sync="ruleOpen" width="880px">
			<el-form :model="form" ref="Form" :inline="true" label-width="92px" :rules="rules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="使用设备" prop="deviceType">
							<el-select v-model="form.deviceType" placeholder="请选择使用设备" clearable multiple size="small">
								<el-option v-for="dict in deviceOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="适用公司" prop="subordinate">
							<treeselect v-model="form.subordinate" :options="deptOptions" placeholder="请选择"
								style="width: 210px;" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="免费时长" prop="freeTime">
							<el-select v-model="form.freeTime" placeholder="请选择免费时长" clearable size="small">
								<el-option v-for="dict in freeOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="缴费通知" prop="inform">
							<el-select v-model="form.inform" placeholder="请选择" clearable size="small">
								<el-option v-for="dict in informOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item>
							<span style="font-size: 18px;color: #34E6FE ;">续费套餐</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(item, index) in form.payTypes" :key="index">
					<el-col :span="9">
						<el-form-item label="续费时长" :prop="'payTypes.' + index + '.validTime'"
							:rules="{ required: true, message: '续费时长不能为空', trigger: 'blur' }">
							<el-select v-model="item.validTime" placeholder="请选择续费时长" clearable size="small">
								<el-option v-for="dict in renewOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="9">
						<el-form-item label="需缴纳金额" :prop="'payTypes.' + index + '.cost'"
							:rules="{ required: true, message: '需缴金额不能为空', trigger: 'blur' }">
							<el-input v-model="item.cost" placeholder="请输入" clearable size="small" />
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<i class="el-icon-circle-plus-outline  push" v-if="index + 1 == form.payTypes.length"
							@click="addItem"></i>
						<i class="el-icon-remove-outline  delete" v-if="index !== 0" @click="deleteItem(item, index)"></i>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">取 消</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import { selectPayRuleList, selectPayRuleById, insertPayRule, updatePayRule, deletePayRuleById } from '@/api/cashierDesk/default.js'
import { removeKeyByNull } from '@/utils/oaktree'
import { traverseTreeArray } from "@/utils/index"
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
	name: "pertinence",
	components: { Treeselect },
	data() {
		return {
			// 遮罩层
			loading: true,
			infoLoading: true,
			// 总条数
			total: 0,
			// 表格数据
			deptOptions: [],
			pertinenceList: [],
			infoList: [],
			// 弹出层标题
			title: "",
			ruleTitle: "",
			// 是否显示弹出层
			open: false,
			ruleOpen: false,
			// 状态数据字典
			deviceOptions: [],
			renewOptions: [],
			informOptions: [],
			freeOptions: [],
			validTimeOptions: [],
			// 查询参数
			queryParams: {
				page: 1,
				size: 10,
				deviceType: undefined,
				subordinate: undefined,
				tag: "1"
			},
			// 表单参数
			form: {
				payTypes: [
					{
						cost: undefined,
						validTime: undefined
					}
				],
				tag: "1"
			},
			// 表单校验
			rules: {
				deviceType: [
					{ required: true, message: "使用设备不能为空", trigger: "blur" }
				],
				subordinate: [
					{ required: true, message: "适用公司不能为空", trigger: "blur" }
				],
				freeTime: [
					{ required: true, message: "免费时长不能为空", trigger: "blur" }
				],
				inform: [
					{ required: true, message: "缴费通知不能为空", trigger: "blur" }
				]
			}
		}
	},
	created() {
		this.getDicts("sys_device_type").then(response => {
			this.deviceOptions = response;
		});
		this.getDicts("sys_renew_time").then(response => {
			this.renewOptions = response;
		});
		this.getDicts("sys_inform_time").then(response => {
			this.informOptions = response;
		});
		this.getDicts("sys_renew_time").then(response => {
			this.validTimeOptions = response;
		});
		this.getDicts("sys_free_time").then(response => {
			this.freeOptions = response;
		});
		this.getList();
		this.getTreeselect();
	},
	methods: {
		// 数据状态字典翻译
		validTimeFormat(row, column) {
			return this.selectDictLabel(this.validTimeOptions, row.validTime);
		},
		/** 查询列表 */
		getList() {
			this.loading = true;
			selectPayRuleList(this.queryParams).then(response => {
				this.pertinenceList = response.queryResult.list;
				this.total = response.queryResult.total;
				this.loading = false;
			}
			);
		},
		// 取消按钮
		cancel() {
			this.ruleOpen = false;
			this.reset();
		},
		// 表单重置
		reset() {
			this.form = {
				subordinate: undefined,
				freeTime: undefined,
				inform: undefined,
				deviceType: undefined,
				payTypes: [{
					validTime: undefined,
					cost: undefined,
				}],
				tag: "1"
			};
			this.resetForm("form");
		},
		/** 查询部门下拉树结构 */
		getTreeselect() {
			treeselect().then(response => {
				this.deptOptions = traverseTreeArray(response, "deptName", 'deptId');
			});
		},
		//点击查看
		handleDefault(row) {
			this.infoList = []
			this.open = true
			selectPayRuleById(row.ruleId).then(res => {
				this.infoList = res.payTypes
				this.infoLoading = false
			})
		},
		/** 搜索按钮操作 */
		handleQuery() {
			this.queryParams.page = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},
		//新增
		handleAdd() {
			this.reset();
			this.getTreeselect();
			this.ruleOpen = true;
			this.ruleTitle = "新增收费规则"
		},
		/** 修改按钮操作 */
		handleUpdate(row) {
			this.reset()
			this.getTreeselect();
			this.form.deviceType = []
			selectPayRuleById(row.ruleId).then(response => {
				this.form = response;
				this.form.deviceType = response.deviceType.split(',')
				this.ruleOpen = true;
				this.ruleTitle = "修改收费规则";
			});
		},
		//表单自增
		addItem() {
			this.form.payTypes.push({
				validTime: "",
				cost: ""
			});
		},
		//表单自减
		deleteItem(item, index) {
			this.form.payTypes.splice(index, 1);
		},
		/** 删除按钮操作 */
		handleDelete(row) {
			this.$confirm('是否确认删除编号为"' + row.ruleId + '"的数据项?', "警告", {
				customClass: "deleteBox",
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				return deletePayRuleById(row.ruleId);
			}).then(() => {
				this.getList();
				this.msgSuccess("删除成功");
			}).catch(function () { });
		},
		/** 提交按钮 */
		submitForm: function () {
			this.$refs["Form"].validate(valid => {
				if (valid) {
					if (this.form.ruleId != undefined) {
						this.form.deviceType = this.form.deviceType.join()
						this.form = removeKeyByNull(this.form)
						updatePayRule(this.form).then(response => {
							if (response.code === 10000) {
								this.ruleOpen = false;
								this.msgSuccess("修改成功");
								this.getList();
							} else {
								this.ruleOpen = false;
								this.msgError(response.msg);
							}
						});
					} else {
						this.form.deviceType = this.form.deviceType.join()
						insertPayRule(this.form).then(response => {
							if (response.code === 10000) {
								this.ruleOpen = false;
								this.msgSuccess("新增成功");
								this.getList();
							} else {
								this.ruleOpen = false;
								this.msgError(response.msg);
							}
						});
					}
				}
			});
		},
	}
};
</script>

<style>
.push {
	color: #34E6FE;
	font-size: 30px;
	margin-right: 10px;
	position: relative;
	top: 5px;
	left: 30px;
}

.delete {
	color: #34E6FE;
	font-size: 30px;
	margin-left: 10px;
	position: relative;
	top: 5px;
	left: 30px;
}

.el-tag.el-tag--info {
	background-color: #081D62;
	border-color: #081D62;
	color: #34E6FE;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
	background-color: #081D62;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
	background-color: rgba(52, 230, 254, 0.4);
}

.el-select .el-tag__close.el-icon-close {
	background-color: rgba(52, 230, 254, 0.4);
}

.el-select .el-tag__close.el-icon-close::before {
	color: #34E6FE;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
	color: #34E6FE;
}
</style>
