<template>
    <div class="components-container">
        <div style="text-align: center;">
            <h3>{{ userDeptInfo.deptName }}报警统计</h3>
            <el-form :inline="true">
                <el-form-item label="统计方式:">
                    <el-select
                        v-model="queryParams.timeFlag"
                        placeholder="统计方式"
                        size="small"
                        @change="handleQuery('timeChange')"
                    >
                        <el-option key="1" :label="'年'" :value="1" />
                        <el-option key="0" :label="'月'" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item label="年选择:" v-if="queryParams.timeFlag == 1">
                    <el-date-picker
                        v-model="queryParams.alarmTime"
                        size="small"
                        type="year"
                        value-format="yyyy"
                        @change="handleQuery()"
                        placeholder="选择年"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="月份选择:" v-else>
                    <el-date-picker
                        v-model="queryParams.alarmTime"
                        type="month"
                        value-format="yyyy-MM"
                        @change="handleQuery()"
                        placeholder="选择月"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>

        <div class="alarmEchartBigBox">
            <div class="echartBoxLeft">
                <div class="leftSmallBox">
                    <p>报警次数统计</p>
                    <div>
                        <div style="font-size: 14px;margin: 10px 0 10px 50px;">
                            总报警次数
                        </div>
                        <div class="alarmBox">
                            <div
                                v-for="(item,
                                index) in alarmCount.allAlarmCount"
                                :key="index"
                            >
                                <div
                                    style="font-size: 30px;"
                                    v-if="item == ','"
                                >
                                    ,
                                </div>
                                <div class="alarmNumBox" v-else>{{ item }}</div>
                            </div>
                            <div style="margin-left: 5px;">次</div>
                        </div>
                    </div>
                    <div class="alarmBottomBox">
                        <el-box>
                            <div>警情次数</div>
                            <div>
                                <span style="font-size: 18px;">{{
                                    alarmCount.callAlarmCount
                                }}</span
                                >次
                            </div>
                        </el-box>
                        <el-box>
                            <div>设备异常次数</div>
                            <div>
                                <span style="font-size: 18px;">{{
                                    alarmCount.exceptionCount
                                }}</span
                                >次
                            </div>
                        </el-box>
                        <el-box>
                            <div>设备离线次数</div>
                            <div>
                                <span style="font-size: 18px;">{{
                                    alarmCount.outLineCount
                                }}</span
                                >次
                            </div>
                        </el-box>
                    </div>
                </div>
                <div class="leftSmallBox">
                    <p>警情次数统计</p>
                    <div>
                        <div style="font-size: 14px;margin: 10px 0 10px 50px;">
                            警情总次数
                        </div>
                        <div class="alarmBox">
                            <div
                                v-for="(item,
                                index) in alarmCount.newCallAlarmCount"
                                :key="index"
                            >
                                <div
                                    style="font-size: 30px;"
                                    v-if="item == ','"
                                >
                                    ,
                                </div>
                                <div class="alarmNumBox" v-else>{{ item }}</div>
                            </div>
                            <div style="margin-left: 5px;">次</div>
                        </div>
                    </div>
                    <div class="alarmBottomBox">
                        <el-box :width="'176px'">
                            <div>真实警情</div>
                            <div>
                                <span style="font-size: 18px;">{{
                                    alarmCount.trueAlarmCount
                                }}</span
                                >次
                            </div>
                        </el-box>
                        <el-box :width="'176px'">
                            <div>虚假警情</div>
                            <div>
                                <span style="font-size: 18px;">{{
                                    alarmCount.falseAlarmCount
                                }}</span
                                >次
                            </div>
                        </el-box>
                    </div>
                </div>
            </div>
            <div class="echartBoxCenter">
                <p>报警折线统计图</p>
                <h4>报警折线统计图</h4>
                <alarmDayEchart :chart-data="yearAlarmData"></alarmDayEchart>
            </div>
            <div class="echartBoxRight">
                <p>报警种类统计图</p>
                <h4>报警种类统计图</h4>
                <alarmTypeEchart :chart-data="alarmTypeData"></alarmTypeEchart>
            </div>
        </div>
    </div>
</template>

<script>
    import alarmTypeEchart from './dashBoard/alarmTypeEchart.vue';
    import alarmDayEchart from './dashBoard/alarmDayEchart.vue';
    import { deAlRecordEcharts } from '@/api/alarm/index';
    import { getDept } from '@/api/system/dept';
    import { toThousands } from '@/utils/index';
    import { traverseTreeArray } from '@/utils/index';
    import { getUserSession } from '@/utils/auth';
    export default {
        name: 'alarmEcharts',
        data() {
            return {
                maxNum: 0,
                // 状态数据字典
                statusOptions: [],
                // 查询参数
                queryParams: {
                    timeFlag: 1,
                    alarmTime: undefined,
                    deptId: undefined
                },
                alarmCount: {},
                yearAlarmData: [],
                alarmTypeData: [],
                alarmTypeMaxNum: 0,
                deptOptions: [],
                /* 本单位信息 */
                userDeptInfo: {}
            };
        },
        components: {
            alarmTypeEchart,
            alarmDayEchart
        },
        created() {
            var date = new Date();
            this.queryParams.alarmTime = date.getFullYear().toString(); //获取完整的年份(4位)
            /* 获取当前用户jwt信息 */
            let userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'));
            this.queryParams.deptId = userInfo.deptId;
            getDept(this.queryParams.deptId).then((response) => {
                this.userDeptInfo = response;
            });

            this.getList();

            this.getDicts('sys_device_type').then((response) => {
                this.statusOptions = response;
            });
        },
        methods: {
            /** 查询单位列表 */
            getList() {
                deAlRecordEcharts(this.queryParams).then((response) => {
                    /* 处理折线图 */
                    let year = '',
                        month = '',
                        yearData = [];
                    if (this.queryParams.timeFlag == 1) {
                        yearData = [
                            '01',
                            '02',
                            '03',
                            '04',
                            '05',
                            '06',
                            '07',
                            '08',
                            '09',
                            '10',
                            '11',
                            '12'
                        ];
                    } else {
                        year = this.queryParams.alarmTime.split('-')[0];
                        month = this.queryParams.alarmTime.split('-')[1];
                        /* 获取当前月份的天数 */
                        var d = new Date(year, month, 0);
                        let days = d.getDate();
                        for (let i = 1; i <= days; i++) {
                            if (i < 10) {
                                yearData.push('0' + i);
                            } else {
                                yearData.push(i);
                            }
                        }
                    }

                    for (let j = 0; j < yearData.length; j++) {
                        this.yearAlarmData.push({
                            month: yearData[j],
                            value: 0
                        });
                        for (let i = 0; i < response.timeList.length; i++) {
                            if (response.timeList[i].alarmTime == yearData[j]) {
                                this.yearAlarmData[j].value =
                                    response.timeList[i].count;
                                break;
                            }
                        }
                    }
                    /* 处理总报警数 */
                    let newAlarmCount = '';
                    if (response.allAlarmCount == null)
                        response.allAlarmCount = '';
                    response.allAlarmCount = String(response.allAlarmCount);
                    if (response.allAlarmCount.length > 7) {
                        newAlarmCount = response.allAlarmCount.splice(-7);
                    } else {
                        newAlarmCount = response.allAlarmCount;
                        for (
                            let i = 0;
                            i < 7 - response.allAlarmCount.length;
                            i++
                        ) {
                            newAlarmCount = '0' + newAlarmCount;
                        }
                    }
                    response.allAlarmCount = toThousands(newAlarmCount);
                    /* 处理警情次数 */
                    response.newCallAlarmCount = response.callAlarmCount;
                    response.newCallAlarmCount = String(
                        response.newCallAlarmCount
                    );
                    let useCallAlarmCount = '';
                    if (response.newCallAlarmCount.length > 7) {
                        useCallAlarmCount = response.newCallAlarmCount.splice(
                            -7
                        );
                    } else {
                        useCallAlarmCount = response.newCallAlarmCount;
                        for (
                            let i = 0;
                            i < 7 - response.newCallAlarmCount.length;
                            i++
                        ) {
                            useCallAlarmCount = '0' + useCallAlarmCount;
                        }
                    }
                    response.newCallAlarmCount = toThousands(useCallAlarmCount);
                    /* 处理报警类型图 */
                    response.circleList.forEach((item, index) => {
                        if (item.count > this.alarmTypeMaxNum) {
                            this.alarmTimerMaxNum = item.count;
                        }
                        this.alarmTypeData.push({
                            name: item.alarmType,
                            value: item.count
                        });
                    });

                    this.alarmCount = response;
                    // this.alarmTypeData.forEach((item,index) =>{
                    // 	if(item.stockCount>this.maxNum){
                    // 		this.maxNum = item.stockCount
                    // 	}
                    // })
                });
            },

            /** 搜索按钮操作 */
            handleQuery(val) {
                if (val == 'timeChange' && this.queryParams.timeFlag == 1) {
                    var date = new Date();
                    this.queryParams.alarmTime = date.getFullYear().toString(); //获取完整的年份(4位)
                } else if (
                    val == 'timeChange' &&
                    this.queryParams.timeFlag == 0
                ) {
                    var date = new Date();
                    this.queryParams.alarmTime =
                        date.getFullYear() + '-' + (date.getMonth() + 1);
                }
                this.yearAlarmData = [];
                this.alarmTypeData = [];
                this.getList();
            }
        }
    };
</script>

<style scoped lang="scss">
    .components-container {
        .alarmEchartBigBox {
            display: flex;
            justify-content: space-between;
            p {
                font-size: 20px;
                padding-top: 17px;
                padding-left: 20px;
            }
            h4 {
                text-align: center;
            }
            .echartBoxLeft {
                width: 419px;
                height: 581px;
                .alarmBox {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    .alarmNumBox {
                        width: 32px;
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        background-color: rgba(52, 230, 254, 0.2);
                        margin-left: 5px;
                    }
                }
                .leftSmallBox {
                    width: 100%;
                    height: 290px;
                    background-image: url('../../assets/image/alarmEchartLeft.png');
                    background-size: 100% 100%;

                    .alarmBottomBox {
                        padding: 15px 28px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .echartBoxCenter {
                width: 657px;
                height: 581px;
                background-image: url('../../assets/image/alarmEchartCenter.png');
                background-size: 100% 100%;
            }
            .echartBoxRight {
                width: 499px;
                height: 581px;
                background-image: url('../../assets/image/alarmEchartRight.png');
                background-size: 100% 100%;
            }
        }
    }
</style>
