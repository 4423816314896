<template>
  <div class="components-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col :span="4" :xs="24">
        <el-back>

          <div class="head-container">
            <el-input v-model="deptName" placeholder="请输入部门名称" clearable size="small" prefix-icon="el-icon-search"
              style="margin-bottom: 20px" />
          </div>
          <div class="head-container">
            <el-tree :data="deptOptions" :props="defaultPropsDept" :expand-on-click-node="false"
              :filter-node-method="filterNode" ref="tree" default-expand-all @node-click="handleNodeClick">
              <span class="span-ellipsis" slot-scope="{ node, data }">
                <span :title="node.label">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>

        </el-back>
      </el-col>
      <!--角色数据-->
      <el-col :span="20" :xs="24">
        <el-back>
          <div class="formBox">角色信息搜索</div>
          <el-form :model="queryParams" ref="queryForm" :inline="true">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="queryParams.roleName" placeholder="请输入角色名称" clearable size="small" style="width: 240px"
                @keyup.enter.native="handleQuery" />
            </el-form-item>

            <el-form-item label="状态" prop="status">
              <el-select v-model="queryParams.status" placeholder="角色状态" clearable size="small" style="width: 240px">
                <el-option v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictLabel"
                  :value="dict.dictValue" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
              <el-button type="primary" icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>

          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
                v-hasPermi="['system:role:add']">新增</el-button>
            </el-col>
            <el-col :span="1.5">
              <el-button type="primary" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                v-hasPermi="['system:role:edit']">修改</el-button>
            </el-col>
            <el-col :span="1.5">
              <el-button type="primary" icon="el-icon-delete" size="mini" :disabled="single" @click="handleDelete"
                v-hasPermi="['system:role:remove']">删除</el-button>
            </el-col>
            <!--      <el-col :span="1.5">
				        <el-button
				          type="warning"
				          icon="el-icon-download"
				          size="mini"
				          @click="handleExport"
				          v-hasPermi="['system:post:export']"
				        >导出</el-button>
				      </el-col> -->
          </el-row>

          <el-table v-loading="loading" :data="roleList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="角色编号" prop="roleId" align="center" />
            <el-table-column label="角色名称" prop="roleName" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="权限字符" prop="roleKey" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="显示顺序" prop="roleSort" align="center" />
            <el-table-column label="角色状态" align="center">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" active-value="0" inactive-value="1"
                  @change="handleStatusChange(scope.row)"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="角色备注" prop="remark" align="center" />

            <el-table-column label="创建时间" align="center" prop="createTime" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="角色操作" align="center" class-name="small-padding fixed-width" width="180">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                  v-if="scope.row.roleKey !== 'admin'" v-hasPermi="['system:role:edit']">修改</el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                  v-if="scope.row.roleKey !== 'admin'" v-hasPermi="['system:role:remove']">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!--    <pagination
				      v-show="total>0"
				      :total="total"
				      :page.sync="queryParams.pageNum"
				      :limit.sync="queryParams.pageSize"
				      @pagination="getList"
				    /> -->
        </el-back>
        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="form.roleName" placeholder="请输入角色名称" />
            </el-form-item>
            <el-form-item label="权限字符" prop="roleKey">
              <el-input v-model="form.roleKey" placeholder="请输入权限字符" />
            </el-form-item>
            <el-form-item label="显示顺序" prop="roleSort">
              <el-input-number v-model="form.roleSort" controls-position="right" :min="0" />
            </el-form-item>
            <el-form-item label="角色状态">
              <el-radio-group v-model="form.status">
                <el-radio v-for="dict in statusOptions" :key="dict.dictValue"
                  :label="dict.dictValue">{{ dict.dictLabel }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="菜单权限">
              <el-tree :data="menuOptions" show-checkbox ref="menu" node-key="menuId" empty-text="加载中，请稍后"
                :props="defaultPropsMenu"></el-tree>
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </el-dialog>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { listRole, getRole, delRole, addRole, updateRole, exportRole, dataScope, changeRoleStatus, checkRoleName, checkRoleKey } from "@/api/system/role";
import { treeselect as menuTreeselect, roleMenuTreeselect } from "@/api/system/menu";

import { traverseTreeArray, translateDataToTree } from "@/utils/index"
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Role",
  components: { Treeselect },
  data() {
    var checkRoleNames = (rule, value, callback) => {
      let roleName = { roleName: value };
      if (this.title == '添加角色') {
        checkRoleName(roleName).then(res => {
          if (res == 0) {
            callback();
          } else {
            callback(new Error('角色名称重复，请重新输入'));
          }
        })
      } else {
        callback();
      }
    };
    var checkRoleKeys = (rule, value, callback) => {
      let roleName = { roleKey: value };
      if (this.title == '添加角色') {
        checkRoleKey(roleName).then(res => {
          if (res == 0) {
            callback();
          } else {
            callback(new Error('角色权限重复，请重新输入'));
          }
        })
      } else {
        callback();
      }
    };
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      deptName: undefined,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      deptOptions: [],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: undefined,
        deptId: undefined
      },
      // 表单参数
      form: {},
      defaultPropsDept: {
        children: "children",
        label: "label"
      },
      defaultPropsMenu: {
        children: "children",
        label: "menuName"
      },
      // 表单校验
      rules: {
        roleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
          { required: true, validator: checkRoleNames, trigger: 'blur' }
        ],
        roleKey: [
          { required: true, message: "权限字符不能为空", trigger: "blur" },
          { required: true, validator: checkRoleKeys, trigger: 'blur' }

        ],
        roleSort: [
          { required: true, message: "角色顺序不能为空", trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.getList();
    this.getTreeselect();
    this.getDicts("sys_normal_disable").then(response => {
      this.statusOptions = response;
    });
    // this.getConfigKey("sys.user.initPassword").then(response => {
    //   this.initPassword = response;
    // });
  },
  methods: {
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      listRole(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.roleList = response.queryResult.list;
          this.total = response.total;
          this.loading = false;
        }
      );
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then(response => {
        this.deptOptions = traverseTreeArray(response, "deptName", 'deptId');
      });
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.deptId = data.id;
      this.getList();
    },
    /** 查询菜单树结构 */
    getMenuTreeselect() {
      menuTreeselect().then(response => {
        this.menuOptions = translateDataToTree(response.queryResult.list, "menuId");
      });
    },

    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getHalfCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },

    /** 根据角色ID查询菜单树结构 */
    getRoleMenuTreeselect(roleId) {
      roleMenuTreeselect(roleId).then(response => {

        this.getMenuTreeselect();
        let that = this
        setTimeout(function () {
          response.queryResult.list.forEach(value => {
            if (value && value.menuId) {
              that.$refs.menu.setChecked(value.menuId, true, false);
            }
          });
        }, 500);

      });
    },

    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === "0" ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', "警告", {
        customClass: "deleteBox",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {

        let form = { roleId: row.roleId, status: row.status }
        return updateRole(form)
      }).then(() => {
        this.msgSuccess(text + "成功");
      }).catch(function () {
        row.status = row.status === "0" ? "1" : "0";
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      this.form = {
        roleId: undefined,
        roleName: undefined,
        roleKey: undefined,
        roleSort: undefined,
        status: "0",
        menuIds: [],
        deptId: undefined,
        remark: undefined,
        params: {}
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.roleId)
      let ad = selection.some(value => value.roleKey == 'admin')
      if (ad) {
        this.single = true;
        this.multiple = true;
      } else {
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      // this.getTreeselect();
      this.getMenuTreeselect();
      this.open = true;
      this.title = "添加角色";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const roleId = row.roleId || this.ids[0]
      this.$nextTick(() => {
        this.getRoleMenuTreeselect(roleId);
      });
      getRole(roleId).then(response => {
        this.form = response.queryResult.list[0];
        this.open = true;
        this.title = "修改角色";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.roleId != undefined) {
            this.form.menuIds = this.getMenuAllCheckedKeys();
            updateRole(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.form.menuIds = this.getMenuAllCheckedKeys();
            if (this.queryParams.deptId) this.form.deptId = this.queryParams.deptId

            addRole(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const roleIds = row.roleId || this.ids;
      this.$confirm('是否确认删除角色编号为"' + roleIds + '"的数据项?', "警告", {
        customClass: "deleteBox",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delRole(roleIds);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      }).catch(function () { });
    },

  }
};
</script>
