<template>
    <div class="components-container">
        <el-back>
            <el-row>
                <el-col :span="8" class="formBox">支付宝收款设置</el-col>
                <el-col :span="7" class="formBox">银联收款设置</el-col>
                <el-col :span="8" class="formBox">微信收款设置</el-col>
            </el-row>
            <el-row class="boder">
                <el-col :span="8" class="boder-l">
                    <p class="padding-20">
                        支付宝收款
                        <el-button
                            icon="el-icon-edit"
                            @click="AlipayUpdate"
                        ></el-button>
                    </p>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">阿里商户账户</el-col>
                            <el-col :span="16">{{ Alipay.aliPid }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">app应用ID</el-col>
                            <el-col :span="16">{{ Alipay.aliAppId }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">web应用ID</el-col>
                            <el-col :span="16">{{ Alipay.aliWebId }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">小程序应用ID</el-col>
                            <el-col :span="16">{{ Alipay.aliMiniId }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">商户ID</el-col>
                            <el-col :span="16">{{ Alipay.aliSeller }}</el-col>
                        </el-row>
                    </div>
                    <div
                        :title="rivateTitle"
                        style="padding: 10px; font-size: 16px; overflow:hidden; width: 520px; height: 100px;"
                    >
                        <el-row style="padding: 10px ;" type="flex">
                            <el-col :span="8">应用私钥</el-col>
                            <el-col :span="16">{{
                                Alipay.aliPrivateKey
                            }}</el-col>
                        </el-row>
                    </div>
                    <div
                        :title="publicTitle"
                        style="padding: 10px; font-size: 16px; overflow:hidden; width: 520px; height: 100px;"
                    >
                        <el-row style="padding: 10px ;">
                            <el-col :span="8">支付宝公钥</el-col>
                            <el-col :span="16">{{
                                Alipay.aliPublicKey
                            }}</el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="7" class="boder-c">
                    <p class="padding-20">
                        银联收款
                        <el-button
                            icon="el-icon-edit"
                            @click="UnionpayUpdate"
                        ></el-button>
                    </p>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">商户代码</el-col>
                            <el-col :span="14">{{
                                Unionpay.unionMerId
                            }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">签名证书密码</el-col>
                            <el-col :span="14">{{
                                Unionpay.unionSigncertPwd
                            }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col
                                >签名证书路径
                                <el-button
                                    style="float: right;"
                                    @click="signcerDownload"
                                    v-if="unionSigncertPath !== null"
                                    >点击下载</el-button
                                >
                            </el-col>
                        </el-row>
                    </div>

                    <!-- 				<div style="padding: 10px; font-size: 16px;">
							<el-row style="padding: 10px ;">
								<el-col>敏感信息加密证书路径
								<el-button style="float: right;" @click="encryptcertDownload"  v-if="unionEncryptcertPath !== null">点击下载</el-button>
								</el-col>
							</el-row>
						</div> -->
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col
                                >验签中级证书路径
                                <el-button
                                    style="float: right;"
                                    @click="middleDownload"
                                    v-if="unionMiddlecertPath !== null"
                                    >点击下载</el-button
                                >
                            </el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col
                                >验签根证书路径
                                <el-button
                                    style="float: right;"
                                    @click="rootcertDownload"
                                    v-if="unionRootcertPath !== null"
                                    >点击下载</el-button
                                >
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="8" class="boder-r">
                    <p class="padding-20">
                        微信收款
                        <el-button
                            icon="el-icon-edit"
                            @click="WeChatUpdate"
                        ></el-button>
                    </p>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">商户ID</el-col>
                            <el-col :span="14">{{ WeChat.wxMecid }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">app应用ID</el-col>
                            <el-col :span="14">{{ WeChat.wxAppId }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">web应用ID</el-col>
                            <el-col :span="14">{{ WeChat.wxWebAppid }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">小程序应用ID</el-col>
                            <el-col :span="14">{{ WeChat.wxMiniAppid }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col :span="10">密钥</el-col>
                            <el-col :span="14">{{ WeChat.wxSecertkey }}</el-col>
                        </el-row>
                    </div>
                    <div style="padding: 10px; font-size: 16px;">
                        <el-row style="padding: 10px ;">
                            <el-col
                                >证书
                                <el-button
                                    style="float: right;"
                                    @click="wxCertDownload"
                                    v-if="wxCert !== null"
                                    >点击下载</el-button
                                >
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-back>

        <!-- 弹出框 -->
        <el-dialog :title="aliTitle" :visible.sync="aliOpen" width="600px">
            <el-form ref="form" :model="aliForm" label-width="110px">
                <el-form-item label="阿里商户账户ID" prop=" aliPid">
                    <el-input v-model="aliForm.aliPid" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="app应用ID" prop=" aliAppId">
                    <el-input v-model="aliForm.aliAppId" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="web应用ID" prop=" aliWebId">
                    <el-input v-model="aliForm.aliWebId" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="小程序应用ID" prop=" aliMiniId">
                    <el-input
                        v-model="aliForm.aliMiniId"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="商户ID" prop="aliSeller">
                    <el-input
                        v-model="aliForm.aliSeller"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="应用私钥" prop="aliPrivateKey">
                    <el-input
                        v-model="aliForm.aliPrivateKey"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="支付宝公钥" prop="aliPublicKey">
                    <el-input
                        v-model="aliForm.aliPublicKey"
                        placeholder="请输入"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="aliSubmit">确 定</el-button>
                <el-button @click="aliCancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="unionTitle" :visible.sync="unionOpen" width="600px">
            <el-form ref="form" :model="unionForm" label-width="160px">
                <el-form-item label="商户代码" prop=" unionMerId">
                    <el-input
                        v-model="unionForm.unionMerId"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="签名证书密码" prop="unionSigncertPwd">
                    <el-input
                        v-model="unionForm.unionSigncertPwd"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="签名证书路径" prop="unionSigncertPath">
                    <el-upload
                        action="#"
                        accept=".docx,.doc,.pdf"
                        :data="unionForm"
                        :headers="header"
                        ref="upload1"
                        :limit="1"
                        :on-change="handleChange1"
                        :on-remove="handleRemove1"
                        :auto-upload="false"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                    </el-upload>
                </el-form-item>
                <!-- 			<el-form-item label="敏感信息加密证书路径" prop="unionEncryptcertPath">
			  <el-upload action="#" accept=".docx,.doc,.pdf" :data="unionForm" :headers="header" ref="upload2" :limit="1"
			    :on-change="handleChange2" :on-remove="handleRemove2" :auto-upload="false" :file-list="fileList">
			    <el-button size="small" type="primary">点击上传</el-button>
			  </el-upload>
			</el-form-item> -->
                <el-form-item
                    label="验签中级证书路径"
                    prop="unionMiddlecertPath"
                >
                    <el-upload
                        action="#"
                        accept=".docx,.doc,.pdf"
                        :data="unionForm"
                        :headers="header"
                        ref="upload3"
                        :limit="1"
                        :on-change="handleChange3"
                        :on-remove="handleRemove3"
                        :auto-upload="false"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                    </el-upload>
                </el-form-item>
                <el-form-item label="验签根证书路径" prop="unionRootcertPath">
                    <el-upload
                        action="#"
                        accept=".docx,.doc,.pdf"
                        :data="unionForm"
                        :headers="header"
                        ref="upload4"
                        :limit="1"
                        :on-change="handleChange4"
                        :on-remove="handleRemove4"
                        :auto-upload="false"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="UnionSubmit">确 定</el-button>
                <el-button @click="UnionCancel">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 微信修改 -->
        <el-dialog
            :title="weixinTitle"
            :visible.sync="weixinOpen"
            width="600px"
        >
            <el-form ref="form" :model="weixinForm" label-width="160px">
                <el-form-item label="商户ID" prop="wxMecid">
                    <el-input
                        v-model="weixinForm.wxMecid"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="app应用ID" prop="wxAppId">
                    <el-input
                        v-model="weixinForm.wxAppId"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="web应用ID" prop="wxWebAppid">
                    <el-input
                        v-model="weixinForm.wxWebAppid"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="小程序应用ID" prop="wxMiniAppid">
                    <el-input
                        v-model="weixinForm.wxMiniAppid"
                        placeholder="请输入"
                    />
                </el-form-item>
                <el-form-item label="密钥" prop="wxSecertkey">
                    <el-input
                        v-model="weixinForm.wxSecertkey"
                        placeholder="请输入"
                    />
                </el-form-item>

                <el-form-item label="证书" prop="wxCert">
                    <el-upload
                        action="#"
                        accept=".docx,.doc,.pdf"
                        :data="weixinForm"
                        :headers="header"
                        ref="upload5"
                        :limit="1"
                        :on-change="handleChange5"
                        :on-remove="handleRemove5"
                        :auto-upload="false"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="weixinSubmit"
                    >确 定</el-button
                >
                <el-button @click="weixinCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getSysDepFreeSetById,
        getFreeSetByLocalDept,
        updSysDeptFreeSet
    } from '@/api/cashierDesk/account.js';
    import { getToken } from '@/utils/auth';
    export default {
        name: 'account',
        data() {
            return {
                // 弹出层标题
                aliTitle: '',
                unionTitle: '',
                weixinTitle: '',
                //列表过长内容
                rivateTitle: '',
                publicTitle: '',
                // 是否显示弹出层
                aliOpen: false,
                unionOpen: false,
                weixinOpen: false,
                // 列表数据
                Alipay: {},
                Unionpay: {},
                WeChat: {},
                //支付配置
                freeSetType: 1,
                //BYid
                id: 0,
                // 表单
                aliForm: {
                    aliPid: undefined,
                    aliAppId: undefined,
                    aliWebId: undefined,
                    aliMiniId: undefined,
                    aliSeller: undefined,
                    aliPrivateKey: undefined,
                    aliPublicKey: undefined,
                    id: undefined
                },
                unionForm: {
                    unionMerId: undefined,
                    unionSigncertPwd: undefined,
                    id: undefined
                    /* unionSigncertPath: undefined,
				unionEncryptcertPath: undefined,
				unionMiddlecertPath: undefined,
				unionRootcertPath: undefined, */
                },
                weixinForm: {
                    wxMecid: undefined,
                    wxAppId: undefined,
                    wxWebAppid: undefined,
                    wxMiniAppid: undefined,
                    wxSecertkey: undefined,
                    id: undefined
                },
                //文件路径
                unionSigncertPath: undefined,
                // unionEncryptcertPath: undefined,
                unionMiddlecertPath: undefined,
                unionRootcertPath: undefined,
                wxCert: undefined,
                //上传
                fileList: [],
                header: {
                    Authorization: ''
                }
            };
        },
        created() {
            this.getWorkInfo();
        },
        methods: {
            /**
             * @description 获取相关信息
             */
            getWorkInfo() {
                this.header.Authorization = 'Bearer ' + getToken('iffp_jwt');
                getFreeSetByLocalDept(this.freeSetType).then((response) => {
                    /* 支付宝*/
                    this.Alipay = response;
                    this.rivateTitle = response.aliPrivateKey;
                    this.publicTitle = response.aliPublicKey;
                    /* 银联 */
                    this.Unionpay = response;
                    this.id = response.id;
                    this.unionSigncertPath = response.unionSigncertPath;
                    // this.unionEncryptcertPath = response.unionEncryptcertPath
                    this.unionMiddlecertPath = response.unionMiddlecertPath;
                    this.unionRootcertPath = response.unionRootcertPath;
                    /* 微信*/
                    this.WeChat = response;

                    this.wxCert = response.wxCert;
                });
            },
            /* 支付宝收款*/
            AlipayUpdate() {
                this.resetForm('aliForm');
                this.fileList = [];
                getFreeSetByLocalDept(this.freeSetType).then((res) => {
                    this.aliForm.aliPid = res.aliPid;
                    this.aliForm.aliAppId = res.aliAppId;
                    this.aliForm.aliWebId = res.aliWebId;
                    this.aliForm.aliMiniId = res.aliMiniId;
                    this.aliForm.aliSeller = res.aliSeller;
                    this.aliForm.aliPrivateKey = res.aliPrivateKey;
                    this.aliForm.aliPublicKey = res.aliPublicKey;
                    this.aliForm.id = this.id;

                    this.aliTitle = '支付宝收款';
                    this.aliOpen = true;
                });
            },
            /* 银联收款*/
            UnionpayUpdate() {
                this.resetForm('unionForm');
                this.fileList = [];
                getFreeSetByLocalDept(this.freeSetType).then((res) => {
                    this.unionForm.unionMerId = res.unionMerId;
                    this.unionForm.unionSigncertPwd = res.unionSigncertPwd;
                    this.unionForm.id = this.id;
                    /* this.unionForm.unionSigncertPath = res.unionSigncertPath
				  this.unionForm.unionEncryptcertPath = res.unionEncryptcertPath
				  this.unionForm.unionMiddlecertPath = res.unionMiddlecertPath
				  this.unionForm.unionRootcertPath = res.unionRootcertPath */
                    this.unionTitle = '银联收款';
                    this.unionOpen = true;
                });
            },
            /* 微信收款*/
            WeChatUpdate() {
                this.resetForm('aliForm');
                this.fileList = [];
                getFreeSetByLocalDept(this.freeSetType).then((res) => {
                    this.weixinForm.wxMecid = res.wxMecid;
                    this.weixinForm.wxAppId = res.wxAppId;
                    this.weixinForm.wxWebAppid = res.wxWebAppid;
                    this.weixinForm.wxMiniAppid = res.wxMiniAppid;
                    this.weixinForm.wxSecertkey = res.wxSecertkey;
                    this.weixinForm.id = this.id;
                    this.weixinTitle = '微信收款';
                    this.weixinOpen = true;
                });
            },
            //点击下载
            signcerDownload() {
                window.location.href = this.$viewIp + this.unionSigncertPath;
            },
            // encryptcertDownload(){
            // 	  window.location.href = this.$viewIp + this.unionEncryptcertPath
            // },
            middleDownload() {
                window.location.href = this.$viewIp + this.unionMiddlecertPath;
            },
            rootcertDownload() {
                window.location.href = this.$viewIp + this.unionRootcertPath;
            },
            wxCertDownload() {
                window.location.href = this.$viewIp + this.wxCert;
            },
            //点击上传
            handleChange1(file, fileList) {
                this.fileList[0] = file.raw;
            },
            // handleChange2(file, fileList){
            //   this.fileList[1] = file.raw
            // },
            handleChange3(file, fileList) {
                this.fileList[2] = file.raw;
            },
            handleChange4(file, fileList) {
                this.fileList[3] = file.raw;
            },
            handleChange5(file, fileList) {
                this.fileList[4] = file.raw;
            },
            /* 删除文件*/
            handleRemove1(file, fileList) {
                this.fileList[0] = [];
            },
            // handleRemove2(file,fileList){
            //   this.fileList[1] = []
            // },
            handleRemove3(file, fileList) {
                this.fileList[2] = [];
            },
            handleRemove4(file, fileList) {
                this.fileList[3] = [];
            },
            handleRemove5(file, fileList) {
                this.fileList[4] = [];
            },
            /* 取消*/
            aliCancel() {
                this.resetForm('aliForm');
                this.aliOpen = false;
            },
            UnionCancel() {
                this.resetForm('unionForm');
                this.unionOpen = false;
            },
            weixinCancel() {
                this.resetForm('weixinForm');
                this.weixinOpen = false;
            },
            /* 支付宝提交*/
            aliSubmit() {
                var upData = new FormData();
                var newForm = this.aliForm;
                for (let i in newForm) {
                    upData.append(i, newForm[i]);
                }
                updSysDeptFreeSet(upData).then((response) => {
                    if (response.code === 10000) {
                        this.msgSuccess('修改成功');
                        this.aliOpen = false;
                        this.getWorkInfo();
                        this.aliOpen = false;
                    } else {
                        this.msgError(response.msg);
                    }
                });
            },
            /* 银联提交*/
            UnionSubmit() {
                var upData = new FormData();
                var newForm = this.unionForm;
                this.fileList.forEach((item, index) => {
                    if (item.size !== undefined && item !== undefined) {
                        upData.append(
                            'file' + (index + 1),
                            this.fileList[index],
                            this.fileList[index].name
                        );
                    } else {
                        upData.append('file' + (index + 1), '');
                    }
                });
                for (let i in newForm) {
                    upData.append(i, newForm[i]);
                }

                updSysDeptFreeSet(upData).then((response) => {
                    if (response.code === 10000) {
                        this.msgSuccess('修改成功');
                        this.unionOpen = false;
                        this.getWorkInfo();
                    } else {
                        this.msgError(response.msg);
                    }
                });
                /* if (this.fileList[0] !== undefined) {
				upData.append('file1', this.fileList[0], this.fileList[0].name);
			  }
			  if (this.fileList[1] !== undefined) {
				upData.append('file2', this.fileList[1], this.fileList[1].name);
			  }
			  if (this.fileList[2] !== undefined) {
				upData.append('file3', this.fileList[2], this.fileList[2].name);
			  }
			  if (this.fileList[3] !== undefined) {
				upData.append('file4', this.fileList[3], this.fileList[3].name);
			  } */
            },
            /* 微信提交*/
            weixinSubmit() {
                var upData = new FormData();
                var newForm = this.weixinForm;

                upData.append('wxfile', this.fileList[4]);

                for (let i in newForm) {
                    upData.append(i, newForm[i]);
                }

                updSysDeptFreeSet(upData).then((response) => {
                    if (response.code === 10000) {
                        this.msgSuccess('修改成功');
                        this.weixinOpen = false;
                        this.getWorkInfo();
                    } else {
                        this.msgError(response.msg);
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    .padding-20 {
        font-size: 24px;
    }
    .boder-l {
        height: 550px;
        margin: 10px;
        background-image: url('../../../assets/image/account.png');
        background-size: 100% 100%;
    }
    .boder-c {
        height: 550px;
        margin: 10px;
        background-image: url('../../../assets/image/account.png');
        background-size: 100% 100%;
    }
    .boder-r {
        height: 550px;
        margin: 10px;
        background-image: url('../../../assets/image/account.png');
        background-size: 100% 100%;
    }
    .el-button--default {
        background-color: #1e2a3a;
    }
    /deep/.el-upload-list__item-name {
        color: #34e6fe;
    }
    /deep/.el-icon-document:before {
        color: #34e6fe;
    }
</style>
