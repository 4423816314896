import request from '@/utils/request'

//列表
export function selectPayRecordsList(data) {
  return request({
    url: '/free/devicePay/selectPayRecordsList',
    method: 'post',
	data: data
  })
}

//缴费统计
export function selectPayRecordsCount(data) {
  return request({
    url: '/free/devicePay/selectPayRecordsCount',
    method: 'post',
	data: data
  })
}

//导出代理商设备缴费记录
export function outAgentDeviceExcel(data) {
  return request({
    url: '/free/devicePayExcel/outAgentDeviceExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}