import request from '@/utils/request'

//列表
export function getReceiverList(data) {
  return request({
    url: '/monitor/receiver/getReceiverList',
    method: 'post',
	data: data
  })
}

//添加
export function insertReceiver(data) {
  return request({
    url: '/monitor/receiver/insertReceiver',
    method: 'post',
	data: data
  })
}

//修改
export function updateReceiver(data) {
  return request({
    url: '/monitor/receiver/updateReceiver',
    method: 'post',
	data: data
  })
}

//个人责任列表
export function receiveDevicePageList(reveiverId) {
  return request({
    url: '/monitor/notRecDev/receiveDevicePageList?reveiverId=' + reveiverId,
    method: 'post'
  })
}

//删除
export function delReceiverById(reveiverId) {
  return request({
    url: '/monitor/receiver/delReceiverById?receiverId=' + reveiverId,
    method: 'post'
  })
}

//分配结果确认
export function updBatchNotRecDev(data) {
  return request({
    url: '/monitor/notRecDev/updBatchNotRecDev',
    method: 'post',
	data: data
  })
}

//详情查询
export function selectReceiverById(reveiverId) {
  return request({
    url: '/monitor/receiver/selectReceiverById?receiverId=' + reveiverId,
    method: 'post'
  })
}

//分配设备列表
export function localDeptRecDev(data) {
  return request({
    url: '/monitor/receiver/localDeptRecDev',
    method: 'post',
	data: data
  })
}