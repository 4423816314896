<template>
  <div class="">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="设备类型" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          placeholder="设备类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in deviceOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="产品ID" prop="deviceCode">
        <el-input
          v-model="queryParams.deviceCode"
          placeholder="产品ID"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName">
        <el-input
          v-model="queryParams.deviceName"
          placeholder="请输入设备名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="undistributeList">
      <el-table-column label="编号" type="index" width="55" align="center" />
      <el-table-column
        label="设备种类"
        align="center"
        prop="deviceType"
        :formatter="deviceFormat"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="设备名称"
        align="center"
        prop="deviceName"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="产品ID"
        align="center"
        prop="deviceCode"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="建筑楼层"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.buildName }}{{ scope.row.unitName
            }}{{ scope.row.tier }}层{{ scope.row.roomNum }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="安装位置"
        align="center"
        prop="address"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="用户操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleChange(scope.row)"
            >分配使用人</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="设备使用人">
          <el-select v-model="form.userId" placeholder="请选择">
            <el-option
              v-for="dict in DeviceUseOptions"
              :key="dict.userId"
              :label="dict.nickname"
              :value="dict.userId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectUserSmokeDetectorList,
  selectDeptRankUser,
  insertUserDevice,
} from "@/api/deviceUse.js";
import qs from "qs";
export default {
  name: "undistributed",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      undistributeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 数据字典
      deviceOptions: [],
      DeviceUseOptions: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        tag: "0",
        deviceType: undefined,
        deviceCode: undefined,
        deviceName: undefined,
      },
      // 表单参数
      form: {},
    };
  },
  created() {
    this.getDicts("sys_device_type").then((response) => {
      this.deviceOptions = response;
    });
    this.getList();
  },
  methods: {
    // 数据状态字典翻译
    deviceFormat(row, column) {
      return this.selectDictLabel(this.deviceOptions, row.deviceType);
    },
    /** 查询列表 */
    getList() {
      this.loading = true;
      selectUserSmokeDetectorList(this.queryParams).then((response) => {
        this.undistributeList = response.queryResult.list;
        this.total = response.queryResult.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        userId: undefined,
        deviceCode: undefined,
        deviceType: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    //分配使用人
    handleChange(row) {
      this.form.deviceType = row.deviceType;
      this.form.deviceCode = row.deviceCode;
      selectDeptRankUser().then((res) => {
        this.DeviceUseOptions = res;
        this.open = true;
        this.title = "分配设备使用人";
      });
    },
    /** 提交按钮 */
    submitForm() {
      if (this.form.userId !== undefined) {
        insertUserDevice(this.form).then((res) => {
          if (res.code === 10000) {
            this.msgSuccess(res.message);
            this.open = false;
            this.getList();
          } else {
            this.msgError(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  margin: 10px;
}
</style>
