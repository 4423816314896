/*
 * @version: V1.0.0
 * @Author: 倪志磊
 * @Date: 2023-04-10 15:10:24
 * @LastEditors: 倪志磊
 * @LastEditTime: 2023-07-25 17:26:58
 * @company: 骅远科技
 * @Descripttion:
 */
import { login, getInfo, logout } from '@/api/login';
import {
    getToken,
    setToken,
    removeToken,
    setUserSession,
    getUserSession,
    delUserSession
} from '@/utils/auth';
import jwtDecode from 'jwt-decode';
const user = {
    state: {
        userName: '',
        token: getUserSession('iffp_jwt'),
        permissions: []
    },

    mutations: {
        SET_NAME: (state, name) => {
            state.userName = name;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const data = {
                username: userInfo.username.trim(),
                password: userInfo.password,
                verifyCode: userInfo.verifyCode
            };
            return new Promise((resolve, reject) => {
                login(data)
                    .then((res) => {
                        setToken('iffp_uid', res.token);
                        console.log(getToken('iffp_uid'));
                        // commit('SET_TOKEN', res.token)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then((res) => {
                        // setToken("iffp_jwt",res.jwt)
                        setUserSession('iffp_jwt', res.jwt);
                        commit('SET_TOKEN', res.jwt);
                        let iffpJwtDecodeVal = jwtDecode(res.jwt);
                        console.log(iffpJwtDecodeVal);
                        commit('SET_NAME', iffpJwtDecodeVal.username);
                        commit('SET_PERMISSIONS', iffpJwtDecodeVal.authorities);

                        setUserSession(
                            'iffpJwtDecodeVal',
                            JSON.stringify(iffpJwtDecodeVal)
                        );
                        resolve(iffpJwtDecodeVal);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout()
                    .then(() => {
                        commit('SET_TOKEN', '');
                        // commit('SET_ROLES', [])
                        // commit('SET_PERMISSIONS', [])
                        removeToken('iffp_uid');
                        delUserSession('iffp_jwt');
                        delUserSession('projectEndDialog');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '');
                removeToken('iffp_uid');
                delUserSession('iffp_jwt');
                delUserSession('projectEndDialog');
                resolve();
            });
        }
    }
};

export default user;
