<template>
<div class="components-container">
    <el-back>
        <tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
        <div class="formBox">预警通讯配置</div>
        <el-form :model="queryParams" ref="queryForm" :inline="true">
            <el-form-item label="通讯状态" prop="sendMessage">
                <el-select v-model="queryParams.sendMessage" placeholder="请选择通讯状态" clearable size="small"
                    style="width: 240px">
                    <el-option label="请选择" value=""/>
                    <el-option label="启用" value="1"/>
                    <el-option label="停用" value="0"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" size="mini" :disabled="!(!single && queryParams.sendMessage == 0)" @click="handleStatusChange({sendMessage: 1})">启用通讯</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="success" size="mini" :disabled="!(!single && queryParams.sendMessage == 1)" @click="handleStatusChange({sendMessage: 0})">停用通讯</el-button>
            </el-col>
        </el-row>
        <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" :selectable="selectable"/>
            <el-table-column label="编号" prop="alarmId" align="center" />
            <el-table-column label="报警种类" prop="alarmType" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="通讯状态" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.sendMessage" active-value="1" inactive-value="0"
                        @change="(val) => handleStatusChange({sendMessage: val, value: scope.row})"></el-switch>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.size" @pagination="getList" />
    </el-back>
</div>
</template>
<script>
import { selectAlarmPageList, updateCommunicatetype } from "@/api/smoke/message"
export default {
    data() {
        return {
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 总条数
            total: 0,
            // 列表
            tableData: [],
            //  查询条件
            queryParams:{
                "page": 1,
                "size": 10,
                "deviceType": 0,
                "sendMessage": ""
            },
            // tab
            tabList: []
        }
    },
    created() {
        this.getDicts("sys_device_type").then(response => {
			response.forEach((val, index) => {
				val.id = val.index;
				val.name = val.dictLabel
				val.deviceType = val.dictValue
			});
			this.tabList = response;
		}).then(() =>{
            this.queryParams.page = 1;
            this.queryParams.deviceType = this.tabList[0].deviceType;
            this.getList();
        });
    },
    methods: {
        /***
         * @description 切换 tab
         */
        getTabIndex(val){
            this.queryParams.page = 1;
            this.queryParams.deviceType = val.deviceType;
            this.getList();
        },
        /***
         * @description 批量选择
         */
        handleSelectionChange(selection){
            if(this.queryParams.sendMessage){
                this.ids = selection.map(item =>{ return item.alarmId })
                this.single = !this.ids.length;
            }else{
                this.$message.warning("请先选择通讯状态！")
            }
        },
        /**
         * @description 是否禁止选择
         */ 
        selectable(row){
            if(this.queryParams.sendMessage){
                if(this.queryParams.sendMessage == row.sendMessage){
                    return true;
                }else{
                    return false;
                }
            }else {
                return false;
            }
        },
        /***
         * @description 获取列表
         */
        getList(){
            this.loading = true;
            selectAlarmPageList(this.queryParams).then(res =>{
                if(res.code == 10000){
                    this.tableData = res.queryResult.list;
                    this.total = res.queryResult.total; 
                }
            }).then(() =>{
                this.loading = false;
            })
        },
        /**
         * @description 启用 停用
         */
        handleStatusChange({sendMessage, value}){
            let alarmIds = [];
            if(value){
                alarmIds = [value.alarmId]
            }else{
                alarmIds = this.ids
            };
            this.$confirm(`是否确认当前设备${sendMessage == 1 ?'启用':'停用'}`, '提示', {
                customClass: "deleteBox",
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() =>{
                let formData = new FormData();
                formData.append('alarmIds', alarmIds)
                formData.append('sendMessage', sendMessage)
                updateCommunicatetype(formData).then(res =>{
                    if(res.code == 10000){
                        this.$message.success(`${sendMessage == 1 ?'启用':'停用'}成功！`)
                    }else{
                        this.$message.warning(res.message || `${sendMessage == 1 ?'启用':'停用'}失败！`)
                    }
                }).then(() =>{
                    this.getList()
                })
            })
            
        },
        /**
         * @description 搜索
         */
        handleQuery(){
            this.queryParams.page = 1;
            this.getList()
        },
        /**
         * @description 重置
         */
        resetQuery(){
            this.queryParams.page = 1;
            this.queryParams.sendMessage = "";
            this.getList();
        }
    },
}
</script>
<style scoped></style>