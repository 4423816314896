<template>
	<div class="">
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
			<el-form-item label="产品ID" prop="deviceCode">
				<el-input v-model="queryParams.deviceCode" placeholder="请输入产品ID" clearable size="small" style="width: 240px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="设备名称" prop="deviceName">
				<el-input v-model="queryParams.deviceName" placeholder="请输入设备名称" clearable size="small" style="width: 240px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="设备类别" prop="deviceType">
				<el-select v-model="queryParams.deviceType" size="small" placeholder="请选择设备类别">
					<el-option v-for="dict in deviceOptions" :key="dict.dictValue" :label="dict.dictLabel"
						:value="dict.dictValue" @keyup.enter.native="handleQuery" />
				</el-select>
			</el-form-item>
			<el-form-item label="报警类型" prop="alarmType">
				<el-select v-model="queryParams.alarmType" size="small" placeholder="请选择报警类型">
					<el-option v-for="dict in alarmList" :key="dict.alarmId" :label="dict.alarmType" :value="dict.typeValue"
						@keyup.enter.native="handleQuery" />
				</el-select>
			</el-form-item>
			<el-form-item label="报警时间">
				<el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd"
					type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button icon="el-icon-download" size="mini" @click="handleExport" type="warning">导出报表</el-button>
			</el-col>
		</el-row>

		<el-table v-loading="loading" :data="recordList">
			<el-table-column label="编号" align="center" type="index" />
			<el-table-column label="产品ID" align="center" prop="deviceCode" :show-overflow-tooltip="true" />
			<el-table-column label="设备名称" align="center" prop="deviceName" :show-overflow-tooltip="true" />
			<el-table-column label="设备类别" align="center" prop="deviceType" :show-overflow-tooltip="true" />
			<el-table-column label="单位名称" align="center" prop="deptName" :show-overflow-tooltip="true" />
			<el-table-column label="责任人电话" align="center" prop="phone" :show-overflow-tooltip="true" />
			<el-table-column label="报警类型" align="center" prop="alarmType" :show-overflow-tooltip="true" />
			<el-table-column label="烟雾浓度" align="center" prop="concn" :show-overflow-tooltip="true">
				<template slot-scope="scope" v-if="scope.row.concn !== null">
					<span>{{ scope.row.concn }}%</span>
				</template>
			</el-table-column>
			<el-table-column label="报警时间" align="center" prop="alarmTime" :show-overflow-tooltip="true" />
			<el-table-column label="是否复位" align="center" prop="resetButton" :show-overflow-tooltip="true" />
		</el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.size"
			@pagination="getList" />

	</div>
</template>

<script>
import {
	alarmAnalyRecord,
	alarmNoPageList,
	alarmAnalyRecordExport
} from "@/api/alarm/index";
export default {
	name: "alarmRecord",
	data() {
		return {
			// 遮罩层
			loading: true,
			// 总条数
			total: 0,
			// 表格数据
			recordList: [],
			alarmList: [],
			// 弹出层标题
			title: "",
			// 是否显示弹出层
			open: false,
			// 状态数据字典
			deviceOptions: [],
			alarmOptions: [],
			// 日期范围
			dateRange: [],
			// 查询参数
			queryParams: {
				page: 1,
				size: 10,
				deviceType: undefined,
				deviceName: undefined,
				deviceCode: undefined,
				alarmType: undefined
			},
			// 表单参数
			form: {},
		}
	},
	watch: {
		"queryParams.deviceType": {
			handler(value) {
				if (value) {
					let alarmtype = {
						deviceType: value,
					}
					alarmNoPageList(alarmtype).then(res => {
						this.alarmList = res;
					})
				} else {
					this.alarmList = []
				}
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.getList();
		this.getDicts("sys_device_type").then(response => {
			this.deviceOptions = response;
		});
	},
	methods: {
		getList() {
			this.loading = true;
			alarmAnalyRecord(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
				this.recordList = response.queryResult.list;
				this.total = response.queryResult.total;
				this.loading = false;
			});
		},
		// 取消按钮
		cancel() {
			this.open = false;
			this.reset();
		},
		// 表单重置
		reset() {
			this.form = {
				deviceCode: undefined,
				deviceName: undefined,
				deviceType: undefined,
				alarmType: undefined,
				remark: undefined,
				alarmTrue: undefined
			};
			this.imageUrls = []
			this.resetForm("form");
		},
		/** 搜索按钮操作 */
		handleQuery() {
			this.queryParams.page = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery() {
			this.alarmList = [];
			this.dateRange = [];
			this.resetForm("queryForm");
			this.handleQuery();
		},
		/** 导出按钮操作 */
		handleExport() {
			let queryData = this.queryParams
			this.$confirm('是否确认导出所有数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				customClass: "deleteBox",
				type: "warning"
			}).then(function () {

				return alarmAnalyRecordExport(queryData);

			}).then(response => {
				let blob = new Blob([response], { type: "application/vnd.ms-excel" });//type是文件类，详情可以参阅blob文件类型
				let objectUrl = URL.createObjectURL(blob);//生成下载链接
				window.location.href = objectUrl

			}).catch(function () {

			});
		},
	}
};
</script>
<style scoped lang="scss">
.mydisabled /deep/ .el-upload--picture-card {
	display: none;
}
</style>