<template>
	<div class="components-container">
		<el-row :gutter="20">
			<el-col :span="24" :xs="24">
				<el-back>
					<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
						<el-form-item label="设备种类" prop="deviceType">
							<el-select v-model="queryParams.deviceType" placeholder="设备种类" clearable size="small">
								<el-option v-for="dict in deviceOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
						<el-form-item label="所在单位" prop="deptId">
							<treeselect v-model="queryParams.deptId" :options="deptOptions" placeholder="请选择"
								style="width: 240px;" />
						</el-form-item>
						<el-form-item label="产品ID" prop="deviceCode">
							<el-input v-model="queryParams.deviceCode" placeholder="产品ID" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="IMEI" prop="imei">
							<el-input v-model="queryParams.imei" placeholder="IMEI" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="创建时间">
							<el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd"
								type="daterange" range-separator="-" start-placeholder="开始日期"
								end-placeholder="结束日期"></el-date-picker>
						</el-form-item>
						<el-form-item label="收款单位" prop="payee">
							<el-select v-model="queryParams.payee" placeholder="请选择收款单位" clearable size="small">
								<el-option v-for="dict in payeeOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
						<el-form-item label="支付途径" prop="way">
							<el-select v-model="queryParams.way" placeholder="请选择支付途径" clearable size="small">
								<el-option v-for="dict in payOptions" :key="dict.dictValue" :label="dict.dictLabel"
									:value="dict.dictValue" />
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
							<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
						</el-form-item>
					</el-form>

					<el-row :gutter="10" class="mb8">
						<el-col :span="1.5">
							<el-button icon="el-icon-download" size="mini" @click="handleExport" type="warning">导出报表</el-button>
						</el-col>
					</el-row>

					<el-table v-loading="loading" :data="chargeList">
						<el-table-column label="编号" type="index" width="55" align="center" />
						<el-table-column label="设备种类" align="center" prop="typeName" :show-overflow-tooltip="true" />
						<el-table-column label="产品ID" align="center" prop="deviceCode" :show-overflow-tooltip="true" />
						<el-table-column label="IMEI" align="center" prop="imei" :show-overflow-tooltip="true" />
						<el-table-column label="所在单位" align="center" prop="deptName" :show-overflow-tooltip="true" />
						<el-table-column label="缴费时间" align="center" prop="createTime" :show-overflow-tooltip="true" />
						<el-table-column label="缴费金额" align="center" prop="cost" :show-overflow-tooltip="true" />
						<el-table-column label="缴费时长" align="center" :formatter="validTimeFormat" prop="validTime"
							:show-overflow-tooltip="true" />
						<el-table-column label="缴费后有效期限" align="center" width="180" prop="indate" />
						<el-table-column label="收款单位" align="center" prop="payee" :show-overflow-tooltip="true" />
						<el-table-column label="支付途径" align="center" prop="way" :show-overflow-tooltip="true" />
					</el-table>

					<pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.size"
						@pagination="getList" />
				</el-back>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { selectPayRecordsList, outAgentDeviceExcel } from "@/api/cashierDesk/charge.js";
import { selectDevicePayList, selectPayTypeList, updateDevicePay } from "@/api/cashierDesk/normalDevice.js"
import { traverseTreeArray } from "@/utils/index"
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from "qs"
export default {
	name: "normalDevice",
	components: { Treeselect },
	data() {
		return {
			// 遮罩层
			loading: true,
			// 总条数
			total: 0,
			// 表格数据
			chargeList: [],
			//日期
			dateRange: [],
			// 弹出层标题
			title: "",
			titleFree: "",
			// 是否显示弹出层
			open: false,
			openFree: false,
			// 状态数据字典
			deviceOptions: [],
			deptOptions: [],
			validTimeOptions: [],
			renewOptions: [],
			freeOptions: [],
			payOptions: [{ dictValue: 0, dictLabel: '线上缴费' }, { dictValue: 1, dictLabel: '线下缴费' }],
			payeeOptions: [{ dictValue: 0, dictLabel: '总公司' }, { dictValue: 1, dictLabel: '本单位' }],
			// 查询参数
			queryParams: {
				page: 1,
				size: 10,
				deviceType: undefined,
				deviceCode: undefined,
				imei: undefined,
				way: undefined,
				deptId: undefined,
				payee: undefined,
				status: '0'
			},
			// 表单参数
			form: {},
			formFree: {},
			// 表单校验
			rules: {
				way: [
					{ required: true, message: "不能为空", trigger: "blur" }
				],
			}
		}
	},
	created() {
		this.getDicts("sys_device_type").then(response => {
			this.deviceOptions = response;
		});
		this.getDicts("sys_renew_time").then(response => {
			this.validTimeOptions = response;
		});
		this.getDicts("sys_free_time").then(response => {
			this.freeOptions = response;
		});
		this.getList();
		this.getTreeselect();
	},
	methods: {

		/** 查询列表 */
		getList() {
			this.loading = true;
			selectPayRecordsList(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
				this.chargeList = response.queryResult.list;
				this.total = response.queryResult.total;
				this.loading = false;
			}
			);
		},
		// 数据状态字典翻译
		validTimeFormat(row, column) {
			return this.selectDictLabel(this.validTimeOptions, row.validTime);
		},
		/** 查询部门下拉树结构 */
		getTreeselect() {
			treeselect().then(response => {
				this.deptOptions = traverseTreeArray(response, "deptName", 'deptId');
			});
		},
		// 表单重置
		reset() {
			this.dateRange = [];
			this.form = {
				pay: undefined,
				typeName: undefined,
				infoId: undefined,
				status: undefined,
				deviceCode: undefined,
				imei: undefined
			};
			this.resetForm("form");
		},
		/** 搜索按钮操作 */
		handleQuery() {
			this.queryParams.page = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery() {
			this.resetForm("queryForm");
			this.dateRange = [];
			this.handleQuery();
		},
		/** 导出按钮操作 */
		handleExport() {
			let queryData = this.queryParams
			this.$confirm('是否确认导出所有数据项?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				customClass: "deleteBox",
				type: "warning"
			}).then(function () {

				return outAgentDeviceExcel(queryData);

			}).then(response => {
				let blob = new Blob([response], { type: "application/vnd.ms-excel" });//type是文件类，详情可以参阅blob文件类型
				let objectUrl = URL.createObjectURL(blob);//生成下载链接
				window.location.href = objectUrl

			}).catch(function () {

			});
		},
	}
};
</script>
<style lang="scss" scoped>
.el-radio {
	margin: 10px;
}
</style>
