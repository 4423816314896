<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../../mixins/resize'
import { fontSize } from '@/utils/index.js'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '550px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }

  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.initChart()
      }
    }
  },
  mounted() {

    // this.$nextTick(()=>{
    //   this.initChart()
    // })


  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },

    setOptions({ dept, cost } = {}) {
      let that = this
      this.chart.setOption({
        xAxis: {
		  data: dept,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          splitLine:{
            show:true,
            lineStyle:{
                type:'dashed',
                color:'rgba(52,230,254,0.1)'
            }
          }
        },
        grid: {
          top: "10%",
          bottom: 0,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          },
          splitLine:{
            show:true,
            lineStyle:{
                type:'dashed',
                color:'rgba(52,230,254,0.1)'
            }
          }
        },
        series: [
			{
			  smooth: true,
			  type: 'line',
			  areaStyle: {
				  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					  offset: 0,
					  color: 'rgba(0, 186, 166, 0.42)'
				  }, {
					  offset: 1,
					  color: 'rgba(1, 37, 33, 0.42)'
				  }])
			  },
			  data: cost,
			  animationDuration: 2800,
			  animationEasing: 'cubicInOut',
			}
		]       
        
      })
    }
  }
}
</script>
