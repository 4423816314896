import request from '@/utils/request'
// import axios from 'axios'
// import MockAdapter from 'axios-mock-adapter';
// // 设置模拟调试器实例
// var mock = new MockAdapter(axios);
// // 模拟任意GET请求到 /users
// //reply的参数为 (status, data, headers)
// mock.onPost('/routes').reply(200,
//       [{"name":"系统管理","path":"/system","redirect":"noRedirect","component":"Layout","alwaysShow":true,"meta":{"title":"系统管理","icon":"system"},"children":
//       [{"name":"用户管理","path":"user","component":"system/user/index","meta":{"title":"用户管理","icon":"user"}},{"name":"角色管理","path":"role","component":"system/role/index","meta":{"title":"角色管理","icon":"peoples"}},{"name":"菜单管理","path":"menu","component":"system/menu/index","meta":{"title":"菜单管理","icon":"tree-table"}},{"name":"部门管理","path":"dept","component":"system/dept/index","meta":{"title":"部门管理","icon":"tree"}},{"name":"短信模版管理","path":"note","component":"system/notes/index","meta":{"title":"短信模版管理","icon":"post"}},{"name":"字典管理","path":"dict","component":"system/dict/index","meta":{"title":"字典管理","icon":"dict"}},{"name":"参数设置","path":"config","component":"system/config/index","meta":{"title":"参数设置","icon":"edit"}},{"name":"通知公告","path":"notice","component":"system/notice/index","meta":{"title":"通知公告","icon":"message"}},{"name":"日志管理","path":"log","redirect":"noRedirect","component":"system/log/index","alwaysShow":true,"meta":{"title":"日志管理","icon":"log"},"children":[{"name":"操作日志","path":"operlog","component":"monitor/operlog/index","meta":{"title":"操作日志","icon":"form"}},{"name":"登录日志","path":"logininfor","component":"monitor/logininfor/index","meta":{"title":"登录日志","icon":"logininfor"}}]}]},
//
//       {"name":"系统工具","path":"/tool","redirect":"noRedirect","component":"Layout","alwaysShow":true,"meta":{"title":"系统工具","icon":"tool"},"children":[{"name":"表单构建","path":"build","component":"tool/build/index","meta":{"title":"表单构建","icon":"build"}},{"name":"代码生成","path":"gen","component":"tool/gen/index","meta":{"title":"代码生成","icon":"code"}},{"name":"系统接口","path":"swagger","component":"tool/swagger/index","meta":{"title":"系统接口","icon":"swagger"}}]},
// 	  {"name":"监控管理","path":"/monitor","component":"Layout","children":[{"name":"接收者管理","path":"receiver","component":"monitor/receiver/index"}]},
// 	  ]
// );
// export const getRouters1 = () =>{
//   return axios.post('/routes')
// }
//





// 获取路由
export const getRouters = () => {
  return request({
    url: 'ucenter/menu/selectMenusByUser.do',
    method: 'post'
  })
}
