<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
  import { scrollTo } from '@/utils/scroll-to'

  export default {
    name: 'Pagination',
    props: {
      total: {
        required: true,
        type: Number
      },
      page: {
        type: Number,
        default: 1
      },
      limit: {
        type: Number,
        default: 10
      },
      pageSizes: {
        type: Array,
        default() {
          return [10, 20, 30, 50, 100, 200]
        }
      },
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper'
      },
      background: {
        type: Boolean,
        default: true
      },
      autoScroll: {
        type: Boolean,
        default: true
      },
      hidden: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      currentPage: {
        get() {
          return this.page
        },
        set(val) {
          this.$emit('update:page', val)
        }
      },
      pageSize: {
        get() {
          return this.limit
        },
        set(val) {
          this.$emit('update:limit', val)
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        this.$emit('pagination', { page: this.currentPage, limit: val })
        if (this.autoScroll) {
          scrollTo(0, 800)
        }
      },
      handleCurrentChange(val) {
        this.$emit('pagination', { page: val, limit: this.pageSize })
        if (this.autoScroll) {
          scrollTo(0, 800)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .pagination-container {
    // background:$resetBackgroundColor;
    padding: 32px 16px;
  }
  .pagination-container.hidden {
    display: none;
  }
  /deep/ .el-pagination span:not([class*='suffix']) {
    font-size: 14px;
  }

  /deep/ .el-pagination__total {
    color: #e3e7f7;
  }
  /deep/ .el-pagination__jump {
    color: #e3e7f7;
    font-size: 0.9rem;
  }

  /deep/ .el-pagination.is-background .btn-next {
    background-color: #054851;
    color: #e3e7f7;
  }
  /deep/ .el-pagination.is-background .btn-prev {
    background-color: #054851;
    color: #e3e7f7;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #054851;
    color: #e3e7f7;
  }

  /deep/ .el-pagination.is-background .el-pager li.disabled {
    background-color: #093064;
    color: #e3e7f7;
  }

  /deep/ .el-pagination.is-background .btn-prev.disabled {
    background-color: #093064;
    color: #e3e7f7;
  }

  /deep/ .el-input__inner {
    background-color: #054851;
    color: $resetColor;
    // border: 1px solid $resetColor;
  }

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0f6d7a;
    color: $resetColor;
  }
</style>
