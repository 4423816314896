import request from '@/utils/request'

// 登录方法
export function login(data) {

  return request({
    url: '/ucenterAuth/userlogin',
    method: 'post',
    params: data
  })
}

// 获取jwt令牌
export function getInfo() {
  return request({
    url: '/ucenterAuth/userjwt',
    method: 'get'
  })
}
//
// 退出方法
export function logout() {
  return request({
    url: '/ucenterAuth/userlogout',
    method: 'post'
  })
}

// 修改密码
export function updatePass(data) {
  return request({
    url: 'ucenter/user/updatePassword.do?'+data,
    method: 'post'
  })
}
/* 下载模板 */
export function downloadExcel(templateName) {
  return request({
    url: 'monitor/deviceExcel/downloadErrorExcel?templateName=' + templateName,
    method: 'post',
	responseType:'blob'
  })
}
// 标题
export function selectShowName() {
  return request({
    url: 'ucenter/dept/selectShowName',
    method: 'get'
  })
}