<template>
    <div class="components-container">
        <el-row :gutter="20">
            <!--部门数据-->
            <el-col :span="4" :xs="24">
                <el-back>
                    <div class="head-container">
                        <el-input
                            v-model="deptName"
                            placeholder="请输入部门名称"
                            clearable
                            size="small"
                            prefix-icon="el-icon-search"
                            style="margin-bottom: 20px"
                        />
                    </div>
                    <div class="head-container">
                        <el-tree
                            :data="deptOptions"
                            :props="defaultProps"
                            :expand-on-click-node="false"
                            :filter-node-method="filterNode"
                            ref="tree"
                            default-expand-all
                            @node-click="handleNodeClick"
                        >
                            <span
                                class="span-ellipsis"
                                slot-scope="{ node, data }"
                            >
                                <span :title="node.label">{{
                                    node.label
                                }}</span>
                            </span>
                        </el-tree>
                    </div>
                </el-back>
            </el-col>
            <!--用户数据-->
            <el-col :span="20" :xs="24">
                <el-back>
                    <div class="formBox">用户信息搜索</div>
                    <el-form
                        :model="queryParams"
                        ref="queryForm"
                        :inline="true"
                        label-width="68px"
                    >
                        <el-form-item label="用户名称" prop="username">
                            <el-input
                                v-model="queryParams.username"
                                placeholder="请输入用户名称"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="手机号码" prop="phone">
                            <el-input
                                v-model="queryParams.phone"
                                placeholder="请输入手机号码"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="用户状态" prop="status">
                            <el-select
                                v-model="queryParams.status"
                                placeholder="用户状态"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <el-date-picker
                                v-model="dateRange"
                                size="small"
                                style="width: 240px"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                size="mini"
                                @click="handleQuery"
                                >查询</el-button
                            >
                            <el-button
                                icon="el-icon-refresh"
                                size="mini"
                                @click="resetQuery"
                                >重置</el-button
                            >
                        </el-form-item>
                    </el-form>

                    <el-row :gutter="10" class="mb8">
                        <el-col :span="1.5">
                            <el-button
                                type="primary"
                                icon="el-icon-plus"
                                size="mini"
                                @click="handleAdd"
                                v-hasPermi="['system:user:add']"
                                >新增</el-button
                            >
                        </el-col>
                        <el-col :span="1.5">
                            <el-button
                                type="success"
                                icon="el-icon-edit"
                                size="mini"
                                :disabled="single"
                                @click="handleUpdate"
                                v-hasPermi="['system:user:edit']"
                                >修改</el-button
                            >
                        </el-col>
                        <el-col :span="1.5">
                            <el-button
                                type="danger"
                                icon="el-icon-delete"
                                size="mini"
                                :disabled="multiple"
                                @click="handleDelete"
                                v-hasPermi="['system:user:remove']"
                                >删除</el-button
                            >
                        </el-col>
                        <!--          <el-col :span="1.5">
			            <el-button
			              type="info"
			              icon="el-icon-upload2"
			              size="mini"
			              @click="handleImport"
							v-hasPermi="['system:dict:import']"
			            >导入</el-button>
			          </el-col>
			          <el-col :span="1.5">
			            <el-button
			              type="warning"
			              icon="el-icon-download"
			              size="mini"
			              @click="handleExport"
							v-hasPermi="['system:dict:resetPwd']"
			            >导出</el-button>
			          </el-col> -->
                    </el-row>

                    <el-table
                        v-loading="loading"
                        :data="userList"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                        />
                        <el-table-column
                            label="用户编号"
                            align="center"
                            type="index"
                            :index="indexMethod"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="用户名称"
                            align="center"
                            prop="username"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="用户昵称"
                            align="center"
                            prop="nickname"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="所属部门"
                            align="center"
                            prop="dept.deptName"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="手机号码"
                            align="center"
                            prop="phone"
                            width="120"
                        />
                        <el-table-column label="用户状态" align="center">
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.status"
                                    active-value="0"
                                    inactive-value="1"
                                    @change="handleStatusChange(scope.row)"
                                ></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="用户备注"
                            align="center"
                            prop="remark"
                        />

                        <el-table-column
                            label="创建时间"
                            align="center"
                            prop="createTime"
                            width="160"
                        >
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.createTime)
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="更新时间"
                            align="center"
                            prop="updateTime"
                            width="160"
                        >
                            <template slot-scope="scope">
                                <span>{{
                                    parseTime(scope.row.updateTime)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="用户操作"
                            align="center"
                            width="180"
                            class-name="small-padding fixed-width"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    v-if="scope.row.username !== 'admin'"
                                    @click="handleUpdate(scope.row)"
                                    v-hasPermi="['system:user:edit']"
                                    >修改</el-button
                                >
                                <el-button
                                    v-if="scope.row.username !== 'admin'"
                                    size="mini"
                                    type="text"
                                    icon="el-icon-delete"
                                    @click="handleDelete(scope.row)"
                                    v-hasPermi="['system:user:remove']"
                                    >删除</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-key"
                                    @click="handleResetPwd(scope.row)"
                                    v-hasPermi="['system:user:resetPwd']"
                                    >重置</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>

                    <pagination
                        v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.page"
                        :limit.sync="queryParams.size"
                        @pagination="getList"
                    />
                </el-back>
            </el-col>
        </el-row>

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户昵称" prop="nickname">
                            <el-input
                                v-model="form.nickname"
                                placeholder="请输入用户昵称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="归属部门" prop="deptId">
                            <treeselect
                                v-model="form.deptId"
                                :options="deptOptions"
                                @select="changeRole"
                                placeholder="请选择归属部门"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号码" prop="phone">
                            <el-input
                                v-model="form.phone"
                                placeholder="请输入手机号码"
                                maxlength="11"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="常用邮箱" prop="email">
                            <el-input
                                v-model="form.email"
                                placeholder="请输入邮箱"
                                maxlength="50"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户名称" prop="username">
                            <el-input
                                v-model="form.username"
                                placeholder="请输入用户名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            v-if="form.userId == undefined"
                            label="用户密码"
                            prop="password"
                        >
                            <el-input
                                v-model="form.password"
                                placeholder="请输入用户密码"
                                type="password"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户性别">
                            <el-select v-model="form.sex" placeholder="请选择">
                                <el-option
                                    v-for="dict in sexOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户状态">
                            <el-radio-group v-model="form.status">
                                <el-radio
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictValue"
                                    >{{ dict.dictLabel }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="用户身份">
                            <el-radio-group v-model="form.rank">
                                <el-radio
                                    v-for="(dict, index) in rankOptions"
                                    :key="index"
                                    :label="dict.rank"
                                    >{{ dict.rankName }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.rank !== '0'">
                        <el-form-item label="角色" prop="roleIds">
                            <el-checkbox-group v-model="form.roleIds">
                                <el-checkbox
                                    v-for="item in roleOptions"
                                    :disabled="form.rank == '0'"
                                    :label="item.roleId"
                                    :key="item.roleId"
                                >
                                    {{ item.roleName }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="APP角色" prop="appRoleIds">
                            <el-checkbox-group v-model="form.appRoleIds">
                                <el-checkbox
                                    v-for="item in appRoleOptions"
                                    :label="item.roleId"
                                    :key="item.roleId"
                                >
                                    {{ item.roleName }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注信息">
                            <el-input
                                v-model="form.remark"
                                type="textarea"
                                placeholder="请输入内容"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        listUser,
        getUser,
        delUser,
        addUser,
        updateUser,
        exportUser,
        resetUserPwd,
        changeUserStatus,
        importTemplate
    } from '@/api/system/user';
    import { listRole } from '@/api/system/beginRole';
    import { getUserSession } from '@/utils/auth';
    import { listAllRole } from '@/api/system/role';

    import { traverseTreeArray } from '@/utils/index';
    import { treeselect } from '@/api/system/dept';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';

    export default {
        name: 'User',
        components: {
            Treeselect
        },
        data() {
            return {
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 总条数
                total: 0,
                // 用户表格数据
                userList: null,
                // 弹出层标题
                title: '',
                // 部门树选项
                deptOptions: undefined,
                // 是否显示弹出层
                open: false,
                // 部门名称
                deptName: undefined,
                // 默认密码
                initPassword: undefined,
                // 日期范围
                dateRange: [],
                // 状态数据字典
                statusOptions: [],
                // 性别状态字典
                sexOptions: [],
                //app角色
                appRoleOptions: [],
                rankOptions: [
                    { rank: '0', rankName: '分管用户' },
                    { rank: null, rankName: '非分管用户' }
                ],
                // 角色选项
                roleOptions: [],
                // 表单参数
                form: {},
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },

                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    username: undefined,
                    phone: undefined,
                    status: undefined,
                    deptId: undefined,
                    params: {}
                },
                userInfo: {},
                // 表单校验
                rules: {
                    username: [
                        {
                            required: true,
                            message: '用户名称不能为空',
                            trigger: 'blur'
                        }
                    ],
                    nickname: [
                        {
                            required: true,
                            message: '用户昵称不能为空',
                            trigger: 'blur'
                        }
                    ],
                    deptId: [
                        {
                            required: true,
                            message: '归属部门不能为空',
                            trigger: 'blur'
                        }
                    ],
                    password: [
                        {
                            required: true,
                            message: '用户密码不能为空',
                            trigger: 'blur'
                        }
                    ],
                    roleIds: [
                        {
                            required: true,
                            message: '角色不能为空',
                            trigger: 'blur'
                        }
                    ],
                    email: [
                        {
                            type: 'email',
                            message: "'请输入正确的邮箱地址",
                            trigger: ['blur', 'change']
                        }
                    ],
                    phone: [
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: '请输入正确的手机号码',
                            trigger: 'blur'
                        }
                    ]
                }
            };
        },
        watch: {
            // 根据名称筛选部门树
            deptName(val) {
                this.$refs.tree.filter(val);
            }
        },
        created() {
            this.getList();
            this.getTreeselect();
            this.getAppRole();
            this.getDicts('sys_normal_disable').then((response) => {
                this.statusOptions = response;
            });
            this.getDicts('sys_user_sex').then((response) => {
                this.sexOptions = response;
            });
            // this.getDicts("sys_app_role").then(response => {
            //   this.appRoleOptions = response;
            // });
            // this.getConfigKey("sys.user.initPassword").then(response => {
            //   this.initPassword = response;
            // });
        },
        mounted() {
            this.userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'));
        },
        methods: {
            /** 查询用户列表 */
            getList() {
                this.loading = true;
                listUser(
                    this.queryParams.page,
                    this.queryParams.size,
                    this.addDateRange(this.queryParams, this.dateRange)
                ).then((response) => {
                    this.userList = response.queryResult.list;
                    this.total = response.queryResult.total;
                    this.loading = false;
                });
            },
            indexMethod(index) {
                return (
                    this.queryParams.page * this.queryParams.size -
                    this.queryParams.size +
                    index +
                    1
                );
            },
            /** 查询部门下拉树结构 */

            getTreeselect() {
                treeselect().then((response) => {
                    this.deptOptions = traverseTreeArray(
                        response,
                        'deptName',
                        'deptId'
                    );
                });
            },
            // 筛选节点
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            // 节点单击事件
            handleNodeClick(data) {
                this.queryParams.deptId = data.id;
                this.getList();
            },
            // 用户状态修改
            handleStatusChange(row) {
                let text = row.status === '0' ? '启用' : '停用';
                this.$confirm(
                    '确认要"' + text + '""' + row.username + '"用户吗?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        return changeUserStatus(row.userId, row.status);
                    })
                    .then(() => {
                        this.msgSuccess(text + '成功');
                    })
                    .catch(function() {
                        row.status = row.status === '0' ? '1' : '0';
                    });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    dept: {
                        params: {}
                    },
                    userId: undefined,
                    deptId: undefined,
                    username: undefined,
                    nickname: undefined,
                    password: undefined,
                    phone: undefined,
                    email: undefined,
                    sex: undefined,
                    status: '0',
                    remark: undefined,
                    rank: undefined,
                    postIds: [],
                    roleIds: [],
                    appRoleIds: [],
                    params: {}
                };
                this.resetForm('form');
            },
            /** 查询按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.dateRange = [];
                this.resetForm('queryForm');
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map((item) => item.userId);
                let ad = selection.some((value) => value.username == 'admin');
                if (ad) {
                    this.single = true;
                    this.multiple = true;
                } else {
                    this.single = selection.length != 1;
                    this.multiple = !selection.length;
                }
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();

                this.getAllRole();

                // getUser().then(response => {
                //   this.postOptions = response.posts;
                //   this.roleOptions = response.roles;
                this.open = true;
                this.title = '添加用户';
                //   this.form.password = this.initPassword;
                // });
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();

                const userId = row.userId || this.ids[0];
                getUser(userId).then((response) => {
                    this.form = response.object;

                    this.changeRole(this.form);

                    this.form.appRoleIds = [];
                    this.form.roleIds = [];
                    response.object.appRoleKey.forEach((item) => {
                        this.form.appRoleIds.push(item.roleId);
                    });
                    response.object.roleKey.forEach((item) => {
                        this.form.roleIds.push(item.roleId);
                    });
                    this.open = true;
                    this.title = '修改用户';
                    this.form.password = '';
                });
            },
            changeRole(node, instans) {
                this.getAllRole(node.deptId);
            },
            //获取所有APP角色
            getAppRole() {
                listRole({}).then((response) => {
                    this.appRoleOptions = response.queryResult.list;
                });
            },
            //获取所有角色
            getAllRole(val) {
                let deptId = '';
                if (!val) {
                    deptId = this.userInfo.deptId;
                } else {
                    deptId = val;
                }
                listAllRole(deptId).then((res) => {
                    this.roleOptions = res;
                });
            },
            /** 重置密码按钮操作 */
            handleResetPwd(row) {
                this.$prompt('请输入"' + row.username + '"的新密码', '提示', {
                    customClass: 'deleteBox',
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'password'
                })
                    .then(({ value }) => {
                        resetUserPwd({
                            userId: row.userId,
                            password: value
                        }).then((response) => {
                            if (response.code === 10000) {
                                this.msgSuccess('重置成功');
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    })
                    .catch(() => {});
            },
            /** 提交按钮 */
            submitForm: function() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.userId != undefined) {
                            updateUser(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('修改成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        } else {
                            addUser(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('新增成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                if (row.userId) {
                    var userIds = [row.userId];
                } else {
                    var userIds = this.ids;
                }
                this.$confirm(
                    '是否确认删除用户编号为"' + userIds + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        return delUser(userIds);
                    })
                    .then(() => {
                        this.getList();
                        this.msgSuccess('删除成功');
                    })
                    .catch(function() {});
            }
        }
    };
</script>
<style scoped lang="scss">
    /deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #34e6fe;
    }
    /deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #0c1638;
        border-color: #0c1638;
    }
</style>
