<template>
  <div class="homeHead">
    <div class="headLeft">
      <!-- <div class="logo">
			<img src="@/assets/image/logo.png" alt="">
			<div class="">
				<p>橡树科技</p>
				<p>Oaktree.ai</p>
			</div>
		</div> -->
      <timer class="timer"></timer>
      <p class="userName">欢迎您！{{ userInfo.nickname }}用户</p>
    </div>

    <div class="homeHeadTitle">
      {{ headTitle }}
    </div>
    <div class="headRight">
      <div class="topRightBox" @click="recordClick">
        <svg-icon slot="prefix" icon-class="bell" />
        <span style="margin-left: 3px;"
          >待处理<span style="color: red;">({{ recordNum }}条)</span></span
        >
      </div>
      <div class="topRightBox" @click="passClick">
        <svg-icon slot="prefix" icon-class="passwd" />
        <span style="margin-left: 3px;">修改密码</span>
      </div>
      <div class="topRightBox" @click="logout">
        <svg-icon slot="prefix" icon-class="drop" />
        <span style="margin-left: 3px;">退出登录</span>
      </div>
    </div>
    <!-- 检验时效性 -->
    <projectEndTime></projectEndTime>
    <el-row>
      <audio
        :src="require('../../../assets/alarm.mp3')"
        loop="loop"
        ref="alarmAudio"
        muted
        id="alarmAudio"
      ></audio>
    </el-row>
    <!-- 修改密码 -->
    <el-dialog :title="'修改密码'" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model="form.oldPassword"
            placeholder="请输入原密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="请输入新密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="surePassword">
          <el-input
            v-model="form.surePassword"
            placeholder="请再次输入密码"
            type="password"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import timer from './homeHead/timer.vue'
  import qs from 'qs'
  import { updatePass, selectShowName } from '@/api/login.js'
  import { getUserSession } from '@/utils/auth'
  import { RecordTitle } from '@/api/alarm/index.js'
  import { alarmDialog, readAlarmDialog } from '@/api/alarm/index'
  import projectEndTime from './projectEndTime'
  export default {
    name: 'homeHead',
    data() {
      var checkNewPassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.form.newPassword) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        open: false,
        form: {
          oldPassword: undefined,
          newPassword: undefined,
          surePassword: undefined
        },
        rules: {
          oldPassword: [
            {
              required: true,
              message: '原密码不能为空',
              trigger: 'blur'
            }
          ],
          newPassword: [
            {
              required: true,
              message: '新密码不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^[0-9a-zA-Z!@#$^]{6,18}$/,
              message: '密码为6-18位字母，数字，特殊字符',
              trigger: 'blur'
            }
          ],
          surePassword: [
            {
              required: true,
              message: '请再次输入密码',
              trigger: 'blur'
            },
            {
              required: true,
              validator: checkNewPassword,
              trigger: 'blur'
            }
          ]
        },
        userInfo: {},
        recordNum: 0,
        dialogFlag: true,
        headTitle: ''
      }
    },
    components: {
      timer,
      projectEndTime
    },
    computed: {},
    watch: {},
    methods: {
      async logout() {
        this.$confirm('确定注销并退出系统吗？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'resetPassword',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('LogOut').then(() => {
            location.reload()
          })
        })
      },
      submitForm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let data = qs.stringify(this.form)
            updatePass(data).then((response) => {
              if (response.code === 10000) {
                this.msgSuccess('修改成功')
                this.open = false
                this.$store.dispatch('LogOut').then(() => {
                  location.reload()
                })
              } else {
                this.msgError(response.msg)
              }
            })
          }
        })
      },
      reset() {
        this.form = {
          oldPassword: undefined,
          newPassword: undefined,
          surePassword: undefined
        }
        this.resetForm('form')
      },
      passClick() {
        this.reset()
        this.open = true
      },
      cancel() {
        this.open = false
        this.reset()
      },
      recordClick() {
        this.$router.push('/alarm')
        this.$store.commit('SET_NOWTOPACTIVE', 1206)
      }
    },
    mounted() {
      this.userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'))
      let that = this
      setInterval(() => {
        RecordTitle().then((res) => {
          this.recordNum = res
        })
      }, 5000)

      /* 获取标题 */
      selectShowName().then((res) => {
        this.headTitle = res
      })

      /* 报警弹窗 */
      setInterval(() => {
        alarmDialog().then((res) => {
          console.log('报警弹窗', res)
          if (res) {
            if (res.processState == '0' || res.processState == '1') {
              this.newAlarm = res
              console.log('that.dialogFlag', this.dialogFlag)
              //判断违规弹窗
              if (this.dialogFlag) {
                this.$refs.alarmAudio.play()
                this.dialogFlag = false

                this.$confirm(
                  `<div>
                    <div>有设备检测到异常，请注意：</div>
                    <div style="margin-top:10px;">
                      <div style="background-color: #081D62;padding: 10px 0;color: #34E4FC;display:flex">
                        
                        <div style="text-align:center;width:138px">设备名称</div>
                        <div style="width:256px">${this.newAlarm.deviceName}</div>
                        
                      </div>
                      <div style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;display:flex">
                        <div style="text-align:center;width:138px">建筑楼层</div>
                        <div style="width:256px">${this.newAlarm.roomAddress}</div>
                      </div>
                      <div style="background-color: #081D62;padding: 10px 0;color: #34E4FC;display:flex">
                        <div style="text-align:center;width:138px">报警种类</div>
                        <div style="width:256px">${this.newAlarm.alarmType}</div>
                      </div>
                      <div style="background-color: #0A1A51;padding: 10px 0;color: #34E4FC;display:flex">
                        <div style="text-align:center;width:138px">报警时间</div>
                        <div style="width:256px">${this.newAlarm.alarmTime}</div>
                      </div>
                    </div>
                  </div>`,
                  '温馨提示',
                  {
                    confirmButtonText: '已读',
                    cancelButtonText: '查看设备详情',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    dangerouslyUseHTMLString: true,
                    customClass: 'alarmBox'
                  }
                )
                  .then(() => {
                    console.log('点击确认')
                    /* 修改状态 */
                    readAlarmDialog(that.newAlarm.dealRecordId)
                    that.$refs.alarmAudio.pause()
                    that.dialogFlag = true
                  })
                  .catch(() => {
                    that.$refs.alarmAudio.pause()
                    readAlarmDialog(that.newAlarm.dealRecordId)
                    that.dialogFlag = true
                    /* 跳转 */
                    that.recordClick()
                  })
              }
            } else {
              if (!that.dialogFlag) {
                if (
                  document.querySelector('.el-message-box__btns .el-button')
                ) {
                  that.dialogFlag = true
                  document
                    .querySelector('.el-message-box__btns .el-button')
                    .click()
                }
              }
            }
          }
        })
      }, 5000)
    }
  }
</script>

<style scoped lang="scss">
  .homeHead {
    position: relative;
    height: 87px;
    padding: 30px 30px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background-image: url('../../../assets/image/topHeadImg.png');
    background-size: 100% 100%;
    @media (max-width: 1400px) {
      font-size: 12px;
    }
    .homeHeadTitle {
      // background-image: url('../../../assets/image/headBackground.png');
      // background-size: 100% 100%;
      width: 44.375%;
      height: 82px;
      margin: 0 auto;
      text-align: center;
      font-size: 2.875em;
      line-height: 82px;
      transform: translate(0, -25px);
    }
    .headLeft {
      width: 27.8125%;
      display: flex;
      .logo {
        display: flex;
        img {
          width: 3.125em;
          height: 2.375em;
          margin-right: 5px;
        }
        p {
          &:nth-child(2) {
            font-size: 1em;
          }
        }
      }
      .timer {
        margin-left: 30px;
      }
      .userName {
        margin-left: 30px;
      }
    }
    .headRight {
      width: 27.8125%;
      display: flex;
      justify-content: flex-end;
      .topRightBox {
        background-color: rgba(10, 22, 59, 0.4);
        cursor: pointer;
        width: 28.839%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 6px;
        box-shadow: 0px 0px 24px 0px rgba(4, 139, 160, 0.72) inset;
        margin-right: 10px;
        color: $tabColor;
      }
    }
  }
</style>
