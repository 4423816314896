import request from '@/utils/request'

// 查询角色列表
export function listRole(data) {
  return request({
    url: '/ucenter/appRole/selectRoleAll',
    method: 'post',
    data: data
  })
}

// 通过用户id展示角色列表
export function selectRole(data) {
  return request({
    url: '/ucenter/appRole/selectRole',
    method: 'post',
    data: data
  })
}

// 查询角色详细
export function getRole(roleId) {
  return request({
    url: '/ucenter/appRole/findRoleInfo?roleId=' + roleId,
    method: 'post'
  })
}

// 修改角色
export function updateRole(data) {
  return request({
    url: '/ucenter/appRole/updateRole',
    method: 'post',
    data: data
  })
}

// 新增角色
export function addRole(data) {
  return request({
    url: '/ucenter/appRole/saveRole',
    method: 'post',
    data: data
  })
}

// 校检角色key
export function checkRoleKey(data) {
  return request({
    url: '/ucenter/appRole/checkRoleKeyUnique',
    method: 'post',
    data: data
  })
}

// 校检角色name
export function checkRoleName(data) {
  return request({
    url: '/ucenter/appRole/checkRoleNameUnique',
    method: 'post',
    data: data
  })
}

// 删除角色
export function delRole(roleId) {
  return request({
    url: '/ucenter/appRole/deleteRole?roleId=' + roleId,
    method: 'post'
  })
}

