import { constantRoutes } from '@/views/home/router'
import { getRouters } from '@/api/menu'
import Layout from '@/views/home/page/home'
import { translateDataToTree } from "@/utils/index"

import Cookies from 'js-cookie'
const permission = {
  state: {
    routes: [],
    addRoutes: [],
	alarmFlag:false,
	nowTopActive:Cookies.get('nowTopActive') ? Cookies.get('nowTopActive') : ''
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes,
      state.routes = constantRoutes.concat(routes)
    },
	SET_NOWTOPACTIVE: (state, active) => {
	  state.nowTopActive = active
	  Cookies.set('nowTopActive', active)
	}

  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit,state }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(res => {
          let val = res.queryResult.list
          for(var i = 0;i<res.queryResult.list.length;i++){
            val[i].name = val[i].menuName;
            val[i].meta = {};
            val[i].meta.title = val[i].menuName;
            val[i].meta.icon = val[i].icon;
            //违规状态改变
            if(val[i].perms == 'monitor:alarmIndex:view'){
              state.alarmFlag = true
              val.splice(i,1)
              i = i - 1
            }
          }
          let accessedRoutes1 = translateDataToTree(res.queryResult.list,"menuId")
          const accessedRoutes = filterAsyncRouter(accessedRoutes1)
          commit('SET_ROUTES', accessedRoutes)
		  let resolveRoute = [{
				path: '/',
				component: () => import('@/views/home/page/home.vue'),
				hidden: true,
				children:[]
			  }
			]
			// console.log(accessedRoutes)
		  resolveRoute[0].children = accessedRoutes
          resolve(resolveRoute)
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(route => {	  
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = loadView( route.perms + '/index')
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

export const loadView = (view) => { // 路由懒加载
  return () => Promise.resolve(require(`@/views/${view}`).default)
}

export default permission
