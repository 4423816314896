import request from '@/utils/request'

//设备缴费列表
export function selectUserDevicePayList(data) {
  return request({
    url: '/free/devicePay/selectUserDevicePayList',
    method: 'post',
	data: data
  })
}

//缴费记录
export function selectUserPayRecordsList(data) {
  return request({
    url: '/free/devicePay/selectUserPayRecordsList',
    method: 'post',
	data: data
  })
}


// 查询当前单位下的支付配置
export function getObjByLocalDept() {
  return request({
    url: '/free/sysDeptFreeSet/getObjByLocalDept',
    method: 'post'
  })
}

// 查询当前单位下的支付配置（新）
export function getWebObjByDeptId(deviceCode) {
  return request({
    url: '/free/sysDeptFreeSet/getWebObjByDeptId?deviceCode=' + deviceCode,
    method: 'post'
  })
}

// 批量查询当前单位下的支付配置
export function getWebObjByAgentId(agentId) {
  return request({
    url: '/free/sysDeptFreeSet/getWebObjByAgentId?agentId=' + agentId,
    method: 'post'
  })
}


//提交支付宝缴费
export function submitaliPay(deviceCode,totalAmount,infoId,remark) {
 return request({
    url: '/free/eapi/aliPay/pay?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark,
    method: 'get',
  })
    // return process.env.VUE_APP_BASE_URL_COUNTY + 'free/eapi/aliPay/pay?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark
}
//提交微信缴费
export function submitweixinPay(deviceCode,totalAmount,infoId,remark) {
	return request({
	   url: '/free/eapi/wxPay/web2?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark,
	   method: 'get'
	 })
    // return process.env.VUE_APP_BASE_URL_COUNTY + 'free/eapi/wxPay/web?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark
}
//提交银联缴费
export function submitunionPay(deviceCode,totalAmount,infoId,remark) {
	/* return request({
	   url: '/free/eapi/unionPay/pay?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark,
	   method: 'get'
	 }) */
    return process.env.VUE_APP_BASE_URL_COUNTY + 'free/eapi/unionPay/pay?deviceCode='+ deviceCode +'&totalAmount=' + totalAmount +'&infoId=' + infoId + '&remark=' + remark
}

//判断银联支付返回结果
export function unionPayResult(orderId,txnTime) {
  return request({
    url: '/free/eapi/unionPay/common/query?orderId=' + orderId + '&txnTime=' + txnTime,
    method: 'get'
  })
}

//判断支付宝支付返回结果
export function aliPayResult(orderNo) {
  return request({
    url: '/free/eapi/aliPay/query?orderNo=' + orderNo,
    method: 'get'
  })
}
//判断微信支付返回结果
export function weixinResult(orderNo) {
  return request({
    url: '/free/eapi/wxPay/query?orderNo=' + orderNo,
    method: 'get'
  })
}
//导出用户设备缴费记录
export function outUserDeviceExcel(data) {
  return request({
    url: '/free/devicePayExcel/outUserDeviceExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}

//批量续费代理商列表
export function findAgentName() {
  return request({
    url: '/monitor/device/findAgentName',
    method: 'post'
  })
}
//批量续费支付宝
export function aliPaywebBatch(data) {
  return request({
    url: '/free/eapi/aliPay/webBatch?' + data,
    method: 'get'
  })
}
//批量续费微信
export function wxPaywebBatch(data) {
  return request({
    url: '/free/eapi/wxPay/webBatch?' + data,
    method: 'get'
  })
}
// 导出
export function outUserDevicePayExcel(data) {
  return request({
    url: '/monitor/deviceExcel/outUserDevicePayExcel',
    method: 'post',
	'responseType':'blob',
	data:data
  })
}
//deviceCode是否存在
export function checkSmokeDevCode(deviceCode) {
  return request({
    url: '/monitor/device/checkSmokeDevCode?deviceCode=' + deviceCode,
    method: 'post'
  })
}
//设备续费查询支付方式
export function deviceGetWebObjByDeptId(deviceCode) {
  return request({
    url: '/free/sysDeptFreeSet/getWebObjByDeptId?deviceCode=' + deviceCode,
    method: 'post'
  })
}
//设备续费查询套餐
export function getPayTypeListByDeviceCode(deviceCode) {
  return request({
    url: '/free/pay/getPayTypesByDeviceCode?deviceCode=' + deviceCode,
    method: 'post'
  })
}