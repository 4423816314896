<template>
	<div class="system module-container">
		<el-back class="menuBack">
			<Sidebar  class="sidebar"></Sidebar>
		</el-back>
		<section class="system-main">
		   <transition name="fade-transform" mode="out-in">
				<router-view  :key="key" />
		   </transition>
		 </section>
	</div>
</template>

<script>
	import Sidebar from '@/views/home/components/Sidebar/index.vue'

	export default {
		name:'system',
		components:{
			Sidebar
		},
		computed: {
		  key() {
		    return this.$route.path
		  }
		},
		data(){
			return {


			}
		},
		methods:{

		},
		created() {

		}
	}
</script>

<style scoped lang="scss">
	.system{
		height: 100%;
		display: flex;
		.menuBack{
			padding: 0;
		}
		.sidebar{
			width: 200px;
		}
		.system-main {
		  height: 100%;
		  position: relative;
		  overflow: hidden;
		  width: calc(100% - 204px);
		}


	}
</style>
