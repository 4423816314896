import request from '@/utils/request'

// 查询部门列表
export function listDept(data) {
  return request({
    url: 'ucenter/dept/selectDeptList',
    method: 'post',
    data: data,

  })
}

// 查询部门详细
export function getDept(deptId) {
  return request({
    url: 'ucenter/dept/findDept?deptId=' + deptId,
    method: 'post'
  })
}

// 查询部门下拉树结构
export function treeselect() {

  return request({
    url: '/ucenter/dept/noFatherTreeData',
    method: 'post',
    data: {params:{}}
  })
}

// 根据角色ID查询部门树结构
export function roleDeptTreeselect(roleId) {
  return request({
    url: '/system/dept/roleDeptTreeselect/' + roleId,
    method: 'get'
  })
}

// 新增部门
export function addDept(data) {
  return request({
    url: 'ucenter/dept/addDept',
    method: 'post',
    data: data
  })
}

// 修改部门
export function updateDept(data) {
  return request({
    url: 'ucenter/dept/updateDept',
    method: 'post',
    data: data
  })
}

// 删除部门
export function delDept(deptId) {
  return request({
    url: 'ucenter/dept/deleteDept?deptId=' + deptId,
    method: 'post'
  })
}
// 校检部门名称
export function checkDeptName(data) {
  return request({
    url: 'ucenter/dept/checkDeptNameUnique',
    method: 'post',
    data: data
  })
}

// 无父级查询单位
export function noFatherAndDlsData(data) {
  return request({
    url: '/ucenter/dept/noFatherAndDlsData',
    method: 'post',
	data: data
  })
}

//检查是否为父级单位
export function checkDeptIsUp(deptId) {
  return request({
    url: '/ucenter/dept/checkDeptIsUp?deptId=' + deptId,
    method: 'post'
  })
}