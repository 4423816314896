<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    },
    maxNum: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        // this.setOptions(val)
        this.$nextTick(() => {
          this.initChart()
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions(val) {
      let colorList1 = ['#FA8E89', '#FDD01A', '#1F9CFC', '#1F9CFC', '#1F9CFC'];
      let colorList2 = ['#F96574', '#FDAA1A', '#1F79FC', '#1F79FC', '#1F79FC'];
      //背景色
      let colorList3 = ['rgba(251,103,119,0.2)', 'rgba(253,170,26,0.2)', 'rgba(31,121,252,0.2)', 'rgba(31,121,252,0.2)', 'rgba(31,121,252,0.2)'];
      let that = this
      this.chart.setOption({
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          x: 20,
          y: 20,
          x2: 30,
          y2: 55,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: '{b1}: {c1}',
          padding: [5, 10]
        },
        yAxis: {
          data: (function () {
            var res = []
            val.forEach((item, index) => {
              res.push(item.name)
            })
            return res;
          })(),
          boundaryGap: ['20%', '20%'],
          axisTick: {
            show: false
          }
        },
        series: [
          /* 设置背景色*/
          {
            type: 'bar',
            itemStyle: {
              color: function (params) {
                return colorList3[params.dataIndex]
              },
            },
            barGap: '-100%',
            barCategoryGap: '40%',
            barWidth: 15,
            data: (function () {
              var res = []
              val.forEach((item, index) => {
                res.push(that.maxNum)
              })
              return res
            })(),
            animation: false
          },
          {
            name: '柱形数',
            smooth: true,
            type: 'bar',
            itemStyle: {
              normal: {
                color: function (params) {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: colorList1[params.dataIndex]

                  }, {
                    offset: 1,
                    color: colorList2[params.dataIndex]
                  }])
                },

                lineStyle: {
                  color: '#3888fa',
                  width: 1
                },
                areaStyle: {
                  color: '#f3f8ff'
                },
                barBorderRadius: [0, 20, 20, 0]
              }
            },
            barWidth: 15,
            data: (function () {
              var res = []
              val.forEach((item, index) => {
                res.push(item.value)
              })
              return res;
            })(),
            animationDuration: 2800,
            animationEasing: 'quadraticOut'
          }]
      })
    }
  }
}
</script>
