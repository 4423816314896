import request from '@/utils/request'

// 根据条件分页查询设备平面图
export function selectDevicePlanList(data) {
  return request({
    url: '/monitor/device/plan/selectDevicePlanList',
    method: 'post',
	data: data
  })
}

// 新增设备平面图
export function insertDevicePlan(data) {
  return request({
    url: '/monitor/device/plan/insertDevicePlan?' + data,
    method: 'post'
  })

}
export function insertDevicePlanPath() {

 return process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/plan/insertDevicePlan'
}
  
// 修改设备平面图
export function updateDevicePlan(data) {
  return request({
    url: '/monitor/device/plan/updateDevicePlan?' + data,
    method: 'post'
  })

}
export function updateDevicePlanPath() {

 return process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/plan/updateDevicePlan'
}
// 根据通知id查询信息
export function selectDevicePlanById(planId) {
  return request({
    url: '/monitor/device/plan/selectDevicePlanById?planId=' + planId,
    method: 'post'
  })
}

// 根据id删除设备平面图
export function deleteDevicePlanById(planId) {
  return request({
    url: '/monitor/device/plan/deleteDevicePlanById?planId=' + planId,
    method: 'post'
  })
}