export const dispenseRoutes = [{
    path: '/',
    component: () => import('@/views/home/page/home.vue'),
    hidden: true,
    children: [{
      path: '/dispense',
      component: () => import('@/views/dispense/index.vue'),
      name: '设备分管',
    }]
  },
]
