<template>
  <div class="components-container">
    <el-back>
      <tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
      <div class="formBox">信息搜索</div>
      <el-form :model="queryParams" ref="queryForm" :inline="true">
        <el-form-item label="产品ID" prop="smokeDeviceCode">
          <el-input
            v-model="queryParams.smokeDeviceCode"
            placeholder="请输入产品ID"
            clearable
            size="small"
            style="width: 240px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>

        <el-form-item label="设备状态" prop="status">
          <el-select
            v-model="queryParams.status"
            placeholder="请输入设备状态"
            clearable
            size="small"
            style="width: 240px"
          >
            <el-option
              v-for="dict in statusOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
              @keyup.enter.native="handleQuery"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="操作记录" prop="smokeInstallStatus">
          <el-select
            v-model="queryParams.smokeInstallStatus"
            placeholder="请输入操作记录"
            clearable
            size="small"
            style="width: 240px"
          >
            <el-option
              v-for="dict in recordOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
              @keyup.enter.native="handleQuery"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="安装时间">
          <el-date-picker
            v-model="dateRange"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
            type="warning"
            >导出报表</el-button
          >
        </el-col>
      </el-row>

      <el-table v-loading="loading" :data="nbList">
        <el-table-column label="编号" type="index" align="center" />
        <el-table-column
          label="产品ID"
          prop="smokeDeviceCode"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="设备类别"
          prop="deviceType"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="建筑楼层"
          prop="roomAddress"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="安装位置"
          prop="address"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="责任人"
          prop="functionaryName"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="责任人电话"
          prop="phone"
          align="center"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="安装人" prop="operateBy" align="center" />
        <el-table-column
          label="安装时间"
          align="center"
          prop="operateTime"
          width="180"
        >
        </el-table-column>
        <el-table-column label="设备状态" prop="alarmType" align="center">
        </el-table-column>
        <el-table-column
          label="操作记录"
          prop="smokeInstallStatus"
          align="center"
        />
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.page"
        @pagination="getList"
      />
    </el-back>
  </div>
</template>

<script>
  import {
    smokeDetecorRecordPageList,
    exportUseSmDeRecord
  } from '@/api/system/erection'
  import qs from 'qs'
  export default {
    name: 'erection',
    data() {
      return {
        tabList: [
          {
            id: 0,
            name: undefined,
            deviceType: undefined
          }
        ],
        tabVal: 0,

        // 遮罩层
        loading: true,
        // 总条数
        total: 0,
        // 角色表格数据
        nbList: [],
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        // 日期范围
        dateRange: [],
        // 状态数据字典
        statusOptions: [],
        recordOptions: [],
        // 菜单列表
        menuOptions: [],
        // 查询参数
        queryParams: {
          page: 1,
          size: 10,
          smokeDeviceCode: undefined,
          smokeInstallStatus: undefined,
          status: undefined,
          deviceType: undefined
        },
        // 表单参数
        form: {}
      }
    },
    created() {
      this.getList()
      this.getDicts('sys_normal_disable').then((response) => {
        this.statusOptions = response
      })
      this.getDicts('smoke_install_status').then((response) => {
        this.recordOptions = response
      })
      this.getDicts('sys_device_type').then((response) => {
        response.forEach((val, index) => {
          val.id = val.index
          val.name = val.dictLabel
          val.deviceType = val.dictValue
        })
        this.tabList = response
      })
    },
    methods: {
      getTabIndex(val) {
        this.tabVal = val.deviceType
        this.getList()
      },
      /** 查询列表 */
      getList() {
        this.loading = true
        let queryData = JSON.parse(JSON.stringify(this.queryParams))
        queryData.deviceType = this.tabVal
        smokeDetecorRecordPageList(
          this.addDateRange(queryData, this.dateRange)
        ).then((response) => {
          this.nbList = response.queryResult.list
          this.total = response.queryResult.total
          this.loading = false
        })
      },
      // 取消按钮
      cancel() {
        this.open = false
        this.reset()
      },
      // 表单重置
      reset() {
        this.form = {
          smokeDeviceCode: undefined,
          smokeInstallStatus: undefined,
          deviceType: undefined,
          status: '0'
        }
        this.resetForm('form')
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.page = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = []
        this.resetForm('queryForm')
        this.handleQuery()
      },
      /** 导出按钮操作 */
      handleExport() {
        let queryData = qs.stringify(this.queryParams)
        this.$confirm('是否确认导出所有数据项?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'deleteBox',
          type: 'warning'
        })
          .then(function() {
            return exportUseSmDeRecord(queryData)
          })
          .then((response) => {
            let blob = new Blob([response], {
              type: 'application/vnd.ms-excel'
            }) //type是文件类，详情可以参阅blob文件类型
            let objectUrl = URL.createObjectURL(blob) //生成下载链接
            window.location.href = objectUrl
          })
          .catch(function() {})
      }
    }
  }
</script>
