<template>
    <div class="">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        >
            <el-form-item label="设备种类" prop="deviceType">
                <el-select
                    v-model="queryParams.deviceType"
                    placeholder="设备种类"
                    clearable
                    size="small"
                >
                    <el-option
                        v-for="dict in deviceOptions"
                        :key="dict.dictValue"
                        :label="dict.dictLabel"
                        :value="dict.dictValue"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="所在单位" prop="subordinate">
                <treeselect
                    v-model="queryParams.subordinate"
                    :options="deptOptions"
                    placeholder="请选择"
                    style="width: 240px"
                />
            </el-form-item>
            <el-form-item label="产品ID" prop="deviceCode">
                <el-input
                    v-model="queryParams.deviceCode"
                    placeholder="产品ID"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="IMEI" prop="imei">
                <el-input
                    v-model="queryParams.imei"
                    placeholder="IMEI"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                    v-model="dateRange"
                    size="small"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    size="mini"
                    @click="handleRenew()"
                    v-hasPermi="['smoke:device:imp']"
                    :disabled="single"
                >
                    批量续费
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    size="mini"
                    @click="handleFree()"
                    v-hasPermi="['smoke:device:imp']"
                    :disabled="single"
                >
                    批量赠送
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="info"
                    icon="el-icon-upload2"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['smoke:device:imp']"
                    >导入</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['smoke:device:export']"
                    >导出</el-button
                >
            </el-col>
        </el-row>

        <el-table
            v-loading="loading"
            :data="normalList"
            @selection-change="handleSelectionChange"
            ref="multipleTable"
        >
            <el-table-column
                type="selection"
                width="55"
                :selectable="selectable"
            ></el-table-column>
            <el-table-column
                label="编号"
                type="index"
                width="55"
                align="center"
            />
            <el-table-column
                label="设备种类"
                align="center"
                prop="typeName"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="产品ID"
                align="center"
                prop="deviceCode"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="IMEI"
                align="center"
                prop="imei"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="所在单位"
                align="center"
                prop="deptName"
                :show-overflow-tooltip="true"
            />
            <el-table-column
                label="有效使用期限"
                align="center"
                prop="createTime"
                width="180"
            />
            <!-- <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column> -->
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleRenew(scope.row)"
                        >线下缴费</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleFree(scope.row)"
                        >赠送时长</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.size"
            @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="400px">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="续费套餐">
                    <el-radio-group v-model="form.validTime">
                        <el-radio
                            v-for="dict in validTimeOptions"
                            :key="dict.validTime"
                            :label="dict.validTime"
                            >{{ dict.validTime }}个月({{
                                dict.cost
                            }}元)</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="支付途径">
			<el-select v-model="form.pay" placeholder="请选择">
				<el-option v-for="dict in payOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
			</el-select>
		</el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="titleFree" :visible.sync="openFree" width="500px">
            <el-form ref="formFree" :model="formFree" label-width="100px">
                <el-form-item label="赠送免费时长">
                    <el-select v-model="formFree.month" placeholder="请选择">
                        <el-option
                            v-for="dict in freeOptions"
                            :key="dict.dictValue"
                            :label="dict.dictLabel"
                            :value="dict.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormFree"
                    >确 定</el-button
                >
                <el-button @click="cancelFree">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 用户导入对话框 -->
        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx, .xls"
                :headers="upload.headers"
                :action="upload.url"
                :disabled="upload.isUploading"
                :on-progress="handleFileUploadProgress"
                :on-success="handleFileSuccess"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <!-- <el-checkbox v-model="upload.updateSupport" />是否更新已经存在的用户数据 -->
                    <el-link
                        type="info"
                        style="font-size: 12px; color: #34e6fe"
                        @click="importTemplate"
                        >下载模板</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xls”或“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFileForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        selectDevicePayList,
        selectPayTypeList,
        updateDevicePay,
        outArrearageDeviceExcel,
        updateDevicePayBatch,
        downloadErrorExcel,
        offlineDevicePayBatch
    } from '@/api/cashierDesk/normalDevice.js';
    import { traverseTreeArray } from '@/utils/index';
    import { treeselect } from '@/api/system/dept';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import qs from 'qs';
    import { getUserSession } from '@/utils/auth';
    export default {
        name: 'arrearsDevice',
        components: { Treeselect },
        data() {
            return {
                // 遮罩层
                loading: true,
                // 总条数
                total: 0,
                // 是否单选
                single: true,
                // 批量选择的数据
                selections: [],
                // 表格数据
                normalList: [],
                //日期
                dateRange: [],
                // 弹出层标题
                title: '',
                titleFree: '',
                // 是否显示弹出层
                open: false,
                openFree: false,
                // 状态数据字典
                deviceOptions: [],
                deptOptions: [],
                validTimeOptions: [],
                renewOptions: [],
                freeOptions: [],
                payOptions: [],
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    deviceType: undefined,
                    deviceCode: undefined,
                    imei: undefined,
                    subordinate: undefined,
                    status: '1'
                },
                // 表单参数
                form: {},
                formFree: {
                    month: undefined,
                    cost: 0,
                    deviceCode: undefined,
                    remark: '1'
                },
                // 表单校验
                rules: {
                    pay: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ]
                },
                // 用户导入参数
                upload: {
                    // 是否显示弹出层（用户导入）
                    open: false,
                    // 弹出层标题（用户导入）
                    title: '',
                    // 是否禁用上传
                    isUploading: false,
                    // 是否更新已经存在的用户数据concnLabel
                    updateSupport: 0,
                    // 设置上传的请求头部
                    headers: {
                        Authorization: 'Bearer ' + getUserSession('iffp_jwt')
                    },
                    // 上传的地址
                    url: updateDevicePayBatch()
                }
            };
        },
        created() {
            this.getDicts('sys_device_type').then((response) => {
                this.deviceOptions = response;
            });
            /* this.getDicts("sys_renew_time").then(response => {
      this.validTimeOptions = response;
    }); */
            this.getDicts('sys_free_time').then((response) => {
                this.freeOptions = response;
            });
            this.getDicts('sys_pay_pattern').then((response) => {
                this.payOptions = response;
            });
            this.getList();
            this.getTreeselect();
        },
        methods: {
            /** 查询列表 */
            getList() {
                this.loading = true;
                selectDevicePayList(
                    this.addDateRange(this.queryParams, this.dateRange)
                ).then((response) => {
                    this.normalList = response.queryResult.list;
                    this.total = response.queryResult.total;
                    this.loading = false;
                });
            },
            // 数据状态字典翻译
            validTimeFormat(row, column) {
                return this.selectDictLabel(
                    this.validTimeOptions,
                    row.validTime
                );
            },
            /** 查询部门下拉树结构 */
            getTreeselect() {
                treeselect().then((response) => {
                    this.deptOptions = traverseTreeArray(
                        response,
                        'deptName',
                        'deptId'
                    );
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            cancelFree() {
                this.formFree.month = undefined;
                this.openFree = false;
            },
            // 表单重置
            reset() {
                this.dateRange = [];
                this.form = {
                    pay: undefined,
                    typeName: undefined,
                    validTime: undefined,
                    deviceCode: undefined,
                    remark: '1'
                };
                this.resetForm('form');
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm('queryForm');
                this.dateRange = [];
                this.handleQuery();
            },
            /** 导出按钮操作 */
            handleExport() {
                let queryData = this.queryParams;
                this.$confirm('是否确认导出所有数据项?', '警告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'deleteBox',
                    type: 'warning'
                })
                    .then(function() {
                        return outArrearageDeviceExcel(queryData);
                    })
                    .then((response) => {
                        let blob = new Blob([response], {
                            type: 'application/vnd.ms-excel'
                        }); //type是文件类，详情可以参阅blob文件类型
                        let objectUrl = URL.createObjectURL(blob); //生成下载链接
                        window.location.href = objectUrl;
                    })
                    .catch(function() {});
            },
            //线下缴费
            handleRenew(row) {
                let payType = {};
                if (row) {
                    this.form.deviceCodes = [row.deviceCode];
                    payType = {
                        subordinate: row.deptId,
                        deviceType: row.deviceType,
                        deptId: row.agentId
                    };
                } else {
                    this.form.deviceCodes = this.selections.map((item) => {
                        return item.deviceCode;
                    });
                    payType = {
                        subordinate: this.selections[0].deptId,
                        deviceType: this.selections[0].deviceType,
                        deptId: this.selections[0].agentId
                    };
                }
                this.form.remark = '1';
                selectPayTypeList(payType).then((res) => {
                    this.validTimeOptions = res;
                    this.open = true;
                    this.title = '线下缴费';
                });
            },
            //免费时长
            handleFree(row) {
                this.openFree = true;
                if (row) {
                    this.formFree.deviceCodes = [row.deviceCode];
                } else {
                    this.formFree.deviceCodes = this.selections.map((item) => {
                        return item.deviceCode;
                    });
                }
                this.formFree.cost = 0;
                console.log(
                    'this.formFree.deviceCodes',
                    this.formFree.deviceCodes
                );
                this.titleFree = '赠送时长';
            },
            /** 提交按钮 */
            submitFormFree() {
                // updateDevicePay(
                //   this.formFree.deviceCode,
                //   this.formFree.month,
                //   this.formFree.cost,
                //   this.formFree.remark
                // ).then((res) => {
                //   if (res.code === 10000) {
                //     this.msgSuccess(res.message);
                //     this.openFree = false;
                //     this.getList();
                //   } else {
                //     this.msgError(res.msg);
                //   }
                // });
                offlineDevicePayBatch({
                    deviceCodes: this.formFree.deviceCodes,
                    month: this.formFree.month,
                    cost: this.formFree.cost,
                    remark: this.formFree.remark
                }).then((res) => {
                    if (res.code === 10000) {
                        this.msgSuccess(res.message);
                        this.openFree = false;
                        this.getList();
                    } else {
                        this.msgError(res.msg);
                    }
                });
            },
            submitForm() {
                // let month = this.form.validTime;
                // updateDevicePay(
                //   this.form.deviceCode,
                //   month,
                //   this.form.cost,
                //   this.form.remark
                // ).then((res) => {
                //   if (res.code === 10000) {
                //     this.msgSuccess(res.message);
                //     this.open = false;
                //     this.getList();
                //   } else {
                //     this.msgError(res.msg);
                //   }
                // });
                offlineDevicePayBatch({
                    deviceCodes: this.form.deviceCodes,
                    month: this.form.validTime,
                    cost: this.form.cost,
                    remark: this.form.remark
                }).then((res) => {
                    if (res.code === 10000) {
                        this.msgSuccess(res.message);
                        this.open = false;
                        this.getList();
                    } else {
                        this.msgError(res.msg);
                    }
                });
            },
            /** 导入按钮操作 */
            handleImport() {
                this.upload.title = '缴费导入';
                this.upload.open = true;
            },
            /** 下载模板操作 */
            importTemplate() {
                downloadErrorExcel('批量缴费模板').then((response) => {
                    let blob = new Blob([response], {
                        type: 'application/vnd.ms-excel'
                    }); //type是文件类，详情可以参阅blob文件类型
                    let objectUrl = document.createElement('a');
                    objectUrl.href = URL.createObjectURL(blob); //生成下载链接
                    objectUrl.download = '批量缴费模板.xls';
                    objectUrl.click();
                });
            },
            // 文件上传中处理
            handleFileUploadProgress() {
                this.upload.isUploading = true;
            },
            // 文件上传成功处理
            handleFileSuccess(response) {
                this.upload.open = false;
                this.upload.isUploading = false;
                this.$refs.upload.clearFiles();
                if (response.code === 10000) {
                    this.msgSuccess('上传成功');
                } else {
                    this.msgInfo(response.message);
                }
                this.getList();
            },
            // 提交上传文件
            submitFileForm() {
                this.$refs.upload.submit();
            },
            // 批量操作
            handleSelectionChange(selection) {
                if (this.queryParams.deviceType) {
                    this.selections = selection;
                    this.single = !selection.length;
                } else {
                    this.$refs.multipleTable.clearSelection();
                    this.msgError('请先选择项目种类！');
                }
            },
            selectable(row, rowIndex) {
                if (row.deviceType === this.queryParams.deviceType) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .el-radio {
        margin: 10px;
    }
</style>
