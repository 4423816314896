<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../../mixins/resize'
import { fontSize } from '@/utils/index.js'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '70%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }

  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.initChart()
      }
    }
  },
  mounted() {

    // this.$nextTick(()=>{
    //   this.initChart()
    // })


  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },

    setOptions(data) {
      let that = this
      this.chart.setOption({
        // title: {
        //     text: '违规次数统计',
        //     left: 'center',
        // },
        xAxis: {
      
          data: (function (){
					  let formData = []
					  data.forEach((item,index) => {
						  formData.push(item.month)
					  })
					  return formData
                })(),
          // boundaryGap: ['20%', '20%'],
          axisTick: {
            show: false
          },
          splitLine:{
            show:true,
            lineStyle:{
                type:'dashed',
                color:'rgba(52,230,254,0.1)'
            }
          }
        },
        grid: {

          top: "10%",
          bottom:0,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          },
          splitLine:{
            show:true,
            lineStyle:{
                type:'dashed',
                color:'rgba(52,230,254,0.1)'
            }
          }
        },
        series: [
			{
			  smooth: true,
			  type: 'line',
			  data: (function (){
					  let formData = []
					  data.forEach((item,index) => {
						  formData.push(item.value)
					  })
					  return formData
                })(),
			  animationDuration: 2800,
			  animationEasing: 'cubicInOut',
			}
		]       
        
      })
    }
  }
}
</script>
