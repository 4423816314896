<template>
  <div class="">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="楼栋名称" prop="buildId">
        <el-select
          v-model="queryParams.buildId"
          placeholder="请选择楼栋名称"
          size="small"
          filterable
          style="width: 240px;"
          @change="floorChange('queryForm')"
        >
          <el-option
            v-for="item in allBuidings"
            :key="item.buildId"
            :label="item.buildName"
            :value="item.buildId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单元名称" prop="unitId">
        <el-select
          v-model="queryParams.unitId"
          placeholder="请选择单元名称"
          size="small"
          clearable
          filterable
          style="width: 240px;"
        >
          <el-option
            v-for="item in allUnits"
            :key="item.unitId"
            :label="item.unitName"
            :value="item.unitId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房屋名称" prop="roomNum">
        <el-input
          v-model="queryParams.roomNum"
          placeholder="请输入单元名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:building:add']"
          >新增</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:building:edit']"
          >修改</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="single"
          @click="handleDelete"
          v-hasPermi="['system:building:remove']"
          >删除</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="info"
          icon="el-icon-upload2"
          size="mini"
          @click="handleImport"
          v-hasPermi="['system:building:imp']"
          >导入</el-button
        >
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      :data="roomList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="房屋编号" align="center" prop="roomId" />
      <el-table-column
        label="房屋名称"
        align="center"
        prop="roomNum"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="所在楼栋"
        align="center"
        prop="buildName"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="所在单元" align="center" prop="unitName" />
      <el-table-column label="所在层数" align="center" prop="tier" />

      <el-table-column label="创建人" align="center" prop="createBy" />
      <el-table-column
        label="创建时间"
        align="center"
        prop="createTime"
        width="180"
      >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:building:edit']"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:building:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="房屋名称" prop="roomNum">
          <el-input v-model="form.roomNum" placeholder="请输入房屋名称" />
        </el-form-item>
        <el-form-item label="楼栋名称" prop="buildId">
          <el-select
            v-model="form.buildId"
            placeholder="请选择楼栋名称"
            clearable
            filterable
            style="width: 100%;"
            @change="floorChange('form')"
          >
            <el-option
              v-for="item in allBuidings"
              :key="item.buildId"
              :label="item.buildName"
              :value="item.buildId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单元名称" prop="unitId">
          <el-select
            v-model="form.unitId"
            placeholder="请选择单元名称"
            clearable
            filterable
            style="width: 100%;"
            @change="unitChange()"
          >
            <el-option
              v-for="item in allUnits"
              :key="item.unitId"
              :label="item.unitName"
              :value="item.unitId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在层数" prop="tier">
          <el-select
            v-model="form.tier"
            placeholder="请选择所在层数"
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              v-for="item in allTiers"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px">
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-link
            type="info"
            style="font-size: 12px; color: #34e6fe"
            @click="importTemplate"
            >下载模板</el-link
          >
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xls”或“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 导入结果信息提示 -->
    <el-dialog title="温馨提示" :visible.sync="imResOpen" width="800px">
      <el-table :data="imRestable">
        <el-table-column label="楼栋名称" align="center" prop="buildName" />
        <el-table-column label="所在单元" align="center" prop="unitName" />
        <el-table-column label="房间名称" align="center" prop="roomNum" />
        <el-table-column label="导入结果" align="center" prop="log" />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imResOpen = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    listRoom,
    getRoom,
    addRoom,
    updateRoom,
    delRoom,
    importRoom
  } from '@/api/system/build/room'
  import { listBuildingsData } from '@/api/system/build/index'
  import { listUnitData } from '@/api/system/build/unit'
  import { getUserSession } from '@/utils/auth'
  import { downloadExcel } from '@/api/login'
  export default {
    name: 'room',
    data() {
      return {
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 总条数
        total: 0,
        // 字典表格数据
        roomList: [],
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        // 状态数据字典

        // 日期范围
        dateRange: [],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          roomNum: undefined,
          buildId: undefined,
          unitId: undefined
        },
        allBuidings: [],
        allUnits: [],
        allTiers: [],
        //回显数据单元
        allBuildUnits: [],
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          roomNum: [
            { required: true, message: '房屋名称不能为空', trigger: 'blur' }
          ],
          unitId: [
            { required: true, message: '单元名称不能为空', trigger: 'blur' }
          ],
          buildId: [
            { required: true, message: '楼栋名称不能为空', trigger: 'blur' }
          ]
        },
        // 用户导入参数
        upload: {
          // 是否显示弹出层（用户导入）
          open: false,
          // 弹出层标题（用户导入）
          title: '',
          // 是否禁用上传
          isUploading: false,
          // 是否更新已经存在的用户数据concnLabel
          updateSupport: 0,
          // 设置上传的请求头部
          headers: {
            Authorization: 'Bearer ' + getUserSession('iffp_jwt')
          },
          // 上传的地址
          url: importRoom()
        },
        imResOpen: false, // 导入结果
        imRestable: []
      }
    },

    created() {
      //获取所有的楼栋
      listBuildingsData().then((res) => {
        this.allBuidings = res
      })
      // this.floorChange();
      this.getList()
    },
    methods: {
      floorChange(val) {
        if (val == 'form') {
          this.queryParams.buildId = this.form.buildId
        }
        this.allUnits = []
        this.allTiers = []
        this.queryParams.unitId = undefined
        this.form.unitId = undefined
        this.form.tier = undefined
        let unitData = { buildId: this.queryParams.buildId }
        //获取当前楼栋的单元
        listUnitData(unitData).then((res) => {
          this.allUnits = res
        })
      },
      unitChange() {
        let tier = undefined
        this.form.tier = undefined
        this.allTiers = []
        this.allUnits.forEach((item, index) => {
          if (item.unitId == this.form.unitId) {
            tier = item.tier
          }
        })
        for (let i = 1; i <= parseInt(tier); i++) {
          this.allTiers.push(i)
        }
      },
      /** 查询字典类型列表 */
      getList() {
        this.loading = true
        listRoom(this.addDateRange(this.queryParams, this.dateRange)).then(
          (response) => {
            this.roomList = response.queryResult.list
            this.total = response.queryResult.total
            this.loading = false
          }
        )
      },

      // 取消按钮
      cancel() {
        this.open = false
        this.reset()
      },
      // 表单重置
      reset() {
        this.form = {
          buildId: undefined,
          unitId: undefined,
          roomNum: undefined,
          tier: undefined
        }
        this.resetForm('form')
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.page = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = []
        this.resetForm('queryForm')
        this.handleQuery()
      },
      /** 新增按钮操作 */
      handleAdd() {
        this.reset()
        this.open = true
        this.form.buildId = this.queryParams.buildId
        this.form.unitId = this.queryParams.unitId
        this.title = '添加房屋信息'
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map((item) => item.roomId)
        this.single = selection.length != 1
        this.multiple = !selection.length
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset()
        const roomId = row.roomId || this.ids[0]
        getRoom(roomId).then((response) => {
          this.form = response
          let unitData = { buildId: this.form.buildId }
          //获取当前单元
          listUnitData(unitData).then((res) => {
            this.allUnits = res
          })

          this.open = true
          this.title = '修改房屋信息'
        })
      },
      /** 提交按钮 */
      submitForm: function() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.form.roomId != undefined) {
              updateRoom(this.form).then((response) => {
                if (response.code === 10000) {
                  this.msgSuccess('修改成功')
                  this.open = false
                  this.getList()
                } else {
                  this.msgError(response.msg)
                }
              })
            } else {
              addRoom(this.form).then((response) => {
                if (response.code === 10000) {
                  this.msgSuccess('新增成功')
                  this.open = false
                  this.getList()
                } else {
                  this.msgError(response.msg)
                }
              })
            }
          }
        })
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        if (row.roomId != undefined) {
          var roomIds = row.roomId
        } else {
          var roomIds = this.ids[0]
        }

        this.$confirm(
          '是否确认删除字典编号为"' + roomIds + '"的数据项?',
          '警告',
          {
            customClass: 'deleteBox',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(function() {
            return delRoom(roomIds)
          })
          .then(() => {
            this.getList()
            this.msgSuccess('删除成功')
          })
          .catch(function() {})
      },
      /** 导入按钮操作 */
      handleImport() {
        this.upload.title = '设备导入'
        this.upload.open = true
        this.upload.isUploading = false
      },
      /** 下载模板操作 */
      importTemplate() {
        downloadExcel('房间批量导入模板').then((response) => {
          let blob = new Blob([response], {
            type: 'application/vnd.ms-excel'
          }) //type是文件类，详情可以参阅blob文件类型
          let objectUrl = URL.createObjectURL(blob) //生成下载链接
          window.location.href = objectUrl
        })
      },
      // 文件上传中处理
      handleFileUploadProgress(event, file, fileList) {
        this.upload.isUploading = true
      },
      // 文件上传成功处理
      handleFileSuccess(response, file, fileList) {
        this.upload.open = false
        this.upload.isUploading = false
        this.$refs.upload.clearFiles()
        if (response.code === 10000 && response.object.length > 0) {
          this.imRestable = response.object
          this.imResOpen = true
        } else {
          this.msgInfo(response.message)
        }
        this.getList()
      },
      // 提交上传文件
      submitFileForm() {
        this.$refs.upload.submit()
      }
    }
  }
</script>
<style scoped lang="scss"></style>
