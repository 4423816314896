/*
 * @Author: 倪志磊 956640361@qq.com
 * @Date: 2022-10-11 18:13:42
 * @LastEditors: 倪志磊 956640361@qq.com
 * @LastEditTime: 2023-02-22 17:04:22
 * @FilePath: \oaktree-ai-iffp-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import 'babel-polyfill' //ie不兼容ES6
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//路由权限
import './permission.js'

//引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
//引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 百度地图
// import BMap from "vue-baidu-map";
// Vue.use(BMap, {
//     // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//     ak: 'pSqnVG16NzbKxKWktWwnph56yY0Kts99'
// })
//全局css
import '@/assets/styles/element-variables.scss'
import '@/assets/styles/index.scss'
import '@/assets/styles/oaktree.scss'
Vue.config.productionTip = false;

/* icon注册 */
import './assets/icons' // icon
/* 按钮权限 */
import permission from './directive/permission'
Vue.use(permission)

import { getDicts } from "@/api/system/dict/data";
import { parseTime, resetForm, addDateRange, selectDictLabel, download } from "@/utils/oaktree";
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.download = download


// 全局图片地址
Vue.prototype.$imgIp = process.env.VUE_APP_IMG_URL

Vue.prototype.$viewIp = process.env.VUE_APP_FAS_TDFS_URL;

Vue.prototype.msgSuccess = function (msg) {
    this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
    this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
    this.$message.info(msg);
}



//全局注册组件
import elBack from '@/components/el-back'
Vue.component('el-back', elBack)
import elBox from '@/components/el-box'
Vue.component('el-box', elBox)
import tabButton from '@/components/tabButton'
Vue.component('tab-button', tabButton)
import Pagination from "@/components/Pagination";
Vue.component('Pagination', Pagination)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");