<template>
	<div class="components-container">
		<el-back>
			<div class="formBox">单位信息搜索</div>
			<el-form :inline="true">
				<el-form-item label="单位名称">
					<el-input v-model="queryParams.deptName" placeholder="请输入单位名称" clearable size="small" @keyup.enter.native="handleQuery" />
				</el-form-item>
				<el-form-item label="设备类型">
					<el-select v-model="queryParams.searchValue" placeholder="设备类型" clearable size="small">
						<el-option v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue" />
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button class="filter-item" type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
					<el-button class="filter-item" icon="el-icon-refresh" type="primary" size="mini" @click="resetQuery">重置</el-button>
				</el-form-item>
			</el-form>

			<el-table v-loading="loading" :data="deptList" row-key="deptId" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column prop="deptName" label="单位名称" :show-overflow-tooltip="true" ></el-table-column>
				<el-table-column prop="count" label="设备总数" :show-overflow-tooltip="true" ></el-table-column>
		
			</el-table>
		</el-back>


	</div>
</template>

<script>
	import {
		listOutDevice
	} from "@/api/reports/outDevice";
	import {
		translateDataToTree
	} from "@/utils/index"


	export default {
		name: "Dept",

		data() {
			return {
				// 遮罩层
				loading: true,
				// 表格树数据
				deptList: [],			
				// 状态数据字典
				statusOptions: [],
				// 查询参数
				queryParams: {
					deptName: undefined,
					searchValue: undefined
				}
			};
		},
		created() {
			this.getList();
			this.getDicts("sys_device_type").then(response => {
				this.statusOptions = response;
			});
		},
		methods: {
			/** 查询单位列表 */
			getList() {
				this.loading = true;
				listOutDevice(this.queryParams).then(response => {
					this.deptList = response
					this.loading = false;
				});
			},


			/** 搜索按钮操作 */
			handleQuery() {
				this.loading = true;
				listOutDevice(this.queryParams).then(response => {
					this.deptList = response;
					this.loading = false;
				});
			},
			/** 重置按钮操作 */
			resetQuery() {				
				this.resetForm("queryForm");
				this.handleQuery();
			}


		}
	};
</script>
