/*
 * @Author: 倪志磊 956640361@qq.com
 * @Date: 2023-02-25 09:53:16
 * @LastEditors: 倪志磊 956640361@qq.com
 * @LastEditTime: 2023-02-25 09:54:51
 * @FilePath: \oaktree-ai-iffp-web\src\api\smoke\message.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request';

export function selectAlarmPageList(data) {
    return request({
        url: '/monitor/alarm/selectAlarmPageList',
        method: 'post',
        data: data
    })
}

export function updateCommunicatetype(data){
    return request({
        url: '/monitor/alarm/updateCommunicatetype',
        method: 'post',
        data: data
    })
}