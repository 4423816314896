<template>
    <div class="components-container">
        <el-back>
            <div class="formBox">单位信息搜索</div>
            <el-form
                :model="queryParams"
                ref="queryForm"
                :inline="true"
                label-width="68px"
            >
                <el-form-item label="单位名称" prop="deptName">
                    <el-input
                        v-model="queryParams.deptName"
                        placeholder="请输入单位名称"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item label="单位状态" prop="status">
                    <el-select
                        v-model="queryParams.status"
                        placeholder="单位状态"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in statusOptions"
                            :key="dict.dictValue"
                            :label="dict.dictLabel"
                            :value="dict.dictValue"
                        />
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="单位地址">
					<el-cascader v-model="value" :options="options" size="small" placeholder="请选择省份 | 市 | 区(县)"></el-cascader>
				</el-form-item> -->
                <el-form-item>
                    <el-button
                        class="filter-item"
                        type="primary"
                        icon="el-icon-search"
                        size="mini"
                        @click="handleQuery"
                        v-hasPermi="['system:dept:list']"
                        >搜索</el-button
                    >
                    <el-button
                        class="filter-item"
                        icon="el-icon-refresh"
                        type="primary"
                        size="mini"
                        @click="resetQuery"
                        >重置</el-button
                    >
                    <el-button
                        class="filter-item"
                        type="primary"
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAdd"
                        v-hasPermi="['system:dept:add']"
                        >新增</el-button
                    >
                </el-form-item>
            </el-form>

            <el-table
                v-loading="loading"
                :data="deptList"
                row-key="deptId"
                default-expand-all
                :tree-props="{
                    children: 'children',
                    hasChildren: 'hasChildren'
                }"
            >
                <el-table-column
                    prop="deptName"
                    label="单位名称"
                    width="300"
                ></el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="排序"
                    width="100"
                ></el-table-column>
                <el-table-column
                    prop="deptNature"
                    label="单位性质"
                    width="200"
                ></el-table-column>
                <el-table-column
                    prop="showName"
                    label="平台名称"
                    width="200"
                ></el-table-column>
                <el-table-column
                    prop="deptAddress"
                    label="单位地址"
                    width="200"
                ></el-table-column>
                <el-table-column
                    prop="status"
                    label="状态显示"
                    :formatter="statusFormat"
                    width="100"
                ></el-table-column>
                <el-table-column
                    label="创建时间"
                    align="center"
                    prop="createTime"
                    width="200"
                >
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.createTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="更新时间"
                    align="center"
                    prop="updateTime"
                    width="200"
                >
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.updateTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="用户操作"
                    align="center"
                    class-name="small-padding fixed-width"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                            v-hasPermi="['system:dept:edit']"
                            >修改</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-plus"
                            v-if="scope.row.deptNature == '代理商'"
                            @click="handleAdd(scope.row)"
                            v-hasPermi="['system:dept:add']"
                            >新增</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.row)"
                            v-hasPermi="['system:dept:remove']"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-back>

        <!-- 添加或修改单位对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item
                    label="上级单位"
                    prop="parentId"
                    v-if="this.userInfo.deptId !== this.form.deptId"
                >
                    <treeselect
                        v-model="form.parentId"
                        :options="deptOptions"
                        :show-count="true"
                        placeholder="选择上级单位"
                    />
                </el-form-item>
                <el-form-item label="单位名称" prop="deptName">
                    <el-input
                        v-model="form.deptName"
                        placeholder="请输入单位名称"
                    />
                </el-form-item>
                <el-form-item label="省市县" prop="area">
                    <v-distpicker
                        :province="form.province"
                        :city="form.city"
                        :area="form.area"
                        @province="getProvince"
                        @city="getCity"
                        @area="getArea"
                    ></v-distpicker>
                </el-form-item>
                <el-form-item label="平台名称" prop="showName">
                    <el-input
                        v-model="form.showName"
                        placeholder="请输入平台名称"
                    />
                </el-form-item>
                <el-form-item label="详细地址" prop="deptAddress">
                    <el-input
                        v-model="form.deptAddress"
                        placeholder="请输入详细地址"
                    />
                </el-form-item>
                <el-form-item
                    label="单位性质"
                    prop="deptNature"
                    v-if="this.form.deptId !== this.userInfo.deptId"
                >
                    <el-select
                        v-model="form.deptNature"
                        placeholder="请输入单位性质"
                        clearable
                        style="width: 480px;"
                    >
                        <el-option
                            v-for="dict in natureOptions"
                            :key="dict.dictValue"
                            :label="dict.dictLabel"
                            :value="dict.dictValue"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="显示排序" prop="orderNum">
                    <el-input-number
                        v-model="form.orderNum"
                        controls-position="right"
                        :min="0"
                    />
                </el-form-item>
                <el-form-item label="单位状态">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions"
                            :key="dict.dictValue"
                            :label="dict.dictValue"
                            >{{ dict.dictLabel }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        listDept,
        getDept,
        treeselect,
        delDept,
        addDept,
        updateDept,
        checkDeptName,
        noFatherAndDlsData,
        checkDeptIsUp
    } from '@/api/system/dept';
    import { translateDataToTree, traverseTreeArray } from '@/utils/index';
    import { getUserSession } from '@/utils/auth';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import VDistpicker from 'v-distpicker';
    export default {
        name: 'Dept',
        components: {
            Treeselect,
            VDistpicker
        },
        data() {
            var checkDeptNames = (rule, value, callback) => {
                let dName = {
                    parentId: this.form.parentId,
                    deptName: value
                };
                if (this.title == '添加单位') {
                    checkDeptName(dName).then((res) => {
                        if (res == 0) {
                            callback();
                        } else {
                            callback(new Error('单位名称重复，请重新输入'));
                        }
                    });
                } else {
                    callback();
                }
            };

            return {
                // 遮罩层
                loading: true,
                // 表格树数据
                deptList: [],
                // 单位树选项
                deptOptions: [],
                // 弹出层标题
                title: '',
                // 是否显示弹出层
                open: false,
                // 状态数据字典
                statusOptions: [],
                natureOptions: [],
                userInfo: {},
                // 查询参数
                queryParams: {
                    deptId: undefined,
                    deptName: undefined,
                    status: undefined,
                    params: {},
                    children: []
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {
                    parentId: [
                        {
                            required: true,
                            message: '上级单位不能为空',
                            trigger: 'blur'
                        }
                        /* {
						required: true,
						validator: checkDeptIsUps,
						trigger: 'blur'
					} */
                    ],
                    deptName: [
                        {
                            required: true,
                            message: '单位名称不能为空',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            validator: checkDeptNames,
                            trigger: 'blur'
                        }
                    ],
                    deptNature: [
                        {
                            required: true,
                            message: '单位性质不能为空',
                            trigger: 'blur'
                        }
                    ],
                    showName: [
                        {
                            required: true,
                            message: '平台名称不能为空',
                            trigger: 'blur'
                        }
                    ],
                    orderNum: [
                        {
                            required: true,
                            message: '菜单顺序不能为空',
                            trigger: 'blur'
                        }
                    ],
                    phone: [
                        {
                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                            message: '请输入正确的手机号码',
                            trigger: 'blur'
                        }
                    ],
                    area: [
                        {
                            required: true,
                            message: '省市县不能为空',
                            trigger: 'blur'
                        }
                    ]
                },
                value: [],
                options: []
            };
        },
        created() {
            this.getList();
            this.getDicts('sys_normal_disable').then((response) => {
                this.statusOptions = response;
            });
            this.getDicts('sys_dept_nature').then((response) => {
                this.natureOptions = response;
            });
        },
        mounted() {
            this.userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'));
        },
        methods: {
            getProvince(data) {
                this.form.province = data.value;
            },
            getCity(data) {
                this.form.city = data.value;
            },
            getArea(data) {
                this.form.area = data.value;
            },
            /** 查询单位列表 */
            getList() {
                this.loading = true;
                listDept(this.queryParams).then((response) => {
                    this.deptList = response;
                    this.loading = false;
                });
            },
            /** 查询单位下拉树结构 */
            getTreeselect() {
                noFatherAndDlsData({}).then((response) => {
                    this.deptOptions = traverseTreeArray(
                        response,
                        'deptName',
                        'deptId'
                    );
                });
            },
            // 字典状态字典翻译
            statusFormat(row, column) {
                return this.selectDictLabel(this.statusOptions, row.status);
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    deptId: undefined,
                    parentId: undefined,
                    deptName: undefined,
                    deptNature: undefined,
                    deptAddress: undefined,
                    orderNum: undefined,
                    leader: undefined,
                    phone: undefined,
                    status: '0',
                    province: undefined,
                    city: undefined,
                    area: undefined,
                    showName: undefined
                };
                this.resetForm('form');
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.loading = true;
                listDept(this.queryParams).then((response) => {
                    this.deptList = response;
                    this.loading = false;
                });
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm('queryForm');
                this.handleQuery();
            },
            /** 新增按钮操作 */
            handleAdd(row) {
                this.reset();
                this.getTreeselect();
                this.open = true;
                if (row.deptId !== undefined) {
                    checkDeptIsUp(row.deptId).then((res) => {
                        if (res) {
                            this.open = false;
                            this.msgError('无权限');
                        } else {
                            this.form.parentId = row.deptId;
                            this.title = '添加单位';
                        }
                    });
                }
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                this.getTreeselect();
                checkDeptIsUp(row.deptId).then((res) => {
                    if (res) {
                        this.open = false;
                        this.msgError('不能修改上级单位信息');
                    } else {
                        getDept(row.deptId).then((response) => {
                            this.form = response;
                            this.open = true;
                            this.title = '修改单位';
                        });
                    }
                });
            },
            /** 提交按钮 */
            submitForm: function() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.deptId != undefined) {
                            updateDept(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('修改成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        } else {
                            addDept(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('新增成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                this.$confirm(
                    '是否确认删除名称为"' + row.deptName + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(function() {
                        return delDept(row.deptId);
                    })
                    .then(() => {
                        this.getList();
                        this.msgSuccess('删除成功');
                    })
                    .catch(function() {});
            },
            hangChange(value) {}
        }
    };
</script>
<style scoped lang="scss">
    /* 修改地区选择样式*/
    .distpicker-address-wrapper /deep/ label {
        display: block;
        width: 100%;
        select {
            width: 100%;
            background-color: $inputBack;
            color: $resetColor;
            &::-webkit-scrollbar-track-piece {
                background: $weakColor;
            }
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background: $resetColor;
                border-radius: 20px;
            }
        }
    }
</style>
