import request from '@/utils/request'

// 查询单元信息列表
export function listUnit(data) {
  return request({
    url: 'ucenter/unit/unitPageList',
    method: 'post',
    data: data
  })
}

// 查询所有单元信息列表
export function listUnitData(data) {
  return request({
    url: '/ucenter/unit/unitList',
    method: 'post',
    data:data
  })
}
// 查询单元信息详细
export function getUnit(unitId) {
  return request({
    url: 'ucenter/unit/getUnitById?unitId=' + unitId,
    method: 'post'
  })
}



// 新增单元信息
export function addUnit(data) {
  return request({
    url: '/ucenter/unit/addUnit',
    method: 'post',
    data: data
  })
}

// 修改单元信息
export function updateUnit(data) {
  return request({
    url: '/ucenter/unit/updateUnit',
    method: 'post',
    data: data
  })
}

// 删除单元信息
export function delUnit(unitId) {
  return request({
    url: 'ucenter/unit/delUnitById?unitId=' + unitId,
    method: 'post',
  })
}
