<template>
  <div class="">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="设备类型" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          placeholder="设备类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in deviceOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
	  <el-form-item label="产品ID" prop="deviceCode">
	    <el-input
	      v-model="queryParams.deviceCode"
	      placeholder="产品ID"
	      clearable
	      size="small"
	      style="width: 240px"
	      @keyup.enter.native="handleQuery"
	    />
	  </el-form-item>
	  <el-form-item label="设备名称" prop="deviceName">
	    <el-input
	      v-model="queryParams.deviceName"
	      placeholder="请输入设备名称"
	      clearable
	      size="small"
	      style="width: 240px"
	      @keyup.enter.native="handleQuery"
	    />
	  </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="allocatedList">
      <el-table-column label="编号" type="index" width="55" align="center" />
      <el-table-column label="设备种类" align="center" prop="deviceType" :formatter="deviceFormat" :show-overflow-tooltip="true" />
	  <el-table-column label="设备名称" align="center" prop="deviceName" :show-overflow-tooltip="true" />
      <el-table-column label="产品ID" align="center" prop="deviceCode" :show-overflow-tooltip="true" />
	  <el-table-column label="建筑楼层" align="center" prop="deviceName" :show-overflow-tooltip="true">
		  <template slot-scope="scope">
			  <span>{{scope.row.buildName}}{{scope.row.unitName}}{{scope.row.tier}}层{{scope.row.roomNum}}</span>
		  </template>
	  </el-table-column>
	  <el-table-column label="设备使用人" align="center" prop="functionaryName" :show-overflow-tooltip="true" />
	  <el-table-column label="上次缴费日期" align="center" prop="lastTime" :show-overflow-tooltip="true" />
	  <el-table-column label="缴费金额" align="center" prop="cost" :show-overflow-tooltip="true" />
	  <el-table-column label="缴费时长" align="center" :formatter="validTimeFormat" prop="validTime" :show-overflow-tooltip="true" />
      <el-table-column label="有效使用期限" align="center" prop="indate" width="180" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleRenew(scope.row)"
          >线下缴费</el-button>
		  <el-button
		    size="mini"
		    type="text"
		    icon="el-icon-edit"
		    @click="handleFree(scope.row)"
		  >赠送时长</el-button>
		  <el-button
		    size="mini"
		    type="text"
		    icon="el-icon-edit"
		    @click="handleChange(scope.row)"
		  >更换使用人</el-button>
		  <el-button
		    size="mini"
		    type="text"
		    icon="el-icon-edit"
		    @click="handleDelete(scope.row)"
		  >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.size"
      @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="400px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="续费套餐">
        	<el-radio-group v-model="form.validTime">
        		<el-radio @change="changeCost(dict.cost)" v-for="dict in validTimeOptions" :key="dict.validTime" :label="dict.validTime">{{dict.validTime}}个月({{dict.cost}}元)</el-radio>
        	</el-radio-group>
        </el-form-item>
		<!-- <el-form-item label="支付途径">
			<el-select v-model="form.pay" placeholder="请选择">
				<el-option v-for="dict in payOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
			</el-select>
		</el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
	
	<el-dialog :title="titleFree" :visible.sync="openFree" width="500px">
	  <el-form ref="formFree" :model="formFree" label-width="100px">
		<el-form-item label="赠送免费时长">
			<el-select v-model="formFree.month" placeholder="请选择">
				<el-option v-for="dict in freeOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue"></el-option>
			</el-select>
		</el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button type="primary" @click="submitFormFree">确 定</el-button>
	    <el-button @click="cancelFree">取 消</el-button>
	  </div>
	</el-dialog>
	
	<el-dialog :title="titleDeviceUse" :visible.sync="openDeviceUse" width="500px">
	  <el-form ref="formDeviceUse" :model="formDeviceUse" label-width="100px">
		<el-form-item label="设备使用人">
			<el-select v-model="formDeviceUse.newUserId" placeholder="请选择">
				<el-option v-for="dict in DeviceUseOptions" :key="dict.userId" :label="dict.nickname" :value="dict.userId"></el-option>
			</el-select>
		</el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button type="primary" @click="submitFormDeviceUse">确 定</el-button>
	    <el-button @click="cancelDeviceUse">取 消</el-button>
	  </div>
	</el-dialog>
  </div>
</template>

<script>
	import { selectUserDeviceList,selectRankPayTypeById,selectRankPayTypeList,updateRankDevicePay,updateUserDevice,selectDeptRankUser,deleteUserDevice } from '@/api/deviceUse.js'
	import qs from "qs"
export default {
  name: "allocated",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      allocatedList: [],
      // 弹出层标题
      title: "",
	  titleFree: "",
	  titleDeviceUse: "",
      // 是否显示弹出层
      open: false,
	  openFree: false,
	  openDeviceUse: false,
      // 数据字典
	  deviceOptions: [],
	  validTimeOptions: [],
	  freeOptions: [],
	  DeviceUseOptions: [],
	  payOptions: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
		tag: '1',
		deviceType: undefined,
		deviceCode: undefined,
		deviceName: undefined,
      },
      // 表单参数
      form: {},
	  formFree: {
		  month: undefined,
		  cost: 0,
		  deviceCode: undefined,
		  remark: '1'
	  },
	  formDeviceUse: {
		  oldUserId: undefined,
		  newUserId: undefined,
		  deviceCode: undefined,
	  },
      // 表单校验
      rules: {
        pay: [
          { required: true, message: "不能为空", trigger: "blur" }
        ],
      }
    }
  },
  created() {
	  this.getDicts("sys_device_type").then(response => {
	    this.deviceOptions = response;
	  });
	  this.getDicts("sys_renew_time").then(response => {
	    this.validTimeOptions = response;
	  });
	  this.getDicts("sys_free_time").then(response => {
	    this.freeOptions = response;
	  });
	  this.getDicts("sys_pay_pattern").then(response => {
	    this.payOptions = response;
	  });
      this.getList();
  },
  methods: {
	  // 数据状态字典翻译
	  deviceFormat(row, column) {
	    return this.selectDictLabel(this.deviceOptions, row.deviceType);
	  },
    /** 查询列表 */
    getList() {
      this.loading = true;
      selectUserDeviceList(this.queryParams).then(response => {
          this.allocatedList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
	// 数据状态字典翻译
	validTimeFormat(row, column) {
	  return this.selectDictLabel(this.validTimeOptions, row.validTime);
	},
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
	cancelFree(){
		this.openFree = false;
		this.resetForm("formFree");
	},
	cancelDeviceUse(){
		this.openDeviceUse = false;
		this.resetForm("formDeviceUse");
	},
    // 表单重置
    reset() {
        this.form = {
			pay: undefined,
			deviceType: undefined,
			validTime: undefined,
			deviceCode: undefined,
			remark: '1'
		};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
	//所选金额的变化
		changeCost(val){
			this.form.cost = val
		},
	//线下缴费
	handleRenew(row){
		this.form.deviceCode = row.deviceCode
		this.form.remark = '1'
		let payType = {
			deviceType: row.deviceType,
		}
		selectRankPayTypeList(payType).then(res =>{
			this.validTimeOptions = res
			this.open = true
			this.title = '线下缴费'
		})
	},
	//免费时长
	handleFree(row){
		this.openFree = true
		this.formFree.deviceCode = row.deviceCode
		this.formFree.cost = 0
		this.titleFree = '赠送时长'
	},
	//更换使用人
	handleChange(row){
		this.formDeviceUse.oldUserId = row.userId
		this.formDeviceUse.deviceCode = row.deviceCode
		selectDeptRankUser().then(res =>{
			this.DeviceUseOptions = res;
			this.openDeviceUse = true;
			this.titleDeviceUse = '更换设备使用人';
		})
	},
	//删除
	handleDelete(row){
		let userDevice = {
			userId: row.userId,
			deviceId: row.deviceId,
			deviceCode: row.deviceCode
		}
		this.$confirm('是否确认删除编号为"' + row.deviceId + '"的数据项?', "警告", {
			customClass: "deleteBox",
		    confirmButtonText: "确定",
		    cancelButtonText: "取消",
		    type: "warning"
		  }).then(() => {
		      return deleteUserDevice(userDevice);
		  }).then(() => {
		    this.getList();
		    this.msgSuccess("删除成功");
		  }).catch(function() {});
	},
	//提交
	submitForm() {
		let month = this.form.validTime
		updateRankDevicePay(this.form.deviceCode,month,this.form.cost,this.form.remark).then(res =>{
			if (res.code === 10000) {
			  this.msgSuccess(res.message);
			  this.open = false;
			  this.getList();
			} else {
			  this.msgError(res.msg);
			}
		})
	},
	submitFormFree(){
		updateRankDevicePay(this.formFree.deviceCode,this.formFree.month,this.formFree.cost).then(res =>{
			if (res.code === 10000) {
			  this.msgSuccess(res.message);
			  this.openFree = false;
			  this.getList();
			} else {
			  this.msgError(res.msg);
			}
		})
	},
	submitFormDeviceUse(){
		updateUserDevice(this.formDeviceUse.oldUserId,this.formDeviceUse.newUserId,this.formDeviceUse.deviceCode).then(res =>{
			if (res.code === 10000) {
			  this.msgSuccess(res.message);
			  this.openDeviceUse = false;
			  this.getList();
			} else {
			  this.msgError(res.msg);
			}
		})
	},
  }
};
</script>
<style lang="scss" scoped>
	.el-radio{
		margin: 10px;
	}
</style>
