<template>
  <div class="components-container">
	<el-back>
		<div class="formBox">单位时效性</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
		      <el-form-item label="单位名称" prop="deptName">
		        <el-input
		          v-model="queryParams.deptName"
		          placeholder="请输入单位名称"
		          clearable
		          size="small"
		          style="width: 240px"
		          @keyup.enter.native="handleQuery"
		        />
		      </el-form-item>

		      <el-form-item>
		        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
		        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
		      </el-form-item>
		    </el-form>
		
		    <el-row :gutter="10" class="mb8">
		      <el-col :span="1.5">
		        <el-button
		          type="primary"
		          icon="el-icon-plus"
		          size="mini"
		          @click="handleAdd"

		        >新增</el-button>
		      </el-col>
			  
		    </el-row>
		
		    <el-table v-loading="loading" :data="dataList">

		      <el-table-column label="单位名称" align="center" prop="deptName" :show-overflow-tooltip="true" />
				<el-table-column label="生效时间" align="center" prop="rsaStartTime" :show-overflow-tooltip="true" />
				<el-table-column label="失效时间" align="center" prop="rsaEndTime" :show-overflow-tooltip="true" />
				<el-table-column label="签名" align="center" prop="sign" :show-overflow-tooltip="true" />

		      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
		        <template slot-scope="scope">
	<!-- 	          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-edit"
		            @click="handleUpdate(scope.row)"
		    
		          >修改</el-button> -->
				  <el-button
				    size="mini"
				    type="text"
			
				    @click="handleCopy(scope.row)"
				  		 
				  >复制签名</el-button>
		          <el-button
		            size="mini"
		            type="text"
		            icon="el-icon-delete"
		            @click="handleDelete(scope.row)"
		 
		          >删除</el-button>
		        </template>
		      </el-table-column>
		    </el-table>
		
		    <pagination
		      v-show="total>0"
		      :total="total"
		      :page.sync="queryParams.page"
		      :limit.sync="queryParams.size"
		      @pagination="getList"
		    />
		
	</el-back>
    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="单位名称" prop="deptName">
          <el-input v-model="form.deptName" :disabled="title == '修改'" placeholder="请输入单位名称" />
        </el-form-item>
		<el-form-item label="生效时间" prop="rsaStartTime">
		  <el-date-picker
		     v-model="form.rsaStartTime"
		     type="date"
		     value-format="yyyy-MM-dd"
		     format="yyyy-MM-dd"
		     :picker-options="startDatePicker"
		     placeholder="选择日期">
		   </el-date-picker>
		</el-form-item>
		<el-form-item label="失效时间" prop="rsaEndTime">
		  <el-date-picker
		     v-model="form.rsaEndTime"
		     type="date"
		     value-format="yyyy-MM-dd"
		     format="yyyy-MM-dd"
		      :picker-options="endDatePicker"
		     placeholder="选择日期">
		   </el-date-picker>
		</el-form-item>
		<el-form-item label="签名" prop="sign" v-if="title == '修改'">
		  <el-input v-model="form.sign" placeholder="请输入签名"  type="textarea" />
		</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
	
	<input  id="copyInput"  />
  </div>
</template>

<script>
import { getPageRsaRecord, addRsaRecord, updLocalRsaDept,deleteRsaDept } from "@/api/system/timeliness";
import qs from 'qs'
export default {
  name: "timeliness",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 字典表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        deptName: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        deptName: [
          { required: true, message: "单位名称不能为空", trigger: "blur" }
        ],
        rsaStartTime: [
          { required: true, message: "生效时间不能为空", trigger: "blur" }
        ],
		rsaEndTime: [
		  { required: true, message: "失效时间不能为空", trigger: "blur" }
		],
		sign: [
		  { required: true, message: "签名不能为空", trigger: "blur" }
		]
      },
	  //开始时间和结束时间的判断
	  startDatePicker: this.beginDate(),
	  endDatePicker: this.processDate(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
	  handleCopy(row){
		  let target = document.getElementById("copyInput")
		  target.value = row.sign // 给input的value赋值
		  target.select() // 选中input
		  document.execCommand('Copy') // 执行浏览器复制命令
		  this.msgSuccess('复制成功')

	  },
	  //开始时间和结束时间的限制
	  beginDate(){
	      const self = this
	      return {
	        disabledDate(time){
	          if (self.form.rsaEndTime) {  //如果结束时间不为空，则小于结束时间
	            return new Date(self.form.rsaEndTime).getTime() < time.getTime()
	          } else {
	            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
	          }
	        }
	      }
	    },
	    processDate() {
	      const  self = this
	      return {
	        disabledDate(time) {
	          if (self.form.rsaStartTime) {  //如果开始时间不为空，则结束时间大于开始时间
	            return new Date(self.form.rsaStartTime).getTime() > time.getTime()
	          } else {
	            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
	          }
	        }
	      }
	    },
    /** 查询字典类型列表 */
    getList() {
      this.loading = true;
      getPageRsaRecord(this.queryParams).then(response => {
          this.dataList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        deptName: undefined,
        rsaStartTime: undefined,
        rsaEndTime: undefined,
        sign: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
	  this.form = {
		  rsaStartTime:row.rsaStartTime,
		  rsaEndTime:row.rsaEndTime,
		  sign:row.sign
	  }
	  this.open = true;
	  this.title = "修改";

    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.rsaRecordId != undefined) {
            updLocalRsaDept(qs.stringify(this.form)).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addRsaRecord(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {

      this.$confirm('是否确认删除该数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
			
		return deleteRsaDept(row.rsaRecordId);


        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },

  }
};
</script>
<style scoped lang="scss">
	#copyInput{
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -10;
	}
</style>