<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<phoneTemplate v-if="tabVal == 1"></phoneTemplate>
				<noteTemplate v-if="tabVal == 2"></noteTemplate>	

			</el-back>
  </div>
</template>

<script>

import phoneTemplate from './phoneTemplate'
import noteTemplate from './noteTemplate'

export default {
  name: "receiver",
  data(){
	  return {
		tabList:[
			{ id:1,name:'电话模版' },
			{ id:2,name:'短信模版' }
		],
		 tabVal:1
	  }
  },
  components:{
	  phoneTemplate,
	  noteTemplate
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
