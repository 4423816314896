<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<smokeReceiver v-if="tabVal == 1"></smokeReceiver>
				<netCardReceiver v-if="tabVal == 2"></netCardReceiver>	

			</el-back>
  </div>
</template>

<script>

import smokeReceiver from './smokeReceiver'
import netCardReceiver from './netCardReceiver'

export default {
  name: "receiver",
  data(){
	  return {
		tabList:[
			{ id:1,name:'NB烟感' },
			{ id:2,name:'物联网卡' }
		],
		 tabVal:1
	  }
  },
  components:{
	  smokeReceiver,
	  netCardReceiver
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
