<template>
  <div class="">

		<div class="formBox">短信模板信息搜索</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true">
		   <el-form-item label="模板名称" prop="templateName">
		     <el-input
		       v-model="queryParams.templateName"
		       placeholder="请输入模板名称"
		       clearable
		       size="small"
		       style="width: 240px"
		       @keyup.enter.native="handleQuery"
		     />
		   </el-form-item>
		   <el-form-item label="签名名称" prop="signName">
		     <el-input
		       v-model="queryParams.signName"
		       placeholder="请输入签名名称"
		       clearable
		       size="small"
		       style="width: 240px"
		       @keyup.enter.native="handleQuery"
		     />
		   </el-form-item>
		   <el-form-item label="报警种类"  prop="noticeType">
		     <el-input
		       v-model="queryParams.noticeType"
		       placeholder="请输入报警种类"
		       clearable
		       size="small"
		       style="width: 240px"
		       @keyup.enter.native="handleQuery"
		     />
		   </el-form-item>
		   <el-form-item>
		     <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
		     <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
		   </el-form-item>
		 </el-form>
		
		 <el-row :gutter="10" class="mb8">
		   <el-col :span="1.5">
		     <el-button
		       type="primary"
		       icon="el-icon-plus"
		       size="mini"
		       @click="handleAdd"
		       v-hasPermi="['system:note:add']"
		     >新增</el-button>
		   </el-col>
		   <el-col :span="1.5">
		     <el-button
		       type="success"
		       icon="el-icon-edit"
		       size="mini"
		       :disabled="single"
		       @click="handleUpdate"
		       v-hasPermi="['system:note:edit']"
		     >修改</el-button>
		   </el-col>
		   <el-col :span="1.5">
		     <el-button
		       type="danger"
		       icon="el-icon-delete"
		       size="mini"
		       :disabled="multiple"
		       @click="handleDelete"
		       v-hasPermi="['system:note:remove']"
		     >删除</el-button>
		   </el-col>
		   <el-col :span="1.5">
		     <el-button
		       type="warning"
		       icon="el-icon-download"
		       size="mini"
		       @click="handleExport"
			   v-hasPermi="['system:note:export']"
		     >导出</el-button>
		   </el-col>
		 </el-row>
		
		 <el-table v-loading="loading" :data="noteList" @selection-change="handleSelectionChange">
		   <el-table-column type="selection" width="55" align="center" />
		   <el-table-column label="编号" align="center" type="index"/>
		   <el-table-column label="模板名称" prop="templateName" align="center"  :show-overflow-tooltip="true" />
		   <el-table-column label="模板内容" prop="templateCode" align="center"  :show-overflow-tooltip="true" />
		   <el-table-column label="签名名称" prop="signName" align="center"  :show-overflow-tooltip="true" />
		   <el-table-column label="适用报警种类" prop="noticeType" align="center"  :show-overflow-tooltip="true" />
		   <el-table-column label="accessKeyId" prop="accessKeyId"   :show-overflow-tooltip="true"/>
		   <el-table-column label="accessKeySecret" prop="accessKeySecret" :show-overflow-tooltip="true"  />
		   <el-table-column label="创建人" prop="signName" :show-overflow-tooltip="true"  />
		   <el-table-column label="创建时间" align="center" prop="createTime" width="180">
		     <template slot-scope="scope">
		       <span>{{ parseTime(scope.row.createTime) }}</span>
		     </template>
		   </el-table-column>
		   <el-table-column label="操作" align="center" class-name="small-padding fixed-width"  width="180">
		     <template slot-scope="scope">
		       <el-button
		         size="mini"
		         type="text"
		         icon="el-icon-edit"
		         @click="handleUpdate(scope.row)"
		         v-hasPermi="['system:note:edit']"
		       >修改</el-button>
		       <el-button
		         size="mini"
		         type="text"
		         icon="el-icon-delete"
		         @click="handleDelete(scope.row)"
		         v-hasPermi="['system:note:remove']"
		       >删除</el-button>
		     </template>
		   </el-table-column>
		 </el-table>
		
		<pagination
		   v-show="total>0"
		   :total="total"
		   :page.sync="queryParams.pageNum"
		   :limit.sync="queryParams.pageSize"
		   @pagination="getList"
		 />


    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
		  <el-row>
			  <el-col :span="24">
				  <el-form-item label="模板名称" prop="templateName">
				    <el-input v-model="form.templateName" placeholder="请输入模板名称" />
				  </el-form-item>
			  </el-col>
			  <el-col :span="12">
				  <el-form-item label="签名名称"  prop="signName">
				    <el-input v-model="form.signName" placeholder="请输入签名名称" />
				  </el-form-item>
			  </el-col>
			  <el-col :span="12">
				  <el-form-item label="适用报警种类"  prop="noticeType">
				    <el-input
				      v-model="form.noticeType"
				      placeholder="请输入报警种类"
				      clearable
				      size="small"
				      @keyup.enter.native="handleQuery"
				    />
				  </el-form-item>
			  </el-col>
			  <el-col :span='12'>
				  <el-form-item label="accessKeyId" prop="accessKeyId">
				    <el-input v-model="form.accessKeyId" placeholder="请输入accessKeyId" />
				  </el-form-item>
			  </el-col>
			  <el-col :span='12'>
			  		<el-form-item label="accessKeySecret" prop="accessKeySecret">
			  		  <el-input v-model="form.accessKeySecret" placeholder="请输入accessKeySecret" />
			  		</el-form-item>		  
			  </el-col>
			  <el-col :span='24'>
				<el-form-item label="模板内容" prop="templateCode">
				  <el-input type="textarea" v-model="form.templateCode" placeholder="请输入模版内容" />
				</el-form-item>
			  </el-col>
		  </el-row>		  
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listNote, getNote, delNote, addNote, updateNote,delNotes,checkSms } from "@/api/system/note";

import { translateDataToTree } from "@/utils/index"
export default {
  name: "Role",
  data() {
    var checkSmsTypes = (rule, value, callback) => {
        let smsType = {smsType:value};
        if(this.title == '添加模版'){
          checkSms(smsType).then(res =>{
              if(res == 0){
                callback();
              }else{
               callback(new Error('短信模板重复，请重新输入'));
              }
            })
        }else{
          callback();
        }
    };
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 角色表格数据
      noteList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],

      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
	  deviceOptions:[],
	  alarmOptions:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        signName: undefined,
        smsType: undefined,
        templateCode: undefined,
        templateName: undefined,
        "params": {},
		tag: "SMS",
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      // 表单校验
      rules: {
        accessKeyId: [
          { required: true, message: "accessKeyId不能为空", trigger: "blur" }
        ],
        accessKeySecret: [
          { required: true, message: "accessKeySecret不能为空", trigger: "blur" }
        ],
        signName: [
          { required: true, message: "签名名称不能为空", trigger: "blur" }
        ],
        noticeType: [
          { required: true, message: "适用报警种类不能为空", trigger: ["blur", 'change'] }
        ],
        templateName: [
          { required: true, message: "模板名称不能为空", trigger: "blur" }
        ],
        templateCode: [
          { required: true, message: "模板code不能为空", trigger: "blur" }
        ],
        smsType: [
          { required: true, message: "短信类型不能为空", trigger: "blur" },
          { required: true,validator: checkSmsTypes, trigger: 'blur'}
        ]
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_device_type").then(response => {
      this.alarmOptions = response;
    });
  },
  methods: {
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      listNote(this.queryParams.pageNum,this.queryParams.pageSize,this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.noteList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },

    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === "0" ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return changeRoleStatus(row.roleId, row.status);
        }).then(() => {
          this.msgSuccess(text + "成功");
        }).catch(function() {
          row.status = row.status === "0" ? "1" : "0";
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset() {

      this.form = {
        accessKeyId: undefined,
        accessKeySecret: undefined,
        signName: undefined,
        templateName: undefined,
        templateCode: undefined,
        smsType: undefined,
        params:{},
		tag: "SMS",
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.noticeId)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();

      this.open = true;
      this.title = "添加模版";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const smsIds = row.noticeId || this.ids[0]
      // this.$nextTick(() => {
      //   this.getRoleMenuTreeselect(roleId);
      // });
      getNote(smsIds).then(response => {
        this.form = response;
        this.open = true;
        this.title = "修改模版";
      });
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.noticeId != undefined) {

            updateNote(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addNote(this.form).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const smsIds = row.noticeId || this.ids

      this.$confirm('是否确认删除编号为"' + smsIds + '"的数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          if(row.noticeId){
            return delNote(smsIds);
          }else{
            return delNotes(this.ids);
          }
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有角色数据项?', "警告", {
		  customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportRole(queryParams);
        }).then(response => {
          this.download(response.msg);
        }).catch(function() {});
    }
  }
};
</script>
