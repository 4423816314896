import request from '@/utils/request'

// 查询字典类型列表
export function listType(page,size,data) {
  return request({
    url: 'system/dictType/selectDictTypeList?page='+page+'&size='+size,
    method: 'post',
    data: data
  })
}
// 查询所有字典类型列表
export function listTypes() {
  return request({
    url: 'system/dictType/selectDictTypeLists',
    method: 'post',

  })
}
// 查询字典类型详细
export function getType(dictId) {
  return request({
    url: 'system/dictType/selectDictTypeById?dictId=' + dictId,
    method: 'post'
  })
}

// 新增字典类型
export function addType(data) {
  return request({
    url: 'system/dictType/insertDictType',
    method: 'post',
    data: data
  })
}

// 修改字典类型
export function updateType(data) {
  return request({
    url: 'system/dictType/updateDictType',
    method: 'post',
    data: data
  })
}

// 删除字典类型
export function delType(dictId) {
  return request({
    url: 'system/dictType/deleteDictTypeById?dictId=' + dictId,
    method: 'post'
  })
}
// 批量删除字典类型
export function delAllType(dictIds) {
  return request({
    url: 'system/dictType/deleteDictTypeByIds',
    method: 'post',
    data:dictIds
  })
}
// 导出字典类型
export function exportType(query) {
  return request({
    url: '/system/dict/type/export',
    method: 'get',
    params: query
  })
}

// 获取字典选择框列表
export function optionselect() {
  return request({
    url: '/system/dict/type/optionselect',
    method: 'get'
  })
}
