<template>
  <div class="clearfix timer">
    <div class="floatDiv timerTime">
      <div>{{caleTime}}</div>
      <!-- <span>{{weekTime}}</span> -->
      <div>{{times}}</div>
    </div>
<!--    <div class="floatDiv">
      <div></div>
      <div></div>
    </div> -->
  </div>
</template>

<script>
  export default {
    name:"timer",
    data(){
      return {
        weekday:['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        times:'',
        weekTime:'',
        caleTime:''
      }
    },
    methods:{
      getTime(){
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()+1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
               // 获取当前星期几
        let we = new Date().getDay();
        this.times = hh+':'+mf+':'+ss;
        this.weekTime = this.weekday[we];
        this.caleTime = yy + '年' + mm+'月'+dd + '日';
      },
      currentTime(){
        setInterval(this.getTime,1000)
    },
    },
    created() {
      this.currentTime();
    }
  }
</script>

<style lang="scss" scoped>
  .timer{
    .floatDiv{
      float: left;
      height: 100%;
      line-height: 1.5em;
      margin-left: 3px;
	  text-align: center;
    }
    .timerTime{
      font-size: 1em;
      // line-height: 50px;
	  // span{
		 //  &:nth-child(2){
			//   display: inline-block;
			//   *display: inline;
			//   *zoom: 1;
			//   margin-left: 5px;
		 //  }
	  // }
    }
  }

</style>
