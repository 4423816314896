<template>
  <div class="">
    <div class="formBox">信息搜索</div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="118px"
    >
      <el-form-item label="产品ID" prop="deviceCode">
        <el-input
          v-model="queryParams.deviceCode"
          placeholder="请输入产品ID"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="删除前所属单位" prop="deptId">
        <treeselect
          v-model="queryParams.deptId"
          :options="deptOptions"
          placeholder="请选择归属部门"
          style="width: 240px"
        />
      </el-form-item>
      <el-form-item label="删除人单位" prop="deptId">
        <treeselect
          v-model="queryParams.deptId"
          :options="deptOptions"
          placeholder="请选择"
          style="width: 240px"
        />
      </el-form-item>
      <el-form-item label="删除时间">
        <el-date-picker
          v-model="dateRange"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="设备类型" prop="deviceType">
        <el-select
          v-model="queryParams.deviceType"
          placeholder="设备类型"
          clearable
          size="small"
          style="width: 240px"
        >
          <el-option
            v-for="dict in deviceTypeOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="delDeviceList">
      <el-table-column
        label="编号"
        prop="etectorId"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="产品ID"
        align="center"
        prop="deviceCode"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="IMEI"
        prop="imei"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="删除前所属单位"
        prop="deptName"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="支持运营商"
        prop="operatorName"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="删除人"
        prop="nickname"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="删除人单位"
        prop="deleteDept"
        align="center"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="删除时间"
        align="center"
        prop="updateTime"
        width="180"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="用户操作"
        align="center"
        class-name="small-padding fixed-width"
        width="220"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['outIn:delDevice:recover']"
            >恢复</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['outIn:delDevice:remove']"
            >彻底删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  deleteSmokeDetectorList,
  getSmokeDevice,
  addSmokeDevice,
  updateSmokeDevice,
  delSmokeDevice,
} from "@/api/outIn/smoke";
import { traverseTreeArray, translateDataToTree } from "@/utils/index";
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "receiver",
  components: {
    Treeselect,
  },
  data() {
    var checkReceiverPhone = (rule, value, callback) => {
      let phone = {
        phone: value,
      };
      if (this.title == "添加接收者") {
        checkPhone(phone).then((res) => {
          if (res == 0) {
            callback();
          } else {
            callback(new Error("用户手机号已添加，请重新输入"));
          }
        });
      } else {
        callback();
      }
    };
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      delDeviceList: [],
      // 弹出层标题
      title: "",
      safeTitle: "",
      // 是否显示弹出层
      open: false,
      safeOpen: false,
      // 日期范围
      dateRange: [],
      // 部门树选项
      deptOptions: [],
      // 状态数据字典
      statusOptions: [],
      /* 查询所有的安全警告*/
      allSafe: [],
      checkedSafe: [],
      // 菜单列表
      menuOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        deviceCode: undefined,
        deptId: undefined,
        deviceType: undefined,
        // flag:'1'
      },
	  deviceTypeOptions: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      // 表单校验
      rules: {
        username: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
          // { required: true,validator: checkReceiverPhone, trigger: 'blur'}
        ],
      },
      receiverId: "",
    };
  },
  created() {
    this.getTreeselect();
    this.getList();
    this.getDicts("sys_normal_disable").then((response) => {
      this.statusOptions = response;
    });
    this.getDicts("sys_device_type").then((response) => {
      this.deviceTypeOptions = response;
    });
  },
  methods: {
    /* handleSafe(row){
			  this.allSafe = []
			  this.checkedSafe = []
			  let that = this
			  this.receiverId = row.receiverId
			  getSafeByReceiver(row.receiverId).then(res => {
			      this.safeOpen = true
			      if(res){
			        res.forEach((val,index) =>{
			        	that.allSafe.push({
			        	    key: val.typeId,
			        	    label: val.type,
			        	    disabled: false
			        	  });

			        	if(val.tag == "0" ){
			        	  that.checkedSafe.push(val.typeId)
			        	}
			        })
			      }

			    })
			}, */
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = traverseTreeArray(response, "deptName", "deptId");
      });
    },
    //分配安全警告给接收者
    /* checkSafes() {

				// 先生成传递数据
				let vdata = []
				this.allSafe.forEach((val1, index1) => {
					vdata.push({
						typeId: val1.key,
						tag: '1',
						receiverId: this.receiverId
					})
				})
				//判断选择的小区

				for (let i = 0; i < this.checkedSafe.length; i++) {
					for (let j = 0; j < vdata.length; j++) {
						if (this.checkedSafe[i] == vdata[j].typeId) {
							vdata[j].tag = '0'
							break
						}
					}
				}

				addSafeByReceiver(vdata).then(res => {
					this.safeOpen = false
					if (res.code === 10000) {
						this.msgSuccess("分配成功");
					} else {
						this.msgError(res.msg);
					}
				})
			}, */
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      deleteSmokeDetectorList(
        this.addDateRange(this.queryParams, this.dateRange)
      ).then((response) => {
        this.delDeviceList = response.queryResult.list;
        this.total = response.queryResult.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        username: undefined,
        phone: undefined,
        status: "0",
        params: {},
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    /* handleSelectionChange(selection) {
				this.ids = selection.map(item => item.receiverId)
				this.single = selection.length != 1
				this.multiple = !selection.length
			}, */
    /** 恢复按钮操作 */
    handleUpdate(row) {
      let that = this;
      this.$confirm("是否确认恢复?", "温馨提示", {
        customClass: "deleteBox",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          let formData = JSON.parse(JSON.stringify(row));
          formData.flag = "0";
          updateSmokeDevice(formData).then((response) => {
            if (response.code == 10000) {
              that.msgSuccess("恢复成功");
              that.getList();
            } else {
              that.msgError(response.msg);
            }
          });
        })
        .catch(function () {});
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      if (row.etectorId) {
        var etectorIds = [row.etectorId];
      } else {
        var etectorIds = this.ids;
      }
      this.$confirm(
        '是否确认删除用户编号为"' + etectorIds + '"的数据项?',
        "警告",
        {
          customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return delSmokeDevice(etectorIds[0]);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
  },
};
</script>
