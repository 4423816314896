import request from '@/utils/request'

// 查询设备信息列表
export function listSmokeDevice(data) {
    return request({
        url: '/monitor/device/selectSmokeDetectorList',
        method: 'post',
        data: data
    })
}

// 查询设备信息详细
export function getSmokeDevice(etectorId) {
    return request({
        url: '/monitor/device/selectSmokeDetectorById?etectorId=' + etectorId,
        method: 'post'
    })
}

// 新增设备信息
export function addSmokeDevice(data) {
    return request({
        url: '/monitor/device/insertSmokeDetector',
        method: 'post',
        data: data
    })
}

// 修改设备信息
export function updateSmokeDevice(data) {
    return request({
        url: '/monitor/device/updateSmokeDetector',
        method: 'post',
        data: data
    })
}


// 删除设备信息
export function delSmokeDevice(etectorId) {
    return request({
        url: 'monitor/device/deleteSmokeDetectorById?etectorId=' + etectorId,
        method: 'post'
    })
}

// 导出设备信息
export function exportSmokeDevice(data) {
    return request({
        url: '/monitor/deviceExcel/outSmokeDetectorExcel',
        method: 'post',
        'responseType': 'blob',
        data: data
    })
}

// 导入设备信息
export function importSmokeDevice() {
    return process.env.VUE_APP_BASE_URL_COUNTY + '/monitor/device/addSmokeDetectors'
}


// 逻辑删除烟感信息
export function deleteSmokeDetectorList(data) {
    return request({
        url: '/monitor/device/deleteSmokeDetectorList',
        method: 'post',
        data: data
    })
}

// 批量出库
export function bantchOut(data) {
    return request({
        url: '/monitor/device/updateSmokeDetectors',
        method: 'post',
        data: data
    })
}