<template>
	<div class="tabButton">
		<ul>
			<li v-for="(item,index) in tabList" :key="item.id" :class="{ 
				 tabIndexFirst:index == 0,
				 activeTabIndexFirst:nowTab == index && index == 0,
				 tabIndexSecond:index%2 == 1 && index !== 0,
				  activeTabIndexSecond:index%2 == 1 && index !== 0 && nowTab == index,
				  tabIndexThird:index%2 == 0 && index !== 0,
				   activeTabIndexThird:index%2 == 0 && index !== 0 && nowTab == index
				 }"			 
				 >
				<div class="tabTextBox" :class="{ secondText:index !== 0 }" @click="tabClick(item,index)">
					{{ item.name }}
				</div>
			</li>
		</ul>
		
	</div>
</template>

<script>

	export default {
		name:'tabButton',
		props:{
			tabList:{
				require:true,
				default:[]			
			}
		},
		data(){
			return {
				nowTab:0
			}
		},
		methods:{
			tabClick(val,index){
				this.nowTab = index
				this.$emit('getTabIndex',val)
			}
		}

	}
</script>

<style scoped lang="scss">
	.tabButton{
		li{
			width: 235px;
			height: 60px;
			display: inline-block;
			*display: inline;
			*zoom: 1;
			margin-left: -30px;
			&:nth-child(1){
				margin-left: 0;
				width: 205px;
				z-index: 1000000000000;
			}
		}
		.tabIndexFirst{
			background-image: url('../assets/image/tabIndex1.png');
			background-size: 100% 100%;
		}
		.activeTabIndexFirst{
			background-image: url('../assets/image/activeTabIndex1.png');
			background-size: 100% 100%;
		}
		.tabIndexSecond{
			background-image: url('../assets/image/tabIndex2.png');
			background-size: 100% 100%;
		}
		.activeTabIndexSecond{
			background-image: url('../assets/image/activeTabIndex2.png');
			background-size: 100% 100%;
		}
		.tabIndexThird{
			background-image: url('../assets/image/tabIndex3.png');
			background-size: 100% 100%;
		}
		.activeTabIndexThird{
			background-image: url('../assets/image/activeTabIndex3.png');
			background-size: 100% 100%;
		}
		.tabTextBox{
			width: 175px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			cursor: pointer;
		}
		.secondText{

			margin-left: 30px;
		}
	}
</style>
