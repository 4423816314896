import request from '@/utils/request'

// 查询字典数据列表
export function listData(page,size,data) {
  return request({
    url: 'system/dictData/selectDictDataList?page='+page+'&size='+size,
    method: 'post',
    data: data
  })
}

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: 'system/dictData/selectDictDataById?dictCode=' + dictCode,
    method: 'post'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: 'system/dictData/selectDictDataType?dictType=' + dictType,
    method: 'post'
  })
}

// 新增字典数据
export function addData(data) {
  return request({
    url: '/system/dictData/insertDictData',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/system/dictData/updateDictData',
    method: 'post',
    data: data
  })
}

// 删除字典数据
export function delData(data) {
  return request({
    url: 'system/dictData/deleteDictDataByIds',
    method: 'post',
    data:data
  })
}

// 导出字典数据
export function exportData(query) {
  return request({
    url: '/system/dict/data/export',
    method: 'get',
    params: query
  })
}
