<template>
	<div class="el-box" :style="{
			width:width,
			height:height,
			boxShadow:shadowColors,
			background:backColor,
			color:color
		}">
		<!-- <div class="boxTop"></div>
		<div class="boxBottom"></div>
		<div class="boxLeft"></div>
		<div class="boxRight"></div> -->
			<slot />			
	</div>
</template>

<script>

	export default {
		name:'el-back',
		props:{
			width:{
				default:'114px'
			},
			height:{
				default:'78px'
			},
			color:{
				default:'#E1E3F0'
			},
			shadowColor:{
				default:'rgba(100, 192, 205, 0.74)'
			},
			backColor:{
				default:''
			}
		},
		computed:{
			shadowColors(){
				return '0px 0px 8px 0px ' + this.shadowColor + ' inset'
			}
		},
		data(){
			return {
				
			}
		},

	}
</script>

<style scoped lang="scss">
	.el-box{
		box-shadow: 0px 0px 8px 0px rgba(100, 192, 205, 0.74) inset;
		border-radius: 6px;
		position: relative;
		padding: 15px;
		font-size: 14px;

		.boxTop{
			width: 80%;					   
			background-image: linear-gradient(90deg,#0A1840,#44d7fc,#0A1840);
			height: 1px;
			position: absolute;
			top: 0;
			left: 10%;
		}
		.boxBottom{
			width: 80%;					   
			background-image: linear-gradient(90deg,#0A1840,#44d7fc,#0A1840);
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 10%;
		}
		.boxLeft{
			width: 1px;					   
			background-image: linear-gradient(0deg,#0A1840,#44d7fc,#0A1840);
			height: 80%;
			position: absolute;
			top: 10%;
			left: 0;
		}
		.boxRight{
			width: 1px;					   
			background-image: linear-gradient(0deg,#0A1840,#44d7fc,#0A1840);
			height: 80%;
			position: absolute;
			top: 10%;
			right: 0;
		}
	}
</style>
