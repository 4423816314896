<template>
  <div class="components-container">
    <el-back>
      <tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
      <div class="formBox">信息搜索</div>
      <el-form :model="queryParams" ref="queryForm" :inline="true">
        <el-form-item label="设备状态" prop="type">
          <el-select
            v-model="queryParams.type"
            placeholder="请选择设备状态"
            clearable
            size="small"
            style="width: 240px"
          >
            <el-option
              v-for="dict in statusOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-hasPermi="['system:secret:add']"
            >新增</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-hasPermi="['system:secret:edit']"
            >修改</el-button
          >
        </el-col>
        <!-- <el-col :span="1.5">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['system:secret:remove']"
            >删除</el-button
          >
        </el-col> -->
      </el-row>
      <el-table
        v-loading="loading"
        :data="alarmList"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
				<el-table-column
          label="appKey"
          prop="appKey"
          align="center"
          :show-overflow-tooltip="true"
        />
				<el-table-column
          label="appSecret"
          prop="appSecret"
          align="center"
          :show-overflow-tooltip="true"
        />
				<el-table-column
          label="masterKey"
          prop="masterKey"
          align="center"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          label="平台类型"
          prop="type"
          align="center"
          :show-overflow-tooltip="true"
          :formatter="formatterType"
        />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="180"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-hasPermi="['system:secret:edit']"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-hasPermi="['system:secret:remove']"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
      />
      <!-- 添加或修改角色配置对话框 -->
      <el-dialog :title="title" :visible.sync="open" width="500px">
        <el-form ref="form" :model="form" label-width="90px">
          <el-form-item label="报警类型" prop="deviceType">
            <el-select
              v-model="form.deviceType"
              placeholder="请选择报警类型"
              clearable
              size="small"
              style="width: 240px"
            >
              <el-option
                v-for="dict in tabList"
                :key="dict.id"
                :label="dict.name"
                :value="dict.deviceType"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="预警状态">
            <el-radio-group v-model="form.type">
              <el-radio
                v-for="dict in statusOptions"
                :key="dict.dictValue"
                :label="dict.dictValue"
                >{{ dict.dictLabel }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="appKey" prop="appKey">
            <el-input v-model="form.appKey" placeholder="请输入appKey" />
          </el-form-item>
          <el-form-item label="appSecret" prop="appSecret">
            <el-input v-model="form.appSecret" placeholder="请输入appSecret" />
          </el-form-item>
          <el-form-item label="masterKey" prop="masterKey">
            <el-input v-model="form.masterKey" placeholder="请输入masterKey" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </el-back>
  </div>
</template>

<script>
import {
  ProductKeyList,
  addProductKey,
  updateProductKey,
  delProductKey
} from "@/api/system/appKeySecret.js";

export default {
  name: "receiver",
  data() {
    return {
      tabList: [
        {
          id: 0,
          name: undefined,
          deviceType: undefined
        }
      ],
      tabVal: 0,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 表格数据
      alarmList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      deptName: undefined,
      // 状态数据字典
      statusOptions: [
        { dictLabel: "中国移动", dictValue: '0' },
        { dictLabel: "中国联通", dictValue: '1' },
        { dictLabel: "中国电信", dictValue: '2' }
      ],
      
      // 菜单列表
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
				type: undefined,
        deviceType: undefined
      },
      // 表单参数
      form: {}
      // 表单校验
      /* rules: {
			  roleName: [
				{ required: true, message: "角色名称不能为空", trigger: "blur" },
				{ required: true,validator: checkRoleNames, trigger: 'blur'}
			  ],
			  roleKey: [
				{ required: true, message: "权限字符不能为空", trigger: "blur" },
				{ required: true,validator: checkRoleKeys, trigger: 'blur'}
		  
			  ],
			  roleSort: [
				{ required: true, message: "角色顺序不能为空", trigger: "blur" }
			  ]
			} */
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_device_type").then(response => {
      response.forEach((val, index) => {
        val.id = val.index;
        val.name = val.dictLabel;
        val.deviceType = val.dictValue;
      });
      this.tabList = response;
    });
  },
  methods: {
    getTabIndex(val) {
      this.tabVal = val.deviceType;
      this.getList();
    },
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      let queryData = JSON.parse(JSON.stringify(this.queryParams));
      queryData.deviceType = this.tabVal;
      ProductKeyList(queryData).then(response => {
        this.alarmList = response.queryResult.list;
        this.total = response.queryResult.total;
        this.loading = false;
      });
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === "0" ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.alarmType + '"?', "警告", {
        customClass: "deleteBox",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(function() {
          let form = {
            type: row.type,
            deviceType: row.deviceType,
            appKey: row.appKey,
            appSecret: row.appSecret,
            masterKey: row.masterKey
          };
          return updateProductKey(form);
        })
        .then(() => {
          this.msgSuccess(text + "成功");
        })
        .catch(function() {
          row.status = row.status === "0" ? "1" : "0";
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset() {
      this.form = {
        deviceType: undefined,
        type: undefined,
        appKey: undefined,
        appSecret: undefined,
        masterKey: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      /* let ad = selection.some(value => value.roleKey == 'admin')
			if(ad){
			  this.single = true;
			  this.multiple = true;
			}else{ */
      this.single = selection.length != 1;
      this.multiple = !selection.length;
      // }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      // this.getMenuTreeselect();
      this.open = true;
      this.title = "添加预警种类";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      /* this.$nextTick(() => {
			  this.getRoleMenuTreeselect(roleId);
			}); */
      // getAlarmById(alarmId).then(response => {
      //   this.form = response;
      //   this.open = true;
      //   this.title = "修改预警种类";
      // });
      this.form = row;
      this.open = true;
      this.title = "修改预警种类";
    },

    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            // this.form.menuIds = this.getMenuAllCheckedKeys();
            let formData = JSON.parse(JSON.stringify(this.form));
            formData.deviceType = this.tabVal;
            updateProductKey(formData).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            // this.form.menuIds = this.getMenuAllCheckedKeys();
            let formData = JSON.parse(JSON.stringify(this.form));
            formData.deviceType = this.tabVal;
            addProductKey(formData).then(response => {
              if (response.code === 10000) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      console.log('wowww',row);
      const typeItem = this.statusOptions.filter(ie => {
        return ie.dictValue == row.type;
      });
      console.log('typeItem',typeItem)
      const deviceTypeItem = this.tabList.filter(ie => {
        return ie.deviceType == row.deviceType;
      });
      console.log('deviceTypeItem',deviceTypeItem)

      const { dictLabel } = typeItem[0];
      const deviceType = deviceTypeItem[0]?.dictLabel;
      const label =  `是否确认删除报警类型为:${deviceType},设备类型:${dictLabel}的数据项`
      const alarmIds = row.id || this.ids;
      this.$confirm(
        label,
        "警告",
        {
          customClass: "deleteBox",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(function() {
          return delProductKey(alarmIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function() {});
    },
    // formatValue
    formatValue(val) {
      switch (Number(val)) {
        case 0:
          return "否";
        case 1:
          return "是";
        default:
          return "-";
      }
    },
    formatterType(row) {
      switch (row.type) {
        case '0':
          return '移动'
          break;
        case '1':
          return '联通'
          break;
        case '2':
          return '电信'
          break;
        default:
          return '-'
          break;
      }
    }
  }
};
</script>
