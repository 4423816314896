<template>
    <div class="components-container">
        <el-row :gutter="20">
            <el-col :span="24" :xs="24">
                <el-back>
                    <div class="formBox">信息搜索</div>
                    <el-form
                        :model="queryParams"
                        ref="queryForm"
                        :inline="true"
                        label-width="88px"
                    >
                        <el-form-item label="责任人" prop="username">
                            <el-input
                                v-model="queryParams.username"
                                placeholder="请输入责任人"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="责任人电话" prop="phone">
                            <el-input
                                v-model="queryParams.phone"
                                placeholder="请输入责任人电话"
                                clearable
                                size="small"
                                style="width: 240px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="接收状态" prop="status">
                            <el-select
                                v-model="queryParams.status"
                                placeholder="接收状态"
                                clearable
                                size="small"
                                style="width: 240px"
                            >
                                <el-option
                                    v-for="dict in statusOptions"
                                    :key="dict.dictValue"
                                    :label="dict.dictLabel"
                                    :value="dict.dictValue"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                size="mini"
                                @click="handleQuery"
                                >搜索</el-button
                            >
                            <el-button
                                icon="el-icon-refresh"
                                size="mini"
                                @click="resetQuery"
                                >重置</el-button
                            >
                        </el-form-item>
                    </el-form>

                    <el-row :gutter="10" class="mb8">
                        <el-col :span="1.5">
                            <el-button
                                type="primary"
                                icon="el-icon-plus"
                                size="mini"
                                @click="handleAdd"
                                v-hasPermi="['smoke:device:add']"
                                >新增</el-button
                            >
                        </el-col>
                    </el-row>
                    <el-table v-loading="loading" :data="dutyList">
                        <el-table-column
                            label="编号"
                            align="center"
                            type="index"
                        />
                        <el-table-column
                            label="责任人"
                            align="center"
                            prop="username"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="责任人电话"
                            align="center"
                            prop="phone"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column label="责任设备" align="center">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    @click="handleDuty(scope.row)"
                                    >点击查看</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="接收状态"
                            align="center"
                            prop="status"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="用户操作"
                            align="center"
                            width="180"
                            class-name="small-padding fixed-width"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="handleUpdate(scope.row)"
                                    >修改</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-delete"
                                    @click="handleDelete(scope.row)"
                                    >删除</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="handlEallot(scope.row)"
                                    >分配设备</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination
                        v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.page"
                        :limit.sync="queryParams.size"
                        @pagination="getList"
                    />
                </el-back>
            </el-col>
        </el-row>

        <el-dialog :title="title" :visible.sync="open" width="600px">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="150px"
            >
                <el-form-item label="责任人" prop="username">
                    <el-input
                        v-model="form.username"
                        placeholder="请输入责任人"
                    />
                </el-form-item>
                <el-form-item label="责任人手机号" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入手机号" />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio
                            v-for="dict in statusOptions"
                            :key="dict.dictValue"
                            :label="dict.dictValue"
                            >{{ dict.dictLabel }}</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="infoTitle" :visible.sync="infoOpen" width="850px">
            <el-table
                v-loading="infoLoading"
                :data="recordList"
                style="margin-top: 20px;"
            >
                <el-table-column label="编号" align="center" type="index" />
                <el-table-column
                    label="设备类别"
                    align="center"
                    prop="deviceType"
                    :show-overflow-tooltip="true"
                />
                <el-table-column
                    label="设备编号"
                    align="center"
                    prop="deviceCode"
                    :show-overflow-tooltip="true"
                />
                <el-table-column
                    label="设备名称"
                    align="center"
                    prop="deviceName"
                    :show-overflow-tooltip="true"
                />
                <el-table-column
                    label="建筑楼层"
                    align="center"
                    prop="roomAddress"
                    :show-overflow-tooltip="true"
                />
            </el-table>
        </el-dialog>

        <el-dialog
            :title="eallotTitle"
            :visible.sync="eallotOpen"
            width="1200px"
        >
            <el-row>
                <el-col :span="20" style="padding-right: 10px;">
                    <el-form
                        ref="eallotForm"
                        :model="eallotForm"
                        label-width="70px"
                        :inline="true"
                    >
                        <el-row>
                            <el-col :span="8">
                                <el-form-item
                                    label="设备名称"
                                    prop="deviceName"
                                >
                                    <el-input
                                        v-model="eallotForm.deviceName"
                                        size="small"
                                        style="width: 200px"
                                        placeholder="请输入设备名称"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="设备类型"
                                    prop="deviceType"
                                >
                                    <el-select
                                        v-model="eallotForm.deviceType"
                                        placeholder="设备类型"
                                        clearable
                                        size="small"
                                        style="width: 200px"
                                    >
                                        <el-option
                                            v-for="dict in deviceOptions"
                                            :key="dict.dictValue"
                                            :label="dict.dictLabel"
                                            :value="dict.dictValue"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="楼栋" prop="buildId">
                                    <el-select
                                        v-model="eallotForm.buildId"
                                        placeholder="请输入所属楼栋"
                                        clearable
                                        size="small"
                                        style="width: 200px"
                                        @change="buildChange()"
                                    >
                                        <el-option
                                            v-for="dict in buildingOptions"
                                            :key="dict.buildId"
                                            :label="dict.buildName"
                                            :value="dict.buildId"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="单元" prop="unitId">
                                    <el-select
                                        v-model="eallotForm.unitId"
                                        placeholder="请输入所属单元"
                                        clearable
                                        style="width: 200px"
                                        size="small"
                                        @change="unitChange()"
                                    >
                                        <el-option
                                            v-for="dict in unitOptions"
                                            :key="dict.unitId"
                                            :label="dict.unitName"
                                            :value="dict.unitId"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="层数" prop="tier">
                                    <el-select
                                        v-model="eallotForm.tier"
                                        placeholder="请输入所属层数"
                                        clearable
                                        style="width: 200px"
                                        size="small"
                                        @change="tierChange()"
                                    >
                                        <el-option
                                            v-for="dict in tierOptions"
                                            :key="dict"
                                            :label="dict"
                                            :value="dict"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="房间" prop="roomId">
                                    <el-select
                                        v-model="eallotForm.roomId"
                                        placeholder="请输入所属房间"
                                        clearable
                                        size="small"
                                        style="width: 200px"
                                    >
                                        <el-option
                                            v-for="dict in roomOptions"
                                            :key="dict.roomId"
                                            :label="dict.roomNum"
                                            :value="dict.roomId"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item>
                                    <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        style="margin-left: 10px;"
                                        size="mini"
                                        @click="eallotHandleQuery"
                                        >搜索</el-button
                                    >
                                    <el-button
                                        icon="el-icon-refresh"
                                        size="mini"
                                        @click="eallotResetQuery"
                                        >重置</el-button
                                    >
                                </el-form-item>
                                <el-form-item style="margin-bottom:0">
                                    <el-button
                                        @click="addEallot"
                                        type="primary"
                                        size="mini"
                                        :disabled="!EallotData.length"
                                        >加入</el-button
                                    >
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-table
                        ref="eallotTable"
                        v-loading="eallotLoading"
                        :data="leftList"
                        @selection-change="handleEallotChange"
                    >
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                            :selectable="checkSelectable"
                        />
                        <el-table-column
                            label="设备类别"
                            prop="deviceType"
                            align="center"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="产品ID"
                            prop="deviceCode"
                            align="center"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="设备名称"
                            prop="deviceName"
                            align="center"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            label="建筑楼层"
                            prop="roomAddress"
                            align="center"
                            :show-overflow-tooltip="true"
                        />
                    </el-table>
                    <Pagination
                        v-show="EallotTotal > 0"
                        :total="EallotTotal"
                        :page.sync="eallotForm.page"
                        :limit.sync="eallotForm.size"
                        @pagination="getSelectedList"
                    />
                </el-col>
                <el-col :span="4">
                    <el-card
                        class="box-card"
                        shadow="never"
                        :body-style="{ height: '580px' }"
                    >
                        <div slot="header" class="clearfix">
                            <span>已选设备</span>
                        </div>
                        <div v-for="item in rightList" :key="item.deviceCode">
                            {{ item.deviceName }}
                            <i
                                class="el-icon-circle-close"
                                style="color: #39f6ff;float: right;"
                                @click="removeRightValue(item)"
                            ></i>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <div
                slot="footer"
                class="dialog-footer"
                style="text-align: center;"
            >
                <el-button type="primary" @click="eallotSubmitForm"
                    >保 存</el-button
                >
                <el-button @click="eallotCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getReceiverList,
        insertReceiver,
        updateReceiver,
        selectReceiverById,
        receiveDevicePageList,
        delReceiverById,
        updBatchNotRecDev,
        localDeptRecDev
    } from '@/api/smoke/duty.js';
    import { getUserSession } from '@/utils/auth';
    import qs from 'qs';
    import { listBuildingsData } from '@/api/system/build/index';
    import { listUnitData } from '@/api/system/build/unit';
    import { roomNoPageList } from '@/api/system/build/room';
    export default {
        name: 'duty',
        data() {
            return {
                // 遮罩层
                loading: true,
                eallotLoading: true,
                // 总条数
                total: 0,
                EallotTotal: 0,
                // 表格数据
                dutyList: [],
                leftList: [],
                rightList: [],
                EallotData: [],
                // 弹出层标题
                title: '',
                infoTitle: '',
                eallotTitle: '',
                // 是否显示弹出层
                open: false,
                infoOpen: false,
                eallotOpen: false,
                // 状态数据字典
                statusOptions: [],
                deviceOptions: [],
                buildOptions: [],
                unitOptions: [],
                roomOptions: [],
                // 表单参数
                form: {},
                eallotForm: {
                    page: 1,
                    size: 10,
                    deviceType: undefined,
                    deviceName: undefined,
                    buildId: undefined,
                    unitId: undefined,
                    roomId: undefined,
                    tier: undefined
                },
                receiverId: undefined,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    deptId: undefined,
                    username: undefined,
                    phone: undefined,
                    status: undefined
                },
                // 表单校验
                rules: {
                    /* username: [
          { required: true, message: "责任人不能为空", trigger: "blur" }
        ],
		phone: [
		  { required: true, message: "责任人电话不能为空", trigger: "blur" }
		],
		status: [
		  { required: true, message: "状态必选", trigger: "blur" }
		], */
                },
                /* 弹窗详情*/
                infoLoading: true,
                recordList: [],
                /* 分配搜索条件参数*/
                buildingOptions: [],
                unitOptions: [],
                tierOptions: [],
                roomOptions: []
            };
        },
        created() {
            this.getList();
            this.getDicts('notice_receiver_status').then((response) => {
                this.statusOptions = response;
            });
            this.getDicts('sys_device_type').then((response) => {
                this.deviceOptions = response;
            });
        },
        methods: {
            /** 查询楼栋 */
            getBuild() {
                listBuildingsData().then((response) => {
                    this.buildingOptions = response;
                });
            },
            getRoom() {
                let room = {
                    buildId: this.eallotForm.buildId,
                    unitId: this.eallotForm.unitId,
                    tier: this.eallotForm.tier
                };
                roomNoPageList(room).then((response) => {
                    this.roomOptions = response;
                });
            },
            buildChange() {
                this.eallotForm.unitId = undefined;
                this.eallotForm.tier = undefined;
                this.eallotForm.roomId = undefined;
                this.unitOptions = [];
                this.tierOptions = [];
                this.roomOptions = [];
                let unitData = { buildId: this.eallotForm.buildId };
                //获取所有的单元
                listUnitData(unitData).then((res) => {
                    this.unitOptions = res;
                });
            },
            unitChange() {
                let tier = undefined;
                this.eallotForm.tier = undefined;
                this.eallotForm.roomId = undefined;
                this.tierOptions = [];
                this.roomOptions = [];

                this.unitOptions.forEach((item, index) => {
                    if (item.unitId == this.eallotForm.unitId) {
                        tier = item.tier;
                    }
                });

                for (let i = 1; i <= parseInt(tier); i++) {
                    this.tierOptions.push(i);
                }
            },
            tierChange() {
                this.eallotForm.roomId = undefined;
                // this.roomOptions = []
                this.getRoom();
            },
            //判断选择框是否能被选择
            checkSelectable(row) {
                return !row.checkFlag;
            },
            /** 查询弹出框列表 */
            getSelectedList(val) {
                this.eallotLoading = true;
                localDeptRecDev(this.eallotForm).then((res) => {
                    this.leftList = res.queryResult.list;
                    this.EallotTotal = res.queryResult.total;
                    this.eallotLoading = false;
                    //判断切换分页之后是否能选择数据
                    if (this.rightList.length !== 0) {
                        for (let i = 0; i < this.rightList.length; i++) {
                            for (let j = 0; j < this.leftList.length; j++) {
                                if (
                                    this.rightList[i] &&
                                    this.leftList[j] &&
                                    this.rightList[i].deviceCode ===
                                        this.leftList[j].deviceCode
                                ) {
                                    this.$set(
                                        this.leftList[j],
                                        'checkFlag',
                                        true
                                    );
                                    this.$nextTick(() => {
                                        this.$refs[
                                            'eallotTable'
                                        ].toggleRowSelection(this.leftList[j]);
                                    });
                                    break;
                                }
                            }
                        }
                    }
                });
            },
            // 将左边表格选择项存入EallotData中
            handleEallotChange(rows) {
                this.EallotData = [];
                if (rows) {
                    rows.forEach((row) => {
                        if (row && !row.checkFlag) {
                            this.EallotData.push(row);
                        }
                    });
                }
            },
            // 左边表格选择项移到右边
            addEallot() {
                this.EallotData.forEach((item, index) => {
                    this.rightList.push(item);
                    for (let i = 0; i < this.leftList.length; i++) {
                        if (item.deviceCode == this.leftList[i].deviceCode) {
                            this.$set(this.leftList[i], 'checkFlag', true);
                            // this.leftList[i].checkFlag = false
                            break;
                        }
                    }
                    //每次点击之后初始化EallotData
                    if (index == this.EallotData.length - 1)
                        this.EallotData = [];
                });
            },
            //删除右边数据
            removeRightValue(item) {
                //删除
                for (let i = 0; i < this.rightList.length; i++) {
                    if (this.rightList[i].deviceCode == item.deviceCode) {
                        this.rightList.splice(i, 1);
                        break;
                    }
                }
                //左边选择数据处理
                for (let j = 0; j < this.leftList.length; j++) {
                    if (this.leftList[j].deviceCode === item.deviceCode) {
                        this.$set(this.leftList[j], 'checkFlag', undefined);
                        this.$nextTick(() => {
                            this.$refs['eallotTable'].clearSelection(item);
                        });
                        break;
                    }
                }
            },
            /** 查询用户列表 */
            getList() {
                // 获取当前用户jwt信息
                /* let userInfo = JSON.parse(getUserSession('iffpJwtDecodeVal'))
		this.queryParams.deptId = userInfo.deptId */
                getReceiverList(this.queryParams).then((res) => {
                    this.dutyList = res.queryResult.list;
                    this.total = res.queryResult.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            eallotCancel() {
                this.eallotOpen = false;
                this.resetForm('eallotForm');
            },
            // 表单重置
            reset() {
                this.form = {
                    username: undefined,
                    phone: undefined,
                    status: undefined,
                    deviceName: undefined,
                    roomAddress: undefined,
                    unitName: undefined,
                    room: undefined
                };
                this.resetForm('form');
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            eallotHandleQuery() {
                this.eallotForm.page = 1;
                this.getSelectedList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm('queryForm');
                this.handleQuery();
            },
            eallotResetQuery() {
                this.resetForm('eallotForm');
                this.eallotHandleQuery();
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = '添加责任人';
            },
            //点击查看
            handleDuty(row) {
                this.recordList = [];
                this.infoOpen = true;
                receiveDevicePageList(row.receiverId).then((response) => {
                    this.recordList = response.queryResult.list;
                    this.rightList = this.recordList;
                    this.infoLoading = false;
                });
            },
            //分配设备
            handlEallot(row) {
                this.receiverId = row.receiverId;
                this.rightList = [];
                this.eallotOpen = true;

                /* 搜索条件初始化 */
                this.getBuild();
                this.resetForm('eallotForm');
                this.unitOptions = [];
                this.tierOptions = [];
                this.roomOptions = [];

                receiveDevicePageList(row.receiverId).then((response) => {
                    this.rightList = response.queryResult.list;
                    this.getSelectedList(row.receiverId);
                });
                this.eallotTitle = '分配设备';
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                selectReceiverById(row.receiverId).then((response) => {
                    this.form = response;
                    this.open = true;
                    this.title = '修改责任人';
                });
            },
            /** 提交按钮 */
            eallotSubmitForm() {
                let formData = [];
                this.rightList.forEach((item, index) => {
                    formData.push({
                        deviceCode: item.deviceCode,
                        receiverId: this.receiverId
                    });
                });
                let dataEallot =
                    this.rightList.length == 0
                        ? JSON.stringify([{ receiverId: this.receiverId }])
                        : JSON.stringify(formData);

                // let dataEallot = qs.stringify({list: JSON.stringify(formData)},{arrayFormat: 'brackets'})
                updBatchNotRecDev(dataEallot).then((res) => {
                    this.eallotOpen = false;
                    if (res.code === 10000) {
                        this.msgSuccess('保存成功');
                        this.getList();
                    } else {
                        this.msgError(res.msg);
                    }
                });
            },
            submitForm: function() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.receiverId != undefined) {
                            updateReceiver(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('修改成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        } else {
                            insertReceiver(this.form).then((response) => {
                                if (response.code === 10000) {
                                    this.msgSuccess('新增成功');
                                    this.open = false;
                                    this.getList();
                                } else {
                                    this.msgError(response.msg);
                                }
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                this.$confirm(
                    '是否确认删除编号为"' + row.receiverId + '"的数据项?',
                    '警告',
                    {
                        customClass: 'deleteBox',
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )
                    .then(() => {
                        return delReceiverById(row.receiverId);
                    })
                    .then(() => {
                        this.getList();
                        this.msgSuccess('删除成功');
                    })
                    .catch(function() {});
            }
        }
    };
</script>

<style>
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #1890ff;
        border-color: #1890ff;
    }
</style>
