<template>
  <div class="components-container">
	  <el-row :gutter="20">
	    <el-col :span="24" :xs="24">
			<el-back>
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="88px">
				  <el-form-item label="设备种类" prop="deviceType">
					<el-select
					  v-model="queryParams.deviceType"
					  placeholder="设备种类"
					  clearable
					  size="small"
					>
					  <el-option
						v-for="dict in deviceOptions"
						:key="dict.dictValue"
						:label="dict.dictLabel"
						:value="dict.dictValue"
					  />
					</el-select>
				  </el-form-item>
				  <el-form-item label="产品ID" prop="deviceCode">
					<el-input
					  v-model="queryParams.deviceCode"
					  placeholder="产品ID"
					  clearable
					  size="small"
					  @keyup.enter.native="handleQuery"
					/>
				  </el-form-item>
				  <el-form-item label="设备名称" prop="deviceName">
					<el-input
					  v-model="queryParams.deviceName"
					  placeholder="请输入设备名称"
					  clearable
					  size="small"
					  @keyup.enter.native="handleQuery"
					/>
				  </el-form-item>
				  <el-form-item label="设备使用人">
				  	<el-select 
						v-model="queryParams.userId" 
						placeholder="请选择"
						size="small"
						>
				  		<el-option 
						v-for="dict in DeviceUseOptions" 
						:key="dict.userId" 
						:label="dict.nickname" 
						:value="dict.userId">
						</el-option>
				  	</el-select>
				  </el-form-item>
				  <el-form-item label="缴费时间">
					<el-date-picker
					  v-model="dateRange"
					  size="small"
					  style="width: 240px"
					  value-format="yyyy-MM-dd"
					  type="daterange"
					  range-separator="-"
					  start-placeholder="开始日期"
					  end-placeholder="结束日期"
					></el-date-picker>
				  </el-form-item>
				  <el-form-item label="支付途径" prop="pay">
					<el-select
					  v-model="queryParams.pay"
					  placeholder="请选择支付途径"
					  clearable
					  size="small"
					>
					  <el-option
						v-for="dict in payOptions"
						:key="dict.dictValue"
						:label="dict.dictLabel"
						:value="dict.dictValue"
					  />
					</el-select>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
				  </el-form-item>
				</el-form>

				<el-row :gutter="10" class="mb8">
				 <el-col :span="1.5">
					<el-button
					  icon="el-icon-download"
					  size="mini"
					  @click="handleExport"
					  type="warning"
					>导出报表</el-button>
				  </el-col>
				</el-row>

				<el-table v-loading="loading" :data="chargeList">
				  <el-table-column label="编号" type="index" width="55" align="center" />
				  <el-table-column label="设备种类" align="center" prop="typeName" :show-overflow-tooltip="true" />
				  <el-table-column label="设备名称" align="center" prop="deviceName" :show-overflow-tooltip="true" />
				  <el-table-column label="产品ID" align="center" prop="deviceCode" :show-overflow-tooltip="true" />
				  <el-table-column label="设备使用人" align="center" prop="username" :show-overflow-tooltip="true" />
				  <el-table-column label="缴费时间" align="center" prop="createTime" :show-overflow-tooltip="true" />
				  <el-table-column label="缴费金额(元)" align="center" prop="cost" :show-overflow-tooltip="true" />
				  <el-table-column label="缴费时长" align="center" :formatter="validTimeFormat" prop="validTime" :show-overflow-tooltip="true" />
				  <el-table-column label="缴费后有效期限" align="center" width="180" prop="indate" />
				  <el-table-column label="支付途径" align="center" prop="way" :show-overflow-tooltip="true" />
				</el-table>

				<pagination
				  v-show="total>0"
				  :total="total"
				  :page.sync="queryParams.page"
				  :limit.sync="queryParams.size"
				  @pagination="getList"
				/>
			</el-back>
		  </el-col>
		</el-row>
  </div>
</template>

<script>
	import { selectRankPayRecordsList,outRankDeviceExcel } from "@/api/dispence/payFree.js"
	import { selectDeptRankUser } from '@/api/deviceUse.js'
	import qs from "qs"
export default {
  name: "normalDevice",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      chargeList: [],
	  //日期
	  dateRange: [],
      // 弹出层标题
      title: "",
	  titleFree: "",
      // 是否显示弹出层
      open: false,
	  openFree: false,
      // 设备种类数据字典
	  deviceOptions: [],
	  //缴费时长字典
	  validTimeOptions: [],
	  //设备使用人
	  DeviceUseOptions:[],
	  payOptions: [{dictValue: 0,dictLabel: '线上缴费'},{dictValue: 1,dictLabel: '线下缴费'}],
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
		deviceType: undefined,
		deviceCode: undefined,
		deviceName: undefined,
		pay: undefined,
		userId: undefined,
		
      },
      // 表单参数
      form: {},
	  formFree: {},
      // 表单校验
      rules: {
        pay: [
          { required: true, message: "不能为空", trigger: "blur" }
        ],
      }
    }
  },
  created() {
	  this.getDicts("sys_device_type").then(response => {
	    this.deviceOptions = response;
	  });
	  this.getDicts("sys_renew_time").then(response => {
	    this.validTimeOptions = response;
	  });
	  selectDeptRankUser().then(res =>{
	  	this.DeviceUseOptions = res;
	  });
      this.getList();
  },
  methods: {

    /** 查询列表 */
    getList() {
      this.loading = true;
      selectRankPayRecordsList(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
          this.chargeList = response.queryResult.list;
          this.total = response.queryResult.total;
          this.loading = false;
        }
      );
    },
	// 数据状态字典翻译
	validTimeFormat(row, column) {
	  return this.selectDictLabel(this.validTimeOptions, row.validTime);
	},
    // 表单重置
    reset() {
		this.dateRange = [];
        this.form = {
			typeName: undefined,
			deviceCode: undefined,
			deviceName: undefined,
			pay: undefined,
			userId: undefined,
		};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
	/** 导出按钮操作 */
	handleExport() {
	let queryData = this.queryParams
	  this.$confirm('是否确认导出所有数据项?', "警告", {
	      confirmButtonText: "确定",
	      cancelButtonText: "取消",
		  customClass: "deleteBox",
	      type: "warning"
	    }).then(function() {
		
	      return outRankDeviceExcel(queryData);
		  
	    }).then(response => {
			let blob = new Blob([response], {type: "application/vnd.ms-excel"});//type是文件类，详情可以参阅blob文件类型
			let objectUrl = URL.createObjectURL(blob);//生成下载链接
			window.location.href = objectUrl
	     
	    }).catch(function() {
	
		});
	},
  }
};
</script>
<style lang="scss" scoped>
	.el-radio{
		margin: 10px;
	}
</style>
