import request from '@/utils/request'

//查询ById
export function getSysDepFreeSetById(id) {
  return request({
    url: '/free/sysDeptFreeSet/getWebObjByDeptId?id=' + id,
    method: 'post'
  })
}

//查询当前单位下的支付配置 收银台和设备分管都是这个
export function getFreeSetByLocalDept(freeSetType) {
  return request({
    url: '/free/sysDeptFreeSet/getFreeSetByLocalDept?freeSetType=' + freeSetType,
    method: 'post'
  })
}

//修改
export function updSysDeptFreeSet(data) {
  return request({
    url: '/free/sysDeptFreeSet/updSysDeptFreeSet',
    method: 'post',
	data: data
  })
}