<template>
  <div class="components-container">		
			<el-back>
				<tab-button :tabList="tabList" @getTabIndex="getTabIndex"></tab-button>
				<alarmRecord v-if="tabVal == 1"></alarmRecord>
				<alarmEchart v-if="tabVal == 2"></alarmEchart>	

			</el-back>
  </div>
</template>

<script>

import alarmRecord from './alarmRecord'
import alarmEchart from './alarmEchart'

export default {
  name: "receiver",
  data(){
	  return {
		tabList:[
			{ id:1,name:'报警记录' },
			{ id:2,name:'统计图表' }
		],
		 tabVal:1
	  }
  },
  components:{
	  alarmRecord,
	  alarmEchart
  },
  methods:{
	  getTabIndex(val){
		  this.tabVal = val.id
	  }
  }
};
</script>
