import request from '@/utils/request'

//列表
export function selectDevicePayList(data) {
  return request({
    url: '/free/devicePay/selectDevicePayList',
    method: 'post',
    data: data
  })
}

//线下缴费
export function selectPayTypeList(data) {
  return request({
    url: '/free/pay/selectPayTypeList',
    method: 'post',
    data: data
  })
}
// 线下批量缴费
export function offlineDevicePayBatch(data) {
  return request({
    url: '/free/devicePay/offlineDevicePayBatch',
    method: 'post',
    data: data
  })
}

//赠送时长+线下缴费
export function updateDevicePay(deviceCode, month, cost, remark) {
  return request({
    url: '/free/devicePay/updateDevicePay?deviceCode=' + deviceCode + '&month=' + month + '&cost=' + cost + '&remark=' + remark,
    method: 'post'
  })
}

//导出正常设备信息
export function outNormalDeviceExcel(data) {
  return request({
    url: '/free/devicePayExcel/outNormalDeviceExcel',
    method: 'post',
    'responseType': 'blob',
    data: data
  })
}

//导出欠费设备信息
export function outArrearageDeviceExcel(data) {
  return request({
    url: '/free/devicePayExcel/outArrearageDeviceExcel',
    method: 'post',
    'responseType': 'blob',
    data: data
  })
}

// 批量缴费模板下载
export function downloadErrorExcel(templateName) {
  return request({
    url: 'free/devicePay/downloadErrorExcel?templateName=' + templateName,
    method: 'post',
    responseType: 'blob'
  })
}

//导入批量缴费模板
export function updateDevicePayBatch() {
  return process.env.VUE_APP_BASE_URL_COUNTY + 'free/devicePay/updateDevicePayBatch'
}