<template>
  <div class="components-container">
    <el-back>
      <div style="text-align: center">
        <h3>收费统计</h3>
        <el-form :inline="true">
          <el-form-item label="统计方式:">
            <el-date-picker
              v-model="queryParams.beginTime"
              type="year"
              value-format="yyyy"
              placeholder="选择年"
              @change="handleQuery"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="收款单位" prop="deptId">
            <treeselect
              v-model="queryParams.deptId"
              @input="handleQuery()"
              :options="deptOptions"
              placeholder="请选择"
              style="width: 240px"
            />
          </el-form-item>
		  <el-form-item label="设备类型" prop="deviceType">
            <el-select
              v-model="queryParams.deviceType"
              placeholder="所有设备"
              clearable
              @input="handleQuery()"
            >
              <el-option
                v-for="dict in deviceOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="EchartBigBox">
        <div class="echartBoxLeft">
          <div class="leftSmallBox">
            <el-box :width="'100%'" :height="'150px'">
              <div>
                <img
                  src="../../../assets/image/allCost.png"
                  style="float: left"
                />
                <div style="font-size: 14px; margin: 10px 0 10px 50px">
                  收费总金额
                </div>
                <div class="chargeBox">
                  <div>
                    {{ chargeCount.cost }}
                  </div>
                  <!-- 			<div v-for="(item,index) in chargeCount.cost" :key="index">
										<div style="font-size: 30px;" v-if="item == ','">,</div>
										<div class="chargeNumBox" v-else>{{ item }}</div>
									</div> -->
                  <div style="margin-left: 5px">元</div>
                </div>
              </div>
            </el-box>
          </div>
          <div class="leftSmallBox">
            <el-box :width="'100%'" :height="'150px'">
              <div>
                <img
                  src="../../../assets/image/onLineCharge.png"
                  style="float: left"
                />
                <div style="font-size: 14px; margin: 10px 0 10px 50px">
                  线上收费金额
                </div>
                <div class="chargeBox">
                  <!-- <div v-for="(item,index) in chargeCount.upCost" :key="index">
										<div style="font-size: 30px;" v-if="item == ','">,</div>
										<div class="chargeNumBox" v-else>{{ item }}</div>
									</div> -->
                  <div>
                    {{ chargeCount.upCost }}
                  </div>
                  <div style="margin-left: 5px">元</div>
                </div>
              </div>
            </el-box>
          </div>
          <div class="leftSmallBox">
            <el-box :width="'100%'" :height="'150px'">
              <div>
                <img
                  src="../../../assets/image/downCharge.png"
                  style="float: left"
                />
                <div style="font-size: 14px; margin: 10px 0 10px 50px">
                  线下收费金额
                </div>
                <div class="chargeBox">
                  <!-- <div v-for="(item,index) in chargeCount.downCost" :key="index">
										<div style="font-size: 30px;" v-if="item == ','">,</div>
										<div class="chargeNumBox" v-else>{{ item }}</div>
									</div> -->
                  <div>
                    {{ chargeCount.downCost }}
                  </div>
                  <div style="margin-left: 5px">元</div>
                </div>
              </div>
            </el-box>
          </div>
        </div>
        <div class="echartBoxCenter">
          <p>收费折线统计图</p>
          <h4>收费折线统计图</h4>
          <!-- <el-select
            v-model="queryParams.deviceType"
            placeholder="所有设备"
            clearable
            size="small"
            class="float-width"
            @change="handleEchartBoxCenter"
          >
            <el-option
              v-for="dict in deviceOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
            />
          </el-select> -->
          <chargeEchart :chart-data="yearData"></chargeEchart>
        </div>
        <div class="echartBoxRight">
          <p>设备类别统计图</p>
          <h4>设备类别统计图</h4>
          <typeEchart :chart-data="typeData" :maxNum="typeMaxNum"></typeEchart>
        </div>
      </div>
    </el-back>
  </div>
</template>

<script>
import typeEchart from "./dashBoard/typeEchart.vue";
import chargeEchart from "./dashBoard/chargeEchart.vue";
import { selectPayRecordsCount } from "@/api/cashierDesk/charge.js";
import { toThousands } from "@/utils/index";
import { traverseTreeArray } from "@/utils/index";
import { treeselect } from "@/api/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "Echarts",
  data() {
    return {
      //设备类别
      deviceOptions: [],
      maxNum: 0,
      deptOptions: [],
      // 查询参数
      queryParams: {
        deptId: undefined,
        beginTime: undefined,
        deviceType: undefined,
      },
      chargeCount: {},
      yearData: [],
      typeData: [],
      typeMaxNum: 0,
    };
  },
  components: {
    typeEchart,
    chargeEchart,
    Treeselect,
  },
  created() {
    var date = new Date();
    this.queryParams.beginTime = date.getFullYear().toString(); //获取完整的年份(4位)
    this.getTreeselect();
    this.getList();
    this.getDicts("sys_device_type").then((response) => {
      this.deviceOptions = response;
    });
  },
  methods: {
    /** 查询单位列表 */
    getList() {
      selectPayRecordsCount(this.queryParams).then((response) => {
        /* 处理折线图 */
        let year = "",
          month = "",
          yearData = [];
        yearData = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ];
        // 获取当前月份的天数
        // var d = new Date(year, month, 0);
        // let days = d.getDate()
        // for(let i = 1;i<=days;i++){
        // 	if(i<10){
        // 		yearData.push('0' + i)
        // 	}else{
        // 		yearData.push(i)
        // 	}
        // }
        for (let j = 0; j < yearData.length; j++) {
          this.yearData.push({
            month: yearData[j],
            value: 0,
          });
          for (let i = 0; i < response.dateCount.length; i++) {
            if (response.dateCount[i].month == yearData[j]) {
              this.yearData[j].value = response.dateCount[i].cost;
              break;
            }
          }
        }
        /* 处理总收费数 */
        // let newCount = ''
        // if(response.cost == null) response.cost = ''
        // response.cost = String(response.cost)
        // if(response.cost.length > 7){
        // 	newCount = response.cost.splice(-7)
        // }else{
        // 	newCount = response.cost
        // 	for( let i = 0;i< ( 7 - response.cost.length );i++ ){
        // 		newCount = '0' + newCount
        // 	}
        // }
        // response.cost = toThousands(newCount)
        /* 处理线上收费 */
        // let onLineCharge = ''
        // if(response.upCost == null) response.upCost = ''
        // response.upCost = String(response.upCost)
        // if(response.upCost.length > 7){
        // 	onLineCharge = response.upCost.splice(-7)
        // }else{
        // 	onLineCharge = response.upCost
        // 	for( let i = 0;i< ( 7 - response.upCost.length );i++ ){
        // 		onLineCharge = '0' + onLineCharge
        // 	}
        // }
        // response.upCost = toThousands(onLineCharge)
        /* 处理线下收费 */
        // let downCharge = ''
        // if(response.downCost == null) response.downCost = ''
        // response.downCost = String(response.downCost)
        // if(response.downCost.length > 7){
        // 	downCharge = response.downCost.splice(-7)
        // }else{
        // 	downCharge = response.downCost
        // 	for( let i = 0;i< ( 7 - response.downCost.length );i++ ){
        // 		downCharge = '0' + downCharge
        // 	}
        // }
        // response.downCost = toThousands(downCharge)
        /* 处理类型图 */
        response.typeCount.forEach((item, index) => {
          /* if(item.typeCount>this.typeMaxNum){
							this.alarmTimerMaxNum = item.typeCount
						} */
          this.typeData.push({
            name: item.typeName,
            value: item.cost,
          });
        });

        this.chargeCount = response;
      });
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = traverseTreeArray(response, "deptName", "deptId");
      });
    },
    /** 搜索按钮操作 */
    handleQuery(val) {
      /* if( val == 'timeChange' && this.queryParams.timeFlag == 1){
					var date = new Date();				
					this.queryParams.time =  date .getFullYear().toString(); //获取完整的年份(4位)		
				}else if( val == 'timeChange' && this.queryParams.timeFlag == 0){
					var date = new Date();					
					this.queryParams.time =  date .getFullYear() + '-' + (date .getMonth() + 1)				
				} */

      this.yearData = [];
      this.typeData = [];
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.components-container {
  .EchartBigBox {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 20px;
      padding-top: 17px;
      padding-left: 20px;
    }
    h4 {
      text-align: center;
    }
    .echartBoxLeft {
      width: 419px;
      height: 581px;
      .chargeBox {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .chargeNumBox {
          width: 32px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background-color: rgba(52, 230, 254, 0.2);
          margin-left: 5px;
        }
      }
      .leftSmallBox {
        width: 100%;
        height: 180px;
        // border: 1px solid #34E6FE;
        // background-image: url('../../../assets/image/alarmEchartLeft.png');
        // background-size: 100% 100%;
      }
    }
    .echartBoxCenter {
      width: 657px;
      height: 581px;
      background-image: url("../../../assets/image/alarmEchartCenter.png");
      background-size: 100% 100%;
    }
    .echartBoxRight {
      width: 499px;
      height: 581px;
      background-image: url("../../../assets/image/alarmEchartRight.png");
      background-size: 100% 100%;
    }
  }
  .float-width {
    right: -60%;
  }
}
</style>
